import { ReactElement } from 'react';

import { DocumentDownloadTableButton, DocumentDownloadTableButtonProps } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { GridColDef, HaloDataGrid, HaloDataGridProps } from '@halodomination/halo-fe-common';
import { ITranslateParams } from '@transifex/native';

export type DocumentDownloadRowModel = {
  id: number | string;
  name: string;
  downloading?: boolean;
  onDownload?: DocumentDownloadTableButtonProps['handleDownload'];
  popover?: DocumentDownloadTableButtonProps['popover'];
  dynamicContent?: ITranslateParams;
};

export type DocumentDownloadTableProps = {
  documents?: Array<DocumentDownloadRowModel>;
  loading?: boolean;
};

export const DocumentDownloadTable = ({
  documents = [],
  loading = false,
}: DocumentDownloadTableProps): ReactElement => {
  const commonCellProps: Partial<GridColDef> = {
    align: 'left',
    editable: false,
    disableColumnMenu: true,
  };

  const columns: HaloDataGridProps['columns'] = [
    {
      ...commonCellProps,
      field: 'name',
      flex: 1,
      headerName: translations.common.name,
      translateCell: true,
    },
    {
      ...commonCellProps,
      field: 'actions',
      headerName: '',
      sortable: false,
      width: 138,
      renderCell: ({ row }) => (
        <DocumentDownloadTableButton handleDownload={row.onDownload} loading={row.downloading} popover={row.popover} />
      ),
    },
  ];

  return <HaloDataGrid rows={documents} columns={columns} loading={loading} hideFooter density="compact" />;
};
