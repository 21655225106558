import { ReactElement } from 'react';

import { CALENDAR_PREFERENCE_TAG } from '@halo-common/constants';
import { CalendarFilterTypeEnum, DynamicTypeFiltersSideBarFiltersEnum } from '@halo-common/enums';
import { DynamicFiltersLayout } from '@halo-common/layouts';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { CalendarExportButton, CalendarsList } from '@halo-modules/app';
import { MustCompleteOnboardingAlert } from '@halo-modules/app/onboarding/subPages/suitability';

export const CalendarsListPage = (): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();

  const pageFilters = [
    DynamicTypeFiltersSideBarFiltersEnum.myOffering,
    DynamicTypeFiltersSideBarFiltersEnum.settlementType,
    DynamicTypeFiltersSideBarFiltersEnum.calendarType,
    DynamicTypeFiltersSideBarFiltersEnum.calendarProductType,
    DynamicTypeFiltersSideBarFiltersEnum.nonCallPeriod,
    DynamicTypeFiltersSideBarFiltersEnum.productFeatures,
    DynamicTypeFiltersSideBarFiltersEnum.principalProtection,
    DynamicTypeFiltersSideBarFiltersEnum.underlyingCount,
    DynamicTypeFiltersSideBarFiltersEnum.termSlider,
    DynamicTypeFiltersSideBarFiltersEnum.calendarIssuer,
    DynamicTypeFiltersSideBarFiltersEnum.calendarAssetType,
    DynamicTypeFiltersSideBarFiltersEnum.currency,
  ];

  const filters =
    userInfo?.calendarPageFilterSettingSet?.settings
      .map((setting) => {
        const calendarFilter = CalendarFilterTypeEnum[setting.type];
        return DynamicTypeFiltersSideBarFiltersEnum[calendarFilter];
      })
      .filter((setting) => pageFilters.includes(setting)) ?? [];

  return (
    <>
      <MustCompleteOnboardingAlert feature={translations.calendars.common.calendars} />
      <DynamicFiltersLayout
        tag={CALENDAR_PREFERENCE_TAG}
        rules={{ approvedAssetsOnly: true }}
        slots={{ ExportButton: <CalendarExportButton /> }}
        filters={filters}
      >
        <CalendarsList />
      </DynamicFiltersLayout>
    </>
  );
};
