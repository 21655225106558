import { executionHubOrderTicketModalAtom, executionHubPDMModalAtom } from '@halo-atoms/executionHub';
import { SubNavLayout } from '@halo-common/layouts';
import { ExecutionCalendarDetailModal, ExecutionOrderTicketReviewModal } from '@halo-common/modals';
import {
  V2ExecutionHubCalendarList,
  V2ExecutionHubCancelModal,
  V2ExecutionHubEditReofferModal,
  V2ExecutionHubFillModal,
  V2ExecutionHubPageHeader,
} from '@halo-modules/admin';
import { PageComponent, PageMeta, Stack } from '@halodomination/halo-fe-common';
import { Container } from '@mui/material';
import { useAtom } from 'jotai';

const containerSx = {
  padding: 3,
};

const ExecutionHubV2: PageComponent = () => {
  const [previewModalState, setPreviewModalState] = useAtom(executionHubPDMModalAtom);
  const [orderTicketModal, setOrderTicketModal] = useAtom(executionHubOrderTicketModalAtom);

  const handleClosePreviewModal = () => {
    setPreviewModalState({ open: false });
  };

  const handleCloseExecutionOrderTicketModal = () => {
    setOrderTicketModal({ reset: true });
  };

  return (
    <>
      <SubNavLayout title="Execution Hub" hideTabs>
        <Container sx={containerSx} maxWidth="xl">
          <Stack direction="column" spacing={1}>
            <V2ExecutionHubPageHeader />
            <V2ExecutionHubCalendarList />
          </Stack>
        </Container>
      </SubNavLayout>
      <V2ExecutionHubFillModal />
      <V2ExecutionHubCancelModal />
      <V2ExecutionHubEditReofferModal />
      <ExecutionCalendarDetailModal
        open={previewModalState.open}
        productId={previewModalState.calendar?.id}
        onClose={handleClosePreviewModal}
        actionable={false}
      />
      <ExecutionOrderTicketReviewModal open={orderTicketModal.open} onClose={handleCloseExecutionOrderTicketModal} />
    </>
  );
};

export const ExecutionHubV2Meta: PageMeta = {
  pageName: 'ExecutionHubV2',
  route: '/admin/v2/calendars/execution-hub',
  routeExpression: '/admin/execution/order/calendar',
};

ExecutionHubV2.pageName = ExecutionHubV2Meta.pageName;
ExecutionHubV2.route = ExecutionHubV2Meta.route;

export default ExecutionHubV2;
