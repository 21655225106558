import { ReactElement } from 'react';

import { ActionButton } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { Iconography, LocalizedTypography, Stack, useCurrencyConverter } from '@halodomination/halo-fe-common';
import { Typography } from '@mui/material';

export type BulkAllocationFooterProps = {
  allocationCount?: number;
  allocationSum?: number;
  onAddAllocation?: () => void;
  onCSVUpload?: () => void;
  slots?: {
    totalLabel?: string;
    accountTotalLabel?: string;
  };
};

export const BulkAllocationFooter = ({
  allocationCount = 0,
  allocationSum = 0,
  onAddAllocation,
  onCSVUpload,
  slots,
}: BulkAllocationFooterProps): ReactElement => {
  const totalQuantity = useCurrencyConverter(allocationSum * 1000);

  const accountLabel = slots?.accountTotalLabel ?? translations.orderTicket.footerAccountTotal;
  const totalLabel = slots?.totalLabel ?? translations.orderTicket.footerNotionalTotal;

  const handleAddAllocation = () => onAddAllocation?.();
  const handleCSVUpload = () => onCSVUpload?.();

  const plusIcon = <Iconography iconName="plus" color="primary.main" />;
  const importIcon = <Iconography iconName="file-import" color="primary.main" />;

  const accountsCount = (
    <Typography component="span" fontWeight="bold">
      {allocationCount}
    </Typography>
  );

  const total = (
    <Typography component="span" fontWeight="bold">
      {totalQuantity}
    </Typography>
  );

  const uploadButton = onCSVUpload ? (
    <ActionButton variant="text" color="primary" startIcon={importIcon} onClick={handleCSVUpload}>
      {translations.orderTicket.uploadSpreadsheetButton}
    </ActionButton>
  ) : null;

  return (
    <Stack direction="row" spacing={2} xs={12} sm={6} alignItems="center">
      <Stack direction="row" spacing={2}>
        <ActionButton variant="text" color="primary" startIcon={plusIcon} onClick={handleAddAllocation}>
          {translations.orderTicket.addAccountButton}
        </ActionButton>
        {uploadButton}
      </Stack>
      <Stack direction="row" spacing={4} justify="end">
        <LocalizedTypography dynamicContent={{ count: accountsCount }}>{accountLabel}</LocalizedTypography>
        <LocalizedTypography dynamicContent={{ total }}>{totalLabel}</LocalizedTypography>
      </Stack>
    </Stack>
  );
};
