import { ReactElement } from 'react';

import { calendarSelectedAtom } from '@halo-atoms/calendars';
import { bulkOrderTicketReceiptAtom, orderTicketReceiptAtom, orderTicketStepAtom } from '@halo-atoms/orderTicket';
import { ActionButton } from '@halo-common/components';
import { useWebSocketContext } from '@halo-common/providers';
import { BulkOrderTicketFormFields, OrderTicketFormModelFields } from '@halo-common/schemas';
import { useOrderTicketSubmissionSwitch } from '@halo-data-sources/switches';
import { Stack } from '@halodomination/halo-fe-common';
import { Button } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { useFormContext } from 'react-hook-form';

export type OrderTicketReceiptFooterProps = {
  review?: boolean;
  onClose?: () => void;
};

export const OrderTicketReceiptFooter = ({ review, onClose }: OrderTicketReceiptFooterProps): ReactElement => {
  const { events } = useWebSocketContext();

  const { handleSubmit } = useFormContext<OrderTicketFormModelFields>();

  const calendar = useAtomValue(calendarSelectedAtom);
  const orderTicketReceipt = useAtomValue(orderTicketReceiptAtom);
  const handleBack = useSetAtom(orderTicketStepAtom.handlePrevAtom);
  const setBulkOrderTicketReceipt = useSetAtom(bulkOrderTicketReceiptAtom);

  const { mutate, isPending } = useOrderTicketSubmissionSwitch();

  const fixPending = events.fix.queue.length > 0;
  const isLoading = isPending || fixPending;

  const onSubmitBulkOrder = handleSubmit((formData) => {
    const data = { janney: orderTicketReceipt, pershing: formData as BulkOrderTicketFormFields };
    mutate({ calendar, data });
  });

  const onBack = () => {
    handleBack();
    setBulkOrderTicketReceipt();
  };

  if (!review) {
    return (
      <Stack direction="row" justify="flex-end">
        <Button type="button" variant="contained" color="primary" onClick={onClose}>
          Close
        </Button>
      </Stack>
    );
  }

  return (
    <Stack direction="row" justify="space-between">
      <Button type="button" variant="outlined" color="primary" onClick={onBack} disabled={isLoading}>
        Edit Allocations
      </Button>
      <ActionButton type="button" variant="contained" color="primary" onClick={onSubmitBulkOrder} loading={isLoading}>
        Acknowledge &amp; Submit Order
      </ActionButton>
    </Stack>
  );
};
