import { ReactElement } from 'react';

import { CalendarProductCardFavoriteBtn } from '@halo-common/components';
import { NoteTypeEnum } from '@halo-common/enums';
import {
  useCalendarIndicationButtonText,
  useCalendarSpecificDetails,
  useModalState,
  usePrimaryAssetIdentifier,
  useWhiteLabelDateConfiguration,
} from '@halo-common/hooks';
import { MUST_COMPLETE_ONBOARDING_MODAL } from '@halo-common/modals';
import { CalendarModel, CalendarPreviewModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { Iconography, LocalizedButton, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';

const containerSx = {
  width: 'fit-content',
  minWidth: 300,
  maxWidth: 500,
};

export type CalendarProductCardAction = (calendar: CalendarModel | CalendarPreviewModel, termsheetId?: number) => void;

export type CalendarProductCardActionsProps = {
  calendar: CalendarModel | CalendarPreviewModel;
  termsheetId?: number;
  actions?: {
    onDetails?: CalendarProductCardAction;
    onSubmitIndication?: CalendarProductCardAction;
    onOfferingDoc?: CalendarProductCardAction;
  };
};

export const CalendarProductCardActions = ({
  calendar,
  termsheetId,
  actions,
}: CalendarProductCardActionsProps): ReactElement => {
  const { activeAllocationCount, note, expirationDate, id, isFavorite, finalTerms, preliminaryTerms } = calendar;

  const type = note?.type;

  const { data: userInfo } = useUserInfoQuery();
  const { setOpen } = useModalState(MUST_COMPLETE_ONBOARDING_MODAL);

  const openOnboardingModal = () => setOpen(true);

  const { solvableParameter } = useCalendarSpecificDetails(calendar);
  const { identifier, identifierType, fallback } = usePrimaryAssetIdentifier(calendar);

  const configureDateTime = useWhiteLabelDateConfiguration();

  const parsedIdentifierText = identifier ? identifier : fallback.identifier;
  const parsedIdentifierType = identifier ? identifierType : fallback.identifierType;

  const todaysDateTime = configureDateTime(DateTime.utc().toISO()) as DateTime;
  const expirationDateTime = configureDateTime(expirationDate) ?? todaysDateTime;

  const hasQuoteLabel = Boolean(solvableParameter?.text);
  const hasAllocations = activeAllocationCount && activeAllocationCount > 0;
  const hasDocuments = finalTerms || preliminaryTerms;
  const isExpired = expirationDateTime < todaysDateTime;
  const isClosed = isExpired && !hasAllocations;
  const isCalendarGeneral = type === NoteTypeEnum.General;
  const isPayoffString = Boolean(calendar.payoffString);
  const isCaptionLabel = isPayoffString || (isCalendarGeneral && hasQuoteLabel);
  const isAllocationsButtonDisabled = !userInfo?.settings?.enableCalendarAllocations || isClosed;
  const showOfferingDocButton = Boolean(hasDocuments && actions?.onOfferingDoc);

  const label = hasQuoteLabel ? `${solvableParameter?.text}` : '-';
  const labelVariant = isCaptionLabel ? 'caption' : 'h2';
  const labelFontWeight = isCaptionLabel ? 'bold' : undefined;

  const indicationButtonText = useCalendarIndicationButtonText(activeAllocationCount, expirationDate);

  const isOnboardingCompleted = userInfo?.organization?.isOnboardingCompleted;

  const handleOfferingDocClick = () => {
    actions?.onOfferingDoc?.(calendar);
  };

  const handleSubmitAllocationsToggle = () => {
    if (!isOnboardingCompleted) openOnboardingModal();
    else actions?.onSubmitIndication?.(calendar, termsheetId);
  };

  const handleDetailsModalToggle = () => {
    actions?.onDetails?.(calendar, termsheetId);
  };

  const offeringDocButton = showOfferingDocButton ? (
    <LocalizedButton
      onClick={handleOfferingDocClick}
      size="small"
      color="primary"
      className="wm-productshelf-offeringdoc"
      startIcon={<Iconography color="primary.main" iconName="file-magnifying-glass" />}
    >
      {translations.calendars.common.offeringDoc}
    </LocalizedButton>
  ) : null;

  const submitIndicationsButton = actions?.onSubmitIndication ? (
    <LocalizedButton
      size="medium"
      fullWidth
      variant="contained"
      onClick={handleSubmitAllocationsToggle}
      disabled={isAllocationsButtonDisabled}
      className="wm-productshelf-submitindication"
    >
      {indicationButtonText}
    </LocalizedButton>
  ) : null;

  const detailButton = actions?.onDetails ? (
    <LocalizedButton
      size="medium"
      variant="text"
      onClick={handleDetailsModalToggle}
      className="wm-productshelf-opendetails"
    >
      {translations.common.details}
    </LocalizedButton>
  ) : null;

  const favoriteButton = id ? (
    <CalendarProductCardFavoriteBtn
      isFavorite={Boolean(isFavorite)}
      calendarId={id}
      assetIdentifier={parsedIdentifierText}
      assetIdentifierType={parsedIdentifierType}
      termsheetId={termsheetId}
    />
  ) : null;

  return (
    <Stack sx={containerSx} direction="column" justifyContent="space-between">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack
          sx={{ pr: { md: 2, xs: 0 }, width: '100%' }}
          direction={{ md: 'row', xs: 'column' }}
          justifyContent="space-between"
          alignItems={{ md: 'center' }}
        >
          {offeringDocButton}
          <Typography variant="body2" color="text.secondary">
            {parsedIdentifierText}
          </Typography>
        </Stack>
        {favoriteButton}
      </Stack>
      <Stack direction="column" sx={{ py: 2 }}>
        <LocalizedTypography variant={labelVariant} fontWeight={labelFontWeight} color="text.primary">
          {label}
        </LocalizedTypography>
        <LocalizedTypography variant="caption" color="text.secondary">
          {solvableParameter?.label}
        </LocalizedTypography>
      </Stack>
      <Stack direction={{ md: 'row', xs: 'column' }} spacing={2} justifyContent={{ md: 'flex-end' }}>
        {submitIndicationsButton}
        {detailButton}
      </Stack>
    </Stack>
  );
};
