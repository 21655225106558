import { ConditionalField, Input } from '@halo-common/components/FormBuilder';
import { translations } from '@halo-common/translations';
import { OnboardingSuitabilityForm } from '@halo-modules/app/onboarding/subPages/suitability/components';
import { SuitabilityStepModel } from '@halo-modules/app/onboarding/subPages/suitability/types';
import { Iconography, LocalizedButton, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Divider } from '@mui/material';
import { useFormContext } from 'react-hook-form';

const suitability = translations.onboarding.suitability;

const ContactFields = ({ prefix }: { prefix: string }) => (
  <>
    <Input label={suitability.fullName} name={`${prefix}.name`} />
    <Input label={suitability.position} name={`${prefix}.position`} />
    <Input label={suitability.phoneNumber} name={`${prefix}.phone_number`} />
    <Input label={suitability.emailAddress} name={`${prefix}.email_address`} />
  </>
);

const AddSecondaryContactButton = () => {
  const form = useFormContext();
  const addSecondaryContact = () => form.setValue('hasSecondaryContact', true);
  return (
    <LocalizedButton
      variant="outlined"
      startIcon={<Iconography iconName="plus" />}
      fullWidth
      onClick={addSecondaryContact}
    >
      {suitability.addSecondaryContact}
    </LocalizedButton>
  );
};

const RemoveSecondaryContactButton = () => {
  const form = useFormContext();
  const removeSecondaryContact = () => form.setValue('hasSecondaryContact', false);
  return (
    <LocalizedButton
      variant="text"
      startIcon={<Iconography iconName="trash" />}
      fullWidth
      onClick={removeSecondaryContact}
    >
      {suitability.removeSecondaryContact}
    </LocalizedButton>
  );
};

const PrincipalContactsForm = () => (
  <OnboardingSuitabilityForm>
    <LocalizedTypography variant="caption" color="text.secondary">
      {suitability.primaryContact}
    </LocalizedTypography>
    <ContactFields prefix="firm_details.principal_contacts.contact_1" />
    <Divider />
    <ConditionalField field="hasSecondaryContact" fallback={<AddSecondaryContactButton />}>
      <LocalizedTypography variant="caption" color="text.secondary">
        {suitability.secondaryContact}
      </LocalizedTypography>
      <ContactFields prefix="firm_details.principal_contacts.contact_2" />
      <Divider />
      <RemoveSecondaryContactButton />
    </ConditionalField>
  </OnboardingSuitabilityForm>
);

export const principalContactsStep: SuitabilityStepModel = {
  id: '1-4',
  title: suitability.whoAreThePrincipalContactsAtTheFirm,
  subtitle: suitability.theseAreThePeopleWeWillContactWithAnyQuestionsOrIssues,
  component: <PrincipalContactsForm />,
  isCompleted: (formData) => {
    const principalContact = formData?.firm_details?.principal_contacts?.contact_1 || {};
    return Boolean(
      principalContact.name &&
        principalContact.position &&
        (principalContact.phone_number || principalContact.email_address),
    );
  },
};
