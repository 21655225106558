import { OrganizationOnboardingStatusEnum } from '@halo-common/enums';
import { OrganizationDateFormat, OrganizationModel } from '@halo-common/models';
import { CreateOrgFormFields } from '@halo-common/schemas/createOrgFormSchema';
import { ApiRelationshipManagerMapper } from '@halo-data-sources/mappers';
import {
  ApiOrganizationDateFormat,
  ApiOrganizationModel,
  CreateOrganizationRequestModel,
} from '@halo-data-sources/models';

const isOnboardingCompleted = (onboardingStatus: OrganizationOnboardingStatusEnum) =>
  onboardingStatus === OrganizationOnboardingStatusEnum.FORM_NOT_APPLICABLE ||
  onboardingStatus === OrganizationOnboardingStatusEnum.ONBOARDED;

export const organizationDateFormatMap: Record<ApiOrganizationDateFormat, OrganizationDateFormat> = {
  'MM-DD-YY': 'LL-dd-yy',
  'DD-MM-YY': 'dd-LL-yy',
  'YY-MM-DD': 'yy-LL-dd',
  'MM/DD/YY': 'LL/dd/yy',
  'DD/MM/YY': 'dd/LL/yy',
  'YY/MM/DD': 'yy/LL/dd',
  'MM.DD.YY': 'LL.dd.yy',
  'DD.MM.YY': 'dd.LL.yy',
  'YY.MM.DD': 'yy.LL.dd',
  'MM DD YY': 'LL dd yy',
  'YY MM DD': 'yy LL dd',
  'MMM DD, YYYY': 'LLL dd, yyyy',
  'MMMM DD, YYYY': 'LLLL dd, yyyy',
  'DD MMM YYYY': 'dd LLL yyyy',
  'DD MMMM YYYY': 'dd LLLL yyyy',
  'YYYY MMM DD': 'yyyy LLL dd',
  'YYYY MMMM DD': 'yyyy LLLL dd',
};

export const ApiOrganizationDateFormatMapper = {
  toDateFormat: (model: ApiOrganizationDateFormat): OrganizationDateFormat => {
    if (organizationDateFormatMap[model]) return organizationDateFormatMap[model];
    return 'LL/dd/yy';
  },
};

export const ApiOrganizationMapper = {
  toOrganization: (model: ApiOrganizationModel): OrganizationModel => {
    const relationshipManager = model.default_relationship_manager
      ? ApiRelationshipManagerMapper.toRelationshipManager(model.default_relationship_manager)
      : undefined;

    const annuityRelationshipManager = model.annuity_relationship_manager
      ? ApiRelationshipManagerMapper.toRelationshipManager(model.annuity_relationship_manager)
      : undefined;

    return {
      id: model.id,
      name: model.name,
      maxCommission: model.max_commission,
      calendarPageId: model.calendar_page_id,
      capacities: model.capacities,
      dateFormat: ApiOrganizationDateFormatMapper.toDateFormat(model.date_format),
      relationshipManager,
      requiresAttestation: model.requires_attestation,
      canWriteAuctionDetails: model.can_write_auction_details,
      onboardingStatus: model.onboarding_status,
      isOnboardingCompleted: isOnboardingCompleted(model.onboarding_status),
      annuityRelationshipManager,
    };
  },
};

export const OrganizationMapper = {
  toCreateOrganizationRequest: (model: CreateOrgFormFields): CreateOrganizationRequestModel => {
    return {
      company_name: model.name,
      email_suffix: model.emailSuffix,
      relationship_manager_id: model.relationshipManager,
    };
  },
};
