import { ReactElement, useMemo } from 'react';

import type { NoteflixNoteModel } from '@halo-common/models';
import { useNoteflixNotesQuery, useRecentQuotesQuery } from '@halo-data-sources/queries';
import { NoteflixEmptyMessage, NoteflixSkeleton, NotesCarousel, renderNoteCardSkeletons } from '@halo-modules/app';

import { NoteflixNoteCard } from './NoteflixNoteCard';

export type NoteflixDetailsProps = {
  id: number;
  notes?: Array<NoteflixNoteModel> | null;
  notesFrom?: number;
  enableCategoryQuotes?: boolean;
};

export const NoteflixDetails = ({
  id,
  notes: passedNotes,
  notesFrom = 0,
  enableCategoryQuotes = false,
}: NoteflixDetailsProps): ReactElement => {
  const { data, isPending, isFetched, isFetchingNextPage, hasNextPage, fetchNextPage } = useNoteflixNotesQuery(id, {
    from: notesFrom,
  });

  const notes = useMemo(() => {
    const fetchedNotes = data?.pages.flatMap((page) => page?.items ?? []) ?? [];
    const notesArray = [passedNotes, fetchedNotes].flat() as Array<NoteflixNoteModel>;
    if (enableCategoryQuotes) return notesArray;
    return notesArray.map((note) => ({ ...note, quote: undefined }));
  }, [data, enableCategoryQuotes]);

  const noteIds = useMemo(() => notes.map((note) => note.id), [notes]);

  const { isLoading: isRecentQuotesLoading } = useRecentQuotesQuery(noteIds);

  const isLoading = isPending || isRecentQuotesLoading;

  if (isLoading) return <NoteflixSkeleton />;

  const isEmpty = isFetched && notes.length === 0;

  if (isEmpty) return <NoteflixEmptyMessage />;

  const lazyLoadNextPage = (currentSlide: number) => {
    const loadingOffset = 4;
    const scrolledToTheEnd = currentSlide >= notes.length - loadingOffset;
    const shouldLoadNextPage = scrolledToTheEnd && hasNextPage && !isFetchingNextPage;
    if (shouldLoadNextPage) void fetchNextPage();
  };

  const hasRibbonNotes = notes.some((note) => note.ribbon);
  const skeletons = isFetchingNextPage ? renderNoteCardSkeletons({ ribbon: true }) : null;

  return (
    <NotesCarousel afterChange={lazyLoadNextPage}>
      {notes.map((note) => (
        <NoteflixNoteCard
          key={note.id}
          note={note}
          hasRibbonNotes={hasRibbonNotes}
          enableCategoryQuotes={enableCategoryQuotes}
        />
      ))}
      {skeletons}
    </NotesCarousel>
  );
};
