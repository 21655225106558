import { MouseEvent, ReactElement, useRef, useState } from 'react';

import { anchorElAtom } from '@halo-atoms/common';
import {
  ProductCardAction,
  ProductCardAnnuityDetails,
  ProductCardAuctionDetails,
  ProductCardNoteDetails,
  ProductCardNoteExpandedDetails,
} from '@halo-common/components';
import { ProductActionTypeEnum, ProductTypeEnum } from '@halo-common/enums';
import type {
  AnnuityModel,
  QuoteAuctionModel,
  AuctionBuyerModel,
  NoteModel,
  ProductModel,
  QuoteModel,
} from '@halo-common/models';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Box, Paper, Skeleton } from '@mui/material';
import { useAtomValue } from 'jotai';

const CARD_WIDTH = 360;

const cardFoldSx = {
  position: 'absolute',
  right: 0,
  bottom: 0,
  width: 0,
  height: 0,
  minWidth: 'unset',
  backgroundColor: 'transparent',
  padding: 0,
  borderRight: 0,
  borderTop: 0,
  borderRadius: '24px 0 24px',
  borderBottom: '23px solid',
  borderBottomColor: (theme: HaloTheme) => theme.palette.grey[300],
  borderLeft: '23px solid',
  borderLeftColor: 'common.white',
  boxShadow: '-2px -2px 4px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.5s',
  '&:hover': {
    cursor: 'pointer',
    borderLeftWidth: 26,
    borderBottomWidth: 26,
    borderRadius: '30px 0 26px',
  },
};

interface ProductCardDefaultProps {
  action?: ProductActionTypeEnum;
  clickable?: boolean;
  isLoading?: boolean;
  onClick?: (id: number, type: ProductTypeEnum, product: ProductModel) => void;
  overrides?: {
    quote?: QuoteModel | QuoteAuctionModel | null;
    hideEmptyQuote?: boolean;
  };
}

interface ProductCardNoteProps extends ProductCardDefaultProps {
  product?: NoteModel | null;
  type: ProductTypeEnum.note;
}

interface ProductCardAuctionProps extends ProductCardDefaultProps {
  product?: AuctionBuyerModel | null;
  type: ProductTypeEnum.auction;
}

interface ProductCardAnnuityProps extends ProductCardDefaultProps {
  product?: AnnuityModel | null;
  type: ProductTypeEnum.annuity;
}

export type ProductCardProps = ProductCardNoteProps | ProductCardAuctionProps | ProductCardAnnuityProps;

export const ProductCard = ({
  type,
  product,
  action = ProductActionTypeEnum.watch,
  isLoading = false,
  onClick,
  overrides,
}: ProductCardProps): ReactElement => {
  const cardHeightRef = useRef<HTMLDivElement | null>(null);

  const anchorElMap = useAtomValue(anchorElAtom);

  const [state, setState] = useState({ peeled: false, refresh: false });

  const { peeled, refresh } = state;

  const cardBorderRadiusSx = {
    borderRadius: 3,
  };

  const containerSx = {
    ...cardBorderRadiusSx,
    backgroundColor: peeled ? 'grey.200' : 'common.white',
    boxShadow: (theme: HaloTheme) => theme.shadows[3],
    height: 'fit-content',
    minHeight: 280,
    padding: 2,
    position: 'relative',
    transition: 'all .2s',
    width: CARD_WIDTH,
    '&:hover': {
      boxShadow: (theme: HaloTheme) => theme.shadows[24],
      cursor: 'pointer',
      transform: `translateY(-2px)`,
    },
  };

  const cardBackSx = {
    height: '100%',
    width: `calc(${CARD_WIDTH}px - 32px)`,
  };

  const annuity = type === ProductTypeEnum.annuity ? product : undefined;
  const auction = type === ProductTypeEnum.auction ? product : undefined;
  const note = type === ProductTypeEnum.note ? product : undefined;

  const cardBackProduct = note ?? auction?.note;
  const overriddenQuote = overrides?.quote;
  const canRefreshQuote = !overriddenQuote && !peeled;

  const walkMeIds = ['wm-product-card'];
  if (note) walkMeIds.push('wm-product-card-note');
  else if (annuity) walkMeIds.push('wm-product-card-annuity');
  const walkMeClassName = walkMeIds.join(' ');

  const handleClick = () => {
    const propagate = product?.id && !anchorElMap[product.id];
    if (propagate) onClick?.(product.id, type, product);
  };

  const handleShowRefresh = canRefreshQuote ? () => void setState({ ...state, refresh: true }) : undefined;
  const handleHideRefresh = canRefreshQuote ? () => void setState({ ...state, refresh: false }) : undefined;

  const handlePeel = (ev: MouseEvent) => {
    ev?.stopPropagation();
    setState({ ...state, peeled: true });
  };

  const handleUnPeel = (ev: MouseEvent) => {
    ev?.stopPropagation();
    setState({ ...state, peeled: false });
  };

  const actions = <ProductCardAction productId={product?.id} productType={type} action={action} />;

  const annuityDetails = annuity ? <ProductCardAnnuityDetails action={actions} annuity={annuity} /> : null;
  const auctionDetails = auction ? <ProductCardAuctionDetails action={actions} auction={auction} /> : null;
  const noteDetails = note ? (
    <ProductCardNoteDetails action={actions} note={note} overrides={overrides} showRefresh={refresh} />
  ) : null;

  const cardDetails = noteDetails ?? auctionDetails ?? annuityDetails;
  const cardBackOverrides = { quote: auction?.winningQuote?.value };
  const cardBackDetails = cardBackProduct ? (
    <ProductCardNoteExpandedDetails note={cardBackProduct} overrides={cardBackOverrides} onBack={handleUnPeel} />
  ) : null;

  const showPeel = type === ProductTypeEnum.note && !peeled;
  const peel = showPeel ? (
    <Box sx={cardFoldSx} className="wm-product-card-flip" component="button" onClick={handlePeel} type="button" />
  ) : null;

  const cardFront = <Box ref={cardHeightRef}>{cardDetails}</Box>;
  const cardBack = <Box sx={cardBackSx}>{cardBackDetails}</Box>;

  const content = peeled ? cardBack : cardFront;

  return isLoading ? (
    <Skeleton variant="rounded" width={350} height={300} sx={cardBorderRadiusSx} />
  ) : (
    <Paper
      sx={containerSx}
      className={walkMeClassName}
      onClick={handleClick}
      onMouseEnter={handleShowRefresh}
      onMouseLeave={handleHideRefresh}
      variant="outlined"
    >
      {content}
      {peel}
    </Paper>
  );
};
