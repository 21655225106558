import { ReactElement } from 'react';

import { useUserInfoQuery } from '@halo-data-sources/queries';
import { EducationBanner, EducationModuleGroup, EducationModuleGroupProps, useCourseManager } from '@halo-modules/app';
import { Stack, Container } from '@mui/material';

export type EducationLandingProps = {
  loading: boolean;
};

export const EducationLanding = (): ReactElement => {
  const { data } = useUserInfoQuery();
  const showEducationBanner = data?.settings.showEducationBanner;

  const { courseGroups, courseCompletion, onStart, onContinue, onSelection } = useCourseManager();

  const banner = showEducationBanner ? <EducationBanner progress={courseCompletion} /> : null;

  return (
    <Stack direction="column" alignItems={'center'}>
      <Container maxWidth="lg">
        <Stack direction="column" gap={2}>
          {banner}
          {courseGroups.map((group) => {
            const handleStart: EducationModuleGroupProps['onStart'] = (reason) =>
              reason === 'continue' ? onContinue(group) : onStart(group);

            const handleSelection: EducationModuleGroupProps['onSelection'] = (module, step) =>
              onSelection(group, module, step);

            return (
              <EducationModuleGroup
                key={group.id}
                title={group.title}
                subtitle={group.description}
                progress={group.percentageComplete}
                modules={group.modules}
                onStart={handleStart}
                onSelection={handleSelection}
              />
            );
          })}
        </Stack>
      </Container>
    </Stack>
  );
};
