import { ToastWebsocketEvent, ToastWebsocketEventLevelEnum } from '@halo-common/hooks';
import { atom } from 'jotai';

type tableRow = {
  products: number;
  pendingProducts: number;
};

export type LoadingDockUploadModel = {
  total: number;
  processed: number;
  rows: {
    added: tableRow;
    edited: tableRow;
    errors: tableRow;
    total: tableRow;
  };
};

export const initialLoadingDockUploadAtom = {
  total: 0,
  processed: 0,
  rows: {
    added: { products: 0, pendingProducts: 0 },
    edited: { products: 0, pendingProducts: 0 },
    errors: { products: 0, pendingProducts: 0 },
    total: { products: 0, pendingProducts: 0 },
  },
};

const _loadingDockUploadAtom = atom<LoadingDockUploadModel>(initialLoadingDockUploadAtom);

export const loadingDockUploadAtom = atom(
  (get) => get(_loadingDockUploadAtom),
  (get, set, payload?: ToastWebsocketEvent) => {
    if (!payload) {
      set(_loadingDockUploadAtom, {
        ...initialLoadingDockUploadAtom,
        rows: {
          added: { products: 0, pendingProducts: 0 },
          edited: { products: 0, pendingProducts: 0 },
          errors: { products: 0, pendingProducts: 0 },
          total: { products: 0, pendingProducts: 0 },
        },
      });
      return;
    }

    const prev = get(_loadingDockUploadAtom);

    const updatedRows = { ...prev.rows };
    const data = payload.data;

    if (data && data?.is_pending !== undefined) {
      const column = data.is_pending ? 'pendingProducts' : 'products';
      if (data.is_added) updatedRows.added[column] += 1;
      if (data.is_edited) updatedRows.edited[column] += 1;

      if (payload.level === ToastWebsocketEventLevelEnum.ERROR) updatedRows.errors[column] += 1;

      updatedRows.total[column] += 1;
    }

    set(_loadingDockUploadAtom, {
      ...prev,
      total: data?.total ?? prev.total,
      processed: data?.processed ?? prev.processed,
      rows: updatedRows,
    });
  },
);
