import { selectedWatchlistNoteProductAtom } from '@halo-atoms/watchlists';
import { useModalState } from '@halo-common/hooks';
import { PageFooterLayout, SubNavLayout } from '@halo-common/layouts';
import {
  CalendarAllocationsModal,
  ConfigureWidgetsModal,
  MustCompleteOnboardingModal,
  PreTradeNoteDetailModal,
} from '@halo-common/modals';
import { NoteflixNoteModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import {
  AddAccountOrPositionModal,
  AddCustodianPositionsModal,
  AddPositionsToAccountModal,
  AddPositionToMultipleAccountsModal,
  CalendarDetailsModal,
  CalendarPdfViewerModal,
  FixedIncomeAllocationsModal,
  FixedIncomeDetailsModal,
  PortfolioAddAccountModal,
} from '@halo-modules/app';
import {
  OnboardingCard,
  OpenCustomizeWidgetsModalButton,
  WidgetsDashboard,
} from '@halo-modules/app/dashboard/components';
import { useDashboardConfigQuery } from '@halo-modules/app/dashboard/hooks';
import { FeaturedNotes } from '@halo-modules/app/dashboard/widgets/FeaturedNotes';
import { PageComponent, PageMeta } from '@halodomination/halo-fe-common';
import { Box, CircularProgress, Container, Stack } from '@mui/material';
import { useAtomValue } from 'jotai';

const containerSx = {
  py: 8,
};

const loaderSx = {
  py: 2,
  width: '100%',
};

const NoteDetailsModal = () => {
  const { open, setOpen } = useModalState('pdm');

  const selectedNoteModel = useAtomValue(selectedWatchlistNoteProductAtom);
  const selectedQuote = (selectedNoteModel as NoteflixNoteModel)?.quote;

  const onClose = () => setOpen(false);

  return (
    <PreTradeNoteDetailModal
      open={open}
      onClose={onClose}
      product={selectedNoteModel}
      options={{ disableAnalyticsAlerts: true, documentQuoteOverride: selectedQuote }}
    />
  );
};

const DashboardPage: PageComponent = () => {
  const { data: userInfo, isPending, featuredNotesWidget } = useDashboardConfigQuery();

  const firstName = userInfo?.details?.firstName;
  const description = translations.dashboard.common.heresTodaysSummary;
  const Localization = { title: { dynamicContent: { firstName } } };

  const showFeaturedNotes = featuredNotesWidget && featuredNotesWidget.visibleByDefault === true;
  const isOnboardingCompleted = userInfo?.details?.organization?.isOnboardingCompleted;

  const featuredNotes = showFeaturedNotes ? <FeaturedNotes /> : null;
  const subNavRightContent = !isOnboardingCompleted ? <OnboardingCard /> : <OpenCustomizeWidgetsModalButton />;

  const content = isPending ? (
    <Stack direction="column" alignItems="center" justifyContent="center" sx={loaderSx}>
      <CircularProgress />
    </Stack>
  ) : (
    <SubNavLayout
      title={translations.dashboard.common.welcome}
      description={description}
      Localization={Localization}
      right={subNavRightContent}
      hideContentPadding
      hideTabs
    >
      {featuredNotes}
      <Box sx={containerSx}>
        <Container maxWidth="xl">
          <WidgetsDashboard />
        </Container>
      </Box>
    </SubNavLayout>
  );

  return (
    <>
      <PageFooterLayout>{content}</PageFooterLayout>
      <AddAccountOrPositionModal />
      <AddPositionToMultipleAccountsModal />
      <AddPositionsToAccountModal />
      <AddCustodianPositionsModal />
      <CalendarAllocationsModal />
      <CalendarDetailsModal />
      <CalendarPdfViewerModal />
      <ConfigureWidgetsModal />
      <FixedIncomeAllocationsModal />
      <FixedIncomeDetailsModal />
      <NoteDetailsModal />
      <PortfolioAddAccountModal />
      <MustCompleteOnboardingModal />
    </>
  );
};

export const DashboardPageMeta: PageMeta = {
  pageName: 'Dashboard',
  route: 'app/dashboard',
  routeExpression: '/user/dashboard',
};

DashboardPage.pageName = DashboardPageMeta.pageName;
DashboardPage.route = DashboardPageMeta.route;

export default DashboardPage;
