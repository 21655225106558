import { modalAtom } from '@halo-atoms/common';
import { CalendarOrderCategoryEnum } from '@halo-common/enums';
import { atom } from 'jotai';

type CreateCalendarModalPayload = {
  noteId?: number;
  displayName?: string;
  issuerId?: number;
  cusip?: number;
  category?: CalendarOrderCategoryEnum;
  closeDate?: string;
  quote?: number;
  createModalOpen?: boolean;
  previewModalOpen?: boolean;
};

const CREATE_CALENDAR_PREVIEW_MODAL_NAME = 'createCalendarPreviewModal';
const CREATE_CALENDAR_MODAL_NAME = 'createCalendarModal';

export const _createCalendarNoteIdAtom = atom<number | null>(null);
export const _createCalendarDisplayNameAtom = atom<string | null>(null);
export const _createCalendarCUSIPAtom = atom<number | null>(null);
export const _createCalendarIssuerIdAtom = atom<number | null>(null);
export const _createCalendarCategoryAtom = atom<CalendarOrderCategoryEnum | null>(null);
export const _createCalendarCloseDateAtom = atom<string | null>(null);
export const _createCalendarQuoteAtom = atom<number | null>(null);

export const createCalendarModalAtom = atom(
  (get) => {
    const noteId = get(_createCalendarNoteIdAtom);
    const displayName = get(_createCalendarDisplayNameAtom);
    const cusip = get(_createCalendarCUSIPAtom);
    const issuerId = get(_createCalendarIssuerIdAtom);
    const category = get(_createCalendarCategoryAtom);
    const closeDate = get(_createCalendarCloseDateAtom);
    const quote = get(_createCalendarQuoteAtom);
    const modalMap = get(modalAtom);

    return {
      noteId,
      displayName,
      cusip,
      issuerId,
      category,
      closeDate,
      quote,
      createModalOpen: modalMap[CREATE_CALENDAR_MODAL_NAME],
      previewModalOpen: modalMap[CREATE_CALENDAR_PREVIEW_MODAL_NAME],
    };
  },
  (_, set, payload?: CreateCalendarModalPayload) => {
    const noteId = payload?.noteId;
    const displayName = payload?.displayName;
    const cusip = payload?.cusip;
    const issuerId = payload?.issuerId;
    const category = payload?.category;
    const closeDate = payload?.closeDate;
    const quote = payload?.quote;
    const createModalOpen = payload?.createModalOpen;
    const previewModalOpen = payload?.previewModalOpen;

    if (noteId !== undefined) set(_createCalendarNoteIdAtom, noteId);
    if (displayName !== undefined) set(_createCalendarDisplayNameAtom, displayName);
    if (cusip !== undefined) set(_createCalendarCUSIPAtom, cusip);
    if (issuerId !== undefined) set(_createCalendarIssuerIdAtom, issuerId);
    if (category !== undefined) set(_createCalendarCategoryAtom, category);
    if (closeDate !== undefined) set(_createCalendarCloseDateAtom, closeDate);
    if (quote !== undefined) set(_createCalendarQuoteAtom, quote);
    if (createModalOpen !== undefined) {
      set(modalAtom, { [CREATE_CALENDAR_MODAL_NAME]: createModalOpen });
    }
    if (previewModalOpen !== undefined) set(modalAtom, { [CREATE_CALENDAR_PREVIEW_MODAL_NAME]: previewModalOpen });

    if (!payload) {
      set(_createCalendarNoteIdAtom, null);
      set(_createCalendarDisplayNameAtom, null);
      set(_createCalendarCUSIPAtom, null);
      set(_createCalendarIssuerIdAtom, null);
      set(_createCalendarCategoryAtom, null);
      set(_createCalendarCloseDateAtom, null);
      set(_createCalendarQuoteAtom, null);
      set(modalAtom, { [CREATE_CALENDAR_MODAL_NAME]: false });
      set(modalAtom, { [CREATE_CALENDAR_PREVIEW_MODAL_NAME]: false });
    }
  },
);
