import { ReactElement, SyntheticEvent, useEffect } from 'react';

import { UserRegionModel } from '@halo-common/models';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { RegisterFormSchema } from '@halo-modules/app/onboarding';
import { LocalizedTextField } from '@halodomination/halo-fe-common';
import { Autocomplete, capitalize } from '@mui/material';
import { useT } from '@transifex/react';
import { useController, useFormContext } from 'react-hook-form';

export type RegionSelectPropModel = {
  country: number;
};

export const RegionSelect = ({ country }: RegionSelectPropModel): ReactElement | null => {
  const translator = useT();

  const { data: user } = useUserInfoQuery();

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<RegisterFormSchema>();

  const {
    field: { onChange, value, ...field },
  } = useController({ name: 'region', control });

  const selectedCountry = user?.geographicSettingSet?.countries.find(({ id }) => id === country);
  const regions = selectedCountry?.regions ?? [];
  const sortedRegions = regions.sort((a, b) => a.name.localeCompare(b.name));

  const regionValue = regions.find((region) => region.id === value);
  const regionName = selectedCountry?.regionName ?? 'Region';
  const label = capitalize(regionName);

  const selectHandler = (_: SyntheticEvent, data: string | UserRegionModel | null) => {
    const selectedValue = typeof data === 'string' ? data : data?.name;
    const selectedState = regions.find((region) => region.name === selectedValue);
    onChange(selectedState?.id);
  };

  const getOptionLabel = (option: UserRegionModel) => {
    const label = translator(option.name);
    return label;
  };

  const getOptionEquality = (option: UserRegionModel, val: UserRegionModel) => {
    return option.id === val.id;
  };

  useEffect(() => {
    if (regions.length === 1) {
      setValue('region', regions[0].id);
    }
  }, [user]);

  const dynamicContent = errors?.region && label ? { helperText: { regionLabel: label } } : undefined;
  const helperText = errors?.region ? errors?.region?.message : undefined;

  return regions.length > 1 ? (
    <Autocomplete
      {...field}
      openOnFocus
      autoSelect
      value={regionValue}
      options={sortedRegions}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={getOptionEquality}
      onChange={selectHandler}
      renderInput={(params) => (
        <LocalizedTextField
          {...params}
          size="large"
          label={label}
          dynamicContent={dynamicContent}
          fullWidth
          helperText={helperText}
          error={Boolean(errors?.region)}
        />
      )}
    />
  ) : null;
};
