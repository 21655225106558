import { ReactElement, useEffect } from 'react';

import { calendarSelectedAtom } from '@halo-atoms/calendars';
import { ConfirmationEnum } from '@halo-common/enums';
import { useOrderTicketDesignation, useOrderTicketSalesPrice } from '@halo-common/hooks';
import { BulkOrderTicketFormFields } from '@halo-common/schemas';
import { CurrencyInput, LocalizedTextField } from '@halodomination/halo-fe-common';
import { Box, TextField } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useFormContext } from 'react-hook-form';

import { DiscretionDropdown, SolicitedDropdown, TTORepCodeTypeAhead } from '../../../../SubmissionSectionFields';
import { BulkAllocationInput } from '../../../BulkAllocationInput';

const disabledInputSx = {
  input: {
    sx: {
      backgroundColor: 'grey.100',
      borderColor: 'grey.300',
    },
  },
};

const additionalFieldSx = {
  flexBasis: '25%',
};

export type JanneyBulkAllocationInputProps = {
  index: number;
  onDelete: () => void;
};

export const JanneyBulkAllocationInput = ({ index, onDelete }: JanneyBulkAllocationInputProps): ReactElement => {
  const { setValue, watch } = useFormContext<BulkOrderTicketFormFields>();

  const selectedCalendar = useAtomValue(calendarSelectedAtom);

  const accountFieldName = `allocations.${index}.accountOption` as const;
  const quantityFieldName = `allocations.${index}.quantity` as const;
  const capacityTypeFieldName = `allocations.${index}.capacityType` as const;
  const discretionFieldName = `allocations.${index}.discretion` as const;
  const solicitedFieldName = `allocations.${index}.solicited` as const;
  const ttoRepCodeFieldName = `allocations.${index}.ttoRepCode` as const;

  const [accountField, quantity] = watch([accountFieldName, quantityFieldName]);
  const account = accountField?.account;

  const { label: designation, isAdvisoryAccount, isBrokerageAccount } = useOrderTicketDesignation(account);

  const salesPrice = useOrderTicketSalesPrice(selectedCalendar, account, quantity);
  const salesCreditValue = isAdvisoryAccount ? '0' : isBrokerageAccount ? salesPrice : '';

  const designationSlotProps = {
    ...disabledInputSx,
    inputLabel: {
      shrink: Boolean(designation),
    },
  };

  const discretionDropdown = isAdvisoryAccount ? (
    <DiscretionDropdown name={discretionFieldName} defaultValue={ConfirmationEnum.No} />
  ) : null;

  const ttoRepCodeTypeAhead = isBrokerageAccount ? <TTORepCodeTypeAhead name={ttoRepCodeFieldName} /> : null;
  const emptyField = <TextField disabled size="large" slotProps={disabledInputSx} />;
  const conditionalField = discretionDropdown ?? ttoRepCodeTypeAhead ?? emptyField;

  useEffect(() => {
    if (designation) setValue(capacityTypeFieldName, designation, { shouldDirty: true });
  }, [designation]);

  return (
    <BulkAllocationInput index={index} status="idle" onDelete={onDelete} density="condensed">
      <Box sx={additionalFieldSx}>
        <LocalizedTextField label="Type" value={designation} disabled size="large" slotProps={designationSlotProps} />
      </Box>
      <Box sx={additionalFieldSx}>
        <SolicitedDropdown name={solicitedFieldName} />
      </Box>
      <Box sx={additionalFieldSx}>{conditionalField}</Box>
      <Box sx={additionalFieldSx}>
        <CurrencyInput
          label="Sales Credit"
          value={salesCreditValue}
          disabled
          size="large"
          slotProps={disabledInputSx}
        />
      </Box>
    </BulkAllocationInput>
  );
};
