import { ReactElement } from 'react';

import { CalendarProductCardDetailColumn } from '@halo-common/components';
import { CalendarOrderCategoryEnum } from '@halo-common/enums';
import { useCalendarSpecificDetails } from '@halo-common/hooks';
import { CalendarModel, CalendarPreviewModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { ApiCalendarPriceDisplayEnum } from '@halo-data-sources/enums/calendars';
import { Stack } from '@mui/material';

export type CalendarProductCardDetailRowProps = {
  calendar: CalendarModel | CalendarPreviewModel;
  category?: CalendarOrderCategoryEnum;
  price: number | null;
  priceDisplay: ApiCalendarPriceDisplayEnum | null;
};

export const CalendarProductCardDetailRow = ({
  calendar,
  category,
  price,
  priceDisplay,
}: CalendarProductCardDetailRowProps): ReactElement => {
  const { highlights, term, protectionDetails } = useCalendarSpecificDetails(calendar);

  const termText = term?.text;
  const protection = protectionDetails?.[0];
  const protectionText = protection?.text ?? '-';

  const isBrokerage = category === CalendarOrderCategoryEnum.brokerage;
  const isExclusive = category === CalendarOrderCategoryEnum.exclusive && price !== 100;
  const isCommissionPriceDisplay = priceDisplay === ApiCalendarPriceDisplayEnum.COMMISSION;
  const isBrokerageOrExclusive = isBrokerage || isExclusive;

  const hasPrice = price !== null;
  const showPriceColumn = isBrokerageOrExclusive && hasPrice;

  const formattedPrice = !hasPrice
    ? ''
    : isCommissionPriceDisplay
      ? `${(100 - price).toFixed(2)}%`
      : `${price.toFixed(3)}`;

  const priceColumnLabel = isCommissionPriceDisplay
    ? translations.calendars.common.commission
    : translations.calendars.common.price;

  const highlightRows = highlights
    ?.filter(({ label }) => label !== translations.calendars.common.commission)
    .map(({ label, alt, icon, dynamicContent }) => (
      <CalendarProductCardDetailColumn key={label} label={label} icon={icon} dynamicContent={dynamicContent}>
        {alt}
      </CalendarProductCardDetailColumn>
    ));

  const priceColumn = showPriceColumn ? (
    <CalendarProductCardDetailColumn label={priceColumnLabel} icon="file-invoice">
      {formattedPrice}
    </CalendarProductCardDetailColumn>
  ) : null;

  return (
    <Stack direction={{ md: 'row', xs: 'column' }} spacing={{ md: 4, xs: 2 }}>
      <CalendarProductCardDetailColumn label={term?.label} icon={term?.icon} dynamicContent={term?.dynamicContent}>
        {termText}
      </CalendarProductCardDetailColumn>
      <CalendarProductCardDetailColumn
        label={protection?.label}
        icon={protection?.icon}
        dynamicContent={protection?.dynamicContent}
      >
        {protectionText}
      </CalendarProductCardDetailColumn>
      {highlightRows}
      {priceColumn}
    </Stack>
  );
};
