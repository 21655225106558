import { EventListenerModel, EventListenerResult, useEventListener } from '@halo-common/hooks';

const SOCKET_NAMESPACE = 'toast';

export enum ToastWebsocketEventLevelEnum {
  'NOTSET' = 'NOTSET',
  'DEBUG' = 'DEBUG',
  'INFO' = 'INFO',
  'WARNING' = 'WARNING',
  'ERROR' = 'ERROR',
  'CRITICAL' = 'CRITICAL',
  'SUCCESS' = 'SUCCESS',
}

export type ToastWebsocketEvent = {
  title: string;
  level: ToastWebsocketEventLevelEnum;
  message: string;
  data?: {
    is_added?: boolean;
    is_edited?: boolean;
    is_pending?: boolean;
    processed?: number;
    total?: number;
  };
};

export type WebsocketToastListenerEvent = EventListenerModel<ToastWebsocketEvent>;

export const useToastEventListener = (): EventListenerResult<ToastWebsocketEvent> => {
  return useEventListener<ToastWebsocketEvent>(SOCKET_NAMESPACE);
};
