import { ReactElement, useEffect } from 'react';

import {
  bulkOrderTicketManagerAtom,
  bulkOrderTicketReceiptAtom,
  fixTimeoutErrorAtom,
  fixValidationErrorsAtom,
  orderTicketCSVUploadModalAtom,
  orderTicketStepAtom,
  setFixTimeoutAtom,
} from '@halo-atoms/orderTicket';
import { BulkOrderTicketFormFields } from '@halo-common/schemas';
import { PERSHING_DEFAULT_TIMEOUT_MESSAGE } from '@halo-data-sources/switches';
import { Alert } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { PershingBulkAllocationInput } from './PershingBulkAllocationInput';
import { BulkAllocationLayout } from '../../BulkAllocationLayout';

export const PershingBulkAllocationSection = (): ReactElement => {
  const { control, trigger, watch } = useFormContext<BulkOrderTicketFormFields>();

  const { fields, remove, append } = useFieldArray<BulkOrderTicketFormFields>({ name: 'allocations', control });

  const [orderTicketState, setOrderTicketState] = useAtom(bulkOrderTicketManagerAtom);
  const fixErrors = useAtomValue(fixValidationErrorsAtom);
  const setBulkOrderTicketReceipt = useSetAtom(bulkOrderTicketReceiptAtom);
  const handleNextStep = useSetAtom(orderTicketStepAtom.handleNextAtom);
  const setFixTimeout = useSetAtom(setFixTimeoutAtom);
  const setCSVUploadModal = useSetAtom(orderTicketCSVUploadModalAtom);
  const fixTimeoutError = useAtomValue(fixTimeoutErrorAtom);

  const allocationError = fixTimeoutError ? PERSHING_DEFAULT_TIMEOUT_MESSAGE : undefined;
  const hasAllocationError = Boolean(allocationError);
  const hasFixErrors = Boolean(Object.keys(fixErrors).length);

  const handleCSVUpload = () => setCSVUploadModal(true);

  const handleDownloadTemplate = () => {
    const fileUrl = 'https://notes.haloinvesting.com/assets/public/bulk_allocation_upload_template.xlsx';
    window.open(fileUrl, '_blank');
  };

  const allocationErrorContent = hasAllocationError ? (
    <Alert variant="outlined" severity="error">
      {allocationError}
    </Alert>
  ) : null;

  const validAllocations = orderTicketState.validatedAllocations;
  const invalidAllocations = orderTicketState.invalidAllocations;
  const pendingAllocations = orderTicketState.pendingValidateAllocations;

  const pendingAllocationLength = pendingAllocations.length || null;
  const allocationSubmissionPending = validAllocations.length + invalidAllocations.length === pendingAllocationLength;
  const validationFinished = fixTimeoutError || allocationSubmissionPending;
  const validationSuccess = validAllocations.length === pendingAllocationLength;

  const count = fields.length;
  const allocations = watch('allocations');
  const sum = allocations.reduce((prev, field) => {
    if (!field.quantity) return prev;
    return prev + Number(field.quantity);
  }, 0);

  const validate = async () => await trigger();

  const handleAddAllocation = () => {
    append({ accountOption: null });
  };

  const slotProps = {
    footer: {
      onAddAllocation: handleAddAllocation,
      onCSVUpload: handleCSVUpload,
      allocationSum: sum,
      allocationCount: count,
    },
  };

  const accountAllocationList = fields.map((field, index) => {
    const handleDelete = (): void => remove(index);
    return <PershingBulkAllocationInput key={field.id} index={index} onDelete={handleDelete} />;
  });

  useEffect(() => {
    if (hasFixErrors) void validate();
  }, [fixErrors]);

  useEffect(() => {
    if (validationFinished) {
      setFixTimeout();
      setOrderTicketState();
      if (!validationSuccess) setBulkOrderTicketReceipt();
    }
  }, [validationFinished]);

  useEffect(() => {
    if (validationSuccess) handleNextStep();
  }, [validationSuccess]);

  return (
    <BulkAllocationLayout error={allocationErrorContent} onDownload={handleDownloadTemplate} slotProps={slotProps}>
      {accountAllocationList}
    </BulkAllocationLayout>
  );
};
