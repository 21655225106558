import { ReactElement } from 'react';

import { AccountFormField } from '@halo-common/formComponents';
import { useCommonUserSetting } from '@halo-common/hooks';
import { AllocationModalFormModel } from '@halo-common/modals';
import { AccountTypeAheadOption, AccountTypeAheadSelectionReasons } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { CurrencyInput, Iconography, IconographyProps, Stack } from '@halodomination/halo-fe-common';
import { Checkbox, Divider, FormControlLabel, IconButton, Typography, TypographyProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const checkboxSx = {
  paddingRight: 8,
};

const errorSx = {
  color: 'error.main',
  ml: 0,
};

const checkboxErrorTypoProps: TypographyProps = {
  color: 'error.main',
  fontSize: '12px',
  ml: 2,
};

const ALLOCATION_CREATE_ACCOUNT_ACTION = translations.allocationsModal.common.accountTypeAheadCreateAccountOption;

export type AllocationRowProps = {
  id: number;
  checkboxLabel?: string;
  currencyCode?: string;
  onCreateAccount: (id: number) => void;
  onDelete: (id: number) => void;
};

export const AllocationRow = ({
  id,
  checkboxLabel,
  currencyCode,
  onCreateAccount,
  onDelete,
}: AllocationRowProps): ReactElement => {
  const {
    control,
    formState: { errors },
    getValues,
    reset,
  } = useFormContext<AllocationModalFormModel>();

  const canCreateAccount = useCommonUserSetting('Order_Ticket_Create_Account', 'Calendar Related Toggles');

  const accountFieldName: `allocations.${number}.accountOption` = `allocations.${id}.accountOption`;
  const checkboxFieldName: `allocations.${number}.checkbox` = `allocations.${id}.checkbox`;
  const amountFieldName: `allocations.${number}.amount` = `allocations.${id}.amount`;

  const accountError = errors?.allocations?.[id]?.accountOption?.message;
  const hasAccountError = Boolean(accountError);
  const amountError = errors?.allocations?.[id]?.amount?.message;
  const hasAmountError = Boolean(amountError);
  const checkboxError = errors?.allocations?.[id]?.checkbox?.message;
  const hasCheckboxError = Boolean(checkboxError);

  const accountErrorSx = { marginTop: hasAccountError ? 2 : 0 };
  const amountErrorSx = { marginTop: hasAmountError ? 2 : 0 };

  const columns = [5, 5, 2];
  const actions = canCreateAccount
    ? [
        {
          icon: 'plus' as IconographyProps['iconName'],
          label: ALLOCATION_CREATE_ACCOUNT_ACTION,
          clearedOnSelection: true,
        },
      ]
    : [];

  const textFieldSx = {
    py: checkboxLabel ? 0 : 2,
  };

  const dividerSx = {
    py: checkboxLabel ? 0 : 1,
  };

  const onDeleteClick = () => {
    const shouldResetFormFields = getValues().allocations.length === 1;
    if (shouldResetFormFields) reset({ allocations: [{ accountOption: null, amount: '', checkbox: false }] });
    else onDelete(id);
  };

  const handleAccountChange = (_: AccountTypeAheadOption | null, reason?: AccountTypeAheadSelectionReasons) => {
    const useCreateAccount = reason === 'action';
    if (useCreateAccount) onCreateAccount(id);
  };

  const checkboxErrorIndication = hasCheckboxError ? (
    <Typography {...checkboxErrorTypoProps}>{checkboxError}</Typography>
  ) : null;

  const checkbox = checkboxLabel ? (
    <Controller
      control={control}
      name={checkboxFieldName}
      render={({ field: { ref, value, ...field } }) => (
        <Stack direction="column">
          <FormControlLabel
            {...field}
            inputRef={ref}
            control={<Checkbox checked={value} />}
            label={checkboxLabel}
            sx={checkboxSx}
          />
          {checkboxErrorIndication}
        </Stack>
      )}
    />
  ) : null;

  return (
    <Stack direction="column" spacing={3}>
      <Stack direction="column" spacing={2} sx={textFieldSx}>
        <Stack direction="row" spacing={3} alignItems="center" xs={columns}>
          <AccountFormField
            control={control}
            actions={actions}
            onSelect={handleAccountChange}
            name={accountFieldName}
            error={hasAccountError}
            helperText={accountError}
            label={translations.common.account}
            sx={accountErrorSx}
            rules={{ disableHouseholds: true }}
          />
          <Controller
            render={({ field: { onChange, ref, value } }) => (
              <CurrencyInput
                onChange={(value?: string | number) => {
                  const parsedValue = value?.toString() ?? '';
                  onChange(parsedValue);
                }}
                CurrencyOptions={{ currency: currencyCode }}
                value={value}
                inputRef={ref}
                size="large"
                fullWidth
                valueType="string"
                allowNegative={false}
                sx={amountErrorSx}
                helperText={amountError}
                error={hasAmountError}
                slotProps={{ formHelperText: { sx: errorSx } }}
              />
            )}
            control={control}
            name={amountFieldName}
          />
          <IconButton onClick={onDeleteClick}>
            <Iconography iconName="trash-alt" />
          </IconButton>
        </Stack>
        {checkbox}
      </Stack>
      <Divider sx={dividerSx} />
    </Stack>
  );
};
