import React, { RefObject } from 'react';

import { ProtectionSelection, PrincipalProtectionCommonChips } from '@halo-common/components';
import { NoteCreateProtectionTypeEnum } from '@halo-common/enums';
import { translations } from '@halo-common/translations';
import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { Box, capitalize, ClickAwayListener, Divider, InputLabel, Popper, Typography } from '@mui/material';

type PrincipalProtectionPopperProps = {
  open: boolean;
  anchorRef: RefObject<HTMLElement>;
  onClose: () => void;
  onSelectProtection: (protection: ProtectionSelection) => void;
  currentProtection: ProtectionSelection;
};

export const PrincipalProtectionPopper = ({
  open,
  anchorRef,
  onClose,
  onSelectProtection,
  currentProtection,
}: PrincipalProtectionPopperProps) => {
  if (!open) return null;

  const popperSx = {
    backgroundColor: 'common.white',
    p: 2,
    boxShadow: 3,
    borderRadius: 1,
    minWidth: 400,
    maxHeight: 400,
    overflow: 'auto',
  };

  const protectionOptionSx = {
    p: 1,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'primary.background',
    },
  };

  const protectionOptions = Object.values(NoteCreateProtectionTypeEnum).map((protectionType) => {
    return (
      <Box
        key={protectionType}
        sx={protectionOptionSx}
        onClick={() => onSelectProtection({ value: currentProtection.value, type: protectionType })}
      >
        <Typography variant="subtitle2">{capitalize(protectionType)}</Typography>
      </Box>
    );
  });

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper
        sx={popperSx}
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [-10, 10],
              },
            },
          ],
        }}
      >
        <InputLabel shrink>
          <LocalizedTypography>{translations.noteCreate.commonProtections}</LocalizedTypography>
        </InputLabel>
        <PrincipalProtectionCommonChips onSelectProtection={onSelectProtection} />
        <Divider sx={{ mt: 1 }} />
        {protectionOptions}
      </Popper>
    </ClickAwayListener>
  );
};
