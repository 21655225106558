import { ReactElement } from 'react';

import { useLoadingDockUpload } from '@halo-common/hooks';
import { UploadFileModal, UploadFileModalStatuses, UploadFileResultStep } from '@halo-common/modals';
import { translations } from '@halo-common/translations';
import { useCalendarOfferingUploadMutation } from '@halo-data-sources/mutations';

import { UploadFilePendingContent } from './UploadFilePendingContent';
import { UploadFileSuccessContent } from './UploadFileSuccessContent';

export type UploadCalendarModalProps = {
  open: boolean;
  onClose: () => void;
};

export const UploadCalendarModal = ({ open = false, onClose }: UploadCalendarModalProps): ReactElement => {
  const {
    mutate: uploadFile,
    reset: resetUpload,
    status: uploadStatus,
    isPending,
    error,
  } = useCalendarOfferingUploadMutation();

  const { processed, total } = useLoadingDockUpload();

  let status = {
    idle: UploadFileModalStatuses.idle,
    pending: UploadFileModalStatuses.idle,
    success: UploadFileModalStatuses.success,
    error: UploadFileModalStatuses.error,
  }[uploadStatus];

  if (total > 0) status = UploadFileModalStatuses.pending;
  if (total > 0 && processed === total) status = UploadFileModalStatuses.success;
  if (status === UploadFileModalStatuses.success && processed === 0) status = UploadFileModalStatuses.error;

  const approvalStepOptions = {
    subtitle: translations.loadingDock.uploadFile.uploadOverrideVerification,
  };

  const uploadFilePending: UploadFileResultStep = {
    icon: 'hourglass',
    title: translations.loadingDock.uploadFile.uploadPendingTitle,
    message: translations.loadingDock.uploadFile.uploadPendingMessage,
    content: <UploadFilePendingContent />,
  };

  const uploadFileSuccess: UploadFileResultStep = {
    icon: 'check-circle',
    title: translations.loadingDock.uploadFile.uploadSuccessTitle,
    content: <UploadFileSuccessContent />,
  };

  const uploadFileError: UploadFileResultStep = {
    icon: 'exclamation-circle',
    title: translations.loadingDock.uploadFile.uploadErrorTitle,
    message: error?.message ?? translations.common.error,
  };

  const handleSubmit = (file: File) => {
    uploadFile(file);
  };

  const handleClose = () => {
    resetUpload();
    onClose();
  };

  const onReset = () => {
    resetUpload();
  };

  return (
    <UploadFileModal
      open={open}
      onSubmit={handleSubmit}
      onClose={handleClose}
      onReset={onReset}
      loading={isPending}
      status={status}
      title={translations.loadingDock.uploadFile.uploadPrelimTerms}
      subtitle={translations.loadingDock.uploadFile.uploadingWillReplaceSpreads}
      approvalStep={approvalStepOptions}
      pendingStep={uploadFilePending}
      successStep={uploadFileSuccess}
      errorStep={uploadFileError}
      fileTypes=".csv,.xls,.xlsx"
    />
  );
};
