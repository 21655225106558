import { ReactElement } from 'react';

import { NoteProtectionTypeEnum } from '@halo-common/enums';
import { useMoneyness } from '@halo-common/hooks';
import {
  LineChart,
  LineChartDataPoint,
  LineChartLineDefinition,
  LineChartReferenceLine,
  useChartColors,
} from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';

export type ProductDetailModalAnalyticsRollingReturnsUnderlyingPerformanceChartProps = {
  lines?: Array<LineChartLineDefinition>;
  data?: Array<LineChartDataPoint>;
  references?: Array<LineChartReferenceLine>;
  referenceLines?: Array<LineChartReferenceLine>;
  hiddenLines: Array<string>;
  loading: boolean;
  basket?: Record<string, number> | null;
  protectionType?: NoteProtectionTypeEnum;
};

export const ProductDetailModalAnalyticsRollingReturnsUnderlyingPerformanceChart = ({
  lines = [],
  data = [],
  references = [],
  hiddenLines = [],
  loading,
  basket,
  protectionType,
}: ProductDetailModalAnalyticsRollingReturnsUnderlyingPerformanceChartProps): ReactElement => {
  const { calculateProtection, calculateCouponProtection } = useMoneyness();
  const { getColorAt } = useChartColors('line');

  const filteredLines = lines.map((line) => ({
    ...line,
    hidden: hiddenLines.some((hiddenLine) => hiddenLine.includes(line.label.toLowerCase())),
  }));

  const yLabelFormatter = (value: string) => {
    return `${value}%`;
  };

  const tooltipFormatter = (value: string | number, name: string) => {
    const parsedValue = typeof value !== 'number' ? parseFloat(value.replace('%', '')) : value;
    if (name === 'Breach Level') {
      const calculatedProtection = calculateProtection(parsedValue * -1, protectionType);
      return `${calculatedProtection?.toFixed(2)}%`;
    }
    if (name === 'Coupon Protection') {
      const calculatedCouponProtection = calculateCouponProtection(parsedValue * -1);
      return `${calculatedCouponProtection?.toFixed(2)}%`;
    }
    return `${parsedValue.toFixed(2)}%`;
  };

  const handleTooltipValueSort = basket
    ? (payload: Array<{ name: string; value: number }>) => {
        const sortedPayload = payload
          .slice(1)
          .sort((a, b) => (basket[a.name] < basket[b.name] ? 1 : basket[a.name] > basket[b.name] ? -1 : 0))
          .map((data, index) => ({ ...data, color: getColorAt(index + 1).main }));

        return [payload[0], ...sortedPayload];
      }
    : undefined;

  if (loading) return <Skeleton variant="rounded" height={420} width="100%" />;

  return (
    <LineChart
      data={data}
      lines={filteredLines}
      referenceLines={references}
      yLabel={{ label: 'Performance', formatter: yLabelFormatter }}
      xLabel="Time"
      dataType="date"
      TooltipProps={{ formatter: tooltipFormatter, dataSorter: handleTooltipValueSort }}
      height={420}
      XAxisProps={{ tickCount: 7 }}
    />
  );
};
