import { ReactElement, ReactNode } from 'react';

import { PageFooter, PageFooterProps } from '@halo-common/layouts';
import { useCombinedStyling } from '@halodomination/halo-fe-common';
import { Box, BoxProps, Stack } from '@mui/material';

const wrapperSx = {
  direction: 'column',
  overflowX: 'hidden',
  width: '100%',
};

const contentSx = {
  pb: 6,
  minHeight: '100vh',
  width: '100%',
};

export type PageFooterLayoutProps = {
  children: ReactNode;
  FooterProps?: PageFooterProps;
  ContentProps?: BoxProps;
};

export const PageFooterLayout = ({ children, FooterProps, ContentProps }: PageFooterLayoutProps): ReactElement => {
  const combinedContentSx = useCombinedStyling(contentSx, ContentProps?.sx);

  return (
    <Stack sx={wrapperSx}>
      <Box sx={combinedContentSx} {...ContentProps}>
        {children}
      </Box>
      <PageFooter {...FooterProps} />
    </Stack>
  );
};
