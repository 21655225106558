import { useState } from 'react';

import { modalAtom } from '@halo-atoms/common';
import { StepWizard } from '@halo-common/components';
import { OrganizationOnboardingStatusEnum } from '@halo-common/enums';
import { translations } from '@halo-common/translations';
import { navigateParentTo } from '@halo-common/utils';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { OnboardingLayout } from '@halo-modules/app/onboarding';
import {
  SKIP_SUITABILITY_FORM_WARNING_MODAL_NAME,
  SkipSuitabilityFormWarningModal,
  ConcurrentEditWarningModal,
  fallbackStep,
  introStep,
  SuitabilityCompleted,
  useSuitabilityFormManager,
} from '@halo-modules/app/onboarding/subPages/suitability';
import { LocalizedButton, LocalizedTypography, PageComponent, PageMeta } from '@halodomination/halo-fe-common';
import { CircularProgress, Stack } from '@mui/material';
import { useSetAtom } from 'jotai';
import { useRouter } from 'next/router';

const contentSx = {
  margin: '24px auto auto',
  maxWidth: 1080,
  width: '100%',
};

const buttonSx = { margin: '-4px -8px', height: 'fit-content' };

const SuitabilityPage: PageComponent = () => {
  const router = useRouter();
  const setModalMap = useSetAtom(modalAtom);
  const { data: userInfo } = useUserInfoQuery();

  const [skipEditingWarning, setSkipEditingWarning] = useState(false);

  const closeEditingWarning = () => setSkipEditingWarning(true);

  const showSkipQuestionnaireWarning = () =>
    setModalMap({
      [SKIP_SUITABILITY_FORM_WARNING_MODAL_NAME]: true,
    });

  const skipQuestionnaireButton = (
    <Stack alignItems="flex-end" ml="auto">
      <LocalizedButton variant="text" color="primary" sx={buttonSx} onClick={showSkipQuestionnaireWarning}>
        {translations.onboarding.suitability.skipQuestionnaireForNow}
      </LocalizedButton>
      <LocalizedTypography variant="caption" color="text.secondary">
        {translations.onboarding.suitability.saveProgressAndGetRightToThePlatform}
      </LocalizedTypography>
    </Stack>
  );

  const slots = {
    skipButton: skipQuestionnaireButton,
  };

  const { isLoading, sections, isFormRecentlyEditedBySomeoneElse } = useSuitabilityFormManager();

  const onboardingStatus = userInfo?.organization?.onboardingStatus as OrganizationOnboardingStatusEnum;

  const skipSuitabilityForm = [
    OrganizationOnboardingStatusEnum.FORM_NOT_APPLICABLE,
    OrganizationOnboardingStatusEnum.UNDER_HALO_REVIEW,
    OrganizationOnboardingStatusEnum.ONBOARDED,
  ].includes(onboardingStatus);

  if (skipSuitabilityForm) {
    navigateParentTo('/user/dashboard');
    return <CircularProgress />;
  }

  const isCompleted = router.query.step === 'completed';

  if (isCompleted) {
    return (
      <OnboardingLayout variant="small" showLocaleDropdown={false}>
        <SuitabilityCompleted />
      </OnboardingLayout>
    );
  }

  const content =
    isLoading || !router.isReady ? (
      <CircularProgress />
    ) : (
      <StepWizard
        title={translations.onboarding.suitability.haloOnboardingSuitability}
        defaultStep={introStep}
        fallbackStep={fallbackStep}
        sections={sections}
        slots={slots}
        nonLinear
      />
    );

  const concurrentEditWarning =
    isFormRecentlyEditedBySomeoneElse && !skipEditingWarning ? (
      <ConcurrentEditWarningModal open onClose={closeEditingWarning} />
    ) : null;

  return (
    <>
      <OnboardingLayout variant="small" showLocaleDropdown={false}>
        <Stack direction="column" alignItems="center" sx={contentSx}>
          {content}
        </Stack>
      </OnboardingLayout>
      <SkipSuitabilityFormWarningModal />
      {concurrentEditWarning}
    </>
  );
};

export const SuitabilityPageMeta: PageMeta = {
  pageName: 'Suitability',
  route: '/app/onboarding/suitability',
};

SuitabilityPage.pageName = SuitabilityPageMeta.pageName;
SuitabilityPage.route = SuitabilityPageMeta.route;

export default SuitabilityPage;
