import { ReactElement, useEffect } from 'react';

import { SubmittedAllocationsTable, SubmittedAllocationsTableProps } from '@halo-common/components';
import { PENDING_ALLOCATION_STATUSES } from '@halo-common/constants';
import { AllocationModalFooter, AllocationModalFooterProps } from '@halo-common/modals';
import { AllocationModel, AllocationSubmissionModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import {
  SMAAllocationModalForm,
  SMAAllocationModalFormModel,
  SMAAllocationModalFormProps,
  SMASubmittedAllocationStatuses,
} from '@halo-modules/app';
import {
  CurrencyMapperOptions,
  HaloDataGridProps,
  mapNumberToLocalCurrency,
  ModalProps,
  TabbedModal,
  TabsProps,
} from '@halodomination/halo-fe-common';
import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useFormContext } from 'react-hook-form';

export type SMAAllocationModalContainerProps = Omit<ModalProps, 'children' | 'FooterProps'> & {
  allocations?: Array<AllocationSubmissionModel>;
  disableEdits?: boolean;
  disableSubmit?: boolean;
  hideForm?: boolean;
  hidePrimaryAction?: boolean;
  status?: 'idle' | 'loading' | 'success' | 'error';
  message?: string;
  TabsProps?: Partial<TabsProps>;
  TableProps?: Partial<Omit<SubmittedAllocationsTableProps, 'height'>>;
  FooterProps?: Partial<AllocationModalFooterProps>;
  FormProps?: Partial<SMAAllocationModalFormProps>;
  onCancel?: () => void;
  onSubmit: (data: SMAAllocationModalFormModel) => void;
  onCancelAllocation: (id: number) => void;
  onEditAllocation?: HaloDataGridProps['onCellEditStop'];
};

export const SMAAllocationModalContainer = ({
  allocations = [],
  disableEdits = false,
  hideForm = false,
  hidePrimaryAction = false,
  status = 'idle',
  message,
  onClose,
  onSubmit,
  onEditAllocation,
  onCancelAllocation,
  TabsProps,
  FooterProps,
  FormProps,
  TableProps,
  ...ModalProps
}: SMAAllocationModalContainerProps): ReactElement => {
  const { handleSubmit: submitHandler, reset } = useFormContext<SMAAllocationModalFormModel>();

  const loading = status === 'loading';
  const success = status === 'success';

  const activeAllocationCount = allocations.filter((allocation) =>
    PENDING_ALLOCATION_STATUSES.includes(allocation.status),
  ).length;

  const tabs: TabsProps['tabs'] = [{ label: 'Submitted Allocations', count: activeAllocationCount }];
  if (!hideForm) tabs.unshift({ label: 'New Allocations' });

  const handleSubmit = submitHandler(onSubmit);

  const handleClose: ModalProps['onClose'] = (ev, reason) => {
    onClose?.(ev, reason);
    reset();
  };

  const handleModalClose: ModalProps['onClose'] = (ev, reason) => {
    if (reason !== 'backdropClick') {
      handleClose(ev, reason);
    }
  };

  const columns: HaloDataGridProps['columns'] = [
    {
      field: 'submittedFor',
      headerName: translations.common.submittedFor,
      flex: 1,
      editable: false,
      valueGetter: (_, row: AllocationModel) => row?.advisorName,
    },
    {
      field: 'name',
      headerName: translations.common.account,
      flex: 1.5,
      editable: false,
      valueFormatter: (_, row: AllocationModel) => {
        const name = row.account?.name;
        const custodian = row.account?.custodian;
        const custodianName = custodian?.name ?? '--';
        const accountId = row?.account?.accountId;
        return `${name} - ${custodianName} - ${accountId}`;
      },
    },
    {
      field: 'status',
      headerName: translations.common.status,
      flex: 1,
      editable: false,
      renderCell: (allocation) => (
        <Box height="100%" width="100%" display="flex" alignItems="center">
          <SMASubmittedAllocationStatuses value={allocation.value} />
        </Box>
      ),
    },
    {
      field: 'amount',
      headerName: translations.common.notional,
      flex: 1,
      editable: disableEdits,
      valueFormatter: (value: number) =>
        mapNumberToLocalCurrency(value, {
          trailingZeroDisplay: 'stripIfInteger',
        } as CurrencyMapperOptions),
    },
  ];

  const formMessage = message ? <Typography>{message}</Typography> : null;
  const form = !hideForm ? (
    <SMAAllocationModalForm {...FormProps} loading={loading}>
      {formMessage}
    </SMAAllocationModalForm>
  ) : null;

  useEffect(() => {
    if (success) reset();
  }, [success]);

  return (
    <TabbedModal
      {...ModalProps}
      tabs={tabs}
      onClose={handleModalClose}
      TabsProps={{ ...TabsProps, loading }}
      footer={
        <AllocationModalFooter
          {...FooterProps}
          onSubmit={handleSubmit}
          onClose={handleClose}
          hidePrimaryAction={hidePrimaryAction}
        >
          {FooterProps?.children}
        </AllocationModalFooter>
      }
    >
      {form}
      <SubmittedAllocationsTable
        {...TableProps}
        loading={loading}
        allocations={allocations}
        columns={columns}
        onCancel={onCancelAllocation}
        onCellEditStop={onEditAllocation}
        isCellEditable={(params) => {
          const isActive = DateTime.fromISO(params?.row?.expirationDate) > DateTime.now();
          const editable = Boolean(params.isEditable && isActive);
          return editable;
        }}
      />
    </TabbedModal>
  );
};
