import { useRef, useState } from 'react';

import { TermPopper } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { LocalizedTypography, NumericSelector } from '@halodomination/halo-fe-common';
import { Box, InputLabel, Stack, SxProps } from '@mui/material';

type TermDropdownProps = {
  sx?: SxProps;
};

export const TermDropdown = ({ sx }: TermDropdownProps) => {
  const [open, setOpen] = useState(false);
  const [term, setTerm] = useState(12);

  const dropdownAnchorRef = useRef(null);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (value: number) => {
    setTerm(value);
  };

  return (
    <Box sx={sx} ref={dropdownAnchorRef}>
      <Box component="button" sx={{ all: 'unset', width: '100%' }} onClick={handleClick}>
        <InputLabel shrink>
          <LocalizedTypography>{translations.common.term}</LocalizedTypography>
        </InputLabel>
        <Stack direction="row">
          <Box onClick={(e) => e.stopPropagation()}>
            <NumericSelector increment={6} label={'mo'} min={12} max={120} value={term} onChange={handleChange} />
          </Box>
        </Stack>
      </Box>
      <TermPopper
        anchorRef={dropdownAnchorRef}
        open={open}
        onClose={handleClose}
        onSelectTerm={(input: number) => setTerm(input)}
      />
    </Box>
  );
};
