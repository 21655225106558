import { ReactElement } from 'react';

import { FIXED_INCOME_PREFERENCE_TAG } from '@halo-common/constants';
import { DynamicTypeFiltersSideBarFiltersEnum } from '@halo-common/enums';
import { DynamicFiltersLayout } from '@halo-common/layouts';
import { translations } from '@halo-common/translations';
import { FixedIncomeList } from '@halo-modules/app';
import { MustCompleteOnboardingAlert } from '@halo-modules/app/onboarding/subPages/suitability';

export const FixedIncomeListPage = (): ReactElement => (
  <>
    <MustCompleteOnboardingAlert feature={translations.calendars.common.calendars} />
    <DynamicFiltersLayout
      rules={{ nmeEnabled: true }}
      tag={FIXED_INCOME_PREFERENCE_TAG}
      filters={[
        DynamicTypeFiltersSideBarFiltersEnum.myOffering,
        DynamicTypeFiltersSideBarFiltersEnum.productFeatures,
        DynamicTypeFiltersSideBarFiltersEnum.nonCallPeriod,
        DynamicTypeFiltersSideBarFiltersEnum.termSlider,
        DynamicTypeFiltersSideBarFiltersEnum.calendarIssuer,
      ]}
    >
      <FixedIncomeList />
    </DynamicFiltersLayout>
  </>
);
