import { ReactElement } from 'react';

import { LexicalTypography } from '@halo-common/components';
import { CalendarOrderCategoryEnum } from '@halo-common/enums';
import { IssuerModel } from '@halo-common/models';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { Box, Skeleton, Stack } from '@mui/material';
import { capitalize } from 'lodash';

const logoImageSx = {
  backgroundColor: 'transparent',
  height: '25px',
  maxWidth: '100px',
};

export type CalendarProductCardIssuerPropTypes = {
  category?: CalendarOrderCategoryEnum;
  issuer?: IssuerModel;
  loading: boolean;
};

export const CalendarProductCardIssuer = ({
  category,
  issuer,
  loading,
}: CalendarProductCardIssuerPropTypes): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();

  const categoryValue = category ?? '';
  const issuerName = issuer?.name;
  const issuerId = issuer?.ergonomicId;
  const showCalendarType = userInfo?.settings?.showCalendarCategoryFilterSetting;
  const staticAssetUrl = userInfo?.configuration.staticAssetUrl;
  const issuerLogoUrl = `${staticAssetUrl}/assets/images/svg/other/${issuerId}.svg`;

  const categoryText = capitalize(categoryValue);

  const issuerContent = loading ? (
    <Skeleton width={150} height={40} variant="rounded" />
  ) : (
    <Box component="img" sx={logoImageSx} alt={issuerName} src={issuerLogoUrl} />
  );

  const categoryContent = showCalendarType ? (
    <LexicalTypography color="textSecondary" variant="caption">
      {categoryText}
    </LexicalTypography>
  ) : null;

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {issuerContent}
      {categoryContent}
    </Stack>
  );
};
