import { UserRoleEnum } from '@halo-common/enums';
import { CalendarModel, OrderTicketReceiptModel } from '@halo-common/models';
import { BulkOrderTicketFormFields, SingleOrderTicketFormFields } from '@halo-common/schemas';
import { BulkAllocationError } from '@halo-data-sources/errors';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query';

import { janneyOrderTicketValidationSwitchFn } from './janneyOrderTicketValidationSwitchFn';
import { pershingOrderTicketBulkValidationSwitchFn } from './pershingOrderTicketBulkValidationSwitchFn';
import { useOrderTicketValidationSwitchHandlers } from './useOrderTicketValidationSwitchHandlers';

export type OrderTicketValidationSwitchPayload = {
  calendar: CalendarModel | null;
  singleData?: SingleOrderTicketFormFields;
  bulkData?: BulkOrderTicketFormFields;
};

export type OrderTicketValidationSwitchErrors = {
  [key: string]: string | undefined;
};

export type OrderTicketValidationSwitchOptions = UseMutationOptions<
  OrderTicketReceiptModel | null,
  BulkAllocationError,
  OrderTicketValidationSwitchPayload
>;

export type OrderTicketValidationResult = UseMutationResult<
  OrderTicketReceiptModel | null,
  BulkAllocationError,
  OrderTicketValidationSwitchPayload
>;

const orderTicketValidationSwitchFn = async (
  payload: OrderTicketValidationSwitchPayload,
  roles?: Array<UserRoleEnum>,
): Promise<OrderTicketReceiptModel | null> => {
  const { calendar, bulkData } = payload;

  if (!calendar) return null;

  const isPershing = roles?.includes(UserRoleEnum.PershingOrderSender);

  if (isPershing) return pershingOrderTicketBulkValidationSwitchFn(calendar, bulkData);
  return janneyOrderTicketValidationSwitchFn(calendar, bulkData);
};

export const useOrderTicketValidationSwitch = (
  options?: Partial<OrderTicketValidationSwitchOptions>,
): OrderTicketValidationResult => {
  const { data: user } = useUserInfoQuery();

  const roles = user?.details.roles;

  const { onSuccess, onMutate, onError } = useOrderTicketValidationSwitchHandlers();

  return useMutation<OrderTicketReceiptModel | null, BulkAllocationError, OrderTicketValidationSwitchPayload>({
    mutationFn: (payload) => orderTicketValidationSwitchFn(payload, roles),
    onMutate: (...args) => {
      options?.onMutate?.(...args);
      onMutate?.(...args);
    },
    onError: (...args) => {
      options?.onError?.(...args);
      onError?.(...args);
    },
    onSuccess: (...args) => {
      options?.onSuccess?.(...args);
      onSuccess?.(...args);
    },
  });
};
