import { ReactElement } from 'react';

import { HaloFooter, PageFooterDisclaimer } from '@halo-common/layouts';
import { UserRelationshipManagerModel } from '@halo-common/models';
import { useLegacyRouter } from '@halo-common/providers';
import { useUserInfoQuery, useUserWebHooksQuery } from '@halo-data-sources/queries';
import { Box, Container, Stack } from '@mui/material';
import { useT } from '@transifex/react';
import { sanitize } from 'dompurify';

export type PageFooterProps = {
  hideRelationshipManager?: boolean;
  altRelationshipManager?: UserRelationshipManagerModel | null;
};

export const PageFooter = ({
  hideRelationshipManager = false,
  altRelationshipManager,
}: PageFooterProps): ReactElement => {
  const translator = useT();
  const { path } = useLegacyRouter();

  const { data: user } = useUserInfoQuery();
  const { data: webhooks } = useUserWebHooksQuery();

  const settings = user?.settings;
  const whiteLabel = user?.whiteLabel;

  const disclaimerHook = `${path}_footer`;
  const showDisclaimer = Boolean(disclaimerHook && webhooks?.[disclaimerHook]?.legacyFeatureEnabled);
  const disclaimer = showDisclaimer ? <PageFooterDisclaimer disclaimerHook={disclaimerHook} /> : null;

  const whiteLabelFooterContent = whiteLabel?.footerContent ?? '';
  const translatedFooter = translator(whiteLabelFooterContent);
  const sanitizedContent = sanitize(translatedFooter);

  const content = settings?.useHaloFooter ? (
    <HaloFooter hideRelationshipManager={hideRelationshipManager} altRelationshipManager={altRelationshipManager} />
  ) : (
    <Box dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
  );

  return (
    <Container id="haloPageFooter" maxWidth="xl">
      <Stack direction="column" spacing={5}>
        {content}
        {disclaimer}
      </Stack>
    </Container>
  );
};
