import { ReactElement, useCallback } from 'react';

import {
  productApprovalDetailsModalAtom,
  productApprovalEditCloseDateModalAtom,
  productApprovalManagerAtom,
  productApprovalPaginationAtom,
  productApprovalPdfViewerModalAtom,
  productApprovalTableSortAtom,
} from '@halo-atoms/productApproval';
import { CalendarProductCard } from '@halo-common/components';
import { PRODUCT_APPROVAL_PREFERENCE_TAG } from '@halo-common/constants';
import { SortModelDirectionEnum } from '@halo-common/enums';
import { useCommonUserSetting, usePrimaryAssetIdentifier, useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { DynamicFiltersEmptyResult } from '@halo-common/layouts';
import { CalendarModel, CalendarPreviewModel } from '@halo-common/models';
import { useCalendarVisibilityMutation } from '@halo-data-sources/mutations';
import { useProductApprovalCalendarQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import {
  ProductApprovalPanelToggle,
  ProductApprovalTableFooter,
  ProductApprovalTableProtectionCell,
} from '@halo-modules/admin';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridPaginationModel,
  GridSortModel,
  HaloDataGrid,
  HaloDataGridProps,
  Iconography,
  LocalizedTypography,
  Stack,
  Tooltip,
} from '@halodomination/halo-fe-common';
import { Box, IconButton, Switch } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

const PRODUCT_APPROVAL_SELECTION_HEADER_CLASS = 'productApprovalSelectionHeaderClass';
const PRODUCT_APPROVAL_SELECTION_CELL_CLASS = 'productApprovalSelectionCellClass';
const PRODUCT_APPROVAL_ENABLED_HEADER_CLASS = 'productApprovalEnabledHeaderClass';

const tableSx = {
  [`& .${PRODUCT_APPROVAL_ENABLED_HEADER_CLASS}`]: {
    backgroundColor: 'unset',
  },
  [`& .${PRODUCT_APPROVAL_SELECTION_HEADER_CLASS}`]: {
    borderRight: 'none !important',
    pl: 1.25,
  },
  [`& .${PRODUCT_APPROVAL_SELECTION_CELL_CLASS}`]: {
    borderRight: 'none !important',
  },
};

export const ProductApprovalTable = (): ReactElement => {
  const configureDateTime = useWhiteLabelDateConfiguration();

  const setProductApprovalTableSortAtom = useSetAtom(productApprovalTableSortAtom);
  const setEditCloseDateModalState = useSetAtom(productApprovalEditCloseDateModalAtom);
  const setDetailsModalState = useSetAtom(productApprovalDetailsModalAtom);
  const setPdfViewerModalState = useSetAtom(productApprovalPdfViewerModalAtom);
  const [paginationState, setPaginationState] = useAtom(productApprovalPaginationAtom);
  const { query, isFiltered } = useAtomValue(productApprovalManagerAtom);

  const { data: userInfo } = useUserInfoQuery();
  const { data, isFetching } = useProductApprovalCalendarQuery(query);

  const { mutate } = useCalendarVisibilityMutation(query);

  const { identifierType } = usePrimaryAssetIdentifier();

  const showEdit = useCommonUserSetting('PA_Edit_Closing_Time', 'Calendar Related Toggles');

  const rows = data?.calendars ?? [];
  const page = data?.pagination.page ?? paginationState.page;
  const pageSize = data?.pagination.resultsPerPage ?? paginationState.resultsPerPage;
  const totalResults = data?.pagination.totalResults ?? 0;
  const showEmptyResult = !isFetching && totalResults === 0;
  const initialPaginationModel = { page: 0, pageSize };
  const initialState = { pagination: { paginationModel: initialPaginationModel } };
  const paginationModel = { page: page - 1, pageSize };

  const calendarPageId = userInfo?.organization?.calendarPageId;

  const selectionHeaderClassName = !isFetching ? PRODUCT_APPROVAL_SELECTION_HEADER_CLASS : undefined;
  const selectionCellClassName = !isFetching ? PRODUCT_APPROVAL_SELECTION_CELL_CLASS : undefined;

  const actions = {
    onOfferingDoc: (calendar: CalendarModel | CalendarPreviewModel) => {
      setPdfViewerModalState({ calendarId: calendar.id, open: true });
    },
    onDetails: (calendar: CalendarModel | CalendarPreviewModel) => {
      setDetailsModalState({ calendarId: calendar.id, open: true });
    },
  };

  const getDetailPanelContent = useCallback<NonNullable<HaloDataGridProps['getDetailPanelContent']>>(
    ({ row: calendar }) => (
      <Box p={2}>
        <CalendarProductCard calendar={calendar} actions={actions} />
      </Box>
    ),
    [actions],
  );

  const getDetailPanelHeight = useCallback<NonNullable<HaloDataGridProps['getDetailPanelHeight']>>(
    () => 'auto' as const,
    [],
  );

  const handlePaginationChange = (model: GridPaginationModel) => {
    if (!isFetching) {
      setPaginationState({
        page: model.page + 1,
        resultsPerPage: model.pageSize,
      });
    }
  };

  const handleSortChange = (model: GridSortModel) => {
    const newSortModel = model.map((option) => {
      const newSortField = option.field;
      const isAssetId = identifierType.toLowerCase() === newSortField;
      const newSortDirection = (option.sort as SortModelDirectionEnum) ?? SortModelDirectionEnum.desc;

      return { field: isAssetId ? newSortField.toUpperCase() : newSortField, direction: newSortDirection };
    });

    setProductApprovalTableSortAtom(newSortModel);
  };

  if (showEmptyResult) {
    return <DynamicFiltersEmptyResult tag={PRODUCT_APPROVAL_PREFERENCE_TAG} isFiltered={isFiltered} />;
  }

  const editColumn: HaloDataGridProps['columns'] = showEdit
    ? [
        {
          field: '',
          resizable: false,
          width: 30,
          headerClassName: PRODUCT_APPROVAL_ENABLED_HEADER_CLASS,
          renderCell: ({ row }) => (
            <Stack direction="row" sx={{ width: '100%' }} justify="center" alignItems="center">
              <IconButton
                size="small"
                onClick={() => setEditCloseDateModalState({ calendar: row, calendarIds: [row.id], open: true })}
              >
                <Iconography iconName="pencil" color="text.secondary" />
              </IconButton>
            </Stack>
          ),
        },
      ]
    : [];

  const columns: HaloDataGridProps['columns'] = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 62,
      sortable: false,
      resizable: false,
      headerClassName: selectionHeaderClassName,
      cellClassName: selectionCellClassName,
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      maxWidth: 30,
      sortable: false,
      resizable: false,
      headerClassName: showEdit ? selectionHeaderClassName : undefined,
      cellClassName: showEdit ? selectionCellClassName : undefined,
      renderCell: (params) => <ProductApprovalPanelToggle id={params.id} value={params.value} />,
    },
    ...editColumn,
    {
      field: 'toggleColumn',
      resizable: false,
      width: 120,
      headerClassName: PRODUCT_APPROVAL_ENABLED_HEADER_CLASS,
      renderCell: ({ row }) => {
        const handleToggle = () => {
          const update = row.approved ? { removeCalendarIds: [row.id] } : { addCalendarIds: [row.id] };
          mutate({ calendarPageId, ...update });
        };

        return (
          <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
            <Switch onChange={handleToggle} checked={row.approved} />
          </Box>
        );
      },
      renderHeader: () => (
        <Stack direction="row" alignItems="flex-end" spacing={1}>
          <LocalizedTypography fontWeight="bold" variant="caption">
            Enabled
          </LocalizedTypography>
          <Tooltip title="When enabled is toggled on, the associated product will immediately be available for users to submit orders up until the specified close date/time.">
            <Iconography prefix="fal" iconName="circle-info" color="text.disabled" />
          </Tooltip>
        </Stack>
      ),
    },
    {
      field: identifierType.toLowerCase(),
      minWidth: 125,
      flex: 1,
      disableHeaderTextFormatting: true,
      headerName: identifierType.toUpperCase(),
    },
    {
      field: 'issuer',
      flex: 1,
      minWidth: 160,
      headerName: 'issuer',
      valueGetter: (_, row) => row.issuer?.name,
    },
    {
      field: 'type',
      minWidth: 110,
      headerName: 'type',
      valueGetter: (_, row) => row.note?.productType,
    },
    {
      field: 'underlyings',
      flex: 2,
      minWidth: 160,
      headerName: 'underlyings',
      valueGetter: (_, row) => row.note?.tradables?.map(({ name }: { name: string }) => name) ?? [],
      valueFormatter: (value: Array<string>) => value.join(', '),
    },
    {
      field: 'term',
      minWidth: 100,
      headerName: 'term',
      valueGetter: (_, row) => row.note?.termInMonths,
      valueFormatter: (value) => `${value}Mo`,
    },
    {
      field: 'protection',
      minWidth: 125,
      flex: 1,
      headerName: 'protection',
      renderCell: ({ row }) => <ProductApprovalTableProtectionCell note={row.note} />,
    },
    {
      field: 'expirationDate',
      minWidth: 180,
      headerName: 'close time',
      valueFormatter: (value: string) => {
        return configureDateTime(value)?.toFormat('MM/dd/yyyy hh:mm a');
      },
    },
    {
      field: 'price',
      minWidth: 100,
      flex: 1,
      headerName: 'sales credit',
    },
  ];

  return (
    <HaloDataGrid
      height="auto"
      density="compact"
      columns={columns}
      rows={rows}
      tableSx={tableSx}
      slots={{ footer: ProductApprovalTableFooter }}
      pagination
      paginationMode="server"
      sortingMode="server"
      paginationModel={paginationModel}
      disableColumnMenu
      disableColumnReorder
      onSortModelChange={handleSortChange}
      onPaginationModelChange={handlePaginationChange}
      pageSizeOptions={paginationState.pageSizeOptions}
      rowCount={totalResults}
      loading={isFetching}
      checkboxSelection
      disableRowSelectionOnClick
      keepNonExistentRowsSelected
      disableColumnSelector
      initialState={initialState}
      getDetailPanelHeight={getDetailPanelHeight}
      getDetailPanelContent={getDetailPanelContent}
    />
  );
};
