import { ConfirmationEnum } from '@halo-common/enums';
import { ExecutionOrderModel } from '@halo-common/models';
import { ApiExecOrder } from '@halo-data-sources/models';

export interface ApiExecOrderMapper {
  toExecutionOrderStatus(model: string): string;
  toExecutionOrder(model: ApiExecOrder): ExecutionOrderModel;
}

export const ApiExecOrderMapper: ApiExecOrderMapper = {
  toExecutionOrderStatus: (status: string): string => {
    return (
      {
        ACTIVE: 'pending',
        FILLING: 'filling',
        FILLED_DK: 'warning',
        CANCELED: 'canceled',
        FILLED: 'filled',
      }[status] ?? 'pending'
    );
  },
  toExecutionOrder: (model: ApiExecOrder): ExecutionOrderModel => ({
    accountId: model.account_identifier,
    cusip: model.cusip,
    discretion: model.custom.DiscretionUsed ? ConfirmationEnum.Yes : ConfirmationEnum.No,
    designation: model.custom.capacity_type as string,
    orderReceiptTime: model.custom.order_receipt_time as string,
    orderReceiveFrom: model.custom.order_receive_from as string,
    solicited: model.custom.solicited as string,
    id: model.id,
    price: model.price,
    originalPrice: model.custom.original_offering_price as number,
    quantity: model.quantity,
    transactionType: model.side,
    comment: model.comment,
    status: ApiExecOrderMapper.toExecutionOrderStatus(model.status),
    ttoRepCode: model.custom.tto_rep_code as string,
  }),
};
