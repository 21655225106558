import { ReactElement } from 'react';

import { SubmittedAllocationsTableActions, SubmittedAllocationsTableActionsProps } from '@halo-common/components';
import { PENDING_ALLOCATION_STATUSES } from '@halo-common/constants';
import { AllocationSubmissionModel } from '@halo-common/models';
import { HaloDataGrid, HaloDataGridProps, Stack, useCurrencyConverter } from '@halodomination/halo-fe-common';
import { Alert, Box, Typography } from '@mui/material';

const alertSx = { backgroundColor: 'error.background' };
const footerSx = { p: 2, width: '100%', borderTop: '1px solid', borderTopColor: 'grey.300' };

export type SubmittedAllocationsTableProps = HaloDataGridProps & {
  allocations: Array<AllocationSubmissionModel>;
  currencyCode?: string;
  error?: string;
  message?: string;
  hideActions?: boolean;
  cancelling?: boolean;
  onCancel: SubmittedAllocationsTableActionsProps['onCancel'];
};

/**
 * ### SubmittedAllocationsTable
 *
 * #### Required Props
 *
 * **allocations** &rarr; Array of allocations to display in the table.
 *
 * **columns** &rarr; Array of MUI column definitions to add before the action column.
 *
 * **onCancel** &rarr; Function called when a user cancels an allocation.
 *
 * #### Optional Props
 *
 * **currencyCode** &rarr; The currency code to use for formatting the total amount.
 *
 * **error** &rarr; An error message to display at the top of the table.
 *
 * **loading** &rarr; Flag used to manage the buttons in the actions column.
 *
 * **message** &rarr; A message to display below the table.
 *
 */
export const SubmittedAllocationsTable = ({
  allocations = [],
  currencyCode,
  columns = [],
  error,
  cancelling = false,
  message,
  hideActions = false,
  onCancel,
  onCellEditStop,
  ...props
}: SubmittedAllocationsTableProps): ReactElement => {
  const actionColumn: HaloDataGridProps['columns'] = [];

  const allocationTotal = allocations.reduce((total, allocation) => {
    if (PENDING_ALLOCATION_STATUSES.includes(allocation.status)) return total + allocation.amount;
    else return total;
  }, 0);

  const [formattedAllocationTotal] = useCurrencyConverter(allocationTotal, { currencyCode });

  if (!hideActions) {
    actionColumn.push({
      disableReorder: true,
      editable: false,
      field: 'actions',
      flex: 2,
      filterable: false,
      headerName: 'actions',
      resizable: false,
      sortable: false,
      renderCell: ({ row }) => {
        const id = row.id;
        const accountId = row.account?.id;
        const status = row.status?.toLowerCase();
        const expirationDate = row.expirationDate;
        const disableCancel = row.disableCancel;

        return (
          <Box height="100%" width="100%" display="flex" alignItems="center">
            <SubmittedAllocationsTableActions
              id={id}
              accountId={accountId}
              status={status}
              disableCancel={disableCancel}
              expirationDate={expirationDate}
              loading={cancelling}
              onCancel={onCancel}
            />
          </Box>
        );
      },
    });
  }

  const tableColumns = [...columns, ...actionColumn];

  const errorMessage = error ? (
    <Alert severity="error" variant="outlined" sx={alertSx}>
      {error}
    </Alert>
  ) : null;

  return (
    <Stack direction="column" spacing={3} xs={12}>
      {errorMessage}
      <HaloDataGrid
        {...props}
        density="compact"
        columns={tableColumns}
        rows={allocations}
        onCellEditStop={onCellEditStop}
        slots={{
          footer: () => (
            <Stack direction="row" sx={footerSx} justify="flex-end" alignItems="center">
              <Typography variant="body1">Total Amount:&nbsp;</Typography>
              <Typography variant="body1" fontWeight="fontWeightBold">
                {formattedAllocationTotal}
              </Typography>
            </Stack>
          ),
        }}
      />
      <Typography color="textSecondary" variant="body2">
        {message}
      </Typography>
    </Stack>
  );
};
