import { ReactElement } from 'react';

import { ActionButton } from '@halo-common/components';
import { BulkOrderTicketFormFields } from '@halo-common/schemas';
import { Iconography } from '@halodomination/halo-fe-common';
import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';

export type BulkAllocationInputActionProps = {
  status: 'idle' | 'valid' | 'invalid' | 'pending';
  onDelete: () => void;
};

export const BulkAllocationInputAction = ({
  status,
  onDelete,
}: BulkAllocationInputActionProps): ReactElement | null => {
  const { watch } = useFormContext<BulkOrderTicketFormFields>();

  const allocations = watch('allocations');

  const isValid = status === 'valid';
  const isInvalid = status === 'invalid';
  const isValidating = status === 'pending';
  const showActionButton = allocations.length > 1 || isValidating;

  const renderContainer = (content: ReactElement) => {
    return <Box sx={{ height: 48, display: 'flex', alignItems: 'center' }}>{content}</Box>;
  };

  if (isValid) return renderContainer(<Iconography iconName="check" color="success.main" size="1.25x" />);
  if (isInvalid) return renderContainer(<Iconography iconName="exclamation" color="error.main" size="1.25x" />);
  if (showActionButton) {
    return renderContainer(
      <ActionButton
        sx={{ p: 0, '&:hover': { backgroundColor: 'transparent' } }}
        size="medium"
        onClick={onDelete}
        loading={isValidating}
        disableRipple
      >
        <Iconography iconName="trash-alt" />
      </ActionButton>,
    );
  }

  return null;
};
