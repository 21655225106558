import { calendarSelectedAtom } from '@halo-atoms/calendars';
import { setFixTimeoutAtom } from '@halo-atoms/orderTicket';
import { PershingStatusEnum } from '@halo-common/enums';
import { FixWebsocketEvent } from '@halo-common/hooks';
import { AllocationModel } from '@halo-common/models';
import { useWebSocketContext } from '@halo-common/providers';
import { putFixOrderCancellation } from '@halo-data-sources/clients';
import { ApiFixEventTypeEnum } from '@halo-data-sources/enums';
import { CANNOT_CANCEL_ORDER_CANCELED } from '@halo-data-sources/models';
import { CalendarsQueryKeyFactory } from '@halo-data-sources/queries';
import { FIVE_MINUTE_TIMEOUT } from '@halo-data-sources/switches';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';

export type CancelFixOrderMutationResult = {
  allocationId: number;
  accountId?: number;
  errorMessage?: string;
};

export type CancelFixOrderPayload = {
  allocationId?: number;
  accountId?: number;
};

const cancelFixOrderMutationFn = async ({ allocationId, accountId }: CancelFixOrderPayload) => {
  if (!allocationId) return null;

  const result = await putFixOrderCancellation(allocationId);

  return {
    allocationId,
    accountId,
    errorMessage: result.message,
  };
};

export type useCancelFixOrderMutationOptions = UseMutationOptions<
  CancelFixOrderMutationResult | null,
  Error,
  CancelFixOrderPayload
>;

export type useCancelFixOrderMutationResult = UseMutationResult<
  CancelFixOrderMutationResult | null,
  Error,
  CancelFixOrderPayload
>;

export const useCancelFixOrderMutation = (
  options?: useCancelFixOrderMutationOptions,
): useCancelFixOrderMutationResult => {
  const { events } = useWebSocketContext();
  const queryClient = useQueryClient();
  const { enqueueErrorEvent } = useSnackbar();

  const selectedCalendar = useAtomValue(calendarSelectedAtom);
  const setFixTimeout = useSetAtom(setFixTimeoutAtom);

  const updateAllocationsData = (calendarId: number, allocationId: number) => {
    const allocationsKey = CalendarsQueryKeyFactory.allocations(calendarId);

    const calendarQueryKey = CalendarsQueryKeyFactory.calendar(calendarId);
    void queryClient.refetchQueries({ queryKey: calendarQueryKey });

    queryClient.setQueryData<Array<AllocationModel>>(allocationsKey, (prev) => {
      if (!prev) return undefined;

      const updatedAllocations = [...prev];
      const allocationIndex = updatedAllocations.findIndex((allocation) => allocation.id === allocationId);

      if (allocationIndex === -1) return prev;

      const foundAllocation = updatedAllocations[allocationIndex];
      const updatedAllocation = {
        ...foundAllocation,
        pershingOrderBookStatus: PershingStatusEnum['Canceled'],
        ergStatus: 'canceled',
        status: 'canceled',
      };

      updatedAllocations.splice(allocationIndex, 1, updatedAllocation);

      return updatedAllocations;
    });
  };

  return useMutation({
    ...options,
    mutationFn: cancelFixOrderMutationFn,
    onMutate: (...props) => {
      options?.onMutate?.(...props);
      const { allocationId, accountId } = props[0] || {};
      if (!allocationId || !accountId || !selectedCalendar?.id) return undefined;

      const websocketCallback = (event: FixWebsocketEvent) => {
        const { event: eventType, allocation: apiAllocation } = event;

        const pendingCancelEvent = eventType === ApiFixEventTypeEnum.PENDING_CANCEL;
        const cancelEvent = eventType === ApiFixEventTypeEnum.CANCELED;
        const handleEvent = pendingCancelEvent || cancelEvent;

        if (!handleEvent || !apiAllocation) return;

        updateAllocationsData(selectedCalendar.id, allocationId);

        events.fix.remove(accountId);

        setFixTimeout();
      };

      events.fix.add(accountId, websocketCallback);
    },
    onSettled: (...props) => {
      options?.onSettled?.(...props);
      const { accountId, allocationId, errorMessage } = props[0] || {};

      if (errorMessage) {
        const alreadyCanceled = errorMessage === CANNOT_CANCEL_ORDER_CANCELED;

        if (accountId) events.fix.remove(accountId);
        if (alreadyCanceled && selectedCalendar && allocationId) {
          updateAllocationsData(selectedCalendar.id, allocationId);
        }

        setFixTimeout();

        enqueueErrorEvent({ message: errorMessage });
      } else {
        const timeout = global.setTimeout(() => {
          if (accountId) events.fix.remove(accountId);
        }, FIVE_MINUTE_TIMEOUT);

        setFixTimeout(timeout);
      }
    },
  });
};
