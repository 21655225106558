import { ConfirmationEnum } from '@halo-common/enums';
import { CalendarModel, OrderTicketReceiptModel } from '@halo-common/models';
import { submitCalendarOrder } from '@halo-data-sources/clients';
import {
  ApiAllocationMapper,
  ApiOrderRequestCustomModel,
  ApiSubmissionOrderRequestModel,
} from '@halo-data-sources/index';
import { ApiExecOrderMapper } from '@halo-data-sources/mappers';
import { DateTime } from 'luxon';

export const janneyOrderTicketSubmissionSwitchFn = async (
  calendar: CalendarModel,
  data?: OrderTicketReceiptModel | null,
): Promise<OrderTicketReceiptModel | null> => {
  if (!data || !data.orders?.length) return null;

  const orderDate = DateTime.local().toISO();

  const response = await submitCalendarOrder({
    calendar_id: calendar.id,
    orders: data.orders.reduce((orders: ApiSubmissionOrderRequestModel['orders'], { accountId, order }) => {
      if (typeof accountId !== 'number' || typeof order.id !== 'number') return orders;

      const custom: ApiOrderRequestCustomModel = {
        capacity_type: order.designation,
        order_receipt_time: orderDate,
        order_receive_from: order.orderReceiveFrom,
      };

      if (calendar.price) custom.original_offering_price = calendar.price;
      if (order.ttoRepCode) custom.tto_rep_code = order.ttoRepCode;
      if (order.solicited) custom.solicited = order.solicited.toUpperCase();
      if (order.discretion) custom.DiscretionUsed = (order.discretion as ConfirmationEnum) === ConfirmationEnum.Yes;

      return [...orders, { account_id: accountId, exec_order_id: order.id, custom }];
    }, []),
  });

  return {
    status: 'success',
    submissionTime: orderDate,
    orders: response.orders.map((order) => {
      const allocation = order.allocation;
      const execOrder = order.exec_order;
      const summary = order.extra;

      return {
        id: execOrder.id,
        allocation: ApiAllocationMapper.toAllocationModel(allocation),
        order: ApiExecOrderMapper.toExecutionOrder(execOrder),
        summary: {
          principal: summary.principal,
          commission: summary.commission,
          otherMiscFees: summary.other_misc_fees,
          salesCredit: summary.sales_credit,
          total: summary.total,
        },
      };
    }),
  };
};
