import React, { ReactElement } from 'react';

import { createCalendarModalAtom } from '@halo-atoms/createCalendar';
import { CalendarOrderCategoryEnum } from '@halo-common/enums';
import { AdminCreateCalendarFields, adminCreateCalendarSchema } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { navigateParentTo } from '@halo-common/utils';
import { Iconography, LocalizedButton, LocalizedTypography, Modal } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Stack } from '@mui/material';
import { useSetAtom } from 'jotai';
import { FormProvider, useForm } from 'react-hook-form';

import { AdminCreateCalendarForm } from './AdminCreateCalendarForm';

export type AdminCreateCalendarModalProps = {
  open?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
};

export const AdminCreateCalendarModal = ({
  open = false,
  onClose,
  onSubmit,
}: AdminCreateCalendarModalProps): ReactElement => {
  const formMethods = useForm<AdminCreateCalendarFields>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    resolver: yupResolver<AdminCreateCalendarFields>(adminCreateCalendarSchema),
    defaultValues: {
      enableNow: true,
      issuer: '',
      category: '',
      settlementType: '',
      settings: [{ wholesaler: '' }],
    },
  });
  const setCreateCalendarModalState = useSetAtom(createCalendarModalAtom);

  const { reset, getValues, handleSubmit, formState } = formMethods;
  const alertMessage = translations.calendars.createCalendar.addNewInstructions;

  const handleClose = () => {
    onClose?.();
    reset();
  };

  const hanlePreview = async () => {
    await formMethods.trigger();

    if (formState.isValid) {
      setCreateCalendarModalState({
        noteId: Number(getValues('noteId')),
        displayName: getValues('displayName'),
        cusip: Number(getValues('cusip')),
        issuerId: Number(getValues('issuer')),
        category: getValues('category') as CalendarOrderCategoryEnum,
        closeDate: getValues('closeDate'),
        quote: Number(getValues('noteQuote')),
        previewModalOpen: true,
      });
    }
  };

  const handleCreate = handleSubmit(() => {
    onSubmit?.();
    // TODO: these logs will be removed when the corresponding tickets are completed
    console.log('submitting');
    console.log(getValues());
  });

  const backIcon = <Iconography iconName="arrow-left" />;

  const footer = (
    <Stack direction="row" justifyContent="space-between">
      <LocalizedButton variant="text" onClick={handleClose} startIcon={backIcon}>
        {translations.common.cancel}
      </LocalizedButton>
      <Stack direction="row" spacing={2}>
        <LocalizedButton variant="text" onClick={hanlePreview}>
          {translations.common.preview}
        </LocalizedButton>
        <LocalizedButton variant="contained" color="primary" onClick={handleCreate}>
          {translations.common.create}
        </LocalizedButton>
      </Stack>
    </Stack>
  );

  return (
    <Modal
      title={translations.calendars.createCalendar.addNewOffering}
      size="small"
      open={open}
      footer={footer}
      onClose={handleClose}
    >
      <Stack spacing={3}>
        <Alert severity="info" variant="filled">
          <LocalizedTypography variant="body1">{alertMessage}</LocalizedTypography>
          <LocalizedButton variant="text" onClick={() => void navigateParentTo('/note_creator', true)}>
            {translations.common.createNote}
          </LocalizedButton>
        </Alert>
        <FormProvider {...formMethods}>
          <AdminCreateCalendarForm />
        </FormProvider>
      </Stack>
    </Modal>
  );
};
