import { ConfirmationEnum } from '@halo-common/enums';
import { CalendarModel, OrderTicketReceiptModel } from '@halo-common/models';
import { BulkOrderTicketFormFields } from '@halo-common/schemas';
import { validateCalendarOrder } from '@halo-data-sources/clients';
import { ApiExecOrderMapper } from '@halo-data-sources/mappers';
import { ApiOrderRequestCustomModel, ApiValidateOrderRequestModel } from '@halo-data-sources/models';

export const janneyOrderTicketValidationSwitchFn = async (
  calendar: CalendarModel,
  data?: BulkOrderTicketFormFields,
): Promise<OrderTicketReceiptModel | null> => {
  if (!data || !data.allocations?.length) return null;

  const response = await validateCalendarOrder({
    calendar_id: calendar.id,
    orders: data.allocations.reduce((orders: ApiValidateOrderRequestModel['orders'], allocation) => {
      const account_id = allocation.accountOption?.account?.id;
      const quantity = allocation.quantity;

      if (typeof quantity !== 'number' || typeof account_id !== 'number') return orders;

      const custom: ApiOrderRequestCustomModel = {
        capacity_type: allocation.capacityType as string,
        order_receipt_time: data.orderDate,
        order_receive_from: data.orderFrom,
      };

      if (calendar.price) custom.original_offering_price = calendar.price;
      if (allocation.ttoRepCode) custom.tto_rep_code = allocation.ttoRepCode;
      if (allocation.solicited) custom.solicited = allocation.solicited.toUpperCase();
      if (allocation.discretion) custom.DiscretionUsed = allocation.discretion === ConfirmationEnum.Yes;

      // TODO: Uncomment when rep code data is complete on BE
      // custom.tto_rep_code = data.ttoRepCode.name;

      return [...orders, { account_id, quantity, custom }];
    }, []),
  });

  return {
    status: 'success',
    submissionTime: data.orderDate,
    orders: response.orders.map((order, index) => {
      const allocation = data.allocations[index];
      const execOrder = order.exec_order;
      const summary = order.extra;

      return {
        id: execOrder.id,
        accountId: allocation?.accountOption?.account?.id,
        order: ApiExecOrderMapper.toExecutionOrder(execOrder),
        summary: {
          principal: summary.principal,
          commission: summary.commission,
          otherMiscFees: summary.other_misc_fees,
          salesCredit: summary.sales_credit,
          total: summary.total,
        },
      };
    }),
  };
};
