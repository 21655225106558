import { NoteCallInformationTypeEnum, NoteParameterEnum, NoteTypeEnum } from '@halo-common/enums';
import { useMoneyness, useQuoteSelector } from '@halo-common/hooks';
import { NoteModel, QuoteModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { roundFloatToSpecificDecimal, roundValue } from '@halo-common/utils';
import { IconographyProps } from '@halodomination/halo-fe-common';
import { capitalize } from '@mui/material';
import { ITranslateParams } from '@transifex/native';
import { useT } from '@transifex/react';

export type NoteSpecificDetailsFlattened = Partial<
  Omit<NoteModel, 'commission' | 'coupon' | 'protectionPercent' | 'tradableWeights' | 'tradables' | 'termInMonths'>
>;

export type NoteSpecificDetails = NoteSpecificDetailsFlattened & {
  couponPeriod: string;
  couponType: string;
};

export type NoteSpecificDetailsOptions = {
  ignoreQuote?: boolean;
  overriddenQuote?: QuoteModel | null;
  showParameter?: boolean;
  overriddenCommission?: number | null;
  disableRounding?: {
    commission?: boolean;
    digitalYield?: boolean;
    participation?: boolean;
    maxReturn?: boolean;
    annualizedYield?: boolean;
    couponProtection?: boolean;
  };
};

export type NoteSpecificDetailsTranslations = {
  commission?: number | null;
  couponProtection?: number | null;
  noteProtection?: number | null;
  protectionType?: string | null;
  term: {
    month: string;
    months: string;
    noTerm: string;
  };
  payoff?: string;
};

export type NoteSpecificDetailsField = {
  label: string;
  icon?: IconographyProps['iconName'];
  original?: number | null;
  text: string;
  alt?: string;
  dynamicContent?: ITranslateParams;
};

export type NoteSpecificDetailsFieldMap = {
  [fieldName: string]: NoteSpecificDetailsField;
};

export type NoteSpecificDetailsResult = {
  earlyRedemptionDetails: Array<NoteSpecificDetailsField>;
  fieldMap: NoteSpecificDetailsFieldMap;
  fields: Array<NoteSpecificDetailsField>;
  highlights: Array<NoteSpecificDetailsField>;
  payoffDetails: Array<NoteSpecificDetailsField>;
  protectionDetails?: Array<NoteSpecificDetailsField>;
  quote?: QuoteModel | null;
  solvableParameter?: NoteSpecificDetailsField;
  term: NoteSpecificDetailsField;
  underlyings: NoteSpecificDetailsField;
};

/**
 * Generate all the fields for each specific section of the result using business logic from the BE.
 * @param {NoteSpecificDetails} note - Massaged note object to pull data from
 * @param {NoteSpecificDetailsTranslations} translations - Mapped data from note object
 * @return {NoteSpecificDetailsFieldMap} Map of all sections by name.
 */
const generateFieldMap = (
  note: NoteSpecificDetails,
  disableRounding?: NoteSpecificDetailsOptions['disableRounding'],
  translations?: NoteSpecificDetailsTranslations,
): NoteSpecificDetailsFieldMap => {
  const {
    formatters: { getCouponProtectionText },
  } = useMoneyness();

  const annualizedYield = note.annualizedYield;
  const maxReturnPercent = note.maxReturnPercent;
  const callInformation = note.callInformation;
  const participationPercent = note.participationPercent;
  const period = note.couponPeriod;
  const digitalYield = note.digitalYield;
  const isCapped = note.capped;
  const couponType = note.couponType;

  const payoffText = translations?.payoff ?? '';
  const commission = translations?.commission;
  const couponProtection = translations?.couponProtection;

  const couponFrequencyText = capitalize(period);
  const couponTypeText = capitalize(couponType);

  const commissionValue = disableRounding?.commission ? commission : commission?.toFixed(3);
  const commissionText = commission ? `${commissionValue}%` : '';

  const digitalYieldValue = disableRounding?.digitalYield ? digitalYield : digitalYield?.toFixed(2);
  const digitalYieldText = digitalYield ? `${digitalYieldValue}%` : '';

  const isAutocall = callInformation && !callInformation.isIssuerCall;
  const callabilityText = isAutocall ? `${capitalize(callInformation.type)} Autocall` : 'Issuer Call';
  const callInfoText = callInformation ? callabilityText : '';

  const hasParticipation = participationPercent !== null && participationPercent !== undefined;
  const participationValue = disableRounding?.participation ? participationPercent : participationPercent?.toFixed(2);
  const participationText = hasParticipation ? `${participationValue}%` : '';

  const hasMaxReturn = maxReturnPercent !== null && maxReturnPercent !== undefined;
  const maxReturnValue = disableRounding?.maxReturn ? maxReturnPercent : maxReturnPercent?.toFixed(2);
  const maxReturnText = isCapped && hasMaxReturn ? `${maxReturnValue}%` : '';

  const hasAnnualizedYield = annualizedYield !== null && annualizedYield !== undefined;
  const annualizedYieldValue = disableRounding?.annualizedYield ? annualizedYield : annualizedYield?.toFixed(2);
  const annualizedYieldText = hasAnnualizedYield ? `${annualizedYieldValue}%` : '';

  const couponProtectionValue = disableRounding?.couponProtection ? couponProtection : couponProtection?.toFixed(0);
  const couponProtectionText = getCouponProtectionText(couponProtectionValue);
  const couponProtectionAltText = couponProtectionText;

  return {
    payoff: {
      label: '',
      original: null,
      icon: 'envelope-open-dollar',
      text: payoffText,
      alt: payoffText,
    },
    annualizedYield: {
      label: 'Annualized Yield',
      icon: 'envelope-open-dollar',
      original: annualizedYield,
      text: annualizedYieldText,
      alt: annualizedYieldText,
    },
    callInfo: {
      label: 'Early Redemption',
      original: null,
      icon: 'megaphone',
      text: callInfoText,
      alt: callInfoText,
    },
    commission: {
      label: 'Commission',
      original: commission,
      icon: 'coins',
      text: commissionText,
      alt: commissionText,
    },
    couponFrequency: {
      label: 'Coupon Frequency',
      original: null,
      icon: 'money-bill-trend-up',
      text: couponFrequencyText,
      alt: couponFrequencyText,
    },
    couponProtection: {
      label: 'Coupon Protection',
      original: couponProtection,
      icon: 'money-bill',
      text: couponProtectionText,
      alt: couponProtectionAltText,
    },
    couponType: {
      label: 'Coupon Type',
      original: null,
      icon: 'file-invoice-dollar',
      text: couponTypeText,
      alt: couponTypeText,
    },
    digitalYield: {
      label: 'Digital Coupon',
      original: digitalYield,
      icon: 'envelope-open-dollar',
      text: digitalYieldText,
      alt: digitalYieldText,
    },
    maxReturn: {
      label: 'Max Return',
      original: maxReturnPercent,
      icon: 'arrow-up-to-line',
      text: maxReturnText,
      alt: maxReturnText,
    },
    participation: {
      label: 'Participation',
      original: participationPercent,
      icon: 'chart-line-up',
      text: participationText,
      alt: participationText,
    },
  };
};

/**
 * Parse out all payoff fields from note object.
 * Uses: PDM Panels
 * @param {NoteSpecificDetails} note - Massaged note object to pull data from
 * @param {NoteSpecificDetailsFieldMap} fieldMap - Parsed and mapped fields for note specific data.
 * @return {NoteSpecificDetailsResult['payoffDetails']} Array of all payoff fields with empty or suppressed values filtered out.
 */
const parsePayoffDetails = (
  note: NoteSpecificDetails,
  fieldMap: NoteSpecificDetailsFieldMap,
  showParameter = false,
): NoteSpecificDetailsResult['payoffDetails'] => {
  const { parameter } = note;

  const payoffDetails = [fieldMap.couponProtection, fieldMap.couponFrequency, fieldMap.couponType];

  if (showParameter || parameter !== NoteParameterEnum.AnnualCoupon) payoffDetails.push(fieldMap.annualizedYield);
  if (showParameter || parameter !== NoteParameterEnum.DigitalCoupon) payoffDetails.push(fieldMap.digitalYield);
  if (showParameter || parameter !== NoteParameterEnum.Participation) payoffDetails.push(fieldMap.participation);
  if (showParameter || parameter !== NoteParameterEnum.MaxReturn) payoffDetails.push(fieldMap.maxReturn);

  return payoffDetails.filter((field) => Boolean(field.label && field.text));
};

/**
 * Parse out all early redemption fields from note object.
 * Uses: PDM Panels
 * @param {NoteSpecificDetails} note - Massaged note object to pull data from
 * @return {NoteSpecificDetailsResult['earlyRedemptionDetails']} Array of all early redemption fields with empty values filtered out.
 */
const parseEarlyRedemptionDetails = (
  note: NoteSpecificDetails,
): NoteSpecificDetailsResult['earlyRedemptionDetails'] => {
  const { callInformation, type: noteType } = note;

  if (noteType !== NoteTypeEnum.Income) return [];
  else if (!callInformation) return [{ label: 'Callability', text: 'Non Callable' }];

  const { nonCallPeriod, period, level, step, schedule = [], type, isIssuerCall } = callInformation;

  const isAutocall = !isIssuerCall;
  const isCustom = type === NoteCallInformationTypeEnum.Custom;
  const isStepdown = type === NoteCallInformationTypeEnum.StepDown;
  const isStepUp = type === NoteCallInformationTypeEnum.StepUp;
  const isStep = isStepdown || isStepUp;

  const typeText = capitalize(type);
  const levelText = typeof level === 'number' ? `${level.toFixed(2)}%` : '';

  const monthlyText = period === 1 ? 'Monthly' : undefined;
  const quarterlyText = period === 3 ? 'Quarterly' : undefined;
  const semiannualText = period === 6 ? 'Semiannually' : undefined;
  const annualText = period === 12 ? 'Annually' : undefined;
  const namedPeriodText = monthlyText ?? quarterlyText ?? semiannualText ?? annualText;
  const unnamedPeriodText = period ? '{period} Months' : undefined;
  const periodText = namedPeriodText ?? unnamedPeriodText ?? '';
  const callPeriodDynamicContent = { period: period ?? '' };

  const nonCallText = nonCallPeriod ? translations.portfolio.positions.monthsCell : '';
  const nonCallPeriodDynamicContent = { months: nonCallPeriod };

  const scheduleText = isCustom
    ? schedule.reduce((text, { level }, index) => {
        const isInteger = Number.isInteger(level);
        const formattedLevel = isInteger ? `${level}%` : `${level.toFixed(2)}%`;
        const formattedText = index === schedule.length - 1 ? `${text}${formattedLevel}` : `${text}${formattedLevel}/`;
        const slashCount = (formattedText.match(/\//g) || []).length;
        return slashCount % 2 === 0 ? `${formattedText}\n` : formattedText;
      }, '')
    : '';

  const earlyRedemptionDetails: Array<NoteSpecificDetailsField> = [
    { label: 'Call Period', original: period, text: periodText, dynamicContent: callPeriodDynamicContent },
    {
      label: 'Non-Call Period',
      original: nonCallPeriod,
      text: nonCallText,
      dynamicContent: nonCallPeriodDynamicContent,
    },
    { label: 'Autocall Schedule', text: scheduleText },
  ];

  if (isCustom) {
    earlyRedemptionDetails.unshift({ label: 'Callability', text: 'Custom Autocall' });
  } else if (isStep) {
    earlyRedemptionDetails.unshift({ label: 'Callability', text: `${typeText} Autocall` });
    earlyRedemptionDetails.push({ label: 'Autocall Step', text: `${step.toFixed(2)}%` });
    earlyRedemptionDetails.push({ label: 'Initial Call Level', original: level, text: levelText });
  } else if (isAutocall) {
    earlyRedemptionDetails.unshift({ label: 'Callability', text: 'Autocall' });
    earlyRedemptionDetails.push({ label: 'Call Level', original: level, text: levelText });
  } else {
    earlyRedemptionDetails.unshift({ label: 'Callability', text: 'Issuer Call' });
  }

  return earlyRedemptionDetails.filter((field) => Boolean(field.label && field.text));
};

/**
 * Parse out payoff highlights from note object.
 * Uses: The front of the ProductCard
 * @param {NoteSpecificDetails} note - Massaged note object to pull data from
 * @param {NoteSpecificDetailsFieldMap} fieldMap - Parsed and mapped fields for note specific data.
 * @return {NoteSpecificDetailsResult['highlights']} Array of payoff highlights with empty or suppressed values filtered out.
 */
const parseHighlights = (
  note: NoteSpecificDetails,
  fieldMap: NoteSpecificDetailsFieldMap,
): NoteSpecificDetailsResult['highlights'] => {
  const { type, parameter } = note;
  const { digitalYield, participation, commission, annualizedYield, couponProtection, maxReturn } = fieldMap;

  const isIncome = type === NoteTypeEnum.Income;
  const isDigital = type === NoteTypeEnum.Digital;
  const isDigitalPlus = isDigital && Boolean(participation?.original);

  const solveForParticipation = parameter === NoteParameterEnum.Participation;
  const solveForMaxReturn = parameter === NoteParameterEnum.MaxReturn;
  const solveForCommission = parameter === NoteParameterEnum.Price;

  const suppressAnnualCoupon = parameter === NoteParameterEnum.AnnualCoupon;
  const suppressParticipation = parameter === NoteParameterEnum.Participation;
  const suppressMaxReturn = parameter === NoteParameterEnum.MaxReturn;
  const suppressPrice = parameter === NoteParameterEnum.Price;
  const suppressDigitalCoupon = parameter === NoteParameterEnum.DigitalCoupon;

  const showDigitalCoupon = isDigitalPlus || isDigital;
  const showAnnualizedYield = isIncome || solveForCommission;
  const showCouponProtection = isDigital || isIncome;
  const showParticipation = isDigitalPlus || solveForMaxReturn || solveForCommission;
  const showMaxReturn = solveForParticipation || solveForCommission;
  const showCommission = !solveForCommission && typeof commission.original === 'number';

  const highlights: Array<NoteSpecificDetailsField> = [];

  if (showCouponProtection) highlights.push(couponProtection);
  if (!suppressAnnualCoupon && showAnnualizedYield) highlights.push(annualizedYield);
  if (!suppressParticipation && showParticipation) highlights.push(participation);
  if (!suppressMaxReturn && showMaxReturn) highlights.push(maxReturn);
  if (!suppressPrice && showCommission) highlights.push(commission);
  if (!suppressDigitalCoupon && showDigitalCoupon) highlights.push(digitalYield);

  return highlights.filter((highlight) => Boolean(highlight.label && highlight.text));
};

/**
 * Parse out defining note fields from note object.
 * Uses: Back of the ProductCard
 * @param {NoteSpecificDetails} note - Massaged note object to pull data from.
 * @param {NoteSpecificDetailsFieldMap} fieldMap - Parsed and mapped fields for note specific data.
 * @return {NoteSpecificDetailsResult['fields']} The defining note fields for a note.
 */
const parseFields = (
  note: NoteSpecificDetails,
  fieldMap: NoteSpecificDetailsFieldMap,
): NoteSpecificDetailsResult['fields'] => {
  const { parameter, type, callInformation } = note;

  const level = callInformation?.level;

  const isNonCallable = !callInformation;
  const isIncome = type === NoteTypeEnum.Income;
  const isFixed = callInformation?.type === NoteCallInformationTypeEnum.Fixed;

  const filteredFields = Object.values(fieldMap).filter(({ label, text }) => {
    if (!label || !text) return false;

    return [
      label === fieldMap.couponType.label,
      label === fieldMap.couponFrequency.label,
      label === fieldMap.callInfo.label && isIncome,
      label === fieldMap.annualizedYield.label && parameter === NoteParameterEnum.AnnualCoupon,
      label === fieldMap.digitalYield.label && parameter === NoteParameterEnum.DigitalCoupon,
      label === fieldMap.participation.label && parameter === NoteParameterEnum.Participation,
      label === fieldMap.maxReturn.label && parameter === NoteParameterEnum.MaxReturn,
      label === fieldMap.commission.label && parameter === NoteParameterEnum.Price,
    ].every((condition) => !condition);
  });

  if (isNonCallable) {
    return [...filteredFields, { label: '', text: 'Non Callable' }];
  } else if (isFixed) {
    return [
      ...filteredFields,
      { label: 'Call Level', icon: 'comment-dollar', original: level, text: `${roundValue(level)}%` },
    ];
  } else {
    return filteredFields;
  }
};

/**
 * Parse out principal protection from note object.
 * Uses: The front of the ProductCard, back of the ProductCard, and PDM headers
 * @param {NoteSpecificDetails} note - Massaged note object to pull data from.
 * @param {NoteSpecificDetailsTranslations} translations - Mapped data from note object.
 * @param {boolean | undefined} isCalendar - Flag to determine if the note is coming from a calendar.
 * @return {NoteSpecificDetailsResult['protectionDetails']} List of the principal protection details for a note.
 */
const parseProtectionDetails = (
  note: NoteSpecificDetails,
  translations: NoteSpecificDetailsTranslations,
): NoteSpecificDetailsResult['protectionDetails'] => {
  const {
    formatters: { getProtectionText },
  } = useMoneyness();

  const { gearingPercent } = note;
  const { noteProtection, protectionType } = translations;

  if (!protectionType || noteProtection === undefined) return undefined;

  const protectionDetails: Array<NoteSpecificDetailsField> = [
    {
      text: getProtectionText(noteProtection, protectionType),
      label: 'Principal Protection',
      original: noteProtection,
      icon: 'shield',
      dynamicContent: { protection: translations.noteProtection ?? '' },
    },
  ];

  if (gearingPercent) {
    const gearingText = `${gearingPercent.toFixed(0)}%`;
    protectionDetails.push({ label: 'Geared', original: gearingPercent, icon: 'gear', text: gearingText });
  }

  return protectionDetails;
};

/**
 * Parse out solvable parameter (primary payoff) from note object.
 * Uses: The front of the ProductCard, back of the ProductCard, and PDM headers
 * @param {NoteSpecificDetails} note - Massaged note object to pull data from.
 * @param {NoteSpecificDetailsFieldMap} fieldMap - Parsed and mapped fields for note specific data.
 * @param {QuoteModel | null | undefined} quote - Quote object associated with the note.
 * @return {NoteSpecificDetailsResult['solvableParameter']} The solvable parameter (primary payoff) for a note.
 */
const parseSolvableParameter = (
  note: NoteSpecificDetails,
  fieldMap: NoteSpecificDetailsFieldMap,
  quote?: QuoteModel | null,
): NoteSpecificDetailsResult['solvableParameter'] => {
  const { type, parameter } = note;
  const { participation, commission, annualizedYield, digitalYield, maxReturn, payoff } = fieldMap;

  const hasQuote = typeof quote?.value === 'number';
  const quotedValue = hasQuote ? roundFloatToSpecificDecimal(quote?.value) : undefined;
  const quotedText = hasQuote ? `${quotedValue?.toFixed(2)}%` : undefined;

  if (type === NoteTypeEnum.General) {
    const text = payoff.text ?? note?.name ?? '';
    return { ...payoff, text, original: quotedValue };
  } else if (!parameter) {
    return undefined;
  } else if (parameter === NoteParameterEnum.Price) {
    const quotedCommissionValue = quotedValue ? 100 - quotedValue : undefined;
    const quotedCommissionText = quotedCommissionValue ? `${quotedCommissionValue.toFixed(2)}%` : undefined;
    const value = quotedCommissionValue ?? quotedValue;
    const text = quotedCommissionText ?? commission.text;
    const original = roundFloatToSpecificDecimal(value);
    return { ...commission, original, text };
  } else if (parameter === NoteParameterEnum.AnnualCoupon) {
    const text = quotedText ?? annualizedYield.text;
    return { ...annualizedYield, original: quotedValue, text };
  } else if (parameter === NoteParameterEnum.DigitalCoupon) {
    const text = quotedText ?? digitalYield.text;
    return { ...digitalYield, original: quotedValue, text };
  } else if (parameter === NoteParameterEnum.Participation) {
    const text = quotedText ?? participation.text;
    return { ...participation, original: quotedValue, text };
  } else if (parameter === NoteParameterEnum.MaxReturn) {
    const text = quotedText ?? maxReturn.text;
    return { ...maxReturn, original: quotedValue, text };
  }
};

/**
 * Parse out the term details from a note.
 * Uses: The front of the ProductCard, back of the ProductCard, and PDM headers
 * @param {number | undefined} termInMonths - Term in months (numerical)
 * @return {NoteSpecificDetailsResult['term']} The term details for a note.
 */
const parseTerm = (
  termInMonths: number,
  translations: NoteSpecificDetailsTranslations,
): NoteSpecificDetailsResult['term'] => {
  const {
    term: { month, months, noTerm },
  } = translations;

  return {
    label: 'Term',
    icon: 'calendar',
    original: termInMonths,
    text: termInMonths ? `${termInMonths} ${termInMonths > 1 ? months : month}` : `${noTerm}`,
  };
};

/**
 * Parse out the list of underlying names associated with the note in sorted order.
 * Uses: The front of the ProductCard, back of the ProductCard, and PDM headers
 * @param {NoteModel['tradables']} underlyings - List of note underlyings.
 * @param {NoteModel['tradableWeights'] | undefined} weights - Map for underlying weights if it's a weighted basket.
 * @return {NoteSpecificDetailsResult['underlyings']} The sorted underlying names for a note.
 */
const parseUnderlyings = (
  underlyings: NoteModel['tradables'] = [],
  weights?: NoteModel['tradableWeights'],
): NoteSpecificDetailsResult['underlyings'] => {
  if (!underlyings?.length) return { icon: 'building', label: 'Underlyings', text: '' };

  const sorted = underlyings.sort((a, b) => {
    const isWorstOf = !weights;
    const alphaSort = a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    if (isWorstOf) return alphaSort;
    else return weights[b.name] - weights[a.name] || alphaSort;
  });

  const names = sorted.map(({ name }) => {
    if (weights?.[name]) {
      const weight = weights[name];
      return `${name} (${roundValue(weight)}%)`;
    }
    return name;
  });

  return { icon: 'building', label: 'Underlyings', text: names.join(', ') };
};

/**
 * Hook to parse out relevant information from a note to display on the FE.
 * @param {NoteModel | null | undefined} note - Note object to pull data from.
 * @param {options | undefined} options - Option to send for post-trade solvable parameters.
 * @param {options.ignoreQuote | undefined} options.ignoreQuote - Optional flag to suppress quote data. Primarily used for Post Trade.
 * @param {options.overriddenQuote | undefined} options.overriddenQuote - Optional quote to override recent quotes. Primarily used for Pre Trade.
 * @param {options.showParameterInPayoff | undefined} options.showParameterInPayoff - Optional flag to show suppressed parameter data. Primarily used for Post Trade.
 * @return {NoteSpecificDetailsResult} Note details with human readable data to display on the FE.
 */
export const useNoteSpecificDetails = (
  note?: NoteModel | null,
  options?: NoteSpecificDetailsOptions,
): NoteSpecificDetailsResult => {
  const translator = useT();

  const { calculateProtection, calculateCouponProtection } = useMoneyness();

  const selectedQuote = useQuoteSelector(note?.id);

  const ignoreQuote = Boolean(options?.ignoreQuote || options?.overriddenQuote);
  const quote = !ignoreQuote ? selectedQuote : options?.overriddenQuote;

  const defaultNote = note ?? ({} as NoteModel);

  const {
    coupon,
    commission,
    digitalYield,
    protectionPercent,
    tradableWeights,
    tradables,
    termInMonths,
    protectionType,
    payoff,
    ...details
  } = defaultNote;

  const parameter = defaultNote.parameter;

  const quoteValue = quote?.value ?? 0;
  const couponProtection = coupon?.protectionPercent;
  const couponPeriod = coupon?.period ?? '';
  const couponType = coupon?.type ?? '';

  const solveForCommission = parameter === NoteParameterEnum.Price;
  const pricedCommission = solveForCommission ? quoteValue : undefined;
  const manualCommission = commission ? commission / 100 : undefined;
  const overriddenCommission = options?.overriddenCommission ? options.overriddenCommission : undefined;
  const translatedCommission = pricedCommission ?? overriddenCommission ?? manualCommission;

  const translatedProtection = calculateProtection(protectionPercent, protectionType);
  const translatedCouponProtection = calculateCouponProtection(couponProtection);
  const translatedGeneralPayoff = payoff ?? '';

  const noteFields: NoteSpecificDetails = {
    ...details,
    couponType,
    couponPeriod,
    digitalYield,
    payoff,
  };

  const noteFieldTranslations: NoteSpecificDetailsTranslations = {
    commission: translatedCommission,
    couponProtection: translatedCouponProtection,
    noteProtection: translatedProtection,
    protectionType,
    term: {
      month: translator(translations.pdm.postTrade.month),
      months: translator(translations.pdm.postTrade.months),
      noTerm: translator(translations.pdm.postTrade.noTerm),
    },
    payoff: translatedGeneralPayoff,
  };

  const fieldMap = generateFieldMap(noteFields, options?.disableRounding, noteFieldTranslations);

  const fields = parseFields(noteFields, fieldMap);
  const earlyRedemptionDetails = parseEarlyRedemptionDetails(noteFields);
  const payoffDetails = parsePayoffDetails(noteFields, fieldMap, options?.showParameter);
  const highlights = parseHighlights(noteFields, fieldMap);
  const protectionDetails = parseProtectionDetails(noteFields, noteFieldTranslations);
  const solvableParameter = parseSolvableParameter(noteFields, fieldMap, quote);
  const term = parseTerm(termInMonths, noteFieldTranslations);
  const underlyings = parseUnderlyings(tradables, tradableWeights);

  return {
    earlyRedemptionDetails,
    fieldMap,
    fields,
    highlights,
    payoffDetails,
    protectionDetails,
    quote,
    solvableParameter,
    term,
    underlyings,
  };
};
