import { calendarManagerAtom, calendarSelectedAtom } from '@halo-atoms/calendars';
import { UserRoleEnum } from '@halo-common/enums';
import { CalendarModel, OrderTicketReceiptModel } from '@halo-common/models';
import { BulkOrderTicketFormFields } from '@halo-common/schemas';
import { BulkAllocationError } from '@halo-data-sources/errors';
import { CalendarsQueryKeyFactory, useUserInfoQuery } from '@halo-data-sources/queries';
import { UseMutationOptions, UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import { janneyOrderTicketSubmissionSwitchFn } from './janneyOrderTicketSubmissionSwitchFn';
import { pershingOrderTicketBulkSubmissionSwitchFn } from './pershingOrderTicketBulkSubmissionSwitchFn';
import { useOrderTicketSubmissionSwitchHandlers } from './useOrderTicketSubmissionSwitchHandlers';

export type OrderTicketSubmissionSwitchPayload = {
  calendar: CalendarModel | null;
  data: {
    janney?: OrderTicketReceiptModel | null;
    pershing?: BulkOrderTicketFormFields;
  };
};

export type OrderTicketSubmissionSwitchErrors = {
  [key: string]: string | undefined;
};

export type OrderTicketSubmissionSwitchOptions = UseMutationOptions<
  OrderTicketReceiptModel | null,
  BulkAllocationError,
  OrderTicketSubmissionSwitchPayload
>;

export type OrderTicketSubmissionResult = UseMutationResult<
  OrderTicketReceiptModel | null,
  BulkAllocationError,
  OrderTicketSubmissionSwitchPayload
>;

const orderTicketSubmissionSwitchFn = async (
  payload: OrderTicketSubmissionSwitchPayload,
  roles?: Array<UserRoleEnum>,
): Promise<OrderTicketReceiptModel | null> => {
  const { calendar, data } = payload;

  if (!calendar) return null;

  const isPershing = roles?.includes(UserRoleEnum.PershingOrderSender);

  if (isPershing) return pershingOrderTicketBulkSubmissionSwitchFn(calendar, data.pershing);
  return janneyOrderTicketSubmissionSwitchFn(calendar, data.janney);
};

export const useOrderTicketSubmissionSwitch = (
  options?: Partial<OrderTicketSubmissionSwitchOptions>,
): OrderTicketSubmissionResult => {
  const queryClient = useQueryClient();

  const { query } = useAtomValue(calendarManagerAtom);
  const selectedCalendar = useAtomValue(calendarSelectedAtom);

  const { data: user } = useUserInfoQuery();
  const roles = user?.details.roles;

  const { onSuccess, onMutate, onError } = useOrderTicketSubmissionSwitchHandlers();

  return useMutation<OrderTicketReceiptModel | null, BulkAllocationError, OrderTicketSubmissionSwitchPayload>({
    mutationFn: (payload) => orderTicketSubmissionSwitchFn(payload, roles),
    onMutate: (...args) => {
      options?.onMutate?.(...args);
      onMutate?.(...args);
    },
    onError: (...args) => {
      options?.onError?.(...args);
      onError?.(...args);
    },
    onSuccess: (...args) => {
      const calendarId = args[1]?.calendar?.id;
      if (calendarId) void queryClient.refetchQueries({ queryKey: CalendarsQueryKeyFactory.allocations(calendarId) });

      const calendarKey = CalendarsQueryKeyFactory.calendar(selectedCalendar?.id);
      void queryClient.refetchQueries({ queryKey: calendarKey });

      const queryKey = CalendarsQueryKeyFactory.infinite(query);
      void queryClient.refetchQueries({ queryKey });

      options?.onSuccess?.(...args);
      onSuccess?.(...args);
    },
  });
};
