import { AllocationStatusEnum, SMAAllocationStatusEnum } from '@halo-common/enums';

export const PENDING_ALLOCATION_STATUSES: Array<string> = [
  AllocationStatusEnum.accepted,
  AllocationStatusEnum.pending,
  AllocationStatusEnum.new,
  SMAAllocationStatusEnum.approved,
  SMAAllocationStatusEnum.pending,
  SMAAllocationStatusEnum.submitted,
];
