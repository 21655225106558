/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AccountDesignationEnum,
  AccountTypeEnum,
  ConfirmationEnum,
  OrganizationCapacityEnum,
} from '@halo-common/enums';
import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { number as yupNumber, object as yupObject, string as yupString } from 'yup';

export type BulkOrderTicketAllocationFields = {
  accountOption: AccountTypeAheadOption | null;
  capacityType?: AccountDesignationEnum | null;
  discretion?: ConfirmationEnum | null;
  solicited?: ConfirmationEnum | null;
  quantity?: number;
  ttoRepCode?: string;
};

export type OrderTicketCommonFields = {
  solicited: ConfirmationEnum | '';
  discretion: ConfirmationEnum | '';
  accountType: AccountTypeEnum | '';
  compensation?: string;
  ip1?: string;
  ip1Percent?: string;
  ip2?: string;
  ip2Percent?: string;
  legend1?: string;
  legend2?: string;
  trailer1?: string;
  trailer2?: string;
  trailer3?: string;
  orderDate: string;
  orderFrom: string;
  disclaimer?: boolean;
};

export type BulkOrderTicketFormFields = OrderTicketCommonFields & {
  allocations: Array<BulkOrderTicketAllocationFields>;
  capacityType?: OrganizationCapacityEnum | null;
};

export type SingleOrderTicketFormFields = OrderTicketCommonFields & {
  account: AccountTypeAheadOption | null;
  quantity: string;
  ttoRepCode: string;
  // TODO: Uncomment when rep code data is complete on BE
  // ttoRepCode: AdviseeSearchModel | null;
};

export type OrderTicketFormModelFields = BulkOrderTicketFormFields | SingleOrderTicketFormFields;

export const orderTicketCompensationSchema = yupString()
  .when('capacityType', {
    is: (capacityType: string) => (capacityType as OrganizationCapacityEnum) === OrganizationCapacityEnum.PRINCIPAL,
    then: (schema) => schema.required(translations.common.required),
  })
  .when('ip1', {
    is: (ip1: string) => Boolean(ip1),
    then: (schema) => schema.required(translations.common.required),
  })
  .when('ip2', {
    is: (ip2: string) => Boolean(ip2),
    then: (schema) => schema.required(translations.common.required),
  })
  .when('ip1Percent', {
    is: (ip1Percent: string) => parseInt(ip1Percent) > 0,
    then: (schema) => schema.required(translations.common.required),
  })
  .when('ip2Percent', {
    is: (ip2Percent: string) => parseInt(ip2Percent) > 0,
    then: (schema) => schema.required(translations.common.required),
  });

export const orderTicketIP1Schema = yupString()
  .when('ip1Percent', {
    is: (ip1Percent: string) => parseInt(ip1Percent) > 0,
    then: (schema) => schema.required(translations.common.required),
  })
  .when('ip2', {
    is: (ip2: string) => Boolean(ip2),
    then: (schema) => schema.required(translations.common.required),
  });

export const orderTicketIP1PercentSchema = yupString().when('ip1', {
  is: (ip1: string) => Boolean(ip1),
  then: (schema) => schema.required(translations.common.required),
});

export const orderTicketIP2Schema = yupString()
  .when('ip2Percent', {
    is: (ip2Percent: string) => parseInt(ip2Percent) > 0,
    then: (schema) => schema.required(translations.common.required),
  })
  .when('ip1Percent', {
    is: (ip1Percent: string) => parseInt(ip1Percent) < 100,
    then: (schema) => schema.required(translations.common.required),
  });

export const orderTicketIP2PercentSchema = yupString()
  .test('is-ip-2-percent-above-0', 'IP2 must be greater than 0.', (ip2Percent?: string) => {
    return !ip2Percent || parseInt(ip2Percent) > 0;
  })
  .test('is-ip-2-percent-below-100', 'IP2 must be less than 100.', (ip2Percent?: string) => {
    return !ip2Percent || parseInt(ip2Percent) < 100;
  })
  .when('ip1Percent', {
    is: (ip1Percent: string) => parseInt(ip1Percent) < 100,
    then: (schema) => schema.required(translations.common.required),
  })
  .when('ip2', {
    is: (ip2: string) => Boolean(ip2),
    then: (schema) => schema.required(translations.common.required),
  });

export const orderTicketAccountOptionSchema = yupObject()
  .shape({
    account: yupObject().shape({
      id: yupNumber(),
      designation: yupString().nullable().notRequired(),
    }),
  })
  .nullable()
  .required(translations.allocationsModal.common.accountRequiredError);

export const orderTicketQuantitySchema = yupNumber()
  .required(translations.allocationsModal.common.qtyRequiredError)
  .positive(translations.allocationsModal.common.qtyRequiredError)
  .integer(translations.allocationsModal.common.qtyIntegerError);

// TODO: Figure out the typing for this
export const duplicateAccountTest = {
  name: 'are-allocation-accounts-unique',
  test: (currentAllocation: BulkOrderTicketAllocationFields, ctx: any) => {
    const { parent, createError, path } = ctx;

    const currentAllocationId = currentAllocation.accountOption?.account?.id;
    const allocations = parent as Array<BulkOrderTicketAllocationFields>;

    const foundDup = allocations?.findIndex((allocation, index) => {
      const ignoreIndex = path === `allocations[${index}]`;
      return !ignoreIndex && allocation.accountOption?.account?.id === currentAllocationId;
    });

    if (foundDup >= 0) {
      return createError({
        message: translations.orderTicket.duplicatedAccountsMessage,
        path: `${path}.accountOption`,
      });
    }

    return true;
  },
} as any;

export const singleOrderTicketSchema = yupObject().shape({
  account: orderTicketAccountOptionSchema,
  quantity: orderTicketQuantitySchema,
  orderFrom: yupString().required(translations.common.required),
});
