import { MutableRefObject, RefObject, ReactElement } from 'react';

import { AuctionAdminModel, NoteModel } from '@halo-common/models';
import { OrdersSelectors } from '@halo-stores/Orders';
import { HaloDataGrid, HaloDataGridProps } from '@halodomination/halo-fe-common';
import { GridAlignment, GridApiPro, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useSelector } from 'react-redux';

import { TackOnTableWhoCell, TackOnTableWhoCellProps } from './TackOnTableWhoCell';

const rowSx = {
  ['& .who-cell']: {
    backgroundColor: 'background.default',
  },
};

export type ColumnHeader = {
  field: string;
  type?: string;
  align?: GridAlignment;
  headerName: string;
};

export type TackOnTableDataGridProps = Partial<HaloDataGridProps> & {
  cta: TackOnTableWhoCellProps['cta'];
  isLoading?: boolean;
  data: Array<AuctionAdminModel>;
};

export type TackOnTableProps = {
  inProgress?: boolean;
  DataGridProps: TackOnTableDataGridProps;
  gridRef: RefObject<GridApiPro>;
  columnHeaders: HaloDataGridProps['columns'];
};

export const TackOnTable = ({
  inProgress = false,
  DataGridProps,
  gridRef,
  columnHeaders,
}: TackOnTableProps): ReactElement => {
  const { cta, data = [], ...otherDataGridProps } = DataGridProps;

  const status = useSelector(OrdersSelectors.selectStatus);

  const isLoading = status === 'requesting';

  const dataGridSpecificColumns = columnHeaders.map((header) => ({
    minWidth: 200,
    flex: 1,
    ...header,
  }));

  const columns = [
    {
      field: 'who',
      headerName: 'WHO',
      minWidth: 300,
      cellClassName: 'who-cell',
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => {
        const orgName = row?.organizationName as string;
        const salesRepName = row?.salesRepresentative as string;
        const buyerName = row?.buyer as string;
        const note = row?.note as NoteModel;

        const underlyers = note?.tradables?.map(({ name }) => name?.toUpperCase())?.join('; ');

        return (
          <TackOnTableWhoCell
            inProgress={inProgress}
            row={row as AuctionAdminModel}
            buyerName={buyerName}
            salesRepName={salesRepName}
            underlyers={underlyers}
            orgName={orgName}
            isLoading={isLoading}
            cta={cta}
          />
        );
      },
    },
    ...dataGridSpecificColumns,
  ];

  return (
    <HaloDataGrid
      {...otherDataGridProps}
      height={800}
      rowHeight={165}
      apiRef={gridRef as MutableRefObject<GridApiPro>}
      rows={data}
      sx={rowSx}
      columns={columns}
      disableRowSelectionOnClick
    />
  );
};
