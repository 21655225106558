import { ReactElement, useState } from 'react';

import { productDetailModalAnalyticsTabStateAtom } from '@halo-atoms/pdm';
import { UnderlyingPerformanceTable } from '@halo-common/components';
import { MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { NoteTypeEnum } from '@halo-common/enums';
import {
  ProductDetailModalAnalyticsRollingReturnsNoteSummary,
  ProductDetailModalAnalyticsRollingReturnsUnderlyingPerformanceChart,
} from '@halo-common/layouts';
import { NoteModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useRollingReturnsUnderlyingQuery } from '@halo-data-sources/queries';
import { HaloDataGridProps, Modal, Stack } from '@halodomination/halo-fe-common';
import { useAtomValue } from 'jotai';
import { DateTime } from 'luxon';

export type ProductDetailModalAnalyticsRollingReturnsUnderlyingModalProps = {
  open: boolean;
  product?: NoteModel | null;
  onClose: () => void;
};

export const ProductDetailModalAnalyticsRollingReturnsUnderlyingModal = ({
  open,
  product,
  onClose,
}: ProductDetailModalAnalyticsRollingReturnsUnderlyingModalProps): ReactElement => {
  const state = useAtomValue(productDetailModalAnalyticsTabStateAtom);

  const [hiddenLines, setHiddenLines] = useState<Array<string>>([]);

  const productId = product?.id;
  const protection = product?.protectionPercent;
  const basket = product?.tradableWeights;
  const isGeneral = product?.type === NoteTypeEnum.General;
  const isRecall = product?.coupon?.isRecall;
  const isIssuerCall = product?.callInformation?.isIssuerCall;
  const couponProtection = product?.coupon?.protectionPercent;

  const { underlyingModalStartDate, underlyingModalEndDate } = state;
  const startDate = underlyingModalStartDate ?? DateTime.local();
  const endDate = underlyingModalEndDate ?? null;
  const formattedStartDate = startDate?.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);
  const formattedEndDate = endDate?.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);

  const chartQuery = { startDate, endDate, basket, protection, couponProtection };

  const hasDates = formattedStartDate && formattedEndDate;
  const dateLine = hasDates ? `${formattedStartDate} - ${formattedEndDate}` : undefined;

  const underlyingQuery = useRollingReturnsUnderlyingQuery(productId, chartQuery, { enabled: open });
  const { data: underlyingData, isPending: underlyingLoading } = underlyingQuery;

  const data = underlyingData?.chart.data ?? [];
  const lines = underlyingData?.chart.lines ?? [];
  const references = underlyingData?.chart.references ?? [];
  const rows = underlyingData?.table.statistics ?? [];

  const showSummarySection = !isIssuerCall && !isRecall && !isGeneral;

  const handleFixedFormat = (value?: number) => {
    return value ? value.toFixed(2) : '-';
  };

  const handleFixedPercentFormat = (value?: number) => {
    return value ? `${value.toFixed(2)}%` : '-';
  };

  const handleLineToggle = (label: string) => {
    setHiddenLines((prev) => (prev.includes(label) ? prev.filter((line) => line !== label) : [...prev, label]));
  };

  const handleClose = () => {
    setHiddenLines([]);
    onClose();
  };

  const columns: HaloDataGridProps['columns'] = [
    {
      field: 'initialLevel',
      headerName: 'initial level',
      width: 100,
      valueFormatter: (value) => handleFixedFormat(value),
    },
    {
      field: 'level',
      headerName: 'level at maturity',
      width: 135,
      valueFormatter: (value) => handleFixedFormat(value),
    },
    {
      field: 'percentageChange',
      headerName: 'change',
      width: 100,
      valueFormatter: (value) => handleFixedPercentFormat(value),
    },
    {
      field: 'breachLevel',
      headerName: 'barrier level',
      width: 110,
      valueFormatter: (value) => handleFixedFormat(value),
    },
    {
      field: 'distanceToBarrier',
      headerName: 'dist. to barrier at maturity',
      flex: 1,
      valueFormatter: (value) => handleFixedPercentFormat(value),
    },
  ];

  const summarySection = showSummarySection ? (
    <ProductDetailModalAnalyticsRollingReturnsNoteSummary product={product} overline={dateLine} />
  ) : null;

  const Localization = {
    title: {
      dynamicContent: {
        dateLine: dateLine || '',
      },
    },
  };

  return (
    <Modal
      open={open}
      title={translations.pdm.preTrade.dateLineReturnPeriod}
      Localization={Localization}
      onClose={handleClose}
      walkMeNamespace="wm-rr-underlying"
    >
      <Stack direction="column" spacing={2} xs={12}>
        <ProductDetailModalAnalyticsRollingReturnsUnderlyingPerformanceChart
          lines={lines}
          data={data}
          references={references}
          hiddenLines={hiddenLines}
          loading={underlyingLoading}
          basket={basket}
          protectionType={product?.protectionType}
        />
        <UnderlyingPerformanceTable
          rows={rows}
          columns={columns}
          loading={underlyingLoading}
          onToggle={handleLineToggle}
        />
        {summarySection}
      </Stack>
    </Modal>
  );
};
