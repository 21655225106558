import { ReactElement } from 'react';

import { Jumbotron } from '@halodomination/halo-fe-common';

const jumbotronSx = {
  backgroundColor: `linear-gradient(270deg, primary.main, primary.dark)`,
};

const titleSx = {
  pl: 5,
};

export type PageHeaderProps = {
  title: string;
};

export const PageHeader = ({ title }: PageHeaderProps): ReactElement => {
  return (
    <Jumbotron sx={jumbotronSx} titleSx={titleSx}>
      {title}
    </Jumbotron>
  );
};
