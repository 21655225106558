import { ReactElement } from 'react';

import { bulkOrderTicketManagerAtom } from '@halo-atoms/orderTicket';
import { ActionButton } from '@halo-common/components';
import { Iconography, Stack } from '@halodomination/halo-fe-common';
import { Button } from '@mui/material';
import { useAtomValue } from 'jotai';

export type OrderTicketFormFooterProps = {
  loading?: boolean;
  hideReviewButton?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
};

export const OrderTicketFormFooter = ({
  onSubmit,
  onCancel,
  loading = false,
  hideReviewButton = false,
}: OrderTicketFormFooterProps): ReactElement => {
  const { pendingValidateAllocations } = useAtomValue(bulkOrderTicketManagerAtom);

  const isLoading = loading || Boolean(pendingValidateAllocations?.length);

  const reviewButton = !hideReviewButton ? (
    <ActionButton loading={isLoading} type="button" variant="contained" color="primary" onClick={onSubmit}>
      Review
    </ActionButton>
  ) : null;

  return (
    <Stack direction="row" justify="space-between">
      <Button
        type="button"
        variant="text"
        color="primary"
        onClick={onCancel}
        startIcon={<Iconography iconName="times" color="primary.main" />}
      >
        Cancel
      </Button>
      {reviewButton}
    </Stack>
  );
};
