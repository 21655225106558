import { ReactElement } from 'react';

import { OrderReviewCard, OrderReviewSection } from '@halo-common/components';
import { MONTH_DAY_YEAR_DATE_FORMAT, ORDER_TICKET_ORDER_DATE_FORMAT } from '@halo-common/constants';
import { useOrderTicketDesignation, useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { OrderTicketExecOrderModel, OrderTicketReceiptModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useCalendarQuery } from '@halo-data-sources/queries';
import {
  HaloDataGrid,
  HaloDataGridProps,
  mapNumberToLocalCurrency,
  useCurrencyConverter,
} from '@halodomination/halo-fe-common';
import { capitalize } from '@mui/material';
import { GridPinnedRowsProp } from '@mui/x-data-grid-pro';

import { ExecutionBulkOrderAlert } from './ExecutionBulkOrderAlert';

export type ExecutionBulkOrderReceiptProps = {
  review?: boolean;
  calendarId?: number | null;
  orders?: OrderTicketReceiptModel['orders'];
  orderReceiptTime?: string | null;
  transactionType?: 'buy' | 'sell';
};

export const ExecutionBulkOrderReceipt = ({
  review = false,
  calendarId,
  orders = [],
  orderReceiptTime,
  transactionType,
}: ExecutionBulkOrderReceiptProps): ReactElement => {
  const configureDateTime = useWhiteLabelDateConfiguration();
  const getDesignation = useOrderTicketDesignation();

  const { data: calendar } = useCalendarQuery(calendarId);

  const note = calendar?.note;
  const cusip = calendar?.cusip ?? '';
  const originalPrice = calendar?.wholesaler?.salePrice;
  const displayName = calendar?.displayName;

  const [wholesalerOfferingPrice] = useCurrencyConverter(originalPrice);

  const offeringPrice = wholesalerOfferingPrice || '-';

  const formattedSettlementDate = configureDateTime(calendar?.settleDate)?.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);
  const formattedOrderDate = configureDateTime(orderReceiptTime)?.toFormat(ORDER_TICKET_ORDER_DATE_FORMAT);

  const underlyings = note?.tradables?.map((tradable) => tradable.name).join(', ') ?? '';
  const transaction = transactionType ? capitalize(transactionType) : '';

  const aggregation = orders.reduce(
    (aggregate, { order, summary }) => ({
      quantity: aggregate.quantity + order.quantity,
      salesCredit: aggregate.salesCredit + (summary?.salesCredit ?? 0),
    }),
    { quantity: 0, salesCredit: 0 },
  );

  const columns: HaloDataGridProps['columns'] = [
    {
      flex: 1,
      field: 'accountNumber',
      headerName: 'Account',
      valueGetter: (_, row: OrderTicketExecOrderModel) => row?.order?.accountId,
    },
    {
      flex: 1,
      field: 'quantity',
      headerName: 'Qty (1000)',
      valueGetter: (_, row: OrderTicketExecOrderModel) => row?.order?.quantity,
    },
    {
      flex: 1,
      field: 'designation',
      headerName: 'Type',
      valueGetter: (_, row: OrderTicketExecOrderModel) => {
        const { label: accountDesignation } = getDesignation(row?.allocation?.account);
        const designation = row?.order?.designation?.toLowerCase() ?? accountDesignation ?? '';
        return designation ? capitalize(designation) : '';
      },
    },
    {
      flex: 1,
      field: 'solicited',
      headerName: 'Solicited?',
      valueGetter: (_, row: OrderTicketExecOrderModel) => {
        const solicited = row?.order?.solicited?.toLowerCase() ?? '';
        return capitalize(solicited);
      },
    },
    {
      flex: 1,
      field: 'discretion',
      headerName: 'Discretionary?',
      valueGetter: (_, row: OrderTicketExecOrderModel) => {
        const discretion = row?.order?.discretion?.toLowerCase() ?? '';
        return capitalize(discretion);
      },
    },
    {
      flex: 1,
      field: 'ttoRepId',
      headerName: 'TTO Rep',
      valueGetter: (_, row) => {
        const defaultValue = row.id !== 'footer' ? '-' : '';
        return row?.order?.ttoRepCode?.toUpperCase() ?? defaultValue;
      },
    },
    {
      flex: 1,
      field: 'salesCredit',
      headerName: 'Sales Credit',
      valueGetter: (_, row: OrderTicketExecOrderModel) => {
        const salesCredit = row?.summary?.salesCredit;
        return mapNumberToLocalCurrency(salesCredit);
      },
    },
    {
      flex: 1,
      field: 'orderEstimate',
      headerName: 'Net Money',
      valueGetter: (_, row: OrderTicketExecOrderModel) => {
        const total = row?.summary?.total;
        return typeof total === 'number' ? mapNumberToLocalCurrency(total) : '';
      },
    },
  ];

  const footerRow: GridPinnedRowsProp = {
    bottom: [
      {
        id: 'footer',
        order: { quantity: aggregation.quantity },
        summary: { salesCredit: aggregation.salesCredit },
      },
    ],
  };

  const transactionTypeContent = transaction ? (
    <OrderReviewSection header="Transaction">{transaction}</OrderReviewSection>
  ) : null;

  return (
    <>
      <ExecutionBulkOrderAlert review={review} date={orderReceiptTime} />
      <OrderReviewCard
        title={translations.orderTicket.reviewCardTitle}
        subtitle={displayName}
        dynamicContent={{ title: { quantity: aggregation.quantity, underlyings } }}
      >
        <HaloDataGrid
          columns={columns}
          rows={orders}
          density="compact"
          pinnedRows={footerRow}
          isCellEditable={() => false}
        />
        <OrderReviewSection header="CUSIP">{cusip}</OrderReviewSection>
        {transactionTypeContent}
        <OrderReviewSection header="Offering Price">{offeringPrice}</OrderReviewSection>
        <OrderReviewSection header="Time Received">{formattedOrderDate}</OrderReviewSection>
        <OrderReviewSection header="Settlement Date">{formattedSettlementDate}</OrderReviewSection>
      </OrderReviewCard>
    </>
  );
};
