import { ReactElement } from 'react';

import { ConfirmationEnum } from '@halo-common/enums';
import { OrderTicketFormModelFields } from '@halo-common/schemas';
import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import { useController } from 'react-hook-form';

type DiscretionDropdownFieldNames = keyof OrderTicketFormModelFields | `allocations.${number}.discretion`;

export type DiscretionDropdownProps = TextFieldProps & {
  name: DiscretionDropdownFieldNames;
  defaultValue?: ConfirmationEnum | null;
};

export const DiscretionDropdown = ({ name, defaultValue, ...props }: DiscretionDropdownProps): ReactElement => {
  const {
    fieldState: { error },
    field: { ref, ...field },
  } = useController<OrderTicketFormModelFields, DiscretionDropdownFieldNames>({ name, defaultValue });

  const discretionError = error?.message as string;

  return (
    <TextField
      {...field}
      inputRef={ref}
      fullWidth
      size="large"
      select
      label="Discretion?"
      helperText={discretionError}
      error={Boolean(discretionError)}
      slotProps={{
        inputLabel: { shrink: Boolean(field.value) },
        select: { disabled: props.disabled },
      }}
      {...props}
    >
      <MenuItem value={ConfirmationEnum.Yes}>Yes</MenuItem>
      <MenuItem value={ConfirmationEnum.No}>No</MenuItem>
    </TextField>
  );
};
