import { portfolioPositionDetailsModalAtom } from '@halo-atoms/portfolio';
import { MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { ProductTypeEnum } from '@halo-common/enums';
import { usePrimaryAssetIdentifier } from '@halo-common/hooks';
import { PortfolioPositionModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import {
  GridRenderCellParams,
  HaloDataGridProps,
  LocalizedButton,
  LocalizedTypography,
  mapNumberToLocalCurrency,
} from '@halodomination/halo-fe-common';
import { Typography } from '@mui/material';
import { useSetAtom } from 'jotai';
import { DateTime } from 'luxon';

const shortNameBtnSx = {
  height: 'unset',
  minWidth: 'unset',
  fontWeight: 400,
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

const financialChangeSx = {
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  width: '100%',
  justifyContent: 'flex-end',
};

const productNameSx = {
  alignItems: 'center',
  color: 'primary.main',
  display: 'flex',
  fontWeight: 600,
  height: '100%',
  width: '100%',
};

export type UseAllPositionsTableColumnParams = {
  columnWidths: Record<string, number | undefined>;
};

export const useAllPositionsTableColumns = ({
  columnWidths,
}: UseAllPositionsTableColumnParams): HaloDataGridProps['columns'] => {
  const { data: user } = useUserInfoQuery();

  const { identifierType } = usePrimaryAssetIdentifier();

  const setPositionModal = useSetAtom(portfolioPositionDetailsModalAtom);

  const currencies = user?.whiteLabel.currencies ?? [];

  const renderFinancialChangeValue = (value: number, formattedValue: string) => {
    const isPositiveChange = value > 0;
    const isNegativeChange = value < 0;

    const color = isPositiveChange
      ? 'common.charts.positive.main'
      : isNegativeChange
        ? 'common.charts.negative.main'
        : undefined;

    return (
      <Typography variant="body2" color={color} sx={financialChangeSx}>
        {formattedValue}
      </Typography>
    );
  };

  const formatCurrency = (value = 0, currencyId: number) => {
    const currency = currencies.find((currency) => currency.id === currencyId);
    const code = currency?.code;
    const symbol = currency?.symbol;

    const currencyOptions = { currency: code, maximumFractionDigits: 0 };

    return mapNumberToLocalCurrency(value, currencyOptions, symbol);
  };

  return [
    {
      field: 'templateDisplayName',
      headerName: translations.portfolio.positions.allPositionsTableHeaderProductType,
      disableColumnMenu: true,
      minWidth: 117,
      width: columnWidths['templateDisplayName'],
      ...(!columnWidths['templateDisplayName'] && { flex: 2 }),
      renderCell: ({ row }: GridRenderCellParams<PortfolioPositionModel>) => {
        const productTypeText = row.templateDisplayName ?? 'N/A';

        return (
          <LocalizedTypography sx={productNameSx} variant="body2">
            {productTypeText}
          </LocalizedTypography>
        );
      },
    },
    {
      field: 'shortName',
      headerName: translations.portfolio.positions.allPositionsTableHeaderShortName,
      disableColumnMenu: true,
      minWidth: 189,
      width: columnWidths['shortName'],
      ...(!columnWidths['shortName'] && { flex: 1 }),
      renderCell: ({ row }: GridRenderCellParams<PortfolioPositionModel>) => {
        const buttonText = row.shortName ?? 'N/A';
        const id = row?.termsheetId;
        const type = row.templateDisplayName?.toLowerCase();

        const handleClick = () => setPositionModal({ id, type: type as ProductTypeEnum, currencyId: row.currencyId });

        return (
          <LocalizedButton sx={shortNameBtnSx} variant="text" color="primary" onClick={handleClick} disableRipple>
            {buttonText}
          </LocalizedButton>
        );
      },
    },
    {
      field: identifierType.toLowerCase(),
      headerName: identifierType.toUpperCase(),
      disableColumnMenu: true,
      minWidth: 117,
      width: columnWidths[identifierType],
      align: 'left',
      ...(!columnWidths[identifierType] && { flex: 1 }),
    },
    {
      field: 'issuerName',
      headerName: translations.portfolio.positions.allPositionsTableHeaderIssuer,
      disableColumnMenu: true,
      minWidth: 141,
      width: columnWidths['issuerName'],
      align: 'left',
      translateCell: true,
      ...(!columnWidths['issuerName'] && { flex: 1 }),
    },
    {
      field: 'notional',
      headerName: translations.portfolio.positions.allPositionsTableHeaderTotalNotional,
      disableColumnMenu: true,
      minWidth: 117,
      width: columnWidths['notional'],
      align: 'right',
      ...(!columnWidths['notional'] && { flex: 1 }),
      valueFormatter: (value: number, row: PortfolioPositionModel) => {
        return formatCurrency(value, row.currencyId);
      },
    },
    {
      field: 'marketValue',
      headerName: translations.common.marketValue,
      disableColumnMenu: true,
      minWidth: 117,
      width: columnWidths['marketValue'],
      align: 'right',
      ...(!columnWidths['marketValue'] && { flex: 1 }),
      valueFormatter: (value: number, row: PortfolioPositionModel) => {
        return formatCurrency(value, row.currencyId);
      },
    },
    {
      field: 'costBasis',
      headerName: translations.portfolio.positions.allPositionsTableHeaderCostBasis,
      disableColumnMenu: true,
      minWidth: 117,
      width: columnWidths['costBasis'],
      align: 'right',
      ...(!columnWidths['costBasis'] && { flex: 1 }),
      valueFormatter: (value: number) => {
        const costBasis = value?.toFixed(2) ?? 0;
        return costBasis.toLocaleString();
      },
    },
    {
      field: 'marketChange',
      headerName: translations.portfolio.positions.allPositionsTableHeaderUnrealizedGL,
      disableHeaderTextFormatting: true,
      disableColumnMenu: true,
      minWidth: 87,
      width: columnWidths['marketChange'],
      align: 'right',
      ...(!columnWidths['marketChange'] && { flex: 1 }),
      renderCell: ({ row }: GridRenderCellParams<PortfolioPositionModel>) => {
        const marketChange = row.marketChange ?? 0;

        const formattedMarketChange = formatCurrency(marketChange, row.currencyId);

        return renderFinancialChangeValue(marketChange, formattedMarketChange);
      },
    },
    {
      field: 'underlyingReturn',
      headerName: translations.portfolio.positions.allPositionsTableHeaderUnderlyingReturn,
      disableColumnMenu: true,
      minWidth: 87,
      width: columnWidths['underlyingReturn'],
      align: 'right',
      ...(!columnWidths['underlyingReturn'] && { flex: 1 }),
      renderCell: ({ row }: GridRenderCellParams<PortfolioPositionModel>) => {
        const underlyingReturn = row.underlyingReturn;

        const underlyingReturnText = underlyingReturn?.toFixed(2) ?? '0';
        const positiveSymbol = underlyingReturn > 0 ? '+' : '';
        const formattedUnderlyingReturn = `${positiveSymbol}${underlyingReturnText.toLocaleString()}%`;

        return renderFinancialChangeValue(underlyingReturn, formattedUnderlyingReturn);
      },
    },
    {
      field: 'noteReturn',
      headerName: translations.portfolio.positions.allPositionsTableHeaderNoteReturn,
      disableColumnMenu: true,
      minWidth: 87,
      width: columnWidths['noteReturn'],
      align: 'right',
      ...(!columnWidths['noteReturn'] && { flex: 1 }),
      renderCell: ({ row }: GridRenderCellParams<PortfolioPositionModel>) => {
        const noteReturn = row.noteReturn;

        const noteReturnText = noteReturn?.toFixed(2) ?? '0';
        const positiveSymbol = noteReturn > 0 ? '+' : '';
        const formattedNoteReturn = `${positiveSymbol}${noteReturnText.toLocaleString()}%`;

        return renderFinancialChangeValue(noteReturn, formattedNoteReturn);
      },
    },
    {
      field: 'pctBreach',
      headerName: translations.portfolio.positions.allPositionsTableHeaderBreach,
      disableColumnMenu: true,
      minWidth: 87,
      width: columnWidths['pctBreach'],
      align: 'right',
      ...(!columnWidths['pctBreach'] && { flex: 1 }),
      renderCell: ({ row }: GridRenderCellParams<PortfolioPositionModel>) => {
        const pctBreach = row.pctBreach;

        const pctBreachText = pctBreach?.toFixed(2) ?? '0';
        const formattedPctBreach = `${pctBreachText.toLocaleString()}%`;

        return renderFinancialChangeValue(pctBreach, formattedPctBreach);
      },
    },
    {
      field: 'valuation',
      headerName: translations.portfolio.positions.allPositionsTableHeaderValuation,
      disableColumnMenu: true,
      minWidth: 85,
      width: columnWidths['valuation'],
      align: 'right',
      ...(!columnWidths['valuation'] && { flex: 1 }),
      valueFormatter: (value: number) => {
        return value?.toFixed(2).toLocaleString() ?? 0;
      },
    },
    {
      field: 'maturityDate',
      headerName: translations.portfolio.positions.allPositionsTableHeaderMaturityDate,
      disableColumnMenu: true,
      minWidth: 117,
      width: columnWidths['maturityDate'],
      align: 'right',
      ...(!columnWidths['maturityDate'] && { flex: 1 }),
      valueFormatter: (value: DateTime) => {
        return value?.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);
      },
    },
  ];
};
