import { ReactNode } from 'react';

import { UserProvider } from '@halo-common/providers';
import { isInDevBuildEnv } from '@halodomination/halo-fe-common';
import { ThemeProvider } from '@halodomination/halo-fe-theme';
import { QueryClientProvider, useQueryClient } from '@tanstack/react-query';
import ReactDOMServer from 'react-dom/server';

const ASSET_IMG_SLUG = '/assets/img';

const getStyleSheets = () => {
  return Array.from(document.styleSheets)
    .map((styleSheet) => {
      try {
        return Array.from(styleSheet.cssRules)
          .map((rule) => rule.cssText)
          .join('');
      } catch (e) {
        // Access to stylesheet is denied, ignoring...
        if (isInDevBuildEnv()) console.log(e);
      }
    })
    .filter(Boolean)
    .join('');
};

/**
 * A custom hook that converts a React component into an HTML string with all necessary CSS styles, and
 * proper image source strings.
 *
 * @returns {Function} - A function that takes a ReactNode and returns its HTML string representation
 * with embedded styles.
 *
 * @example
 * const stringifyComponent = useStringifyComponent();
 * const htmlString = stringifyComponent(<MyComponent />);
 * console.log(htmlString);
 */
export const useStringifyComponent = () => {
  const queryClient = useQueryClient();
  const styles = getStyleSheets();

  const imgSrc = `${window.location.origin}${ASSET_IMG_SLUG}`;

  return (Component: ReactNode) => {
    const wrappedComponent = (
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <UserProvider>{Component}</UserProvider>
        </ThemeProvider>
      </QueryClientProvider>
    );

    const stringifiedComponent = ReactDOMServer.renderToString(wrappedComponent).replaceAll(ASSET_IMG_SLUG, imgSrc);

    return `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>${styles}</style>
      </head>
      <body>
        <div id="root">${stringifiedComponent}</div>
      </body>
    </html>
  `;
  };
};
