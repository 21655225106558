import { CalendarReducer } from '@halo-stores/Calendar';
import { FixReducer } from '@halo-stores/Fix';
import { OrdersReducer } from '@halo-stores/Orders';
import { PershingReducer } from '@halo-stores/Pershing';
import { HaloAppState } from '@halo-stores/types';
import { WebsocketReducer } from '@halo-stores/Websocket';
import { isInDevBuildEnv } from '@halodomination/halo-fe-common';
import { Action, combineReducers, EnhancedStore, Reducer, configureStore as rtkConfigureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

const reducers: Record<keyof HaloAppState, Reducer> = {
  Calendar: CalendarReducer,
  Fix: FixReducer,
  Orders: OrdersReducer,
  Pershing: PershingReducer,
  Websocket: WebsocketReducer,
};

const isDevMode = isInDevBuildEnv();

type ConfigureStoreResult = {
  store: EnhancedStore<HaloAppState, Action>;
};

export const configureStore = (): ConfigureStoreResult => {
  const logger = createLogger({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    predicate: (_getState, action) => isDevMode && action?.type,
    collapsed: true,
    duration: true,
    diff: true,
  });

  const persistedReducer = combineReducers(reducers);

  const store = rtkConfigureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        logger,
        serializableCheck: {
          ignoredActions: [],
        },
      }),
    devTools: true,
  });

  return { store };
};
