import { ProductApprovalQueryModel } from '@halo-atoms/productApproval';
import { AssetIdentifierEnum } from '@halo-common/enums';
import { CalendarModel, PaginationModel } from '@halo-common/models';
import { getCalendarOfferingsV2 } from '@halo-data-sources/clients';
import { ApiCalendarsMapper, PaginationMapper, ProductApprovalMapper } from '@halo-data-sources/mappers';
import { ProductApprovalQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query';

export type ProductApprovalCalendarResult = {
  calendars: Array<CalendarModel>;
  pagination: PaginationModel;
};

const productApprovalCalendarQueryFn = async (query: ProductApprovalQueryModel) => {
  const filters = { ...query, isVisible: true, showAsNME: false };

  const comparisons = ProductApprovalMapper.toProductQuery(filters);

  const sortKeys = {
    [AssetIdentifierEnum.CUSIP]: 'calendar_notes.cusip',
    [AssetIdentifierEnum.FUNDSERVCODE]: 'calendar_notes.fundservcode',
    [AssetIdentifierEnum.ISIN]: 'calendar_notes.isin',
    isActive: 'calendar_offering.is_active',
    payoff: 'calendar_notes.payoffstr',
    expirationDate: 'calendar_offering.expiration_date',
    issuer: 'issuers.name',
    protection: 'notes.protection_percent',
    protectionRank: 'computed.protection_rank',
    price: 'calendar_notes.price',
    term: 'terms.months',
    toggleColumn: 'calendar_offering.approved',
    type: 'notes.product',
    underlyings: 'computed.tradable_names',
  };

  const sort = query.sort.map((model) => ({
    ...model,
    field: sortKeys[model.field as keyof typeof sortKeys],
  }));

  const response = await getCalendarOfferingsV2({
    comparisons,
    comparison_type: 'AND',
    page: query.page ?? 1,
    page_length: query.pageLength ?? 100,
    sort,
  });

  const calendars = response.results.map(ApiCalendarsMapper.toCalendarModel);
  const pagination = PaginationMapper.toInfinitePaginationModel(response.pagination);

  return { calendars, pagination };
};

export const useProductApprovalCalendarQuery = (
  payload: ProductApprovalQueryModel,
): UseQueryResult<ProductApprovalCalendarResult, Error> =>
  useQuery<ProductApprovalCalendarResult, Error>({
    queryKey: ProductApprovalQueryKeyFactory.products(payload),
    queryFn: () => productApprovalCalendarQueryFn(payload),
    placeholderData: keepPreviousData,
    staleTime: 0,
  });
