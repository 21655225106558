import { useWhiteLabelRole, WhiteLabelRole } from '@halo-common/hooks';
import {
  BulkOrderTicketFormFields,
  janneyOrderTicketSchema,
  OrderTicketFormModelFields,
  pershingOrderTicketSchema,
  SingleOrderTicketFormFields,
  singleOrderTicketSchema,
} from '@halo-common/schemas';
import { OrderTicketValidationSwitchErrors } from '@halo-data-sources/index';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver } from 'react-hook-form';
import { AnyObjectSchema } from 'yup';

import { buildPershingOrderTicketFormResolver } from './resolvers/pershingResolver';

const BULK_DEFAULTS: Pick<
  BulkOrderTicketFormFields,
  'allocations' | 'accountType' | 'capacityType' | 'discretion' | 'solicited'
> = {
  allocations: [{ accountOption: null }],
  capacityType: null,
  accountType: '',
  discretion: '',
  solicited: '',
};

const SINGLE_DEFAULTS: Pick<SingleOrderTicketFormFields, 'account' | 'discretion' | 'ttoRepCode'> = {
  account: null,
  discretion: '',
  ttoRepCode: '',
  // TODO: Uncomment when rep code data is complete on BE
  // ttoRepCode: null,
};

export type OrderTicketFormOptions = {
  fixErrors?: OrderTicketValidationSwitchErrors;
  legendCodeOverride?: string;
};

const buildOrderTicketSchema = (whiteLabel: WhiteLabelRole): AnyObjectSchema => {
  switch (whiteLabel) {
    case 'janney':
      return janneyOrderTicketSchema;

    case 'pershing':
    case 'pershingDev':
      return pershingOrderTicketSchema;

    default:
      return singleOrderTicketSchema;
  }
};

const buildOrderTicketFormDefaults = (whiteLabel: WhiteLabelRole, options: OrderTicketFormOptions) => {
  switch (whiteLabel) {
    case 'janney':
      return BULK_DEFAULTS;

    case 'pershing':
    case 'pershingDev':
      return { ...BULK_DEFAULTS, legend1: options.legendCodeOverride };

    default:
      return SINGLE_DEFAULTS;
  }
};

const buildOrderTicketFormResolver = (
  whiteLabel: WhiteLabelRole,
  schema: AnyObjectSchema,
  options: OrderTicketFormOptions,
): Resolver<OrderTicketFormModelFields> => {
  switch (whiteLabel) {
    case 'pershing':
    case 'pershingDev':
      return buildPershingOrderTicketFormResolver(schema, options.fixErrors);

    default:
      return yupResolver(schema);
  }
};

export const useOrderTicketForm = (options: OrderTicketFormOptions) => {
  const role = useWhiteLabelRole();

  const schema = buildOrderTicketSchema(role);
  const defaults = buildOrderTicketFormDefaults(role, options);
  const resolver = buildOrderTicketFormResolver(role, schema, options);

  return { schema, defaults, resolver };
};
