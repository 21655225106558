import { translations } from '@halo-common/translations';
import { array as yupArray, boolean as yupBoolean, object as yupObject, string as yupString } from 'yup';

import {
  orderTicketAccountOptionSchema,
  orderTicketQuantitySchema,
  duplicateAccountTest,
  orderTicketCompensationSchema,
  orderTicketIP1Schema,
  orderTicketIP1PercentSchema,
  orderTicketIP2Schema,
  orderTicketIP2PercentSchema,
} from '../orderTicketFormSchema';

const pershingBulkOrderTicketAllocationSchema = yupObject()
  .shape({
    accountOption: orderTicketAccountOptionSchema,
    quantity: orderTicketQuantitySchema,
  })
  .test(duplicateAccountTest);

const pershingBulkOrderTicketAllocationListSchema = yupArray()
  .of(pershingBulkOrderTicketAllocationSchema)
  .min(1, translations.allocationsModal.common.allocationRequiredError)
  .required(translations.allocationsModal.common.allocationRequiredError);

export const pershingOrderTicketSchema = yupObject().shape(
  {
    allocations: pershingBulkOrderTicketAllocationListSchema,
    orderFrom: yupString().required(translations.common.required),
    accountType: yupString().required(translations.common.required),
    capacityType: yupString().required(translations.common.required),
    compensation: orderTicketCompensationSchema,
    disclaimer: yupBoolean().notRequired(),
    discretion: yupString().required(translations.common.required),
    ip1: orderTicketIP1Schema,
    ip1Percent: orderTicketIP1PercentSchema,
    ip2: orderTicketIP2Schema,
    ip2Percent: orderTicketIP2PercentSchema,
    legend1: yupString(),
    legend2: yupString(),
    trailer1: yupString(),
    trailer2: yupString(),
    trailer3: yupString(),
    solicited: yupString().required(translations.common.required),
  },
  [
    ['ip1', 'ip2'],
    ['ip1', 'ip1Percent'],
    ['ip2', 'ip2Percent'],
  ],
);
