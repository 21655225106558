import { ReactElement, ReactNode } from 'react';

import { Iconography, IconographyProps, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';
import { ITranslateParams } from '@transifex/native';

const iconLabelSx = {
  textTransform: 'capitalize',
  ml: 0.5,
};

export type FixedIncomeProductCardDetailColumnProps = {
  label?: string;
  icon?: IconographyProps['iconName'];
  children: ReactNode;
  dynamicContent?: ITranslateParams;
};

export const FixedIncomeProductCardDetailColumn = ({
  children,
  label = '',
  icon = 'info-circle',
  dynamicContent,
}: FixedIncomeProductCardDetailColumnProps): ReactElement => (
  <Stack spacing={0.5} direction={{ md: 'column', xs: 'row' }} alignItems={{ md: 'flex-start', xs: 'center' }}>
    <Stack direction="row" alignItems="center">
      <Iconography iconName={icon} prefix="fal" color="text.disabled" />
      <LocalizedTypography sx={iconLabelSx} variant="subtitle2" dynamicContent={dynamicContent}>
        {children}
      </LocalizedTypography>
    </Stack>
    <LocalizedTypography variant="caption" color="text.secondary">
      {label}
    </LocalizedTypography>
  </Stack>
);
