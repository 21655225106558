import { ReactElement } from 'react';

import { useWhiteLabelRole } from '@halo-common/hooks';

import { OrderTicketJanneyReceipt } from './OrderTicketJanneyReceipt';
import { OrderTicketPershingReceipt } from './OrderTicketPershingReceipt';

export type OrderTicketReceiptContentProps = {
  review?: boolean;
};

export const OrderTicketReceiptContent = ({ review = false }: OrderTicketReceiptContentProps): ReactElement | null => {
  const role = useWhiteLabelRole();

  switch (role) {
    case 'janney':
      return <OrderTicketJanneyReceipt review={review} />;

    case 'pershing':
    case 'pershingDev':
      return <OrderTicketPershingReceipt review={review} />;

    default:
      return null;
  }
};
