export enum CallEventCalledStatus {
  CALLED = 'Called',
  NOT_CALLED = 'Not Called',
  PENDING = 'Pending',
}

export enum CouponEventPaidStatus {
  PAID = 'Paid',
  NOT_PAID = 'Not Paid',
  PENDING = 'Pending',
}
