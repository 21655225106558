import { useEffect } from 'react';

import { loadingDockUploadAtom, LoadingDockUploadModel } from '@halo-atoms/loadingDock';
import { translations } from '@halo-common/translations';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { useAtom } from 'jotai';

import { ToastWebsocketEvent, ToastWebsocketEventLevelEnum, WebsocketToastListenerEvent } from '../useEventListener';

type LoadingDockUploadHook = LoadingDockUploadModel & {
  reset: () => void;
};

/**
 * useLoadingDockUpload is a custom hook that manages the state of the Loading Dock's UploadCalendarModal.
 *
 * This hook provides the current state of the Loading Dock upload and a function to reset the state.
 * It also listens for toast events from a WebSocket context and displays appropriate notifications.
 *
 * @returns {LoadingDockUploadHook} An object containing the current state of the loading dock upload and function to reset the state.
 * - total: The total number of items to process.
 * - processed: The number of items processed.
 * - rows: An object containing the number of items added, edited, errored, and the total number of items.
 * - reset: A function to reset the loading dock upload state.
 *
 * @example
 * const { total, processed, rows, reset } = useLoadingDockUpload();
 *
 * // Reset the loading dock upload state
 * reset();
 */
export const useLoadingDockUpload = (toastEvent?: WebsocketToastListenerEvent): LoadingDockUploadHook => {
  const [loadingDockUpload, setLoadingDockUpload] = useAtom(loadingDockUploadAtom);
  const { enqueueErrorEvent, enqueueWarningEvent } = useSnackbar();

  const toastNotification = toastEvent?.current;

  const setAtom = (payload?: ToastWebsocketEvent) => {
    setLoadingDockUpload(payload);

    if (payload?.level === ToastWebsocketEventLevelEnum.WARNING) {
      const defaultMessage = translations.loadingDock.uploadFile.uploadWarning;
      const message = `${payload?.title}: ${payload?.message || defaultMessage}`;
      enqueueWarningEvent({ message });
    }

    if (payload?.level === ToastWebsocketEventLevelEnum.ERROR) {
      const defaultMessage = translations.messages.errorContactSupport;
      const message = `${payload?.title}: ${payload?.message || defaultMessage}`;
      enqueueErrorEvent({ message });
    }
  };

  useEffect(() => {
    if (toastNotification) setAtom(toastNotification);
  }, [toastNotification]);

  return {
    ...loadingDockUpload,
    reset: () => setAtom(),
  };
};
