import { BulkOrderTicketFormFields, OrderTicketFormModelFields } from '@halo-common/schemas';
import { OrderTicketValidationSwitchErrors } from '@halo-data-sources/switches';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldErrors, Resolver } from 'react-hook-form';
import { AnyObjectSchema } from 'yup';

export const buildPershingOrderTicketFormResolver = (
  schema: AnyObjectSchema,
  fixErrors?: OrderTicketValidationSwitchErrors,
): Resolver<OrderTicketFormModelFields> => {
  return async (data, context, options) => {
    const schemaErrors = await yupResolver(schema)(data, context, options);

    const { allocations } = data as BulkOrderTicketFormFields;
    const errors = schemaErrors.errors as FieldErrors<BulkOrderTicketFormFields>;
    const schemaAllocationErrors = (errors.allocations ?? []) as FieldErrors<BulkOrderTicketFormFields['allocations']>;

    const mergedErrors = allocations.map((allocation, index) => {
      const accountId = allocation.accountOption?.account?.id;
      const fixError = accountId && fixErrors ? fixErrors[accountId] : undefined;
      const validationError = schemaAllocationErrors[index]?.accountOption?.message;
      if (fixError) {
        const errorMessages = [validationError, fixError].filter(Boolean).join(' - ');
        return { accountOption: { message: errorMessages } };
      }
      return schemaAllocationErrors[index];
    });

    const hasAllocationErrors = mergedErrors.filter(Boolean).length > 0;

    let updatedErrors = {
      ...errors,
      allocations: mergedErrors,
    } as FieldErrors<OrderTicketFormModelFields>;

    if (!hasAllocationErrors) updatedErrors = { ...errors };

    return {
      values: schemaErrors.values,
      errors: updatedErrors,
    };
  };
};
