import { getQueryParams } from '@halo-common/utils';
import { sendResetPasswordEmailV2 } from '@halo-data-sources/clients';
import { CheckEmailPageMeta } from '@halo-pages/app/onboarding/check-email';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

export type SendResetPasswordEmailModel = {
  email: string;
};

const sendResetPasswordEmailMutationFn = async (payload: SendResetPasswordEmailModel) => {
  await sendResetPasswordEmailV2(payload);
  return payload.email;
};

export const useSendResetPasswordEmailMutation = (
  navigate?: boolean,
): UseMutationResult<string, Error, SendResetPasswordEmailModel> =>
  useMutation<string, Error, SendResetPasswordEmailModel>({
    mutationFn: sendResetPasswordEmailMutationFn,
    onSettled: (_, __, payload) => {
      if (navigate) {
        const email = payload.email;
        const queryParams = getQueryParams();
        const params = new URLSearchParams({ ...queryParams, email });
        window.location.assign(`/react${CheckEmailPageMeta.route}?${params.toString()}`);
      }
    },
  });
