import { ActionButton } from '@halo-common/components';
import { ConnectedContactButton } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { useSendResetPasswordEmailMutation } from '@halo-data-sources/mutations/onboarding';
import { OnboardingLayout } from '@halo-modules/app/onboarding';
import {
  Iconography,
  LocalizedAlert,
  LocalizedButton,
  LocalizedTypography,
  PageComponent,
  PageMeta,
} from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';
import { useRouter } from 'next/router';

const buttonSx = {
  mt: 3,
  mb: 1,
};

const contactButtonSx = {
  color: 'warning.dark',
  pl: 0,
  textDecoration: 'underline',
};

const alertSx = {
  alignItems: 'flex-start',
};

const CheckEmailPage: PageComponent = () => {
  const router = useRouter();

  const email = (router.query.email ?? '') as string;

  const emailDynamicContent = { email };

  const { mutate: resendEmail, isPending, isSuccess, isError } = useSendResetPasswordEmailMutation();

  const emailResent = isSuccess || isError;

  const handleResendEmail = () => {
    const payload = { email };
    resendEmail(payload);
  };

  const fallback = [
    <Skeleton key="checkEmailContent1" width="100%" height={36} />,
    <Skeleton key="checkEmailContent2" width="100%" height={36} />,
    <Skeleton key="checkEmailContent3" width={68} height={60} />,
  ];

  const startIcon = <Iconography color="text.disabled" iconName="check" />;

  const button = emailResent ? (
    <LocalizedButton sx={buttonSx} disabled variant="contained" startIcon={startIcon}>
      {translations.onboarding.recoverPassword.resentEmail}
    </LocalizedButton>
  ) : (
    <ActionButton
      sx={buttonSx}
      onClick={handleResendEmail}
      loading={isPending}
      type="submit"
      color="primary"
      variant="contained"
    >
      {translations.onboarding.recoverPassword.resendEmail}
    </ActionButton>
  );

  const alert = emailResent ? (
    <LocalizedAlert sx={alertSx} variant="filled" color="warning">
      <LocalizedTypography variant="body2">
        {translations.onboarding.recoverPassword.notReceivedEmail}
      </LocalizedTypography>
      <ConnectedContactButton color="warning" size="small" sx={contactButtonSx} />
    </LocalizedAlert>
  ) : null;

  return (
    <OnboardingLayout variant="small" fallback={fallback}>
      <Iconography color="common.black" iconName="envelope-open-text" prefix="fal" size="3x" />
      <LocalizedTypography id="main" mt={2} variant="h4">
        {translations.onboarding.recoverPassword.checkEmail}
      </LocalizedTypography>
      <LocalizedTypography
        textAlign="center"
        mt={1}
        variant="subtitle2"
        color="text.secondary"
        dynamicContent={emailDynamicContent}
      >
        {translations.onboarding.recoverPassword.accountAssociate}
      </LocalizedTypography>
      {button}
      {alert}
    </OnboardingLayout>
  );
};

export const CheckEmailPageMeta: PageMeta = {
  pageName: 'CheckEmail',
  route: '/app/onboarding/check-email',
  iconName: 'mailbox',
};

CheckEmailPage.pageName = CheckEmailPageMeta.pageName;
CheckEmailPage.route = CheckEmailPageMeta.route;
CheckEmailPage.iconName = CheckEmailPageMeta.iconName;

export default CheckEmailPage;
