import { RepSettingInputFieldSchema } from '@halo-common/formComponents';
import { UserModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { postUserProfile } from '@halo-data-sources/clients';
import { ApiUserMapper } from '@halo-data-sources/mappers';
import { USER_LOCAL_KEY, UserInfoModel, UserQueryKeyFactory } from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation, useQueryClient, UseMutationOptions } from '@tanstack/react-query';

export type UpdateUserInfoMutationPayload = {
  firstName?: string;
  lastName?: string;
  phone?: string;
  locale?: string;
  repSettingArrayField?: RepSettingInputFieldSchema['repSettingArrayField'];
};

export type UpdateUserInfoMutationOption = UseMutationOptions<UserModel, Error, UpdateUserInfoMutationPayload>;

const updateUserInfoMutationFn = async (payload: UpdateUserInfoMutationPayload) => {
  const repArrayIds = payload?.repSettingArrayField?.length
    ? payload.repSettingArrayField
        .flatMap((setting) => setting)
        .map((setting) => ({
          rep_id_setting_id: setting.repSettingId,
          value: setting.value,
        }))
        .filter(({ value }) => value)
    : [];

  const response = await postUserProfile({
    first_name: payload?.firstName,
    last_name: payload?.lastName,
    phone: payload?.phone,
    locale: payload?.locale,
    rep_ids: repArrayIds,
  });

  return ApiUserMapper.toUser(response);
};

export const useUpdateUserInfoMutation = (
  options?: UpdateUserInfoMutationOption,
): UseMutationResult<UserModel, Error, UpdateUserInfoMutationPayload> => {
  const queryClient = useQueryClient();

  const { enqueueSuccessEvent, enqueueErrorEvent } = useSnackbar();

  return useMutation<UserModel, Error, UpdateUserInfoMutationPayload>({
    mutationFn: updateUserInfoMutationFn,
    onError: () => {
      enqueueErrorEvent({ message: translations.profile.details.updateFailure });
    },
    onSuccess: (...props) => {
      options?.onSuccess?.(...props);
      enqueueSuccessEvent({ message: translations.profile.details.updateSuccess });

      const key = UserQueryKeyFactory.info();
      queryClient.setQueryData<UserInfoModel>(key, (prev) => {
        if (!prev) return undefined;

        const preferredLanguage = props[0].preferredLanguage;
        if (preferredLanguage) window.localStorage.setItem(USER_LOCAL_KEY, preferredLanguage);
        return { ...prev, details: { ...prev.details, ...props[0] } };
      });
    },
  });
};
