import { ReactElement, ReactNode, useCallback, useState } from 'react';

import { CalendarProductCard, PaginationDots } from '@halo-common/components';
import { WidgetEnginesEnum } from '@halo-common/enums';
import { useCalendarProductCardActions } from '@halo-common/hooks';
import type { DashboardWidgetModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { ApiComparisonOptionEnum } from '@halo-data-sources/enums';
import { ApiQueryRequestModel } from '@halo-data-sources/models';
import { useFeaturedCalendarQuery } from '@halo-data-sources/queries';
import { Widget } from '@halo-modules/app';
import { Iconography, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Box, Button, Container, Skeleton, Stack, Typography } from '@mui/material';

import { getSlideState } from './getSlideState';
import { WatchFeaturedNoteVideoModal } from './WatchFeaturedNoteVideoModal';

const engine = WidgetEnginesEnum.FEATURED_NOTES;

const title = translations.dashboard.featuredNotes.title;

const containerSx = {
  backgroundColor: 'info.background',
  py: 7,
  overflow: 'hidden',
};

const contentWrapperSx = {
  display: 'grid',
  width: '100%',
  '& > div': {
    gridColumn: 1,
    gridRow: 1,
    opacity: 0,
    pointerEvents: 'none',
    userSelect: 'none',
    transform: 'translateY(12px)',
    transition: '0.6s cubic-bezier(0.16, 1, 0.3, 1)',
    transitionProperty: 'opacity, transform',
    display: 'flex',
    flexDirection: 'column',
    '&[data-state="current"]': {
      opacity: 1,
      pointerEvents: 'auto',
      userSelect: 'auto',
      transform: 'none',
    },
  },
};

const slidesWrapperSx = {
  display: 'grid',
  listStyle: 'none',
  width: '100%',
  '& > li': {
    gridColumn: 1,
    gridRow: 1,
    opacity: 0,
    pointerEvents: 'none',
    userSelect: 'none',
    transform: 'translateY(16px) scale(0.96)',
    transformOrigin: 'bottom',
    transition: '0.6s cubic-bezier(0.16, 1, 0.3, 1)',
    transitionProperty: 'opacity, transform',
    minHeight: 'max(200px, 100%)',
    '& > div': {
      minHeight: 'inherit',
    },
    '&[data-state="previous-1"]': {
      transform: 'translateY(-16px) scale(1.04)',
      zIndex: 5,
    },
    '&[data-state="previous"]': {
      transform: 'translateY(-8px) scale(1.02)',
      zIndex: 4,
    },
    '&[data-state="current"]': {
      opacity: 1,
      pointerEvents: 'auto',
      userSelect: 'auto',
      transform: 'none',
      zIndex: 3,
    },
    '&[data-state="next"]': {
      opacity: 1,
      transform: 'translateY(8px) scale(0.98)',
      zIndex: 2,
    },
    '&[data-state="next+1"]': {
      opacity: 1,
      zIndex: 1,
    },
  },
};

const arrowButtonSx = {
  borderRadius: '50%',
  border: '1px solid currentColor',
  minHeight: '40px',
  minWidth: '40px',
  height: '40px',
  width: '40px',
};

const calendarSx = {
  boxShadow: '0px 0px 22px 6px #E1E1EF',
  marginBottom: 0,
};

const Wrapper = ({ children }: { children: ReactNode }) => (
  <Box sx={containerSx}>
    <Container maxWidth="xl">
      <Widget
        id={engine}
        title={title}
        subTitle={translations.dashboard.featuredNotes.subTitle}
        className="wm-dashboard-featured-notes"
        navigateButtonHref="/calendar"
        navigateButtonLabel={translations.dashboard.featuredNotes.calendars}
        navigateButtonClassName="wm-dashboard-featured-notes-viewall"
        showCustomizeButton={false}
      >
        <Stack direction="row" spacing={3} pt={4}>
          {children}
        </Stack>
      </Widget>
    </Container>
  </Box>
);

const LoadingSkeleton = () => (
  <>
    <Box flex="0 0 326px" data-testid="loading-skeleton">
      <Skeleton variant="rounded" height={16} width="50%" sx={{ marginBottom: '8px' }} />
      <Skeleton variant="rounded" height={24} width="100%" sx={{ marginBottom: '32px' }} />
      <Skeleton variant="rounded" height={16} width="100%" sx={{ marginBottom: '6px' }} />
      <Skeleton variant="rounded" height={16} width="100%" sx={{ marginBottom: '6px' }} />
      <Skeleton variant="rounded" height={16} width="80%" />
    </Box>
    <Box flex="1 1 auto">
      <Skeleton variant="rounded" height={200} width="100%" />
    </Box>
  </>
);

export const FeaturedNotes = (): ReactElement | null => {
  const actions = useCalendarProductCardActions();
  const [activeIndex, setActiveIndex] = useState(0);

  const todaysIsoDate = new Date().toISOString().split('T')[0];

  const featuredCalendarQueryPayload: ApiQueryRequestModel = {
    comparisons: [
      { field: 'calendar_offering.expiration_date', value: todaysIsoDate, op: ApiComparisonOptionEnum.GTE },
    ],
    sort: [
      {
        field: 'featured_calendar_note.priority',
        direction: 'desc',
      },
      {
        field: 'featured_calendar_note.id',
        direction: 'asc',
      },
    ],
    page: 1,
    page_length: 15,
  };

  const { data, isPending } = useFeaturedCalendarQuery(featuredCalendarQueryPayload);

  const featuredCalendars = data || [];

  const showPrevious = useCallback(() => {
    setActiveIndex((prevIndex) => {
      const newIndex = prevIndex - 1;
      return newIndex < 0 ? featuredCalendars.length - 1 : newIndex;
    });
  }, [featuredCalendars.length]);

  const showNext = useCallback(() => {
    setActiveIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      return newIndex >= featuredCalendars.length ? 0 : newIndex;
    });
  }, [featuredCalendars.length]);

  if (isPending) {
    return (
      <Wrapper>
        <LoadingSkeleton />
      </Wrapper>
    );
  }

  if (!Array.isArray(featuredCalendars) || featuredCalendars.length === 0) return null;

  if (activeIndex >= featuredCalendars.length) {
    setActiveIndex(0);
  }

  const navigationArrows =
    featuredCalendars.length > 1 ? (
      <Stack direction="row" spacing={3} alignItems="center" mb={3}>
        <Button onClick={showPrevious} sx={arrowButtonSx} className="wm-carousel-left" aria-label="Show Previous">
          <Iconography iconName="chevron-left" />
        </Button>
        <Button onClick={showNext} sx={arrowButtonSx} className="wm-carousel-right" aria-label="Show Next">
          <Iconography iconName="chevron-right" />
        </Button>
        <Typography color="textSecondary" variant="caption">
          {activeIndex + 1} / {featuredCalendars.length}
        </Typography>
      </Stack>
    ) : null;

  let sideBar: ReactElement | null = (
    <Box flex="0 0 326px">
      {navigationArrows}
      <Box sx={contentWrapperSx}>
        {featuredCalendars.map((calendar, index) => {
          const state = index === activeIndex ? 'current' : 'inactive';

          const overline = calendar.overline ? (
            <LocalizedTypography variant="overline" color="textSecondary" mb="4px">
              {calendar.overline}
            </LocalizedTypography>
          ) : null;

          const title = calendar.title ? (
            <LocalizedTypography variant="h5" color="primary" mb={3}>
              {calendar.title}
            </LocalizedTypography>
          ) : null;

          const description = calendar.description ? (
            <LocalizedTypography variant="caption" color="textSecondary">
              {calendar.description}
            </LocalizedTypography>
          ) : null;

          const watchVideoButton = calendar.video_link ? <WatchFeaturedNoteVideoModal note={calendar} /> : null;

          return (
            <div data-state={state} data-testid="featured-calendar-content" key={calendar.id}>
              {overline}
              {title}
              {description}
              {watchVideoButton}
            </div>
          );
        })}
      </Box>
    </Box>
  );

  const hasContent = featuredCalendars[0].overline || featuredCalendars[0].title || featuredCalendars[0].description;
  if (featuredCalendars.length === 1 && !hasContent) {
    sideBar = null;
  }

  let dotsMt = 3;
  if (featuredCalendars.length === 2) dotsMt = 4;
  if (featuredCalendars.length > 2) dotsMt = 5;

  return (
    <Wrapper>
      {sideBar}
      <Box flex="1 1 auto">
        <Box sx={slidesWrapperSx} component="ul">
          {featuredCalendars.map(({ id, calendar }, index) => {
            const state = getSlideState(index, activeIndex, featuredCalendars.length);
            return (
              <li data-state={state} data-testid="featured-calendar-note" key={id}>
                <CalendarProductCard calendar={calendar} sx={calendarSx} actions={actions} />
              </li>
            );
          })}
        </Box>
        <PaginationDots
          total={featuredCalendars.length}
          active={activeIndex}
          onClick={setActiveIndex}
          mt={dotsMt}
          zIndex={5}
        />
      </Box>
    </Wrapper>
  );
};

const widget: DashboardWidgetModel = {
  engine,
  title,
  component: FeaturedNotes,
};

export default widget;
