import { ReactElement, useEffect } from 'react';

import { calendarManagerAtom, calendarSelectedAtom } from '@halo-atoms/calendars';
import { orderTicketValidationApprovalModalAtom } from '@halo-atoms/orderTicket';
import { CalendarOrderStatusEnum } from '@halo-common/enums';
import { useProductProgress } from '@halo-common/hooks';
import { AllocationModel } from '@halo-common/models';
import { BulkOrderTicketFormFields, OrderTicketFormModelFields } from '@halo-common/schemas';
import { useCancelExecCalendarOrderMutation } from '@halo-data-sources/mutations';
import { CalendarsQueryKeyFactory, useCalendarAllocationsQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import { useOrderTicketValidationSwitch } from '@halo-data-sources/switches';
import { Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';
import { useFormContext } from 'react-hook-form';

import { OrderTicketAllocationsTable } from '../../../OrderTicketAllocationsTable';
import { OrderTicketConfirmationModal } from '../../../OrderTicketConfirmationModal';
import { OrderTicketFormLayout } from '../../OrderTicketFormLayout';
import {
  DisclaimerSection,
  JanneyBulkAllocationSection,
  OrderDetailSection,
  TicketSection,
} from '../../OrderTicketFormSections';

export type OrderTicketJanneyFormProps = {
  onClose: () => void;
};

export const OrderTicketJanneyForm = ({ onClose }: OrderTicketJanneyFormProps): ReactElement => {
  const queryClient = useQueryClient();

  const { handleSubmit, setValue, getValues } = useFormContext<OrderTicketFormModelFields>();

  const { query } = useAtomValue(calendarManagerAtom);
  const [approvalModalOpen, setApprovalModalOpen] = useAtom(orderTicketValidationApprovalModalAtom);
  const selectedCalendar = useAtomValue(calendarSelectedAtom);

  const { data: userInfo } = useUserInfoQuery();
  const { data: allocations = [] } = useCalendarAllocationsQuery(selectedCalendar?.id);

  const { mutate: validateData, isPending: validatingOrder } = useOrderTicketValidationSwitch();

  const { mutate: cancelExecOrder, isPending: cancellingExecOrder } = useCancelExecCalendarOrderMutation({
    onSuccess: (data, allocationId) => {
      if (!data) return undefined;

      const calendarQueryKey = CalendarsQueryKeyFactory.calendar(selectedCalendar?.id);
      void queryClient.refetchQueries({ queryKey: calendarQueryKey });

      const calendarsQueryKey = CalendarsQueryKeyFactory.infinite(query);
      void queryClient.refetchQueries({ queryKey: calendarsQueryKey });

      const allocationsQueryKey = CalendarsQueryKeyFactory.allocations(selectedCalendar?.id);
      queryClient.setQueryData<Array<AllocationModel>>(allocationsQueryKey, (prev) => {
        if (!prev) return undefined;

        const updatedAllocations = [...prev];
        const index = updatedAllocations.findIndex(({ id }) => id === allocationId);
        updatedAllocations.splice(index, 1, data.allocation);

        return updatedAllocations;
      });
    },
  });

  const { status } = useProductProgress({ calendar: selectedCalendar });

  const isClosed = status === CalendarOrderStatusEnum.Closed;
  const hideForm = isClosed || selectedCalendar?.paused;

  const userFullName = userInfo?.details?.name;

  const hasAllocations = allocations.some(({ account, status }) => {
    const isAllocationCanceled = status === 'canceled';

    if (isAllocationCanceled) return false;

    const accountId = account.id;
    const formAllocations = getValues('allocations') ?? [];

    return formAllocations.some((allocation) => allocation.accountOption?.account?.id === accountId);
  });

  const validationHandler = (fields: OrderTicketFormModelFields) => {
    const bulkData = fields as BulkOrderTicketFormFields;
    validateData({ calendar: selectedCalendar, bulkData });
  };

  const validationSubmitHandler = (fields: OrderTicketFormModelFields) => {
    if (hasAllocations) setApprovalModalOpen(true);
    else validationHandler(fields);
  };

  const confirmationSubmitHandler = (fields: OrderTicketFormModelFields) => {
    setApprovalModalOpen(false);
    validationHandler(fields);
  };

  const handleCancelOrder = (orderId: number) => {
    cancelExecOrder(orderId);
  };

  const onValidationSubmit = handleSubmit(validationSubmitHandler);
  const onConfirmationSubmit = handleSubmit(confirmationSubmitHandler);
  const closeConfirmationHandler = () => setApprovalModalOpen(false);

  const entryFormContent = !hideForm ? (
    <form>
      <Stack direction="column" spacing={2}>
        <JanneyBulkAllocationSection />
        <TicketSection />
        <OrderDetailSection />
        <DisclaimerSection />
      </Stack>
    </form>
  ) : null;

  useEffect(() => {
    if (userFullName) setValue('orderFrom', userFullName, { shouldDirty: true });
  }, [userFullName, setValue]);

  return (
    <>
      <OrderTicketFormLayout onSubmit={onValidationSubmit} onClose={onClose} loading={validatingOrder}>
        {entryFormContent}
        <OrderTicketAllocationsTable loading={cancellingExecOrder} onCancel={handleCancelOrder} />
      </OrderTicketFormLayout>
      <OrderTicketConfirmationModal
        open={approvalModalOpen}
        onSubmit={onConfirmationSubmit}
        onClose={closeConfirmationHandler}
      />
    </>
  );
};
