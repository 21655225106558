export const CREATE_CALENDAR_ERROR_TRANSLATIONS = {
  categoryRequired: 'Category is required',
  closeDateRequired: 'Close Date is required',
  cusipRequired: 'CUSIP is required',
  displayNameRequired: 'Display Name is required',
  issueDateRequired: 'Issue Date is required',
  issuerRequired: 'Issuer is required',
  noteIdRequired: 'Note ID is required',
  noteIdType: 'Note ID must be a positive integer',
  noteQuoteMin: 'Note Quote must be greater than or equal to 0',
  noteQuoteRequired: 'Note Quote is required',
  cusipType: 'CUSIP must be a positive integer',
  reofferRequired: 'Reoffer is required',
  reofferType: 'Reoffer must be a number 0-100',
  reofferMin: 'Reoffer must be greater than or equal to 0',
  reofferMax: 'Reoffer must be less than or equal to 100',
  tradeDateRequired: 'Trade Date is required',
  preliminaryTermsheetRequired: 'File type must be .pdf',
  purchasePrice: 'Purchase Price must be a positive number',
  resalePrice: 'Resale Price must be a positive number',
  settlementTypeRequired: 'Settlement Type is required',
  wholesalerIsAlreadySelected: 'You have already selected this wholesaler',
  wholesalerRequired: 'Please select a wholesaler',
};
