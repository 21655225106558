import { ReactElement } from 'react';

import { AuctionStatusEnum, AuctionStatusKeyEnum } from '@halo-common/enums';
import { OrderHubOrderedColumnsModel } from '@halo-common/models';
import { useUserSettingMutation } from '@halo-data-sources/mutations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import {
  AuctionTable,
  AuctionTableLayout,
  OH_ORDERED_COLUMNS_KEY,
  OH_PINNED_COLUMNS_KEY,
  useAuctionTableData,
} from '@halo-modules/admin';
import { GridPinnedColumnFields } from '@mui/x-data-grid-pro';
import { DateTime } from 'luxon';

export const CanceledTable = (): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();
  const { mutate: updateUserSetting } = useUserSettingMutation();
  const { data, gridRef, issuersVisible, handleDataSwitch } = useAuctionTableData(AuctionStatusEnum.Canceled);

  const orderHubPinnedColumns = userInfo?.settings?.orderHubPinnedColumns;
  const orderHubOrderedColumns = userInfo?.settings?.orderHubOrderedColumns;

  const selectOrderHubPinnedColumns = orderHubPinnedColumns?.[AuctionStatusKeyEnum.Canceled];
  const selectOrderHubOrderedColumns = orderHubOrderedColumns?.[AuctionStatusKeyEnum.Canceled];

  const handleColumnPin = (updatedGridPinnedColumns: GridPinnedColumnFields) => {
    updateUserSetting({
      key: OH_PINNED_COLUMNS_KEY,
      value: { ...orderHubPinnedColumns, canceled: updatedGridPinnedColumns },
    });
  };

  const handleColumnReorder = (updatedColumnOrders: OrderHubOrderedColumnsModel) => {
    updateUserSetting({
      key: OH_ORDERED_COLUMNS_KEY,
      value: { ...orderHubOrderedColumns, canceled: updatedColumnOrders },
    });
  };

  const description = `The client elected not to purchase the note that was auctioned.`;

  return (
    <AuctionTableLayout
      description={description}
      title="Canceled"
      onIssuersToggle={handleDataSwitch}
      onExport={gridRef.current?.exportDataAsCsv}
    >
      <AuctionTable
        onColumnPin={handleColumnPin}
        onColumnReorder={handleColumnReorder}
        defaultPinnedColumns={selectOrderHubPinnedColumns}
        defaultOrderedColumns={selectOrderHubOrderedColumns}
        issuersVisible={issuersVisible}
        DataGridProps={{
          data,
          cta: {
            icon: 'clock',
            label: DateTime.local().toFormat('hh:mm:ss a'),
            disabled: true,
          },
        }}
        gridRef={gridRef}
      />
    </AuctionTableLayout>
  );
};
