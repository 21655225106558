import { ReactElement } from 'react';

import { FixedIncomeProductCardFavoriteBtn, ProductInventoryTrackingBar } from '@halo-common/components';
import {
  useModalState,
  usePrimaryAssetIdentifier,
  useProductInventoryTracking,
  useProductProgress,
} from '@halo-common/hooks';
import { MUST_COMPLETE_ONBOARDING_MODAL } from '@halo-common/modals';
import { CalendarModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { LocalizedButton, ProgressBar, ProgressBarEnum } from '@halodomination/halo-fe-common';
import { Skeleton, Stack } from '@mui/material';

const containerSx = {
  minWidth: 425,
};

const progressBarSx = {
  width: 250,
};

export type ExecutionFixedIncomeDetailModalHeaderActionsProps = {
  product?: CalendarModel | null;
  loading?: boolean;
  onSubmitIndication: () => void;
};

export const ExecutionFixedIncomeDetailModalHeaderActions = ({
  product,
  onSubmitIndication,
  loading = true,
}: ExecutionFixedIncomeDetailModalHeaderActionsProps): ReactElement | null => {
  const { data: userInfo } = useUserInfoQuery();

  const { setOpen } = useModalState(MUST_COMPLETE_ONBOARDING_MODAL);

  const { percentFilled, variant, label, dynamicContent } = useProductProgress({
    calendar: product,
    isFixedIncome: true,
  });

  const { identifier, identifierType, fallback } = usePrimaryAssetIdentifier(product);

  const isClosed = variant === ProgressBarEnum.DISABLED;

  const inventory = useProductInventoryTracking({
    inventoryRemaining: product?.inventoryRemaining,
    inventoryConsumed: product?.inventoryConsumed,
    inventoryTotal: product?.inventoryTotal,
    isCalendarClosed: isClosed,
  });

  if (loading) {
    return (
      <Stack direction="row" spacing={1}>
        <Skeleton width={100} height={42} />
      </Stack>
    );
  }

  if (!product || !onSubmitIndication) return null;

  const allocationsEnabled = userInfo?.settings.enableCalendarAllocations;
  const hasInventory = product?.inventoryRemaining ? product.inventoryRemaining : true;
  const isDisabled = isClosed || !hasInventory || !allocationsEnabled;

  const parsedIdentifierText = identifier ? identifier : fallback.identifier;
  const parsedIdentifierType = identifier ? identifierType : fallback.identifierType;

  const isOnboardingCompleted = userInfo?.organization?.isOnboardingCompleted;

  const openOnboardingModal = () => setOpen(true);

  const handleSubmitIndication = () => {
    if (!isOnboardingCompleted) openOnboardingModal();
    else onSubmitIndication();
  };

  return (
    <Stack direction="row" sx={containerSx} alignItems="center" flexWrap="wrap" spacing={2} useFlexGap>
      <ProductInventoryTrackingBar inventory={inventory} sx={progressBarSx} />
      <ProgressBar
        sx={progressBarSx}
        percentFilled={percentFilled}
        variant={variant}
        label={label}
        dynamicContent={dynamicContent}
      />
      <LocalizedButton
        type="button"
        variant="contained"
        color="primary"
        onClick={handleSubmitIndication}
        disabled={isDisabled}
      >
        {translations.common.allocations}
      </LocalizedButton>
      <FixedIncomeProductCardFavoriteBtn
        calendarId={product?.id}
        isFavorite={product?.isFavorite}
        assetIdentifier={parsedIdentifierText}
        assetIdentifierType={parsedIdentifierType}
      />
    </Stack>
  );
};
