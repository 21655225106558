import { ReactElement, useEffect } from 'react';

import { calendarSelectedAtom } from '@halo-atoms/calendars';
import { ContentHider } from '@halo-common/components';
import { OrganizationCapacityEnum } from '@halo-common/enums';
import { useDebounced } from '@halo-common/hooks';
import { OrderTicketFormModelFields } from '@halo-common/schemas';
import { useCalendarQuery } from '@halo-data-sources/queries';
import { CurrencyInput, LinedCollapsibleHeader } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useController, useFormContext } from 'react-hook-form';

import { IpField } from '../SubmissionSectionFields';

export type CompensationSectionProps = {
  quantity?: number;
};

export const CompensationSection = ({ quantity }: CompensationSectionProps): ReactElement => {
  const selectedCalendar = useAtomValue(calendarSelectedAtom);
  const { data: calendar } = useCalendarQuery(selectedCalendar?.id);

  const { formState, watch, trigger, setValue } = useFormContext<OrderTicketFormModelFields>();
  const { errors } = formState;

  const {
    field: { ref: compensationRef, value: compensationValue, ...compensationField },
  } = useController({ name: 'compensation' });

  const [capacityType, ip1, ip2, ip1Percent, ip2Percent] = watch([
    'capacityType',
    'ip1',
    'ip2',
    'ip1Percent',
    'ip2Percent',
  ]);

  const isCommission = capacityType && OrganizationCapacityEnum[capacityType] === OrganizationCapacityEnum.AGENCY;
  const isSalesCredit = !isCommission;
  const roundCredit = isSalesCredit && compensationValue;
  const parsedValue = roundCredit ? parseFloat(compensationValue).toFixed(2) : compensationValue;

  const title = isCommission ? 'Commission' : 'Sales Credit';
  const label = isCommission ? 'Commission Value' : 'Sales Credit';
  const compensationError = errors?.compensation?.message;
  const calendarPrice = calendar?.wholesaler?.salePrice ?? calendar?.note?.price ?? 100;

  const validateFields = async (
    fieldNames?: keyof OrderTicketFormModelFields | Array<keyof OrderTicketFormModelFields>,
  ) => (fieldNames ? await trigger(fieldNames) : await trigger());

  const debouncedValidateFields = useDebounced(validateFields, 300);

  useEffect(() => {
    void debouncedValidateFields(['compensation', 'ip1', 'ip2', 'ip1Percent', 'ip2Percent']);
  }, [capacityType, ip1, ip2, ip1Percent, ip2Percent]);

  useEffect(() => {
    const isCommission = capacityType && OrganizationCapacityEnum[capacityType] === OrganizationCapacityEnum.AGENCY;
    const shouldClearCompensation = !isCommission && typeof quantity !== 'number';
    const shouldUpdateCompensation = !isCommission && typeof quantity === 'number';

    if (shouldClearCompensation) {
      setValue('compensation', '0', { shouldValidate: true, shouldDirty: true });
    } else if (shouldUpdateCompensation) {
      const salesCredit = 10 * quantity * (100 - calendarPrice);
      setValue('compensation', salesCredit.toString(), { shouldValidate: true, shouldDirty: true });
    }
  }, [quantity, capacityType, calendarPrice]);

  return (
    <ContentHider hook="react:soe:compensation-section">
      <Stack direction="column" spacing={3}>
        <LinedCollapsibleHeader title={title} alwaysExpanded />
        <Stack direction="row" spacing={2} flexWrap="wrap" useFlexGap>
          <CurrencyInput
            {...compensationField}
            sx={{ flex: 2 }}
            value={parsedValue}
            inputRef={compensationRef}
            label={label}
            fullWidth
            size="large"
            valueType="string"
            disabled={isSalesCredit}
            helperText={compensationError}
            error={Boolean(compensationError)}
          />
          <IpField fieldName="ip1" />
          <IpField fieldName="ip2" />
        </Stack>
      </Stack>
    </ContentHider>
  );
};
