import { ReactElement } from 'react';

import { useOrderTicketDesignation } from '@halo-common/hooks';
import { SingleOrderTicketFormFields } from '@halo-common/schemas';
import { AccountTypeAhead, AccountTypeAheadOption } from '@halo-common/smartComponents';
import { LinedCollapsibleHeader, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

export type AccountSectionProps = {
  showDesignation?: boolean;
};

export const AccountSection = ({ showDesignation }: AccountSectionProps): ReactElement => {
  const { control, formState, watch, setValue } = useFormContext<SingleOrderTicketFormFields>();
  const { errors } = formState;

  const accountOption = watch('account');

  const { label: designation } = useOrderTicketDesignation(accountOption?.account);

  const accountError = errors?.account?.message;
  const hasAccountError = Boolean(accountError);
  const showAccountDesignation = Boolean(accountOption && showDesignation);

  const handleSelect = (value: AccountTypeAheadOption | null) => {
    setValue('account', value, { shouldDirty: true, shouldValidate: true, shouldTouch: true });
  };

  const accountDesignation = showAccountDesignation ? (
    <Stack direction="column">
      <LocalizedTypography variant="caption">Account Type</LocalizedTypography>
      <LocalizedTypography fontWeight="bold">{designation}</LocalizedTypography>
    </Stack>
  ) : null;

  return (
    <Stack direction="row" alignItems="center" spacing={2} flexWrap="wrap">
      <LinedCollapsibleHeader title="Account Section" alwaysExpanded />
      <Stack direction="row" alignItems="center" spacing={2} flexWrap="wrap">
        <Controller
          render={({ field: { ref, ...options } }) => (
            <AccountTypeAhead
              {...options}
              ref={ref}
              value={accountOption}
              onSelect={handleSelect}
              label="Account"
              rules={{ disableHouseholds: true }}
              helperText={accountError}
              error={hasAccountError}
              disableClearable
            />
          )}
          control={control}
          name="account"
        />
        {accountDesignation}
      </Stack>
    </Stack>
  );
};
