import { ReactElement, useEffect } from 'react';

import { modalAtom } from '@halo-atoms/common';
import {
  addPositionsAssetSubmittedAtom,
  addPositionToMultipleAccountModalAtom,
  assetIdSearchValueAtom,
} from '@halo-atoms/portfolio';
import { ActionButton } from '@halo-common/components';
import { AssetIdentifierEnum, AssetIdLocationEnum } from '@halo-common/enums';
import { PortfolioPositionReferenceModel } from '@halo-common/models';
import { AddPositionToMultipleAccountsFormFields } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { validateFundServCode } from '@halo-common/utils';
import { CreatePositionsRequest } from '@halo-data-sources/mutations';
import { usePortfolioAssetExistenceQuery } from '@halo-data-sources/queries';
import { LocalizedButton } from '@halodomination/halo-fe-common';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useFormContext } from 'react-hook-form';

export type AddPositionToMultipleAccountsFooterContinueButtonProps = {
  onSubmit: (payload: CreatePositionsRequest) => void;
  isLoading: boolean;
  isSuccess: boolean;
};

export const AddPositionToMultipleAccountsFooterContinueButton = ({
  onSubmit,
  isLoading,
}: AddPositionToMultipleAccountsFooterContinueButtonProps): ReactElement => {
  const { handleSubmit, reset, setError, clearErrors } = useFormContext<AddPositionToMultipleAccountsFormFields>();

  const updateModal = useSetAtom(modalAtom);
  const [modalState, setModalState] = useAtom(addPositionToMultipleAccountModalAtom);
  const { modalStepMap } = modalState;
  const { handleResetAtom, currentPageAtom, handleNextAtom } = modalStepMap;

  const [assetIdSubmitted, setAssetIdSubmitted] = useAtom(addPositionsAssetSubmittedAtom);

  const assetIdValue = useAtomValue(assetIdSearchValueAtom);
  const assetIds = assetIdValue ? [assetIdValue] : undefined;

  const handleNext = useSetAtom(handleNextAtom);
  const handleReset = useSetAtom(handleResetAtom);
  const step = useAtomValue(currentPageAtom);

  const { isFetching, data: assets, isSuccess, isRefetching, refetch } = usePortfolioAssetExistenceQuery(assetIds);

  const selectedAsset = assets?.[0];
  const assetIdExists = selectedAsset?.location;
  const isFundServ = selectedAsset?.assetIdType === AssetIdentifierEnum.FUNDSERVCODE;
  const isValidFundServ = validateFundServCode(selectedAsset?.assetId, selectedAsset?.assetIdType);

  const submitAssetId = () => {
    if (!assetIds) setError('assetId', { type: 'manual' });
    if (isFundServ && !isValidFundServ) return;
    setAssetIdSubmitted(true);
    void refetch();
  };

  const arePositionAccountsUnique = (positions: Array<PortfolioPositionReferenceModel>): boolean => {
    const isValid = positions.every((position, index) => {
      const matches = positions.filter((positionForMatch) => positionForMatch.accountId === position.accountId);
      const hasMatch = matches.length > 1;

      const fieldRowName = `positions.${index}.account` as const;

      if (hasMatch) {
        const errorMessage = translations.portfolio.addPositionToAccountsModal.multiAllocationError;
        setError(fieldRowName, { type: 'manual', message: errorMessage });
      } else {
        clearErrors(fieldRowName);
      }

      return !hasMatch;
    });

    return isValid;
  };

  const handleAddPositionToAccounts = (data: AddPositionToMultipleAccountsFormFields) => {
    const positions = data.positions.map((position) => ({
      termsheetId: position.termsheetId,
      accountId: position.account?.account?.id?.toString() ?? '',
      notional: position.notional ?? 0,
      assetId: position.assetId,
      location: position.location,
    }));

    const isLocationEdgar = positions.some((position) => position.location === AssetIdLocationEnum.edgar);
    const validPositionAccountIds = arePositionAccountsUnique(positions);
    const addQueuedPositions = validPositionAccountIds && isLocationEdgar;

    if (addQueuedPositions) onSubmit({ queuedPositions: [...positions] });
    else if (validPositionAccountIds) onSubmit({ positions: [...positions] });
  };

  const handleFinish = () => {
    reset();
    handleReset();
    setModalState();
    updateModal({ addAccountOrPositionsModal: false });
  };

  useEffect(() => {
    if (isSuccess && assetIdSubmitted && assetIdExists && step === 0) handleNext();
  }, [isSuccess, assetIdExists, isRefetching, assetIdSubmitted, isFundServ, isValidFundServ]);

  const chooseAssetButton = (
    <ActionButton loading={isFetching} onClick={submitAssetId} variant="contained" type="submit" color="primary">
      {translations.common.continue}
    </ActionButton>
  );

  const chooseAccountsButton = (
    <ActionButton
      loading={isLoading}
      onClick={handleSubmit(handleAddPositionToAccounts)}
      variant="contained"
      type="submit"
      color="primary"
    >
      {translations.common.continue}
    </ActionButton>
  );

  const finishButton = (
    <LocalizedButton type="submit" variant="contained" color="primary" onClick={handleFinish}>
      {translations.common.finish}
    </LocalizedButton>
  );

  const continueButtonList = [chooseAssetButton, chooseAccountsButton, finishButton];

  return continueButtonList[step];
};
