import { translations } from '@halo-common/translations';
import { array as yupArray, boolean as yupBoolean, object as yupObject, string as yupString, lazy } from 'yup';

import {
  orderTicketAccountOptionSchema,
  orderTicketQuantitySchema,
  duplicateAccountTest,
} from '../orderTicketFormSchema';

const janneyBulkOrderTicketAllocationSchema = yupObject()
  .shape({
    accountOption: orderTicketAccountOptionSchema,
    quantity: orderTicketQuantitySchema,
    capacityType: yupString().nullable(),
    discretion: yupString().nullable(),
    solicited: yupString().required(translations.common.required),
    ttoRepCode: lazy((value) => {
      if (value?.length) return yupString().length(4, translations.orderTicket.ttoRepCodeLengthMessage);
      else return yupString().notRequired();
    }),
  })
  .test(duplicateAccountTest);

const janneyBulkOrderTicketAllocationListSchema = yupArray()
  .of(janneyBulkOrderTicketAllocationSchema)
  .min(1, translations.allocationsModal.common.allocationRequiredError)
  .required(translations.allocationsModal.common.allocationRequiredError);

export const janneyOrderTicketSchema = yupObject().shape({
  allocations: janneyBulkOrderTicketAllocationListSchema,
  orderFrom: yupString().required(translations.common.required),
  disclaimer: yupBoolean()
    .isTrue(translations.allocationsModal.common.disclaimerRequiredError)
    .required(translations.allocationsModal.common.disclaimerRequiredError),
});
