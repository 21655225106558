import { ReactElement } from 'react';
import { FormEventHandler } from 'react';

import { translations } from '@halo-common/translations';
import { LocalizedButton, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';

const mainButtonStyling = { color: 'common.white', backgroundColor: 'primary.main' };
const captionStyling = { color: 'text.secondary', fontStyle: 'italic' };

export type DisclaimerModalButtonProps = {
  disclaimerIsAccepted: boolean;
  isDisabled: boolean;
  disclaimerId: number;
  handleDisclaimerAcceptance: (id: number) => void;
  handleClose: () => void;
  disclaimerFormId?: string;
  handleSubmit?: FormEventHandler;
};

export const DisclaimerModalButton = ({
  disclaimerIsAccepted,
  isDisabled,
  disclaimerId,
  handleDisclaimerAcceptance,
  handleClose,
  disclaimerFormId,
}: DisclaimerModalButtonProps): ReactElement => {
  const agreeHandler = disclaimerFormId ? undefined : () => void handleDisclaimerAcceptance(disclaimerId);

  return !disclaimerIsAccepted ? (
    <Stack direction="row" justify="flex-end" spacing={2} alignItems="center">
      <LocalizedTypography variant="caption" sx={captionStyling}>
        {translations.onboarding.disclaimers.scrollToBottom}
      </LocalizedTypography>
      <LocalizedButton
        variant="contained"
        color="primary"
        type="submit"
        form={disclaimerFormId}
        sx={mainButtonStyling}
        onClick={agreeHandler}
        disabled={isDisabled}
      >
        {translations.common.agree}
      </LocalizedButton>
    </Stack>
  ) : (
    <Stack direction="row" justify="flex-end">
      <LocalizedButton variant="contained" color="primary" sx={mainButtonStyling} onClick={handleClose}>
        {translations.common.close}
      </LocalizedButton>
    </Stack>
  );
};
