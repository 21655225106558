import { ReactElement } from 'react';

import { calendarSelectedAtom } from '@halo-atoms/calendars';
import { orderTicketReceiptAtom } from '@halo-atoms/orderTicket';
import { ExecutionBulkOrderReceipt } from '@halo-common/components';
import { useLiveTime } from '@halo-common/hooks';
import { useAtomValue } from 'jotai';

export type OrderTicketJanneyReceiptProps = {
  review?: boolean;
};

export const OrderTicketJanneyReceipt = ({ review = false }: OrderTicketJanneyReceiptProps): ReactElement => {
  const liveDateTime = useLiveTime()?.toISO();

  const selectedCalendar = useAtomValue(calendarSelectedAtom);
  const orderTicketReceipt = useAtomValue(orderTicketReceiptAtom);

  const orders = orderTicketReceipt?.orders;
  const orderDate = review ? liveDateTime : orderTicketReceipt?.submissionTime;

  return (
    <ExecutionBulkOrderReceipt
      review={review}
      calendarId={selectedCalendar?.id}
      orders={orders}
      orderReceiptTime={orderDate}
      transactionType="buy"
    />
  );
};
