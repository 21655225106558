import { ReactElement } from 'react';

import { DynamicFiltersToggleModel, dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { LexicalTypography } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { Chip, SxProps } from '@mui/material';
import { useT } from '@transifex/react';
import { useAtom } from 'jotai';
import { startCase } from 'lodash';

export type ToggleChipsProps = {
  tag: string;
  sx: SxProps;
};

export const ToggleChips = ({ tag, sx }: ToggleChipsProps): ReactElement | null => {
  const translator = useT();
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);

  const sideBarFilters = getSideBarFilters(tag);

  const validChips = Object.keys(sideBarFilters.toggles).filter(
    (key) => sideBarFilters.toggles[key as keyof DynamicFiltersToggleModel],
  );

  const handleStaticToggleChips = (label: string) => {
    setSideBarFilters({ tag, toggle: label });
  };

  const chips = validChips.map((label) => {
    const translatedLabel = translator(label);
    const text = translations.dynamicFilters.common.toggleChip;
    const dynamicContent = { label: startCase(translatedLabel) };
    const chipLabel = (
      <LexicalTypography variant="caption" dynamicContent={dynamicContent}>
        {text}
      </LexicalTypography>
    );

    return (
      <Chip
        key={label}
        sx={sx}
        size="small"
        variant="outlined"
        label={chipLabel}
        onDelete={() => handleStaticToggleChips(label)}
      />
    );
  });

  if (!validChips.length) return null;
  return <>{chips}</>;
};
