import { ReactElement } from 'react';

import { calendarSelectedAtom } from '@halo-atoms/calendars';
import { MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { useWhiteLabelDateConfiguration, useWhiteLabelRole, WhiteLabelRole } from '@halo-common/hooks';
import { useCalendarQuery } from '@halo-data-sources/queries';
import { mapNumberToLocalCurrency } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';
import { useAtomValue } from 'jotai';

import { TicketDetail } from './TicketDetail';

export type TicketDetailsProps = {
  showParOfferingPrice?: boolean;
};

export const TicketDetails = ({ showParOfferingPrice }: TicketDetailsProps): ReactElement | null => {
  const role = useWhiteLabelRole();
  const configureDateTime = useWhiteLabelDateConfiguration();

  const selectedCalendar = useAtomValue(calendarSelectedAtom);
  const { data: calendar } = useCalendarQuery(selectedCalendar?.id);

  const cusip = calendar?.cusip;
  const parsedOfferingPrice = showParOfferingPrice ? 'Par' : mapNumberToLocalCurrency(calendar?.wholesaler?.salePrice);
  const settlementDate = configureDateTime(calendar?.settleDate)?.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);

  const cusipDetail = <TicketDetail key="cusipKey" label="CUSIP" value={cusip} />;
  const transactionDetail = <TicketDetail key="transactionKey" label="Transaction" value="Buy" />;
  const settlementDateDetail = <TicketDetail key="settleDateKey" label="Settlement Date" value={settlementDate} />;
  const offeringPriceDetail = <TicketDetail key="opKey" label="Offering Price" value={parsedOfferingPrice} />;

  const buildDetailList = (role: WhiteLabelRole): Array<ReactElement> => {
    switch (role) {
      case 'janney':
        return [cusipDetail, transactionDetail, settlementDateDetail, offeringPriceDetail];

      case 'pershing':
      case 'pershingDev':
        return [cusipDetail, transactionDetail, offeringPriceDetail];

      default:
        return [];
    }
  };

  // TODO: Add these below when ready
  // const firmAccountDetail = <TicketDetail label="Firm Account" />;
  // const ibdDetail = <TicketDetail label="IBD" />;

  const details = buildDetailList(role);

  if (!details?.length) return null;

  return (
    <Stack direction="row" spacing={5} flexWrap="wrap">
      {details}
    </Stack>
  );
};
