import { ReactElement } from 'react';

import {
  FixedIncomeProductCardActions,
  FixedIncomeProductCardActionsProps,
  FixedIncomeProductCardDetailRow,
  FixedIncomeProductCardIssuer,
  ProductFeatureList,
  ProductInventoryTrackingBar,
  ProductNoteTypeChip,
  UnderlyingGlyphs,
} from '@halo-common/components';
import { useBreakpointView, useProductInventoryTracking, useProductProgress } from '@halo-common/hooks';
import { CalendarModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { ApiCalendarPriceDisplayEnum } from '@halo-data-sources/enums';
import { ProgressBar, ProgressBarEnum } from '@halodomination/halo-fe-common';
import { Box, Divider, Paper, Stack, Typography } from '@mui/material';

const calendarCardSx = {
  padding: 2,
  marginBottom: 2,
  border: '1px solid',
  borderColor: 'grey.300',
};

const calendarNameSx = {
  marginBottom: 2,
  wordWrap: 'break-word',
};

const calendarCardDetailSx = {
  width: '100%',
};

const disabledCalendarSx = {
  backgroundColor: 'grey.100',
};

const disabledCalendarCardSx = {
  ...calendarCardSx,
  ...disabledCalendarSx,
};

const progressBarSx = {
  width: 250,
};

export type FixedIncomeProductCardProps = {
  calendar: CalendarModel;
  actions?: FixedIncomeProductCardActionsProps['actions'];
  loading: boolean;
};

export const FixedIncomeProductCard = ({ calendar, loading, actions }: FixedIncomeProductCardProps): ReactElement => {
  const {
    note,
    noteV2,
    issuer,
    category,
    displayName,
    price: calendarPrice,
    priceDisplay,
    inventoryConsumed,
    inventoryRemaining,
    inventoryTotal,
  } = calendar;

  const tradables = note?.tradables;
  const currencyCode = note?.currencyCode;
  const tradableWeights = note?.tradableWeights;

  const isDesktop = useBreakpointView('lg', 'up');

  const { percentFilled, variant, label, dynamicContent } = useProductProgress({
    calendar,
    isFixedIncome: true,
  });

  const isComission = priceDisplay === ApiCalendarPriceDisplayEnum.COMMISSION;
  const notePrice = isComission && note.price ? 100 - note.price : note.price;
  const price = calendarPrice ?? notePrice;

  const type = noteV2?.type;
  const features = noteV2?.features ?? [];

  const isCalendarClosed = variant === ProgressBarEnum.DISABLED;

  const inventory = useProductInventoryTracking({
    inventoryConsumed,
    inventoryRemaining,
    inventoryTotal,
    isCalendarClosed,
    isPaused: calendar.paused,
  });

  const isUnderlyingsScrollable = tradables.length >= 4;
  const useUnderlyingGlyphs = Boolean(noteV2?.underlyings.length);
  const noInventoryRemaining = inventory?.variant === ProgressBarEnum.DISABLED;
  const isCalendarDisabled = noInventoryRemaining || isCalendarClosed || calendar.paused;

  const calendarDateProgressVariant = noInventoryRemaining ? ProgressBarEnum.DISABLED : variant;
  const calendarDateProgressLabel = noInventoryRemaining ? translations.calendars.common.offeringClosed : label;

  const inventoryTooltipAlignment = isDesktop ? 'left' : 'right';
  const calendarItemSx = isCalendarDisabled ? disabledCalendarCardSx : calendarCardSx;

  const underlyingGlyphs = useUnderlyingGlyphs ? (
    <UnderlyingGlyphs
      underlyings={tradables}
      scrollable={isUnderlyingsScrollable}
      weights={tradableWeights}
      size="large"
    />
  ) : null;

  return (
    <Paper sx={calendarItemSx} elevation={0}>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Stack sx={calendarCardDetailSx} direction="column" spacing={1} justifyContent="space-between">
          <Stack direction="row" spacing={0.5} alignItems="center">
            <ProductFeatureList features={features} type={type} descriptionAsLabel>
              <ProductNoteTypeChip type={type} subtype={type} />
            </ProductFeatureList>
            <Typography color="textSecondary" variant="caption">
              {currencyCode}
            </Typography>
          </Stack>
          <Stack direction={{ md: 'row', xs: 'column' }} spacing={2}>
            <Box sx={{ flex: 1 }}>{underlyingGlyphs}</Box>
            <Stack sx={{ flex: 3 }} direction="column" justifyContent="center" spacing={1}>
              <Typography sx={calendarNameSx} variant="subtitle1">
                {displayName}
              </Typography>
              <FixedIncomeProductCardDetailRow
                calendar={calendar}
                category={category}
                price={price}
                priceDisplay={priceDisplay}
              />
            </Stack>
          </Stack>
          <Stack
            sx={{ pt: { md: 0, xs: 1 } }}
            spacing={{ md: 0, xs: 2 }}
            direction={{ md: 'row', xs: 'column' }}
            alignItems={{ md: 'center' }}
            justifyContent={{ md: 'space-between' }}
          >
            <FixedIncomeProductCardIssuer issuer={issuer} loading={loading} />
            <Stack direction={{ md: 'row', xs: 'column' }} spacing={2} flexWrap="wrap" useFlexGap>
              <ProductInventoryTrackingBar
                inventory={inventory}
                sx={progressBarSx}
                tooltipAlignment={inventoryTooltipAlignment}
              />
              <ProgressBar
                label={calendarDateProgressLabel}
                variant={calendarDateProgressVariant}
                percentFilled={percentFilled}
                dynamicContent={dynamicContent}
                sx={progressBarSx}
              />
            </Stack>
          </Stack>
        </Stack>
        <FixedIncomeProductCardActions calendar={calendar} actions={actions} />
      </Stack>
    </Paper>
  );
};
