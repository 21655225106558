import React, { ReactElement } from 'react';

import { StatusChip } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { Typography } from '@mui/material';
import { camelCase } from 'lodash';

type CamelCaseStatus = 'paid' | 'notPaid' | 'pending' | 'called' | 'notCalled';
type IconStatuses = 'completed' | 'pending' | 'closed';

const statusMap: Record<CamelCaseStatus, IconStatuses> = {
  paid: 'completed',
  notPaid: 'closed',
  pending: 'pending',
  called: 'completed',
  notCalled: 'closed',
};

type TableStatusChipProps = {
  status: string;
};

export const TableStatusChip = ({ status }: TableStatusChipProps): ReactElement => {
  if (!status) return <Typography>-</Typography>;

  const camelCaseValue = camelCase(status) as CamelCaseStatus;

  return <StatusChip label={translations.common[camelCaseValue]} status={statusMap[camelCaseValue]} />;
};
