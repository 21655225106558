import { ReactElement } from 'react';

import { LocaleDropdown } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { LocalizedTypography, useCombinedStyling } from '@halodomination/halo-fe-common';
import { Skeleton, Stack, SxProps } from '@mui/material';

const formControlSx = {
  height: 40,
  width: 220,
};

export type UserLocaleDropdownPropTypes = {
  sx?: SxProps;
};

export const UserLocaleDropdown = ({ sx }: UserLocaleDropdownPropTypes): ReactElement | null => {
  const { isPending } = useUserInfoQuery();

  const combinedSx = useCombinedStyling(formControlSx, sx);

  if (isPending) {
    return (
      <Stack direction="column" spacing={2}>
        <Skeleton height={20} width={100} />
        <Skeleton height={20} width={450} />
        <Skeleton sx={combinedSx} height={40} width={220} />
      </Stack>
    );
  }

  const dropdown = <LocaleDropdown />;

  return dropdown ? (
    <Stack direction="column" spacing={2}>
      <LocalizedTypography variant="h6">{translations.profile.details.languagePreference}</LocalizedTypography>
      <LocalizedTypography variant="body2" color="text.secondary">
        {translations.profile.details.languagePreferenceMessage}
      </LocalizedTypography>
      {dropdown}
    </Stack>
  ) : null;
};
