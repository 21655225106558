import { KeyboardEvent, ReactElement } from 'react';

import { useMobileView } from '@halo-common/hooks';
import { loginFormSchema } from '@halo-common/schemas';
import { ConnectedContactButton } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { useLoginUserMutation } from '@halo-data-sources/mutations';
import { ForgotPasswordPageMeta } from '@halo-pages/app/onboarding/forgot-password';
import { RegisterPageMeta } from '@halo-pages/app/onboarding/register';
import { LocalizedButton, LocalizedTextField, LocalizedTypography } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, CircularProgress, Paper, Stack } from '@mui/material';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';

const pageLayoutContentSx = {
  py: 5,
  px: 3,
  overflowY: 'auto',
  mb: { xs: 2, sm: 8 },
  minWidth: { xs: 343, sm: 'default' },
  width: { xs: '100%', sm: 450 },
};

const forgotPasswordLinkSx = {
  fontSize: 14,
};

const contactButtonSx = {
  color: 'error.dark',
  pl: 0,
  textDecoration: 'underline',
};

const alertSx = {
  alignItems: 'flex-start',
};

export type LoginFormSchema = {
  email: string;
  password: string;
};

export const LoginForm = (): ReactElement => {
  const router = useRouter();
  const isMobile = useMobileView();

  const { mutate: onLogin, isError: isLoginError, isPending, error: loginError } = useLoginUserMutation();

  const isPasswordSetSuccess = router.query['passwordSuccess'] === 'true';

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<LoginFormSchema>({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    resolver: yupResolver<LoginFormSchema>(loginFormSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { ref: emailRef, ...emailProps } = register('email');
  const { ref: passwordRef, ...passwordProps } = register('password');

  const onSubmit = handleSubmit((data: LoginFormSchema) => {
    const urlParams = new URLSearchParams(window.parent.location.search);
    const nextUrl = urlParams.get('next') ?? '';

    onLogin({
      username: data.email,
      password: data.password,
      next: nextUrl,
    });
  });

  const forgotPasswordNavigationHandler = () => {
    window.location.assign(`/react${ForgotPasswordPageMeta.route}${window.location.search}`);
  };

  const getStartedNavigationHandler = () => {
    window.location.assign(`/react${RegisterPageMeta.route}${window.location.search}`);
  };

  const handleKeyDownEvent = (ev: KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter') void onSubmit();
  };

  const errorAlert = isLoginError ? (
    <Alert sx={alertSx} severity="error" variant="filled">
      <LocalizedTypography variant="body2">{loginError.message}</LocalizedTypography>
      <ConnectedContactButton color="warning" size="small" sx={contactButtonSx} />
    </Alert>
  ) : null;

  const successRegistrationAlert = isPasswordSetSuccess ? (
    <Alert sx={alertSx} variant="filled" severity="success">
      <LocalizedTypography variant="body2">{translations.onboarding.login.passwordSetSuccessfully}</LocalizedTypography>
    </Alert>
  ) : null;

  const startIcon = isPending ? <CircularProgress size="1rem" /> : undefined;

  return (
    <Paper sx={pageLayoutContentSx} variant="outlined">
      <Stack direction="column" spacing={2}>
        <Stack direction="column" spacing={3}>
          {successRegistrationAlert}
          {errorAlert}
          <LocalizedTextField
            id="main"
            fullWidth
            size="large"
            label={translations.onboarding.login.email}
            inputRef={emailRef}
            {...emailProps}
            error={Boolean(errors?.email)}
            helperText={errors?.email?.message}
            onKeyDown={handleKeyDownEvent}
          />
          <Stack direction="column" spacing={1}>
            <LocalizedTextField
              fullWidth
              size="large"
              label={translations.onboarding.login.password}
              type="password"
              inputRef={passwordRef}
              {...passwordProps}
              error={Boolean(errors?.password)}
              helperText={errors?.password?.message}
              autoComplete="current-password"
              onKeyDown={handleKeyDownEvent}
            />
            <LocalizedButton
              sx={forgotPasswordLinkSx}
              size="small"
              color="primary"
              onClick={forgotPasswordNavigationHandler}
            >
              {translations.onboarding.login.forgotPassword}
            </LocalizedButton>
          </Stack>
        </Stack>
        <Stack direction="column" spacing={3}>
          <LocalizedButton
            startIcon={startIcon}
            disabled={isPending}
            fullWidth
            color="primary"
            variant="contained"
            onClick={onSubmit}
          >
            {translations.onboarding.login.login}
          </LocalizedButton>
          <Stack direction="row" spacing={2} alignItems="center" flexWrap="wrap">
            <LocalizedTypography variant="body2">{translations.onboarding.login.noAccount}</LocalizedTypography>
            <LocalizedButton
              disabled={isPending}
              type="button"
              size="small"
              color="primary"
              variant="outlined"
              fullWidth={isMobile}
              onClick={getStartedNavigationHandler}
            >
              {translations.onboarding.login.getStarted}
            </LocalizedButton>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};
