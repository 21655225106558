import { ReactElement } from 'react';

import { AccountTypeEnum } from '@halo-common/enums';
import { OrderTicketFormModelFields } from '@halo-common/schemas';
import { MenuItem, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

export const AccountTypeDropdown = (): ReactElement => {
  const { control, formState } = useFormContext<OrderTicketFormModelFields>();
  const { errors } = formState;

  const accountTypeError = errors?.accountType?.message;

  return (
    <Controller
      render={({ field: { ref, ...field } }) => (
        <TextField
          {...field}
          inputRef={ref}
          fullWidth
          size="large"
          select
          label="Account Type"
          helperText={accountTypeError}
          error={Boolean(accountTypeError)}
        >
          <MenuItem value={AccountTypeEnum.Cash}>Cash</MenuItem>
          <MenuItem value={AccountTypeEnum.Margin}>Margin</MenuItem>
          <MenuItem value={AccountTypeEnum.DVP}>DVP</MenuItem>
        </TextField>
      )}
      control={control}
      defaultValue={undefined}
      name="accountType"
    />
  );
};
