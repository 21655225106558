import { getQueryParams } from '@halo-common/utils';
import { registerUser } from '@halo-data-sources/clients';
import { RegisterFormSchema } from '@halo-modules/app/onboarding';
import { ApprovalRequiredMeta } from '@halo-pages/app/onboarding/approval-required';
import { AwaitRegistrationMeta } from '@halo-pages/app/onboarding/await-registration';
import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';

export type UseRegisterUserMutationOption = UseMutationOptions<RegisterUserMutationModel, Error, RegisterFormSchema>;

export type RegisterUserMutationModel = {
  whiteListed: boolean;
  name: string;
  email: string;
  result: string;
};

export type UseRegisterUserMutationPayload = RegisterFormSchema & { locale: string };

const useRegisterUserMutationFn = async (user: UseRegisterUserMutationPayload) => {
  const repArrayIds = user?.repSettingArrayField?.length
    ? user.repSettingArrayField
        .flatMap((setting) => setting)
        .map((setting) => ({
          rep_id_setting_id: setting.repSettingId,
          value: setting.value,
        }))
        .filter((setting) => setting.value)
    : null;

  const registerRequest = {
    company_role: user.role,
    country_id: user.country,
    company_name: user.companyName,
    email: user.emailAddress,
    first_name: user.firstName,
    last_name: user.lastName,
    locale: user.locale,
    phone: user.phoneNumber,
    region_id: user.region,
    rep_ids: repArrayIds,
    survey_question_answer: user.surveyAnswer ?? undefined,
  };

  const result = await registerUser(registerRequest);

  return {
    whiteListed: result.whitelisted,
    name: user.firstName,
    email: user.emailAddress,
    result: result.result,
  };
};

export const useRegisterUserMutation = (
  options?: UseRegisterUserMutationOption,
): UseMutationResult<RegisterUserMutationModel, Error, UseRegisterUserMutationPayload> =>
  useMutation<RegisterUserMutationModel, Error, UseRegisterUserMutationPayload>({
    mutationFn: useRegisterUserMutationFn,
    onSuccess: (data) => {
      const queryParams = getQueryParams();
      if (data.result.includes('pending')) {
        const params = new URLSearchParams({ ...queryParams, email: data.email });
        window.location.assign(`/react${ApprovalRequiredMeta.route}?${params.toString()}`);
      } else {
        const params = new URLSearchParams({ ...queryParams, email: data.email, name: data.name });
        window.location.assign(`/react${AwaitRegistrationMeta.route}?${params.toString()}`);
      }
    },
    ...options,
  });
