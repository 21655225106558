import { ReactElement } from 'react';

import { calendarSelectedAtom } from '@halo-atoms/calendars';
import { OrderTicketFormModelFields } from '@halo-common/schemas';
import { useCalendarAllocationsQuery } from '@halo-data-sources/queries';
import {
  HaloDataGrid,
  Iconography,
  LocalizedTypography,
  mapNumberToLocalCurrency,
  Modal,
} from '@halodomination/halo-fe-common';
import { Alert, Button, Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useFormContext } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

type OrderTicketConfirmationAllocationRow = {
  id: string;
  identifier?: string;
  count: number;
  total: string;
};

export type OrderTicketConfirmationModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
};

export const OrderTicketConfirmationModal = ({
  open,
  onClose,
  onSubmit,
}: OrderTicketConfirmationModalProps): ReactElement => {
  const { watch } = useFormContext<OrderTicketFormModelFields>();

  const selectedAllocations = watch('allocations');
  const selectedCalendar = useAtomValue(calendarSelectedAtom);

  const { data: allocations = [] } = useCalendarAllocationsQuery(selectedCalendar?.id);

  const rows = selectedAllocations.reduce((list: Array<OrderTicketConfirmationAllocationRow>, { accountOption }) => {
    const account = accountOption?.account;
    const accountId = account?.id;

    const filteredAllocations = allocations.filter(
      ({ account, status }) => account.id === accountId && status !== 'canceled' && status !== 'rejected',
    );

    const allocationCount = filteredAllocations.length;

    if (!allocationCount) return list;

    const identifier = account?.name ?? account?.accountId;
    const allocationTotal = filteredAllocations.reduce((total, { amount }) => total + amount, 0);
    const allocationTotalText = mapNumberToLocalCurrency(allocationTotal);

    return [...list, { id: uuid(), identifier, count: allocationCount, total: allocationTotalText }];
  }, []);

  const handleSubmit = () => void onSubmit();

  return (
    <Modal
      open={open}
      onClose={onClose}
      ContentProps={{ sx: { py: 1, px: 2 } }}
      FooterProps={{ sx: { p: 2 } }}
      size="small"
      title="Warning: Existing Allocations"
      footer={
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Button variant="text" onClick={onClose} startIcon={<Iconography iconName="arrow-left" />}>
            Go Back
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            Acknowledge & Continue To Review
          </Button>
        </Stack>
      }
    >
      <Alert variant="filled" severity="warning">
        <Stack direction="column" spacing={2}>
          <LocalizedTypography variant="body2">
            The following account(s) have existing allocations on this offering. Continuing will submit an additional
            order for these account(s).
          </LocalizedTypography>
          <HaloDataGrid
            disableColumnFilter
            disableColumnMenu
            disableColumnSorting
            disableColumnResize
            disableColumnReorder
            disableColumnPinning
            pagination={rows.length > 10}
            rowCount={rows.length}
            pageSizeOptions={[10]}
            density="compact"
            rows={rows}
            columns={[
              { flex: 2, headerName: 'Account Name', field: 'identifier' },
              { flex: 1, headerName: 'Total Orders', field: 'count' },
              { flex: 1, headerName: 'Total Notional', field: 'total' },
            ]}
          />
        </Stack>
      </Alert>
    </Modal>
  );
};
