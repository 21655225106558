import * as ALLOCATIONS_MODAL_TRANSLATIONS from './allocationsModal';
import * as ANALYTICS_TRANSLATIONS from './analytics';
import * as AURA_TRANSLATIONS from './aura';
import * as CALENDAR_TRANSLATIONS from './calendars';
import * as DASHBOARD_TRANSLATIONS from './dashboard';
import * as DOCUMENTS_TRANSLATIONS from './documents';
import * as EDUCATION_TRANSLATIONS from './education';
import * as EXECUTION_HUB_TRANSLATIONS from './executionHub';
import * as FOOTER_TRANSLATIONS from './footer';
import * as LOADING_DOCK_TRANSLATIONS from './loadingDock';
import * as ONBOARDING_TRANSLATIONS from './onboarding';
import * as ORDERBOOK from './orderbook';
import * as PORTFOLIO_TRANSLATIONS from './portfolio';
import * as PRODUCT_APPROVAL_TRANSLATIONS from './productApproval';
import * as PDM_TRANSLATIONS from './productDetailsModal';
import * as PROFILE_TRANSLATIONS from './profile';
import * as WATCHLIST_TRANSLATIONS from './watchlist';

export const translations = {
  aura: {
    common: AURA_TRANSLATIONS.AURA_TRANSLATIONS_COMMON,
  },
  allocationsModal: {
    calendars: ALLOCATIONS_MODAL_TRANSLATIONS.ALLOCATIONS_MODAL_CALENDAR_TRANSLATIONS,
    common: ALLOCATIONS_MODAL_TRANSLATIONS.ALLOCATIONS_MODAL_COMMON_TRANSLATIONS,
    fixedIncome: ALLOCATIONS_MODAL_TRANSLATIONS.ALLOCATIONS_MODAL_FIXED_INCOME_TRANSLATIONS,
  },
  analytics: {
    common: ANALYTICS_TRANSLATIONS.ANALYTICS_COMMON_TRANSLATIONS,
    rollingReturns: ANALYTICS_TRANSLATIONS.RR_COMMON_TRANSLATIONS,
  },
  annuities: {
    annuities: 'Annuities',
    insuranceTitle: 'Life & Other Insurance',
    insuranceDescription: 'Request a consultation with a life specialist.',
    legacyIntakeDescription: 'Move an existing annuity to Halo through our Legacy Annuity Program.',
    legacyIntakeTitle: 'Legacy Intake',
    legacyIntakeTooltip: 'Please contact your Relationship Manager for more information.',
    newAnnuityDescription: 'Submit client information to start a new annuity case.',
    newAnnuityTitle: 'New Annuity',
    productShelfDescription: 'Access best in class advisory annuity solutions from top carriers in the industry.',
    productShelfTitle: 'Product Shelf',
    reviewAnnuityDescription: 'Submit existing annuity details for an annuity specialist to review.',
    reviewAnnuityTitle: 'Review Annuity',
    serviceCenterDescription: 'Submit a service request to our Service Team.',
    serviceCenterTitle: 'Service Center',
  },
  calendars: {
    common: CALENDAR_TRANSLATIONS.COMMON_TRANSLATIONS,
    createCalendar: CALENDAR_TRANSLATIONS.CREATE_CALENDAR_TRANSLATIONS,
  },
  common: {
    accept: 'Accept',
    account: 'Account',
    accountHousehold: 'Account Household',
    accountName: 'Account Name',
    accountNumber: 'Account Number',
    accountNumberOrName: 'Type account number or name',
    accountNumberPound: 'Account #',
    accounts: 'Accounts',
    accountType: 'Account Type',
    actions: 'Actions',
    addAnother: 'Add Another',
    advisory: 'Advisory',
    agree: 'Agree',
    alerts: 'Alerts',
    all: 'All',
    allocated: 'Allocated',
    allocations: 'Allocations',
    amount: 'Amount',
    analytics: 'Analytics',
    and: 'and',
    annually: 'Annually',
    any: 'Any',
    approved: 'Approved',
    autocall: 'Autocall',
    autoCallable: 'Autocallable',
    average: 'Average',
    back: 'Back',
    begin: 'Begin',
    best: 'Best',
    brokerage: 'Brokerage',
    calendarType: 'Calendar Type',
    callObservation: 'Call Observation',
    cancel: 'Cancel',
    canceled: 'Canceled',
    capped: 'Capped',
    change: 'Change',
    clear: 'Clear',
    clearAll: 'Clear All',
    close: 'Close',
    closed: 'Closed',
    closes: 'Closes',
    commission: 'Commission',
    committed: 'Committed',
    companyName: 'Company Name',
    complete: 'Complete',
    conditionalCoupon: 'Conditional Coupon',
    confirm: 'Confirm',
    confirmDelete: 'Confirm Delete',
    contactUs: 'Contact Us',
    contingent: 'Contingent',
    continue: 'Continue',
    continuousProtection: 'Continuous Protection',
    copied: 'Copied',
    copy: 'Copy',
    country: 'Country',
    coupon: 'Coupon',
    coupons: 'Coupons',
    couponSchedule: 'Coupon Schedule',
    create: 'Create',
    createAnother: 'Create Another',
    createNote: 'Create Note',
    currency: 'Currency',
    custodian: 'Custodian',
    custom: 'Custom',
    dailyProtection: 'Daily Protection',
    date: 'Date',
    delete: 'Delete',
    description: 'Description',
    details: 'Details',
    difference: 'Difference',
    documents: 'Documents',
    done: 'Done',
    download: 'Download',
    downloadCSVTemplate: 'Download CSV Template',
    earlyRedemption: 'Early Redemption',
    edit: 'Edit',
    email: 'Email',
    emailAddress: 'Email Address',
    enabled: 'Enabled',
    error: 'Error',
    excelExport: 'Excel Export',
    exportResults: 'Export Results',
    finish: 'Finish',
    finishNow: 'Finish Now',
    firstName: 'First Name',
    fixed: 'Fixed',
    fixedCoupon: 'Fixed Coupon',
    frequency: 'Frequency',
    from: 'From',
    full: 'Full',
    geared: 'Geared',
    gearing: 'Gearing',
    generateReport: 'Generate Report',
    goBack: 'Go Back',
    guaranteed: 'Guaranteed',
    hard: 'Hard',
    hardProtection: 'Hard Protection',
    highlighted: 'Highlighted',
    household: 'Household',
    issuer: 'Issuer',
    issuerCallable: 'Issuer Callable',
    issuerName: 'Issuer Name',
    language: 'Language',
    lastName: 'Last Name',
    level: 'Level',
    lifecycle: 'Lifecycle',
    marketValue: 'Market Value',
    maturity: 'Maturity',
    max: 'Max',
    memory: 'Memory',
    memoryCoupon: 'Memory Coupon',
    message: 'Message',
    min: 'Min',
    monthly: 'Monthly',
    name: 'Name',
    negative: 'Negative',
    next: 'Next',
    no: 'No',
    noFileError: 'No file provided',
    nonCallable: 'Non Callable',
    nonCallPeriod: 'Non-Call Period',
    noResultsFound: 'No Results Found',
    notCallable: 'Not Callable',
    noteDetails: 'Note Details',
    noteflix: 'Noteflix',
    noteType: 'Note Type',
    notification: 'Notification',
    notifications: 'Notifications',
    notional: 'Notional',
    number: 'Number',
    observationDate: 'Observation Date',
    off: 'Off',
    on: 'On',
    open: 'Open',
    or: 'or',
    organization: 'Organization',
    paid: 'Paid',
    notPaid: 'Not Paid',
    called: 'Called',
    notCalled: 'Not Called',
    pending: 'Pending',
    pendingApproval: 'Pending Approval',
    performance: 'Performance',
    preview: 'Preview',
    principal: 'Principal',
    principalProtection: 'Principal Protection',
    probability: 'Probability',
    pendingProducts: 'Pending Products',
    positive: 'Positive',
    product: 'Product',
    products: 'Products',
    productType: 'Product Type',
    profile: 'Profile',
    quarterly: 'Quarterly',
    quotes: 'Quotes',
    recall: 'Recall',
    received: 'Received',
    refresh: 'Refresh',
    rejected: 'Rejected',
    relationshipManager: 'Relationship Manager',
    remove: 'Remove',
    repCode: 'Rep Code',
    required: 'Required',
    resetZoom: 'Reset Zoom',
    return: 'Return',
    roles: 'Roles',
    salesCredit: 'Sales Credit',
    save: 'Save',
    search: 'Search',
    searchingEllipsis: 'Searching...',
    sendLink: 'Send Link',
    share: 'Share',
    soft: 'Soft',
    softProtection: 'Soft Protection',
    sortBy: 'Sort By',
    spCreditRating: 'S&P Credit Rating',
    status: 'Status',
    submit: 'Submit',
    submitted: 'Submitted',
    submittedFor: 'Submitted For',
    success: 'Success',
    target: 'Target',
    term: 'Term',
    time: 'Time',
    timeElapsed: 'Time Elapsed',
    to: 'To',
    total: 'Total',
    totalAmount: 'Total Amount',
    totalResults: '{count} total results',
    type: 'Type',
    uncapped: 'Uncapped',
    upload: 'Upload',
    view: 'View',
    viewAuction: 'View Auction',
    viewCalendar: 'View Calendar',
    viewMore: 'View More',
    watch: 'Watch',
    watching: 'Watching',
    with: 'with',
    workEmailAddress: 'Work Email Address',
    worst: 'Worst',
    yes: 'Yes',
    zero: 'Zero',
  },
  components: {
    accountTypeAheadNoHouseholdsOption: 'No Households',
    accountTypeAheadNoMatchesMessage: 'No Matching Accounts or Households',
    accountTypeAheadPlaceholder: 'Search by account id, or household name',
    addOption: 'Add "{value}"',
    contactFormCompanyRequired: 'Company name is required.',
    contactFormEmailRequired: 'Email is required.',
    contactFormFirstNameRequired: 'First name is required.',
    contactFormHeaderHelp: "Sorry you're having trouble, we're here to help.",
    contactFormHeaderSuccess: 'Thanks, a member of our team will reach out to you shortly.',
    contactFormIssue: 'Please be descriptive about your issue, and a representative will reach out to help shortly.',
    contactFormLastNameRequired: 'Last name is required.',
    contactFormMessageRequired: 'Message is required.',
    contactFormOverLine: 'Contact Us',
    createSimilarButtonText: 'Create Similar',
    endOfResults: 'End of Results',
    estimatedTransactionSectionTitle: 'Estimated Transaction Value',
    findPriceButtonRefreshText: 'Refresh Price',
    findPriceButtonText: 'Get Price',
    findPriceMessage: 'Finding price',
    householdFormFieldHelperText: 'NEW - This will create a new Household.',
    organizationTypeAheadBegin: 'Begin by typing an organization name',
    organizationTypeAheadNoResults: '{org} was not found',
    productCardBackTitle: 'note summary',
    productCardRemoveMessage: `This cannot be undone and the product will need to be recreated if you’d like to re-add it.`,
    productCardRemoveTitle: 'Remove product from watchlist',
    relationshipManagerTypeAhead: 'Search For Relationship Manager',
    setRange: 'SET RANGE',
    underlyingAutocompleteBegin: 'Begin by typing a ticker or company name.',
    underlyingAutocompleteNoResults: '"{ticker}" was not found.',
    underlyingPerformanceTableBarrierLevelHeader: 'barrier level',
    underlyingPerformanceTableCurrentLevelHeader: 'current level',
    underlyingPerformanceTableDistanceBarrierHeader: 'dist. to barrier',
    underlyingPerformanceTableInitialLevelHeader: 'initial level',
    uploadCsv: 'Upload CSV',
    watchlistNewWatchlistOption: 'New Watchlist',
    watchlistPopoverTab: 'Watchlist',
    watchlistPopoverTargetingMessage: `Targeting {targetedQuote}%`,
    watchlistPopoverTargetTab: 'Target Quote',
    watchlistTypeAheadEmptyMessage: 'Enter Text',
    watchListTypeAheadHelperText: 'Start typing to create a new watchlist',
    watchlistTypeAheadLabel: 'Search for a watchlist',
  },
  contentManagement: {
    loginPageManagement: ONBOARDING_TRANSLATIONS.LOGIN_MANAGEMENT_TRANSLATIONS,
    title: 'Content Management',
  },
  dashboard: {
    common: DASHBOARD_TRANSLATIONS.DASHBOARD_COMMON_TRANSLATIONS,
    educationCourses: DASHBOARD_TRANSLATIONS.DASHBOARD_EDUCATION_TRANSLATIONS,
    featuredNotes: DASHBOARD_TRANSLATIONS.DASHBOARD_FEATURED_NOTES_TRANSLATIONS,
    noteflix: DASHBOARD_TRANSLATIONS.DASHBOARD_NOTEFLIX_TRANSLATIONS,
    orderBook: DASHBOARD_TRANSLATIONS.DASHBOARD_ORDER_BOOK_TRANSLATIONS,
    portfolioLifecycle: DASHBOARD_TRANSLATIONS.DASHBOARD_PORTFOLIO_LIFECYCLE_TRANSLATIONS,
    portfolioPositions: DASHBOARD_TRANSLATIONS.DASHBOARD_PORTFOLIO_POSITIONS_TRANSLATIONS,
    productShelf: DASHBOARD_TRANSLATIONS.DASHBOARD_PRODUCT_SHELF_TRANSLATIONS,
    watchlist: DASHBOARD_TRANSLATIONS.DASHBOARD_WATCHLIST_TRANSLATIONS,
  },
  documents: {
    termsheets: DOCUMENTS_TRANSLATIONS.DOCUMENTS_TERMSHEETS_TRANSLATIONS,
  },
  dynamicFilters: {
    common: CALENDAR_TRANSLATIONS.DYNAMIC_FILTER_TRANSLATIONS,
  },
  education: {
    educationFinishedPage: EDUCATION_TRANSLATIONS.EDUCATION_FINISHED_PAGE_TRANSLATIONS,
    educationLanding: EDUCATION_TRANSLATIONS.EDUCATION_LANDING_TRANSLATIONS,
    educationQuizManager: EDUCATION_TRANSLATIONS.EDUCATION_QUIZ_MANAGER_TRANSLATIONS,
  },
  executionHub: {
    fillModal: EXECUTION_HUB_TRANSLATIONS.EXECUTION_HUB_FILL_MODAL,
    reofferModal: EXECUTION_HUB_TRANSLATIONS.EXECUTION_HUB_EDIT_REOFFER_MODAL,
  },
  footer: {
    pageFooter: FOOTER_TRANSLATIONS.PAGE_FOOTER_TRANSLATIONS,
  },
  loadingDock: {
    uploadFile: LOADING_DOCK_TRANSLATIONS.UPLOAD_FILE,
  },
  messages: {
    areYouSure: 'Are you sure?',
    endOfResults: 'End of Results',
    error: 'Something went wrong, please try again later.',
    errorContactSupport: `An unexpected error occurred. Please try again later or contact support if the problem persists.`,
    generateHistoricalReportPending: 'Generating historical report...',
    generateHistoricalReportSuccess: 'Successfully generated the report.',
    invalidDate: 'Invalid date.',
    marketDataUnavailable: 'Market Data Unavailable',
    noAssetsFound: 'No Assets Found',
    noAvailableFilters: 'No Available Filters',
    noNotes: 'No Notes',
    noResults: 'No Results',
    calendarMissingAccess: 'That Calendar might not exist, or you may not have access to it.',
    noteMissingAccess: 'That Note might not exist, or you may not have access to it.',
    calendarNotFound: 'Calendar not found.',
    noteNotFound: 'Note not found.',
    requiredField: 'This field is required.',
    returnHome: 'Return to Home',
    targetQuoteMaximum: 'Target quote must be a maximum of 99,999.99',
    targetQuoteMinimum: 'Target quote must be a minimum of 0.01',
    validEmail: 'Please enter a valid email address.',
    youMustSelectAFile: 'You must select a file.',
    zoomInstructions: 'Click and drag to zoom',
  },
  months: {
    april: 'April',
    aprilShort: 'Apr',
    august: 'August',
    augustShort: 'Aug',
    december: 'December',
    decemberShort: 'Dec',
    february: 'February',
    februaryShort: 'Feb',
    january: 'January',
    januaryShort: 'Jan',
    july: 'July',
    julyShort: 'Jul',
    june: 'June',
    juneShort: 'Jun',
    march: 'March',
    marchShort: 'Mar',
    may: 'May',
    september: 'September',
    septemberShort: 'Sep',
    october: 'October',
    octoberShort: 'Oct',
    november: 'November',
    novShort: 'Nov',
  },
  noteCreate: {
    basketType: 'Basket Type',
    commonAndTrending: 'Common & Trending Tickers',
    commonProtections: 'Common Protection Selections',
    commonTerms: 'Common Term Lengths (Months)',
    title: 'Note Creator',
    underlyingAssets: 'Underlying Assets',
    principalProtection: 'Principal Protection',
    weightsAddUp: 'All weights must add up to 100%',
    weighted: 'Weighted',
    worstOf: 'Worst Of',
  },
  noteTypes: {
    Absolute: 'Absolute',
    Digital: 'Digital',
    General: 'General',
    Growth: 'Growth',
    Income: 'Income',
  },
  onboarding: {
    crd: ONBOARDING_TRANSLATIONS.CRD_TRANSLATIONS,
    disclaimers: ONBOARDING_TRANSLATIONS.DISCLAIMERS_TRANSLATIONS,
    linkExpiredForm: ONBOARDING_TRANSLATIONS.LINK_EXPIRED_TRANSLATIONS,
    login: ONBOARDING_TRANSLATIONS.LOGIN_TRANSLATIONS,
    recoverPassword: ONBOARDING_TRANSLATIONS.RECOVER_PASSWORD_TRANSLATIONS,
    register: ONBOARDING_TRANSLATIONS.REGISTER,
    setPassword: ONBOARDING_TRANSLATIONS.SET_PASSWORD_TRANSLATIONS,
    suitability: ONBOARDING_TRANSLATIONS.SUITABILITY_TRANSLATIONS,
  },
  orderBook: {
    auctions: ORDERBOOK.AUCTIONS,
  },
  orderTicket: {
    janneyFooterNotionalTotal: 'Total Par Value: {total}',
    footerNotionalTotal: 'Total: {total}',
    footerAccountTotal: 'Accounts: {count}',
    addAccountButton: 'Add Account',
    dateTimeCaption: 'Order Receipt Date & Time',
    disclaimer: `I have reviewed and disclosed the details regarding this bond to my client. This includes information known by or reasonably accessible to the market and available to me through publicly available sources`,
    downloadTemplateButton: 'Download Template',
    duplicatedAccountsMessage: `Account Duplicated`,
    reviewCardTitle: `Buy {quantity} Bond(s) of {underlyings}`,
    submissionAlertMessage: `This interest was submitted on {dateText} at {timeText}.`,
    ttoRepCodeLengthMessage: `Rep codes must be 4 characters long.`,
    uploadSpreadsheetButton: 'Upload Spreadsheet',
  },
  pdm: {
    calendars: PDM_TRANSLATIONS.PRODUCT_DETAILS_MODAL_CALENDARS,
    common: PDM_TRANSLATIONS.PRODUCT_DETAILS_MODAL_COMMON,
    execution: PDM_TRANSLATIONS.PRODUCT_DETAILS_MODAL_EXECUTION,
    postTrade: PDM_TRANSLATIONS.PRODUCT_DETAILS_MODAL_POST_TRADE,
    preTrade: PDM_TRANSLATIONS.PRODUCT_DETAILS_MODAL_PRE_TRADE,
    rollingReturns: PDM_TRANSLATIONS.PRODUCT_DETAILS_ROLLING_RETURNS,
  },
  portfolio: {
    accounts: PORTFOLIO_TRANSLATIONS.ACCOUNT_PAGE_TRANSLATIONS,
    addAccountModal: PORTFOLIO_TRANSLATIONS.ADD_ACCOUNT_TRANSLATIONS,
    addAccountOrPositionsModal: PORTFOLIO_TRANSLATIONS.ADD_ACCOUNT_OR_POSITIONS_TRANSLATIONS,
    addPositionsToAccountModal: PORTFOLIO_TRANSLATIONS.ADD_POSITIONS_TO_ACCOUNT_TRANSLATIONS,
    addPositionToAccountsModal: PORTFOLIO_TRANSLATIONS.ADD_POSITION_TO_ACCOUNTS_TRANSLATIONS,
    common: PORTFOLIO_TRANSLATIONS.COMMON_TRANSLATIONS,
    custodianModal: PORTFOLIO_TRANSLATIONS.CUSTODIAN_MODAL_TRANSLATIONS,
    deleteAccountModal: PORTFOLIO_TRANSLATIONS.DELETE_ACCOUNT_MODAL_TRANSLATIONS,
    generateReportModal: PORTFOLIO_TRANSLATIONS.GENERATE_REPORT_TRANSLATIONS,
    lifecycle: PORTFOLIO_TRANSLATIONS.LIFECYCLE_PAGE_TRANSLATIONS,
    positions: PORTFOLIO_TRANSLATIONS.POSITIONS_PAGE_TRANSLATIONS,
  },
  productApproval: {
    editCloseDateModal: PRODUCT_APPROVAL_TRANSLATIONS.PRODUCT_APPROVAL_EDIT_CLOSE_DATE_MODAL_TRANSLATIONS,
  },
  profile: {
    alerts: PROFILE_TRANSLATIONS.ALERT_TRANSLATIONS,
    common: PROFILE_TRANSLATIONS.PROFILE_TRANSLATIONS_COMMON,
    details: PROFILE_TRANSLATIONS.PROFILE_DETAILS_TRANSLATIONS,
    documents: PROFILE_TRANSLATIONS.PROFILE_DOCUMENTS_TRANSLATIONS,
    notifications: PROFILE_TRANSLATIONS.PROFILE_NOTIFICATIONS_TRANSLATIONS,
    password: PROFILE_TRANSLATIONS.PROFILE_PASSWORD_TRANSLATIONS,
  },
  sma: {
    acceptAcknowledgement: 'You must accept the acknowledgement(s) to proceed.',
    acceptStrategyDeliveredADVs: `I have delivered the Halo and Third-Party Manager ADVs and Form CRS to the End Client(s).“`,
    acceptStrategyInvestmentObjectives: `I have reviewed the selected strategy and it is consistent with the client's investment objectives and risk tolerance.`,
    allocationAmount: 'Allocation Amount',
    custodianWarning: 'Only accounts that custody at {custodians} may be invested in this SMA.',
    haloInvestmentServices: 'Halo Investment Services',
    investmentRestriction: 'Include any reasonable investment restrictions that exist for the entered account, if any.',
    minAmount: 'Amount must be at least {minimumInvestment}',
    minimum: 'Minimum',
    minimumDynamic: 'Minimum: {minimumInvestment}',
    minimumInvestment: 'Minimum Investment',
    minimumThousand: 'Please enter at least $1,000',
    multipleOfThousands: 'Amount must be in increments of $1,000',
    orderDetails: 'Order Details',
    orderReceivedFrom: 'Order Received From',
    reasonableRestrictions: 'Reasonable Restrictions',
    receiptDateAndTime: 'Order Receipt Date & Time',
    selectAccount: 'Please select an account.',
    submitAllocation: 'Submit Allocation',
  },
  underlying: {
    basketTypeSingle: 'Single',
    basketTypeWeighted: 'Weighted',
    basketTypeWorst: 'Worst Of',
  },
  userAndOrgCreation: {
    addRow: 'Add Row',
    addUser: 'Add User',
    bulkCreateUsers: 'Bulk Create Users',
    bulkCreateUsersSuccess: 'Successfully Created Users',
    bulkActivationEmailSent: 'An activation email was sent to these users to set a password',
    createMoreUsers: 'Create More Users',
    createNewUser: 'Create New User',
    createNewOrg: 'Create New Organization',
    createUser: 'Create User',
    createUsers: 'Create Users',
    downloadTemplate: 'Download Template CSV',
    emailError: 'Please enter a valid email address.',
    emailSuffix: 'Email Suffix',
    emailSuffixError: 'Please enter a valid email suffix.',
    emailSuffixHelperText: 'Anyone with this email suffix URL will be automatically added into this organization.',
    firstNameError: 'Please enter a first name.',
    lastNameError: 'Please enter a last name.',
    newOrganization: 'New Organization',
    newOrgAlert: `By hitting Next, a new organization with these details will be created with the default White Label settings.`,
    nextCreateUser: 'Next: Create User',
    nextCreateUsers: 'Next: Create Users',
    orgNameError: 'Please enter an organization name.',
    orgSelectError: 'Please select an existing Organization or create a new one.',
    prefilledFromOrg: 'Prefilled from the Organization settings',
    relationshipManagerError: 'Please select a relationship manager.',
    roleError: 'Please select at least one role.',
    rowsEditable: 'Rows can be edited directly.',
    selectOrganization: 'Select Organization',
    successUserCreation: 'User Created Successfully',
    successOrgCreation: 'Successfully created {org}, new user will be assigned.',
    userInformation: 'User Information',
    userCreated: 'User Created Successfully',
  },
  watchlist: {
    common: WATCHLIST_TRANSLATIONS.DEFAULT_WATCHLIST_TRANSLATIONS,
  },
};
