import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { navigateParentTo } from '@halo-common/utils';
import { useSuitabilityFormManager } from '@halo-modules/app/onboarding/subPages/suitability/hooks';
import { Confetti, Iconography, LocalizedButton, LocalizedTypography } from '@halodomination/halo-fe-common';

export const SuitabilityCompleted = (): ReactElement => {
  const { formData } = useSuitabilityFormManager();

  const navigateToHalo = () => navigateParentTo('/user/dashboard');

  const authorizedSigner = formData?.firm_details?.authorized_persons?.authorized_signer;

  const dynamicContent = {
    authorizedSignerName: authorizedSigner?.name || 'authorized signer',
  };

  return (
    <>
      <Confetti width={window.innerWidth} numberOfPieces={115} initialVelocityY={4} />
      <Iconography iconName="party-horn" prefix="far" size="3x" />
      <LocalizedTypography variant="h4" align="center" mt={3} mb={1}>
        {translations.onboarding.suitability.completed.title}
      </LocalizedTypography>
      <LocalizedTypography variant="body1" align="center" color="text.secondary" mb={3} dynamicContent={dynamicContent}>
        {translations.onboarding.suitability.completed.description}
      </LocalizedTypography>
      <LocalizedButton variant="contained" onClick={navigateToHalo} size="large">
        {translations.onboarding.suitability.continueToHalo}
      </LocalizedButton>
    </>
  );
};
