import { getClientConfiguration, request } from '@halo-data-sources/clients';
import { ApiUserRoleEnum } from '@halo-data-sources/enums';
import {
  ApiAdminAuctionChooseWinnerRequestModel,
  ApiAdminAuctionOpsApprovalPreviewRequestModel,
  ApiAdminAuctionOpsApprovalRequestModel,
  ApiAdminAuctionPendingResponseModel,
  ApiAdminAuctionPreApprovalRequestModel,
  ApiAdminAuctionPreviewResponseModel,
  ApiAdminAuctionResponseModel,
  ApiAdminAuctionSendTestEmailRequestModel,
  ApiAdminAuctionTackOnResponseModel,
  ApiAdminAuctionsResponseModel,
  ApiDocumentModel,
  ApiGetAdminCalendarPagesResponseModel,
  ApiGetAdminCalendarsResponse,
  ApiGetAdminIssuersByCalendarPageResponseModel,
  ApiGetCalendarsCsvResponse,
  ApiGetEducationHubCSVRequestModel,
  ApiGetEducationHubDetailsRequestModel,
  ApiGetEducationHubDetailsResponseModel,
  ApiGetPendingAdminCalendarsResponse,
  ApiPagedUserActivityModel,
  ApiPagedUserManagementModel,
  ApiPatchAdminAuctionRequestModel,
  ApiPostAdminCalendarsOptionsModel,
  ApiPostBatchAdminCalendarsResponse,
  ApiPostCalendarWholesalersResponseModel,
  ApiPutCalendarsResponse,
  ApiSearchAdminOrganizationsResponse,
  ApiSearchAdminUsersResponse,
  ApiUpdateUserRolesResponseModel,
  ApiUserActivityActionResponseModel,
  ApiUserManagementUserModel,
  CreateUsersResponse,
  CreateUsersRequest,
  GetRelationshipManagerResponseModel,
  ApiQueryRequestModel,
} from '@halo-data-sources/models';

const EXECUTION_AUCTION_ADMIN_PATH = '/execution/auction/admin';
const PRETRADE_ADMIN_PATH = '/pretrade/admin';
const COMMON_PATH = '/common';

const ADMIN_PATH = '/issuer/v1/admin';

// TODO: Remove when HADT-18520 is ready to be worked on
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const putAdminCalendarFile = async (file: File): Promise<any> => {
  const clientConfiguration = await getClientConfiguration(ADMIN_PATH);

  const path = `${clientConfiguration.basePath}/calendar/upload/csv`;

  const data = new FormData();
  data.append('file', file);
  data.append('_csrf_token', clientConfiguration.csrfToken as string);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return await request<any>(path, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    headers: { 'Content-Type': 'multipart/form-data' },
    body: data,
  });
};

export const getAdminUsers = async (params: URLSearchParams): Promise<ApiPagedUserManagementModel> => {
  const clientConfiguration = await getClientConfiguration(ADMIN_PATH);

  const queryString = params.toString() ? `?${params.toString()}` : '';
  const path = `${clientConfiguration.basePath}/manage/users${queryString}`;

  const response = await request<ApiPagedUserManagementModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const updateUserRoles = async (
  userId: number,
  role: ApiUserRoleEnum,
  addRole: boolean,
): Promise<ApiUpdateUserRolesResponseModel> => {
  const clientConfiguration = await getClientConfiguration(ADMIN_PATH);

  const response = await request<ApiUpdateUserRolesResponseModel>(`${clientConfiguration.basePath}/manage/user/roles`, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      user: userId,
      role,
      action: addRole ? 'add' : 'remove',
    }),
  });

  return response;
};

export const searchRelationshipManagers = async (
  payload?: ApiQueryRequestModel,
): Promise<GetRelationshipManagerResponseModel> => {
  const clientConfiguration = await getClientConfiguration(COMMON_PATH);

  const response = await request<GetRelationshipManagerResponseModel>(
    `${clientConfiguration.basePath}/relationship_manager/query`,
    {
      ...clientConfiguration.requestInit,
      method: 'POST',
      body: JSON.stringify({
        _csrf_token: clientConfiguration.csrfToken,
        ...payload,
      }),
    },
  );

  return response;
};

export const updateUserRelationshipManager = async (
  userId: number,
  managerId: number,
): Promise<ApiUserManagementUserModel> => {
  const clientConfiguration = await getClientConfiguration(ADMIN_PATH);

  const response = await request<ApiUserManagementUserModel>(
    `${clientConfiguration.basePath}/manage/relationship-managers/users/${userId}`,
    {
      ...clientConfiguration.requestInit,
      method: 'POST',
      body: JSON.stringify({
        _csrf_token: clientConfiguration.csrfToken,
        relationship_manager: managerId,
      }),
    },
  );

  return response;
};

export const getUserActivityActions = async (params: URLSearchParams): Promise<ApiUserActivityActionResponseModel> => {
  const clientConfiguration = await getClientConfiguration(ADMIN_PATH);

  const query = params.toString();
  const queryParameters = query ? `?${query}` : '';

  const response = await request<ApiUserActivityActionResponseModel>(
    `${clientConfiguration.basePath}/manage/activity-feed-actions${queryParameters}`,
    {
      ...clientConfiguration.requestInit,
      method: 'GET',
    },
  );

  return response;
};

export const getUserActivity = async (params: URLSearchParams): Promise<ApiPagedUserActivityModel> => {
  const clientConfiguration = await getClientConfiguration(ADMIN_PATH);

  const query = params.toString();
  const queryParameters = query ? `?${query}` : '';

  const response = await request<ApiPagedUserActivityModel>(
    `${clientConfiguration.basePath}/manage/activity-feed${queryParameters}`,
    {
      ...clientConfiguration.requestInit,
      method: 'GET',
    },
  );

  return response;
};

export const fetchAdminAuctionDetails = async (auctionId: string): Promise<ApiAdminAuctionResponseModel> => {
  const clientConfiguration = await getClientConfiguration(ADMIN_PATH);

  const response = await request<ApiAdminAuctionResponseModel>(
    `${clientConfiguration.basePath}/auction/${auctionId}/details`,
    {
      ...clientConfiguration.requestInit,
      method: 'GET',
    },
  );

  return response;
};

export const fetchOrderAuctions = async (): Promise<ApiAdminAuctionsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_AUCTION_ADMIN_PATH);

  const response = await request<ApiAdminAuctionsResponseModel>(`${clientConfiguration.basePath}/query`, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const fetchOrderAuction = async (id: number): Promise<ApiAdminAuctionPendingResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_AUCTION_ADMIN_PATH);

  const response = await request<ApiAdminAuctionPendingResponseModel>(`${clientConfiguration.basePath}/${id}`, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const requestOrderAuctionQuoteCommentApproval = async (
  id: string,
  comment: string,
): Promise<ApiAdminAuctionPendingResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_AUCTION_ADMIN_PATH);

  const response = await request<ApiAdminAuctionPendingResponseModel>(
    `${clientConfiguration.basePath}/comment/${id}/approve`,
    {
      ...clientConfiguration.requestInit,
      method: 'PUT',
      body: JSON.stringify({
        approved: true,
        issuerComment: comment,
        _csrf_token: clientConfiguration.csrfToken,
      }),
    },
  );

  return response;
};

export const createOrderAuctionQuoteComment = async (
  id: string,
  comment: string,
): Promise<ApiAdminAuctionPendingResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_AUCTION_ADMIN_PATH);

  const response = await request<ApiAdminAuctionPendingResponseModel>(
    `${clientConfiguration.basePath}/comment/${id}/create`,
    {
      ...clientConfiguration.requestInit,
      method: 'POST',
      body: JSON.stringify({
        comment,
        _csrf_token: clientConfiguration.csrfToken,
      }),
    },
  );

  return response;
};

export const updateAdminAuction = async (
  id: number,
  body: ApiPatchAdminAuctionRequestModel,
): Promise<ApiAdminAuctionPendingResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_AUCTION_ADMIN_PATH);

  const response = await request<ApiAdminAuctionPendingResponseModel>(`${clientConfiguration.basePath}/${id}`, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      ...body,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const sendOrderAuctionTestEmail = async (
  auctionId: number,
  body: ApiAdminAuctionSendTestEmailRequestModel,
): Promise<void> => {
  const clientConfiguration = await getClientConfiguration(ADMIN_PATH);

  await request<void>(`${clientConfiguration.basePath}/sendmail`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      ...body,
      auction_id: auctionId,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });
};

export const requestOrderAuctionPreApproval = async (
  body: ApiAdminAuctionPreApprovalRequestModel,
): Promise<ApiAdminAuctionPendingResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_AUCTION_ADMIN_PATH);

  const response = await request<ApiAdminAuctionPendingResponseModel>(`${clientConfiguration.basePath}/approve`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      ...body,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const requestOrderAuctionOpsApproval = async (
  body: ApiAdminAuctionOpsApprovalRequestModel,
): Promise<ApiAdminAuctionPendingResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_AUCTION_ADMIN_PATH);

  const response = await request<ApiAdminAuctionPendingResponseModel>(`${clientConfiguration.basePath}/ops/approve`, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      ...body,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const requestOrderAuctionOpsApprovalPreview = async (
  body: ApiAdminAuctionOpsApprovalPreviewRequestModel,
): Promise<ApiAdminAuctionPreviewResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_AUCTION_ADMIN_PATH);

  const response = await request<ApiAdminAuctionPreviewResponseModel>(
    `${clientConfiguration.basePath}/ops/approve/email/preview`,
    {
      ...clientConfiguration.requestInit,
      method: 'POST',
      body: JSON.stringify({
        ...body,
        _csrf_token: clientConfiguration.csrfToken,
      }),
    },
  );

  return response;
};

export const requestOrderAuctionChooseWinner = async (
  body: ApiAdminAuctionChooseWinnerRequestModel,
): Promise<ApiAdminAuctionPendingResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_AUCTION_ADMIN_PATH);

  const { auction_id, quote_id, force } = body.payload;

  const path = `${clientConfiguration.basePath}/${auction_id}/choose-winner`;

  const response = await request<ApiAdminAuctionPendingResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      quote_id,
      force,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const getAdminCalendars = async (query: string): Promise<ApiGetAdminCalendarsResponse> => {
  const clientConfiguration = await getClientConfiguration(ADMIN_PATH);

  const path = `${clientConfiguration.basePath}/calendars${query}`;

  return await request<ApiGetAdminCalendarsResponse>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });
};

export const postBatchAdminCalendars = async (
  options: Array<ApiPostAdminCalendarsOptionsModel>,
): Promise<ApiPostBatchAdminCalendarsResponse> => {
  const clientConfiguration = await getClientConfiguration(ADMIN_PATH);

  const requests = options.map(({ query = {}, sorting }) =>
    request<ApiGetAdminCalendarsResponse>(`${clientConfiguration.basePath}/calendars`, {
      ...clientConfiguration.requestInit,
      method: 'POST',
      body: JSON.stringify({
        ...query,
        sort: sorting,
        _csrf_token: clientConfiguration.csrfToken,
      }),
    }),
  );

  return await Promise.all(requests);
};

export const postPendingAdminCalendars = async (
  option: ApiPostAdminCalendarsOptionsModel,
): Promise<ApiGetPendingAdminCalendarsResponse> => {
  const clientConfiguration = await getClientConfiguration(ADMIN_PATH);

  const path = `${clientConfiguration.basePath}/calendars/pending`;

  return await request<ApiGetPendingAdminCalendarsResponse>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      ...option.query,
      sort: option.sorting,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });
};

export const putAdminCalendar = async (
  id: number,
  body: { closingDate: string; timezone: string | null },
): Promise<ApiPutCalendarsResponse> => {
  const clientConfiguration = await getClientConfiguration(ADMIN_PATH);

  const path = `${clientConfiguration.basePath}/calendar/${id}`;

  return await request<ApiPutCalendarsResponse>(path, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...body,
    }),
  });
};

export const postTackOn = async (
  id: number,
  reoffer: number,
  pages: Array<number>,
  externalName: string | null,
  internalName: string | null,
  issuer: number | null,
  category: string,
  startShowing: string,
  expiration: string,
): Promise<ApiAdminAuctionTackOnResponseModel> => {
  const clientConfiguration = await getClientConfiguration(ADMIN_PATH);

  const path = `${clientConfiguration.basePath}/auction/tackon/${id}`;

  const response = await request<ApiAdminAuctionTackOnResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      reoffer,
      pages,
      externalName,
      internalName,
      issuer,
      category,
      startShowing,
      expiration,
    }),
  });

  return response;
};

export const putTackOn = async (
  id: number,
  reoffer: number,
  pages: Array<number>,
  externalName: string | null,
  internalName: string | null,
  issuer: number | null,
  category: string,
  startShowing: string,
  expiration: string,
): Promise<ApiAdminAuctionTackOnResponseModel> => {
  const clientConfiguration = await getClientConfiguration(ADMIN_PATH);

  const path = `${clientConfiguration.basePath}/auction/tackon/${id}`;

  const response = await request<ApiAdminAuctionTackOnResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'PUT',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      reoffer,
      pages,
      externalName,
      internalName,
      issuer,
      category,
      startShowing,
      expiration,
    }),
  });

  return response;
};

export const deleteTackOn = async (id: number): Promise<ApiAdminAuctionTackOnResponseModel> => {
  const clientConfiguration = await getClientConfiguration(ADMIN_PATH);

  const path = `${clientConfiguration.basePath}/auction/tackon/${id}`;

  const response = await request<ApiAdminAuctionTackOnResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'DELETE',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const getAdminCalendarPages = async (): Promise<ApiGetAdminCalendarPagesResponseModel> => {
  const clientConfiguration = await getClientConfiguration(ADMIN_PATH);

  const path = `${clientConfiguration.basePath}/calendarpage/search`;

  const response = await request<ApiGetAdminCalendarPagesResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const getAdminIssuersByCalendarPage = async (
  calendarPageId: string,
  queryParams?: string,
): Promise<ApiGetAdminIssuersByCalendarPageResponseModel> => {
  const clientConfiguration = await getClientConfiguration(ADMIN_PATH);

  const path = `${clientConfiguration.basePath}/calendar/page/${calendarPageId}/search${queryParams}`;

  const response = await request<ApiGetAdminIssuersByCalendarPageResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const getAdminCalendarsCsv = async (route: string, query = ''): Promise<ApiGetCalendarsCsvResponse> => {
  const clientConfiguration = await getClientConfiguration(ADMIN_PATH);

  const path = `${clientConfiguration.basePath}/calendar/csv/${route}${query}`;

  return await request<ApiGetCalendarsCsvResponse>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });
};

export const searchAdminUsers = async (query: string): Promise<ApiSearchAdminUsersResponse> => {
  const clientConfiguration = await getClientConfiguration(ADMIN_PATH);

  const queryParameters = query ? `?${query}` : '';
  const path = `${clientConfiguration.basePath}/user_search${queryParameters}`;

  return await request<ApiSearchAdminUsersResponse>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });
};

export const searchAdminOrganization = async (query: string): Promise<ApiSearchAdminOrganizationsResponse> => {
  const clientConfiguration = await getClientConfiguration(ADMIN_PATH);

  const path = `${clientConfiguration.basePath}/org_search${query}`;

  return await request<ApiSearchAdminOrganizationsResponse>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });
};

export const getEducationHubCSV = async (payload?: ApiGetEducationHubCSVRequestModel): Promise<ApiDocumentModel> => {
  const clientConfiguration = await getClientConfiguration(ADMIN_PATH);

  return await request<ApiDocumentModel>(`${clientConfiguration.basePath}/user/education/csv`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      ...payload,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });
};

export const getEducationHubDetails = async (
  payload?: ApiGetEducationHubDetailsRequestModel,
): Promise<ApiGetEducationHubDetailsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(ADMIN_PATH);

  const response = await request<ApiGetEducationHubDetailsResponseModel>(
    `${clientConfiguration.basePath}/user/education`,
    {
      ...clientConfiguration.requestInit,
      method: 'POST',
      body: JSON.stringify({
        ...payload,
        _csrf_token: clientConfiguration.csrfToken,
      }),
    },
  );

  return response;
};

export const fetchWholesalers = async (): Promise<ApiPostCalendarWholesalersResponseModel> => {
  const clientConfiguration = await getClientConfiguration(ADMIN_PATH);

  const path = `${clientConfiguration.basePath}/wholesaler`;

  const response = await request<ApiPostCalendarWholesalersResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const createUsers = async (payload: CreateUsersRequest): Promise<CreateUsersResponse> => {
  const clientConfiguration = await getClientConfiguration(PRETRADE_ADMIN_PATH);

  const path = `${clientConfiguration.basePath}/create_user`;

  const response = await request<CreateUsersResponse>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });

  return response;
};
