import { translations } from '@halo-common/translations';
import {
  mixed,
  object as yupObject,
  array as yupArray,
  string as yupString,
  number as yupNumber,
  boolean as yupBoolean,
} from 'yup';

const noteIdRequired = translations.calendars.createCalendar.errors.noteIdRequired;
const noteIdType = translations.calendars.createCalendar.errors.noteIdType;
const noteQuoteRequired = translations.calendars.createCalendar.errors.noteQuoteRequired;
const noteQuoteMin = translations.calendars.createCalendar.errors.noteQuoteMin;
const displayNameRequired = translations.calendars.createCalendar.errors.displayNameRequired;
const cusipRequired = translations.calendars.createCalendar.errors.cusipRequired;
const cusipType = translations.calendars.createCalendar.errors.cusipType;
const reofferRequired = translations.calendars.createCalendar.errors.reofferRequired;
const reofferType = translations.calendars.createCalendar.errors.reofferType;
const reofferMin = translations.calendars.createCalendar.errors.reofferMin;
const reofferMax = translations.calendars.createCalendar.errors.reofferMax;
const tradeDateRequired = translations.calendars.createCalendar.errors.tradeDateRequired;
const issueDateRequired = translations.calendars.createCalendar.errors.issueDateRequired;
const closeDateRequired = translations.calendars.createCalendar.errors.closeDateRequired;
const preliminaryTermsheetRequired = translations.calendars.createCalendar.errors.preliminaryTermsheetRequired;
const issuerRequired = translations.calendars.createCalendar.errors.issuerRequired;
const categoryRequired = translations.calendars.createCalendar.errors.categoryRequired;
const settlementTypeRequired = translations.calendars.createCalendar.errors.settlementTypeRequired;
const wholesalerIsAlreadySelected = translations.calendars.createCalendar.errors.wholesalerIsAlreadySelected;
const wholesalerRequired = translations.calendars.createCalendar.errors.wholesalerRequired;
const purchasePrice = translations.calendars.createCalendar.errors.purchasePrice;
const resalePrice = translations.calendars.createCalendar.errors.resalePrice;

export const adminCreateCalendarSettingsSectionSchema = yupArray()
  .of(
    yupObject()
      .shape({
        wholesaler: yupString().required(wholesalerRequired),
        purchasePrice: yupNumber().typeError(purchasePrice).required(purchasePrice),
        resalePrice: yupNumber().typeError(resalePrice).required(resalePrice),
        // TODO: Add IBD input in following ticket
      })
      .test({
        name: 'are-wholesalers-unique',
        test: (currentEntry, ctx) => {
          const { parent, createError, path } = ctx;
          const settings = parent as AdminCreateCalendarFields['settings'];

          const foundDup = settings?.findIndex((entry, index) => {
            const ignoreIndex = path === `settings[${index}]`;
            return !ignoreIndex && entry.wholesaler === currentEntry.wholesaler;
          });

          if (foundDup >= 0) {
            return createError({
              message: wholesalerIsAlreadySelected,
              path: `${path}.wholesaler`,
            });
          }

          return true;
        },
      }),
  )
  .min(1)
  .required();

export const adminCreateCalendarSchema = yupObject().shape({
  noteId: yupNumber().typeError(noteIdType).integer(noteIdType).positive(noteIdType).required(noteIdRequired),
  noteQuote: yupNumber().typeError(noteQuoteMin).required(noteQuoteRequired).min(0, noteQuoteMin),
  displayName: yupString().required(displayNameRequired),
  cusip: yupNumber().typeError(cusipType).integer(cusipType).required(cusipRequired),
  reoffer: yupNumber().typeError(reofferType).required(reofferRequired).min(0, reofferMin).max(100, reofferMax),
  tradeDate: yupString().nullable().required(tradeDateRequired),
  issueDate: yupString().nullable().required(issueDateRequired),
  closeDate: yupString().nullable().required(closeDateRequired),
  enableNow: yupBoolean().required(),
  preliminaryTermsheet: mixed<File>()
    .required(preliminaryTermsheetRequired)
    .test('fileType', preliminaryTermsheetRequired, (value) => {
      if (!value) return false;
      return value.type === 'application/pdf';
    }),
  issuer: yupString().required(issuerRequired),
  category: yupString().required(categoryRequired),
  settlementType: yupString().required(settlementTypeRequired),
  settings: adminCreateCalendarSettingsSectionSchema,
});

export type AdminCreateCalendarFields = {
  noteId: number;
  noteQuote: number;
  displayName: string;
  cusip: number;
  reoffer: number;
  tradeDate: string;
  issueDate: string;
  closeDate: string;
  enableNow: boolean;
  preliminaryTermsheet: File;
  issuer: string;
  category: string;
  settlementType: string;
  settings: Array<{
    wholesaler?: string;
    purchasePrice?: number;
    resalePrice?: number;
  }>;
};
