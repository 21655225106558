import React, { RefObject } from 'react';

import { Chipography } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { ClickAwayListener, InputLabel, Popper } from '@mui/material';

const commonTermLengths = [12, 18, 24, 36, 48, 60];

type TermPopperProps = {
  open: boolean;
  anchorRef: RefObject<HTMLElement>;
  onClose: () => void;
  onSelectTerm: (input: number) => void;
};

export const TermPopper = ({ open, anchorRef, onClose, onSelectTerm }: TermPopperProps) => {
  if (!open) return null;

  const popperStyling = {
    backgroundColor: 'common.white',
    p: 2,
    boxShadow: 3,
    borderRadius: 1,
    minWidth: 400,
    maxHeight: 400,
    overflow: 'auto',
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper
        sx={popperStyling}
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [-10, 10],
              },
            },
          ],
        }}
      >
        <InputLabel shrink>
          <LocalizedTypography>{translations.noteCreate.commonTerms}</LocalizedTypography>
        </InputLabel>
        {commonTermLengths.map((termLength) => (
          <Chipography
            size="small"
            key={termLength}
            label={termLength}
            variant="outlined"
            sx={{
              bgcolor: 'common.white',
              color: 'primary.main',
              borderColor: 'primary.main',
              m: 0.5,
              cursor: 'pointer',
            }}
            onClick={() => onSelectTerm(termLength)}
          />
        ))}
      </Popper>
    </ClickAwayListener>
  );
};
