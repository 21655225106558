import { ReactElement } from 'react';

import { modalAtom } from '@halo-atoms/common';
import { selectedWatchlistAtom } from '@halo-atoms/watchlists';
import { translations } from '@halo-common/translations';
import { WatchlistHomeButton } from '@halo-modules/app';
import { Iconography, IconographySizes, LocalizedButton, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack, Theme, Typography, TypographyVariant, useMediaQuery, useTheme } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';

const watchlistNameSx = {
  width: 'fit-content',
  wordBreak: 'break-all',
  maxWidth: (theme: Theme) => ({
    xs: theme.breakpoints.values.sm,
    md: theme.breakpoints.values.md,
    lg: theme.breakpoints.values.lg,
    xl: theme.breakpoints.values.xl,
  }),
};

type WatchlistDetailsHeaderProps = { count: number };

export const WatchlistDetailsHeader = ({ count }: WatchlistDetailsHeaderProps): ReactElement => {
  const setModalMap = useSetAtom(modalAtom);
  const selectedWatchlist = useAtomValue(selectedWatchlistAtom);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleEditModalOpen = () => setModalMap({ editWatchlist: true });

  const titleVariant: TypographyVariant = isMobile ? 'h5' : 'h2';
  const iconSize: IconographySizes = isMobile ? '1x' : '1.75x';
  const contentSpacing = isMobile ? 1 : 3;
  const titleSpacing = isMobile ? 0 : 1;

  const watchlistIcon = selectedWatchlist?.icon ?? 'pencil';
  const variant: TypographyVariant = isMobile ? 'body2' : 'subtitle1';

  const productCount = (
    <Stack direction="row" alignItems="center" spacing={1}>
      <LocalizedTypography variant={variant} dynamicContent={{ count }}>
        {translations.watchlist.common.productsCount}
      </LocalizedTypography>
      <LocalizedButton className="wm-watchlist-edit" size="small" variant="text" onClick={handleEditModalOpen}>
        {translations.common.edit}
      </LocalizedButton>
    </Stack>
  );

  const desktopProductCount = !isMobile ? productCount : null;
  const mobileProductCount = isMobile ? productCount : null;

  const flairProps = {
    sx: { borderWidth: '2px' },
  };

  return (
    <Stack direction="column" spacing={contentSpacing}>
      <WatchlistHomeButton />
      <Stack direction="row" spacing={2} alignItems="center">
        <Iconography
          color="primary.dark"
          prefix="fal"
          size={iconSize}
          iconName={watchlistIcon}
          flair
          FlairProps={flairProps}
        />
        <Stack direction="column" spacing={titleSpacing}>
          <Typography variant={titleVariant} sx={watchlistNameSx}>
            {selectedWatchlist?.name}
          </Typography>
          {desktopProductCount}
        </Stack>
      </Stack>
      {mobileProductCount}
    </Stack>
  );
};
