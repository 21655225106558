import { ReactElement } from 'react';

import { CalendarProductCard, HaloLink } from '@halo-common/components';
import { useCalendarProductCardActions } from '@halo-common/hooks';
import type { PortfolioLifecycleRecommendationModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useLifecycleEventCalendarQuery, usePortfolioOrderQuery } from '@halo-data-sources/queries';
import { Iconography, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Button, Skeleton, Stack } from '@mui/material';
import { useT } from '@transifex/react';

import { PortfolioEventsCalendarReinvestmentPopover } from './components';

export type PortfolioEventsCalendarReinvestmentProps = {
  reinvestment?: PortfolioLifecycleRecommendationModel;
  filterTypes?: Array<string>;
};

export const PortfolioEventsCalendarReinvestment = ({
  reinvestment,
  filterTypes,
}: PortfolioEventsCalendarReinvestmentProps): ReactElement => {
  const translator = useT();

  const { data: order } = usePortfolioOrderQuery(reinvestment?.productId);
  const { data: calendar } = useLifecycleEventCalendarQuery(order?.termsheet?.id);

  const actions = useCalendarProductCardActions();

  const calendarCard = calendar ? (
    <CalendarProductCard calendar={calendar} actions={actions} loading={false} termsheetId={order?.termsheet?.id} />
  ) : (
    <Skeleton variant="rounded" height={190} width="100%" />
  );

  const popover = filterTypes ? (
    <PortfolioEventsCalendarReinvestmentPopover calendar={calendar} filterTypes={filterTypes} />
  ) : null;

  const endIcon = <Iconography iconName="arrow-right" />;
  const baseUrl = window?.location?.origin;
  const calendarLink = `${baseUrl}/calendar`;

  const buttonText = translator(translations.portfolio.lifecycle.viewCalendarOfferings);

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={1}>
          <LocalizedTypography variant="subtitle1">
            {translations.portfolio.lifecycle.seeSimilarCalendar}
          </LocalizedTypography>
          {popover}
        </Stack>
        <Button variant="text" component={HaloLink} endIcon={endIcon} target="_parent" href={calendarLink}>
          {buttonText}
        </Button>
      </Stack>
      {calendarCard}
    </Stack>
  );
};
