import { WatchlistModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { updateWatchlistProducts } from '@halo-data-sources/clients';
import { ApiWatchlistMapper } from '@halo-data-sources/mappers';
import { WatchlistQueryKeyFactory } from '@halo-data-sources/queries';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';

export type UpdateNoteWatchlistMutationPayload = {
  productId?: number;
  productType?: string;
  addIds?: Array<number>;
  removeIds?: Array<number>;
};

export type UpdateNoteWatchlistMutationResult = {
  added: Array<WatchlistModel>;
  removed: Array<WatchlistModel>;
  productId: number;
  productType: string;
  message: string;
};

const updateNoteWatchlistMutationFn = async (payload: UpdateNoteWatchlistMutationPayload) => {
  if (!payload.productId || !payload.productType) return null;

  const { productId, productType, addIds = [], removeIds = [] } = payload;

  const request = {
    add_to: addIds,
    products: [{ product_id: productId, product_type: productType }],
    remove_from: removeIds,
  };

  const response = await updateWatchlistProducts(request);

  const watchlists = response.watchlists.map((watchlist) => ApiWatchlistMapper.toWatchlistModel(watchlist));

  return {
    productId,
    productType,
    added: watchlists.filter((watchlist) => addIds.includes(watchlist.id)),
    removed: watchlists.filter((watchlist) => removeIds.includes(watchlist.id)),
    message: 'Successfully updated watchlists',
  };
};

export const useUpdateNoteWatchlistMutation = (): UseMutationResult<
  UpdateNoteWatchlistMutationResult | null,
  Error,
  UpdateNoteWatchlistMutationPayload
> => {
  const queryClient = useQueryClient();

  const { enqueueSuccessEvent, enqueueErrorEvent } = useSnackbar();

  return useMutation({
    mutationFn: updateNoteWatchlistMutationFn,
    onError: () => {
      enqueueErrorEvent({ message: translations.watchlist.common.updateNoteWatchlistError });
    },
    onSuccess: (data) => {
      if (!data) return undefined;

      enqueueSuccessEvent({ message: translations.watchlist.common.updateNoteWatchlistSuccess });

      const watchlistsKey = WatchlistQueryKeyFactory.all();
      void queryClient.refetchQueries({ queryKey: watchlistsKey });
    },
  });
};
