import { ReactElement } from 'react';

import { FixedIncomeProductCardDetailColumn } from '@halo-common/components';
import { CalendarOrderCategoryEnum } from '@halo-common/enums';
import { useCalendarSpecificDetails } from '@halo-common/hooks';
import { CalendarModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { ApiCalendarPriceDisplayEnum } from '@halo-data-sources/enums/calendars';
import { Stack } from '@mui/material';

export type FixedIncomeProductCardDetailRowProps = {
  calendar: CalendarModel;
  category?: CalendarOrderCategoryEnum;
  price: number | null;
  priceDisplay: ApiCalendarPriceDisplayEnum;
};

export const FixedIncomeProductCardDetailRow = ({
  calendar,
  category,
  price,
  priceDisplay,
}: FixedIncomeProductCardDetailRowProps): ReactElement => {
  const { highlights, term, protectionDetails } = useCalendarSpecificDetails(calendar);

  const termText = term?.text;
  const principalProtection = protectionDetails?.[0];
  const principalProtectionText = principalProtection?.text ?? '-';

  const isBrokerage = category === CalendarOrderCategoryEnum.brokerage;
  const isExclusive = category === CalendarOrderCategoryEnum.exclusive && price !== 100;
  const isCommissionPriceDisplay = priceDisplay === ApiCalendarPriceDisplayEnum.COMMISSION;
  const isBrokerageOrExclusive = isBrokerage || isExclusive;

  const hasPrice = price !== null;
  const showPriceColumn = isBrokerageOrExclusive && hasPrice;

  const formattedPrice = !hasPrice
    ? ''
    : isCommissionPriceDisplay
      ? `${(100 - price).toFixed(2)}%`
      : `${price.toFixed(3)}`;

  const priceColumnLabel = isCommissionPriceDisplay
    ? translations.calendars.common.commission
    : translations.calendars.common.price;

  const highlightRows =
    highlights
      ?.filter(({ label }) => label !== translations.calendars.common.commission)
      .map(({ label, alt, icon, dynamicContent }) => (
        <FixedIncomeProductCardDetailColumn key={label} label={label} icon={icon} dynamicContent={dynamicContent}>
          {alt}
        </FixedIncomeProductCardDetailColumn>
      )) ?? null;

  const priceColumn = showPriceColumn ? (
    <FixedIncomeProductCardDetailColumn label={priceColumnLabel} icon="file-invoice">
      {formattedPrice}
    </FixedIncomeProductCardDetailColumn>
  ) : null;

  return (
    <Stack direction={{ md: 'row', xs: 'column' }} spacing={{ md: 4, xs: 2 }}>
      <FixedIncomeProductCardDetailColumn label={term?.label} icon={term?.icon}>
        {termText}
      </FixedIncomeProductCardDetailColumn>
      <FixedIncomeProductCardDetailColumn label={principalProtection?.label} icon={principalProtection?.icon}>
        {principalProtectionText}
      </FixedIncomeProductCardDetailColumn>
      {highlightRows}
      {priceColumn}
    </Stack>
  );
};
