import { ReactElement } from 'react';

import { OrderTicketFormModelFields } from '@halo-common/schemas';
import { LinedCollapsibleHeader } from '@halodomination/halo-fe-common';
import { Stack, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';

export const NoteSection = (): ReactElement => {
  const { register, formState } = useFormContext<OrderTicketFormModelFields>();

  const disableLegend1 = Boolean(formState.defaultValues?.legend1);
  const shrinkLegend1 = disableLegend1 ? true : undefined;

  const { ref: legend1Ref, ...legend1InputProps } = register('legend1');
  const { ref: legend2Ref, ...legend2InputProps } = register('legend2');
  const { ref: trailer1Ref, ...trailer1InputProps } = register('trailer1');
  const { ref: trailer2Ref, ...trailer2InputProps } = register('trailer2');
  const { ref: trailer3Ref, ...trailer3InputProps } = register('trailer3');

  return (
    <Stack direction="column" spacing={3}>
      <LinedCollapsibleHeader title="Notes" alwaysExpanded />
      <Stack direction="column" spacing={2}>
        <Stack direction="row" spacing={2}>
          <TextField
            {...legend1InputProps}
            sx={{ flex: 1 }}
            inputRef={legend1Ref}
            label="Legend"
            fullWidth
            size="large"
            disabled={disableLegend1}
            slotProps={{ inputLabel: { shrink: shrinkLegend1 }, htmlInput: { maxLength: 1 } }}
          />
          <TextField
            {...legend2InputProps}
            sx={{ flex: 1 }}
            inputRef={legend2Ref}
            label="Legend"
            fullWidth
            size="large"
            slotProps={{ htmlInput: { maxLength: 1 } }}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            {...trailer1InputProps}
            sx={{ flex: 1 }}
            inputRef={trailer1Ref}
            label="Trailer 1"
            fullWidth
            size="large"
            slotProps={{ htmlInput: { maxLength: 30 } }}
          />
          <TextField
            {...trailer2InputProps}
            sx={{ flex: 1 }}
            inputRef={trailer2Ref}
            label="Trailer 2"
            fullWidth
            size="large"
            slotProps={{ htmlInput: { maxLength: 30 } }}
          />
          <TextField
            {...trailer3InputProps}
            sx={{ flex: 1 }}
            inputRef={trailer3Ref}
            label="Trailer 3"
            fullWidth
            size="large"
            slotProps={{ htmlInput: { maxLength: 30 } }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
