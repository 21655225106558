import { ReactElement } from 'react';

import { ConfirmationEnum } from '@halo-common/enums';
import { OrderTicketFormModelFields } from '@halo-common/schemas';
import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import { useController } from 'react-hook-form';

type SolicitedDropdownFieldNames = keyof OrderTicketFormModelFields | `allocations.${number}.solicited`;

export type SolicitedDropdownProps = TextFieldProps & {
  name: SolicitedDropdownFieldNames;
};

export const SolicitedDropdown = ({ name, ...props }: SolicitedDropdownProps): ReactElement => {
  const {
    fieldState: { error },
    field: { ref, ...field },
  } = useController<OrderTicketFormModelFields, SolicitedDropdownFieldNames>({ name });

  const solicitedError = error?.message;

  return (
    <TextField
      {...field}
      inputRef={ref}
      fullWidth
      size="large"
      select
      label="Solicited?"
      helperText={solicitedError}
      error={Boolean(solicitedError)}
      {...props}
    >
      <MenuItem value={ConfirmationEnum.Yes}>Yes</MenuItem>
      <MenuItem value={ConfirmationEnum.No}>No</MenuItem>
    </TextField>
  );
};
