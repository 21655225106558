import { ReactElement, useState } from 'react';

import { usePrimaryAssetIdentifier } from '@halo-common/hooks';
import { PdfViewerModal } from '@halo-common/modals';
import { translations } from '@halo-common/translations';
import { useCalendarQuery, useCalendarTermsheetDownloadQuery } from '@halo-data-sources/queries';

export type ProductApprovalCalendarPdfViewerModalProps = {
  productId?: number | null;
  open?: boolean;
  onClose: () => void;
};

export const ProductApprovalCalendarPdfViewerModal = ({
  productId,
  open = false,
  onClose,
}: ProductApprovalCalendarPdfViewerModalProps): ReactElement => {
  const [disposition, setDisposition] = useState<'inline' | 'attachment'>('inline');

  const { data: calendar, isPending: loadingCalendar } = useCalendarQuery(productId);

  const { data: termsheetPath, isPending: loadingTermsheet } = useCalendarTermsheetDownloadQuery({
    calendarId: calendar?.id,
    contentDisposition: disposition,
    fileName: calendar?.preliminaryTerms?.filename ?? 'termsheet',
  });

  const { identifierText } = usePrimaryAssetIdentifier(calendar);

  const path = termsheetPath ?? '';
  const isPending = loadingCalendar || loadingTermsheet;

  const handleDownloadClick = () => {
    setDisposition('attachment');
    onClose();
  };

  return (
    <PdfViewerModal
      isLoading={isPending}
      path={path}
      open={open}
      onClose={onClose}
      onDownload={handleDownloadClick}
      overline={identifierText}
      title={translations.calendars.common.pdfViewerModalTitle}
    />
  );
};
