import { ReactElement, useEffect } from 'react';

import { calendarSelectedAtom } from '@halo-atoms/calendars';
import { orderTicketCSVUploadModalAtom } from '@halo-atoms/orderTicket';
import { CalendarOrderCategoryEnum, CalendarOrderStatusEnum, OrganizationCapacityEnum } from '@halo-common/enums';
import { useExcelFileUploader, useProductProgress } from '@halo-common/hooks';
import { UploadFileModal } from '@halo-common/modals';
import { useWebSocketContext } from '@halo-common/providers';
import { BulkOrderTicketFormFields, OrderTicketFormModelFields } from '@halo-common/schemas';
import { useAccountAllocationCSVUploadMutation, useCancelFixOrderMutation } from '@halo-data-sources/mutations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { useOrderTicketValidationSwitch } from '@halo-data-sources/switches';
import { Stack } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { useFormContext } from 'react-hook-form';

import { OrderTicketAllocationsTable } from '../../../OrderTicketAllocationsTable';
import { OrderTicketFormLayout } from '../../OrderTicketFormLayout';
import {
  CompensationSection,
  NoteSection,
  OrderDetailSection,
  PershingBulkAllocationSection,
  TicketSection,
} from '../../OrderTicketFormSections';

export type OrderTicketPershingFormProps = {
  onClose: () => void;
};

export const OrderTicketPershingForm = ({ onClose }: OrderTicketPershingFormProps): ReactElement => {
  const { events } = useWebSocketContext();
  const { setValue, handleSubmit, watch } = useFormContext<OrderTicketFormModelFields>();

  const [csvUploadModalOpen, setCSVUploadModal] = useAtom(orderTicketCSVUploadModalAtom);
  const selectedCalendar = useAtomValue(calendarSelectedAtom);

  const { data: user } = useUserInfoQuery();
  const { mutate: validateData, isPending: validatingOrder } = useOrderTicketValidationSwitch();

  const { mutate: cancelFixOrder, isPending: cancellingFixOrder } = useCancelFixOrderMutation();
  const { mutate: uploadCSV, isPending: csvLoading } = useAccountAllocationCSVUploadMutation();

  const { status } = useProductProgress({ calendar: selectedCalendar });

  const capacities = user?.organization?.capacities;

  const pendingCancelFix = Boolean(events.fix.queue.length);
  const cancellingOrder = cancellingFixOrder || pendingCancelFix;
  const isClosed = status === CalendarOrderStatusEnum.Closed;
  const hideForm = isClosed || selectedCalendar?.paused;

  const allocations = watch('allocations') ?? [];
  const quantity = allocations.reduce((prev, field) => {
    if (!field.quantity) return prev;
    return prev + Number(field.quantity);
  }, 0);

  const uploadModalSubtitle = 'Uploading a file here will replace all previously entered spreads.';

  const handleExcelUpload = useExcelFileUploader(uploadCSV, ['Account Number', 'Quantity in Thousands']);

  const validationSubmitHandler = (fields: OrderTicketFormModelFields) => {
    validateData({ calendar: selectedCalendar, bulkData: fields as BulkOrderTicketFormFields });
  };

  const handleCancelOrder = (orderId: number, accountId?: number) => {
    cancelFixOrder({ allocationId: orderId, accountId });
  };

  const handleCloseCSVUpload = () => {
    setCSVUploadModal(false);
  };

  const onValidationSubmit = handleSubmit(validationSubmitHandler, (errors) => console.log(errors));

  const entryFormContent = !hideForm ? (
    <form>
      <Stack direction="column" spacing={2}>
        <PershingBulkAllocationSection />
        <TicketSection
          showParOfferingPrice
          showSolicitedDropdown
          showDiscretionDropdown
          showCapacityTypeDropdown
          showAccountTypeDropdown
        />
        <CompensationSection quantity={quantity} />
        <NoteSection />
        <OrderDetailSection />
      </Stack>
    </form>
  ) : null;

  useEffect(() => {
    const contentLoaded = selectedCalendar?.id && capacities?.length;

    if (contentLoaded) {
      const calendarCategory = selectedCalendar?.category?.toLowerCase() as CalendarOrderCategoryEnum;

      const capacityType = {
        [CalendarOrderCategoryEnum.advisory]: OrganizationCapacityEnum.AGENCY,
        [CalendarOrderCategoryEnum.brokerage]: OrganizationCapacityEnum.PRINCIPAL,
        [CalendarOrderCategoryEnum.exclusive]: undefined,
      }[calendarCategory];

      const index = capacities.findIndex((capacity) => capacity.toLowerCase() === capacityType?.toLowerCase());
      const selectedCapacity = capacities[index] as OrganizationCapacityEnum;

      setValue('capacityType', selectedCapacity, { shouldDirty: true });
    }
  }, [selectedCalendar?.id, selectedCalendar?.category, capacities?.length]);

  return (
    <>
      <OrderTicketFormLayout onSubmit={onValidationSubmit} onClose={onClose} loading={validatingOrder}>
        {entryFormContent}
        <OrderTicketAllocationsTable loading={cancellingOrder} onCancel={handleCancelOrder} />
      </OrderTicketFormLayout>
      <UploadFileModal
        open={csvUploadModalOpen}
        onSubmit={handleExcelUpload}
        subtitle={uploadModalSubtitle}
        title="Upload file"
        fileTypes=".csv,.xls,.xlsx"
        loading={csvLoading}
        onClose={handleCloseCSVUpload}
      />
    </>
  );
};
