import { ReactElement } from 'react';

import { Doughnut, LinedCircle, YellowCircleOfCircles } from '@halo-common/components';
import { useDesktopView } from '@halo-common/hooks';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Box, useTheme } from '@mui/material';

const LINED_CIRCLE_HEIGHT = 573;

const mobileSx = { display: { xs: 'none', lg: 'none' } };

export const PageMessageAnimation = (): ReactElement => {
  const theme = useTheme<HaloTheme>();
  const isMediumScreen = useDesktopView();

  const linedCircleOffset = isMediumScreen ? parseFloat(theme.spacing(2.5)) : parseFloat(theme.spacing(19));

  const linedCircleSx = {
    left: `calc(50% - (${LINED_CIRCLE_HEIGHT}px / 2))`,
    position: 'absolute',
    top: linedCircleOffset,
  };

  const doughnutSx = {
    left: { xs: -15, md: 1 },
    position: 'absolute',
  };

  const yellowCircleOfCirclesSx = {
    position: 'absolute',
    right: { xs: -25, md: 10 },
  };

  return (
    <>
      <LinedCircle disableAnimation sx={linedCircleSx} />
      <Box sx={mobileSx}>
        <Doughnut sx={doughnutSx} />
      </Box>
      <Box sx={mobileSx}>
        <YellowCircleOfCircles sx={yellowCircleOfCirclesSx} disableAnimation />
      </Box>
    </>
  );
};
