import { ReactElement, ReactNode } from 'react';

import { TablePresentationLayout } from '@halo-common/layouts';
import { CalendarModel, CalendarPendingModel, GridPaginationModel } from '@halo-common/models';
import { buildLoadingDockColumns, LoadingDockStatusEnum, usePershingAdminContext } from '@halo-modules/admin';
import { CalendarSortingColumnNames } from '@halo-stores/Calendar';
import { HaloDataGrid } from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';
import { GridCallbackDetails, GridSortModel } from '@mui/x-data-grid-pro';

const tableSx = {
  marginBottom: 3,
};

export type LoadingDockTableProps = {
  calendars: Array<CalendarModel> | Array<CalendarPendingModel>;
  label: string;
  total?: number;
  navigateOnCusipClick?: boolean;
  tableAction?: ReactNode;
  status?: 'available' | 'pending' | 'archived';
};

export const LoadingDockTable = ({
  calendars,
  total = 0,
  label,
  navigateOnCusipClick = true,
  tableAction,
  status = LoadingDockStatusEnum.available,
}: LoadingDockTableProps): ReactElement => {
  const adminCalendarContext = usePershingAdminContext();

  const { actions, pageLoading, tableConfiguration } = adminCalendarContext;
  const { contentLoading, onPageChange, onSortingChange, paginatingMap, pageSizeMap, pageMap, sortingMap } =
    tableConfiguration;

  const handleSorting = (sortingModels: GridSortModel) => {
    const options = sortingModels.map((sortingModel) => ({
      field: sortingModel.field as CalendarSortingColumnNames,
      direction: sortingModel.sort,
    }));

    onSortingChange(status, options);
  };

  const handlePaginationChange = (model: GridPaginationModel, details: GridCallbackDetails) => {
    if (details.reason === 'setPaginationModel')
      onPageChange({ filter: status, pageNumber: model.page + 1, pageSize: model.pageSize });
  };

  const paginationModel = { page: pageMap[status] - 1, pageSize: pageSizeMap[status] };
  const initialPaginationModel = { page: 0, pageSize: pageSizeMap[status] };
  const initialState = { pagination: { paginationModel: initialPaginationModel } };
  const subtitle = `List of ${label.toLowerCase()} to IBDs`;
  const loading = pageLoading || contentLoading || paginatingMap[status] || sortingMap[status];

  const showLoadingTableAction = Boolean(tableAction && loading);
  const tableActions = showLoadingTableAction ? <Skeleton width={104} height={50} /> : tableAction;
  const menuAction = status === LoadingDockStatusEnum.archived ? actions?.onOpenEditCalendarModal : undefined;

  const columns = buildLoadingDockColumns(status, loading, navigateOnCusipClick, menuAction);

  return (
    <TablePresentationLayout sx={tableSx} title={label} subtitle={subtitle} actions={tableActions} collapsible>
      <HaloDataGrid
        columns={columns}
        loading={pageLoading}
        noResultsMessage="No offerings at this time. Upload or add new offerings."
        rowCount={total}
        rows={calendars}
        disableColumnReorder
        paginationModel={paginationModel}
        density="compact"
        pagination
        paginationMode="server"
        initialState={initialState}
        onPaginationModelChange={handlePaginationChange}
        onSortModelChange={handleSorting}
        sortingMode="server"
        sx={tableSx}
      />
    </TablePresentationLayout>
  );
};
