import { useMemo } from 'react';

import { COMBINED_DATE_TIME_ZONE_FORMAT } from '@halo-common/constants';
import { OrderBookJanneyStatusEnum } from '@halo-common/enums';
import { useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { CalendarModel, OrderBookCalendarDetails } from '@halo-common/models';
import {
  CancelJanneyOrderButton,
  OrderBookCalendarsViewOrderDetailsButton,
  OrderBookJanneyStatus,
  ViewApproveOrderModalButton,
} from '@halo-modules/app';
import { HaloDataGridProps, mapNumberToLocalCurrency, useCurrencyConverter } from '@halodomination/halo-fe-common';
import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';

const actionCellSx = {
  alignItems: 'center',
  display: 'flex',
  gap: 1,
  height: '100%',
  width: 'fit-content',
  '& button': { maxWidth: 90, width: 'fit-content' },
};

const commonCellProps = {
  editable: false,
  disableColumnMenu: true,
  disableReorder: true,
  flex: 1,
};

const formatCurrency = (value: number) => useCurrencyConverter(value);

export const useOrderBookJanneyTableColumns = (): HaloDataGridProps['columns'] => {
  const configureDateTime = useWhiteLabelDateConfiguration();

  const renderDisabledCell = (row: OrderBookCalendarDetails, value: string) => {
    const isRejected = (row.allocation.status as OrderBookJanneyStatusEnum) === OrderBookJanneyStatusEnum.rejected;
    const isCanceled = (row.allocation.status as OrderBookJanneyStatusEnum) === OrderBookJanneyStatusEnum.canceled;

    const shouldStyleEmpty = isCanceled || isRejected;
    const amountSx = shouldStyleEmpty ? { color: 'text.disabled', textDecoration: 'line-through' } : undefined;

    return (
      <Typography sx={amountSx} variant="body2" height="100%" display="flex" alignItems="center">
        {value}
      </Typography>
    );
  };

  const columns: HaloDataGridProps['columns'] = useMemo(
    () => [
      {
        ...commonCellProps,
        width: 100,
        field: 'executionOrderId',
        headerName: 'JANNEY Order #',
        valueGetter: (_, row: OrderBookCalendarDetails) => row?.allocation?.executionOrderId,
      },
      {
        ...commonCellProps,
        width: 100,
        field: 'household',
        headerName: 'Rep Code',
        valueGetter: (_, row: OrderBookCalendarDetails) => row?.allocation?.account?.household,
      },
      {
        ...commonCellProps,
        width: 275,
        field: 'accountName',
        headerName: 'account name',
        valueGetter: (_, row: OrderBookCalendarDetails) => row.allocation?.account?.name,
      },
      {
        ...commonCellProps,
        width: 100,
        field: 'accountId',
        headerName: 'account #',
        valueGetter: (_, row: OrderBookCalendarDetails) => row.allocation?.account?.accountId,
      },
      {
        ...commonCellProps,
        width: 150,
        field: 'designation',
        translateCell: true,
        headerName: 'Account Type',
        valueGetter: (_, row: OrderBookCalendarDetails) => row?.allocation?.account?.designation,
      },
      {
        ...commonCellProps,
        width: 150,
        field: 'user',
        headerName: 'User Name',
        valueGetter: (_, row: OrderBookCalendarDetails) => row?.allocation?.user?.name,
      },
      {
        ...commonCellProps,
        width: 150,
        field: 'calendar',
        headerName: 'CUSIP',
        valueGetter: (_, row: OrderBookCalendarDetails) => row.calendar?.cusip || 'N/A',
        renderCell: ({ row, value }) => {
          const calendarId = row.calendar?.id;
          return calendarId ? <OrderBookCalendarsViewOrderDetailsButton cusip={value} calendarId={calendarId} /> : null;
        },
      },
      {
        ...commonCellProps,
        width: 200,
        field: 'tradables',
        headerName: 'Underlying',
        sortable: false,
        valueGetter: (_, row: OrderBookCalendarDetails) => {
          const tradables = (row?.calendar as CalendarModel)?.note?.tradables ?? [];
          return tradables.map((tradable) => tradable.name).join(', ');
        },
      },
      {
        ...commonCellProps,
        width: 200,
        field: 'issuer',
        headerName: 'Issuer',
        valueGetter: (_, row: OrderBookCalendarDetails) => (row?.calendar as CalendarModel)?.issuer?.name,
      },
      {
        ...commonCellProps,
        align: 'right',
        field: 'amount',
        headerName: 'Principal',
        width: 115,
        valueGetter: (_, row: OrderBookCalendarDetails) => formatCurrency(row?.allocation?.amount),
        renderCell: ({ row, value }) => renderDisabledCell(row, value),
      },
      {
        ...commonCellProps,
        width: 100,
        align: 'right',
        field: 'janney.commission',
        headerName: 'commission',
        valueGetter: (_, row: OrderBookCalendarDetails) => {
          return mapNumberToLocalCurrency(row.transactionSummary?.salesCredit);
        },
        renderCell: ({ row, value }) => renderDisabledCell(row, value),
      },
      {
        ...commonCellProps,
        width: 225,
        field: 'updatedOn',
        flex: 1.5,
        headerName: 'Last Update',
        valueGetter: (_, row: OrderBookCalendarDetails) => {
          const lastUpdatedDateTime = configureDateTime(row?.allocation?.updatedOn);
          return lastUpdatedDateTime?.toFormat(COMBINED_DATE_TIME_ZONE_FORMAT);
        },
      },
      {
        ...commonCellProps,
        field: 'status',
        headerName: 'Status',
        width: 135,
        renderCell: ({ id, row }) =>
          id !== 'footer' ? (
            <OrderBookJanneyStatus value={row.allocation.status} message={row.allocation.reason} />
          ) : null,
      },
      {
        ...commonCellProps,
        flex: undefined,
        field: 'actions',
        headerName: '',
        sortable: false,
        resizable: false,
        width: 150,
        renderCell: ({ id, row }) => {
          const expirationDateTime = configureDateTime(row.allocation.expirationDate);
          const nowDateTime = configureDateTime(DateTime.local().toISO());

          const isAccepted = row.allocation.status === OrderBookJanneyStatusEnum.accepted;
          const isExpired = expirationDateTime && nowDateTime && expirationDateTime < nowDateTime;
          const hideCancelButton = !isAccepted || isExpired;

          const cancelButton = !hideCancelButton ? <CancelJanneyOrderButton allocation={row.allocation} /> : null;

          return id !== 'footer' ? (
            <Box sx={actionCellSx}>
              <ViewApproveOrderModalButton allocation={row.allocation} />
              {cancelButton}
            </Box>
          ) : null;
        },
      },
    ],
    [configureDateTime],
  );

  return columns;
};
