import { ReactElement, ReactNode } from 'react';

import {
  FindPriceButton,
  ProductCardNoteBase,
  ProductCardProps,
  ProductCardSolvableParameter,
} from '@halo-common/components';
import { NoteTypeEnum } from '@halo-common/enums';
import { useNoteSpecificDetails } from '@halo-common/hooks';
import { NoteModel, QuoteModel } from '@halo-common/models';
import { useWebSocketContext } from '@halo-common/providers';
import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { Typography } from '@mui/material';

export type ProductCardNoteDetailsProps = {
  action: ReactNode;
  note: NoteModel;
  showRefresh: boolean;
  overrides?: ProductCardProps['overrides'];
};

export const ProductCardNoteDetails = ({
  action,
  note,
  overrides,
  showRefresh,
}: ProductCardNoteDetailsProps): ReactElement => {
  const { id } = note;

  const { events } = useWebSocketContext();

  const { highlights, solvableParameter, term, protectionDetails, quote } = useNoteSpecificDetails(note, {
    overriddenQuote: overrides?.quote as QuoteModel,
  });

  const solvableParameterText = solvableParameter?.text ?? '';
  const solvableParameterLabel = solvableParameter?.label ?? '';
  const solvableParameterValue = solvableParameter?.original ?? null;
  const principalProtection = protectionDetails?.[0];

  const hasQuote = Boolean(quote);
  const onlyShowParameter = !hasQuote && overrides?.hideEmptyQuote;
  const refreshEnabled = showRefresh || events.quote.contains(id);
  const showRefreshButton = !onlyShowParameter && refreshEnabled;
  const isGeneral = note.type === NoteTypeEnum.General;

  const noteParameterContent = isGeneral ? (
    <Typography variant="subtitle2" color="textPrimary">
      {solvableParameterText}
    </Typography>
  ) : (
    <FindPriceButton productId={id} size="small" label={solvableParameter?.label} />
  );

  const parameterContent = onlyShowParameter ? (
    <LocalizedTypography variant="subtitle2" color="textSecondary">
      {solvableParameterLabel}
    </LocalizedTypography>
  ) : hasQuote ? (
    <ProductCardSolvableParameter
      productId={id}
      label={solvableParameterLabel}
      showRefresh={showRefreshButton}
      value={solvableParameterValue}
    />
  ) : (
    noteParameterContent
  );

  return (
    <ProductCardNoteBase
      action={action}
      highlights={highlights}
      note={note}
      principalProtection={principalProtection}
      term={term}
      slots={{ payoff: parameterContent }}
    />
  );
};
