import { KeyboardEvent, ReactElement } from 'react';

import { AdminCreateCalendarFields } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { handleRestrictedKeyDown } from '@halo-common/utils';
import { Collapsible, Iconography, LocalizedButton, LocalizedTextField } from '@halodomination/halo-fe-common';
import { useHaloTheme } from '@halodomination/halo-fe-theme';
import { Divider, Stack } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { WholesalerField } from './WholesalerField';

const sectionSx = { mx: -3 };

export const SettingsSection = (): ReactElement => {
  const { theme } = useHaloTheme();

  const { formState, register, resetField, trigger } = useFormContext<AdminCreateCalendarFields>();
  const { fields, append, remove } = useFieldArray<AdminCreateCalendarFields>({ name: 'settings' });

  const { errors } = formState;

  const addIcon = <Iconography color={theme.palette.primary.contrastText} iconName="plus" />;

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    handleRestrictedKeyDown({ event, invalidKeyRegex: /[^\d.]/ });
  };

  const handleDelete = (index: number) => {
    if (fields.length === 1) return resetField('settings');
    remove(index);
    fields.forEach((_, fieldIndex) => {
      void trigger(`settings.${fieldIndex}.wholesaler`);
    });
  };

  return (
    <Collapsible
      HeaderProps={{ sx: sectionSx }}
      ContentProps={{ sx: sectionSx }}
      title={translations.calendars.common.loadingDock}
      defaultExpanded
      alwaysExpanded
    >
      <Stack direction="column" spacing={2}>
        {fields.map((field, index) => {
          const purchasePriceError = errors.settings?.[index]?.purchasePrice?.message;
          const resalePriceError = errors.settings?.[index]?.resalePrice?.message;

          return (
            <Stack key={field.id} direction="column" spacing={1}>
              <Stack direction="row" spacing={1}>
                <WholesalerField index={index} />
                <LocalizedTextField
                  sx={{ flex: 1 }}
                  {...register(`settings.${index}.purchasePrice`)}
                  label={translations.calendars.common.purchasePrice}
                  helperText={purchasePriceError}
                  error={Boolean(purchasePriceError)}
                  onKeyDown={handleKeyDown}
                />
                <LocalizedTextField
                  sx={{ flex: 1 }}
                  {...register(`settings.${index}.resalePrice`)}
                  label={translations.calendars.common.resalePrice}
                  helperText={resalePriceError}
                  error={Boolean(resalePriceError)}
                  onKeyDown={handleKeyDown}
                />
              </Stack>
              <LocalizedButton
                fullWidth
                variant="text"
                onClick={() => handleDelete(index)}
                startIcon={<Iconography iconName="trash" />}
              >
                {translations.common.delete}
              </LocalizedButton>
            </Stack>
          );
        })}
        <Divider />
        <LocalizedButton fullWidth variant="contained" onClick={() => append({ wholesaler: '' })} startIcon={addIcon}>
          {translations.calendars.common.addWholesaler}
        </LocalizedButton>
      </Stack>
    </Collapsible>
  );
};
