import { ReactElement } from 'react';

import { modalAtom } from '@halo-atoms/common';
import { accountModalAtom, addPositionToMultipleAccountModalAtom } from '@halo-atoms/portfolio';
import { UserSaveAccessEnum } from '@halo-common/enums';
import { translations } from '@halo-common/translations';
import { useCustodianQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import { AddAccountOrPositionModalButton, AddAccountOrPositionModalCustodianList } from '@halo-modules/app';
import { Iconography, Modal, Stack } from '@halodomination/halo-fe-common';
import { Divider } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';

export const AddAccountOrPositionModal = (): ReactElement => {
  const { data: user } = useUserInfoQuery();
  const { data, isPending } = useCustodianQuery();

  const custodians = data?.filter((custodian) => Boolean(custodian.integrationLink));

  const [modalMap, onModalToggle] = useAtom(modalAtom);
  const toggleAccountModal = useSetAtom(accountModalAtom);
  const togglePositionToMultipleAccountsModal = useSetAtom(addPositionToMultipleAccountModalAtom);

  const addAccountAccess = user?.whiteLabel.accountSaveAccess;
  const positionAddAccess = user?.whiteLabel.positionSaveAccess;

  const isAddAccountEnabled = addAccountAccess === UserSaveAccessEnum.ENABLED;
  const isAddAccountManualOnly = addAccountAccess === UserSaveAccessEnum.MANUAL_ONLY;
  const isPositionAddEnabled = positionAddAccess === UserSaveAccessEnum.ENABLED;

  const handleAccountModalOpen = () => {
    void onModalToggle({ addAccountOrPositionsModal: false });
    void toggleAccountModal({ account: null, open: true });
  };
  const handlePositionModalOpen = () => {
    void onModalToggle({ addAccountOrPositionsModal: false });
    void togglePositionToMultipleAccountsModal({ asset: null, open: true });
  };
  const handleAddOrPositionModalClose = () => void onModalToggle({ addAccountOrPositionsModal: false });

  const positionLogo = <Iconography iconName="file-plus" prefix="far" />;
  const clientLogo = <Iconography iconName="user-plus" prefix="far" />;

  const showAddAccountButton = isAddAccountEnabled || isAddAccountManualOnly;
  const showAddPositionButton = isPositionAddEnabled;
  const showCreationButtons = showAddAccountButton || showAddPositionButton;

  const addAccountButton = showAddAccountButton ? (
    <AddAccountOrPositionModalButton
      name={translations.portfolio.addAccountOrPositionsModal.addAccountButton}
      onClick={handleAccountModalOpen}
      sx={{ flexBasis: '50%' }}
      icon={clientLogo}
    />
  ) : null;

  const addPositionButton = showAddPositionButton ? (
    <AddAccountOrPositionModalButton
      name={translations.portfolio.addAccountOrPositionsModal.addPositionButton}
      onClick={handlePositionModalOpen}
      sx={{ flexBasis: '50%' }}
      icon={positionLogo}
    />
  ) : null;

  const custodianButtons = custodians?.length ? (
    <AddAccountOrPositionModalCustodianList custodians={custodians} loading={isPending} />
  ) : null;

  const divider = showCreationButtons && custodianButtons ? <Divider /> : null;
  const creationButtons = showCreationButtons ? (
    <Stack direction="row" xs={6} spacing={3}>
      {addPositionButton}
      {addAccountButton}
    </Stack>
  ) : null;

  return (
    <Modal
      icon="chart-network"
      overline={translations.portfolio.common.yourPortfolioTitle}
      title={translations.portfolio.addAccountOrPositionsModal.title}
      open={modalMap.addAccountOrPositionsModal}
      onClose={handleAddOrPositionModalClose}
      hideDivider
    >
      <Stack direction="column" spacing={3}>
        {custodianButtons}
        {divider}
        {creationButtons}
      </Stack>
    </Modal>
  );
};
