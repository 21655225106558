import { useOrderTicketDesignation } from '@halo-common/hooks';
import { AccountModel, CalendarModel } from '@halo-common/models';

export const useOrderTicketSalesPrice = (
  calendar?: CalendarModel | null,
  account?: AccountModel | null,
  quantity?: number,
): number | null => {
  const { isAdvisoryAccount } = useOrderTicketDesignation(account);

  if (isAdvisoryAccount || !quantity) return 0;
  else if (!calendar || !account) return null;

  const parsedQuantity = quantity * 1000;

  // TODO: Use sales price off of the new calendar model when implemented.
  // NOTE: The endpoint execution/calendar/offering/query sends the sales price in the price field.
  const salesCreditPercentage = 100 - (calendar.price ?? 100);
  const salesPrice = (salesCreditPercentage / 100) * parsedQuantity;

  return Number(salesPrice.toFixed(2));
};
