import { ReactElement } from 'react';

import { bulkOrderTicketManagerAtom } from '@halo-atoms/orderTicket';
import { useWebSocketContext } from '@halo-common/providers';
import { BulkOrderTicketFormFields } from '@halo-common/schemas';
import { useAtomValue } from 'jotai';
import { useFormContext } from 'react-hook-form';

import { BulkAllocationInput, BulkAllocationInputProps } from '../../../BulkAllocationInput';

export type PershingBulkAllocationInputProps = {
  index: number;
  onDelete: () => void;
};

export const PershingBulkAllocationInput = ({ index, onDelete }: PershingBulkAllocationInputProps): ReactElement => {
  const { watch } = useFormContext<BulkOrderTicketFormFields>();

  const { events } = useWebSocketContext();

  const { pendingValidateAllocations, validatedAllocations, invalidAllocations } =
    useAtomValue(bulkOrderTicketManagerAtom);

  const accountFieldName = `allocations.${index}.accountOption` as const;

  const accountValue = watch(accountFieldName);
  const accountId = accountValue?.account?.id;

  const isValid = accountId ? validatedAllocations.includes(accountId) : false;
  const isInvalid = accountId ? invalidAllocations.includes(accountId) : false;
  const isValidating = Boolean(pendingValidateAllocations?.length && events.fix.contains(accountId));

  let status: BulkAllocationInputProps['status'] = 'idle';
  if (isValid) status = 'valid';
  if (isInvalid) status = 'invalid';
  if (isValidating) status = 'pending';

  return <BulkAllocationInput index={index} status={status} onDelete={onDelete} />;
};
