import { MouseEvent, ReactElement, useEffect } from 'react';

import { fixValidationErrorsAtom, orderTicketStepAtom } from '@halo-atoms/orderTicket';
import { useOrderTicketForm } from '@halo-common/hooks';
import { OrderTicketFormModelFields } from '@halo-common/schemas';
import { useUserWebHooksQuery } from '@halo-data-sources/queries';
import { ElementSelector, ModalBase } from '@halodomination/halo-fe-common';
import { useAtomValue, useSetAtom } from 'jotai';
import { FormProvider, useForm } from 'react-hook-form';

import { OrderTicketForm } from './OrderTicketForm';
import { OrderTicketReceipt } from './OrderTicketReceipt';

export type OrderTicketModalProps = {
  open: boolean;
  onClose: () => void;
};

export const OrderTicketModal = ({ open, onClose }: OrderTicketModalProps): ReactElement => {
  const { data: webhooks } = useUserWebHooksQuery();

  const step = useAtomValue(orderTicketStepAtom.currentPageAtom);
  const handleResetStep = useSetAtom(orderTicketStepAtom.handleResetAtom);
  const fixErrors = useAtomValue(fixValidationErrorsAtom);

  const overrideLegend1 = Boolean(webhooks?.['react:soe:legend_code']);

  const { defaults, resolver } = useOrderTicketForm({
    fixErrors,
    legendCodeOverride: overrideLegend1 ? '8' : '',
  });

  const formMethods = useForm<OrderTicketFormModelFields>({
    mode: 'onSubmit',
    resolver,
    defaultValues: defaults,
  });

  const { reset: resetForm } = formMethods;

  const handleClose = () => {
    resetForm(defaults);
    handleResetStep();
    onClose();
  };

  const handleModalClose = (_: MouseEvent<HTMLElement>, reason: string) => {
    if (reason !== 'backdropClick') handleClose();
  };

  useEffect(() => {
    if (overrideLegend1) resetForm(defaults);
  }, [overrideLegend1]);

  return (
    <FormProvider {...formMethods}>
      <ModalBase open={open} onClose={handleModalClose}>
        <ElementSelector selected={step}>
          <OrderTicketForm onClose={handleClose} />
          <OrderTicketReceipt review onClose={handleClose} />
          <OrderTicketReceipt onClose={handleClose} />
        </ElementSelector>
      </ModalBase>
    </FormProvider>
  );
};
