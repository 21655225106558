import { useEffect } from 'react';

import { PortfolioTabEnum } from '@halo-common/enums';
import { PageFooterLayout, SubNav, SubNavLayout } from '@halo-common/layouts';
import { MustCompleteOnboardingModal } from '@halo-common/modals';
import { useLegacyRouter } from '@halo-common/providers';
import { translations } from '@halo-common/translations';
import {
  AccountsTable,
  AddAccountOrPositionButton,
  AddAccountOrPositionModal,
  AddCustodianPositionsModal,
  AddPositionToMultipleAccountsModal,
  AddPositionsToAccountModal,
  GenerateReportModal,
  PortfolioAddAccountModal,
  PortfolioDropdownFilters,
  PortfolioLifecycleTab,
  PortfolioPositionsPage,
  PortfolioProductDetailsModal,
} from '@halo-modules/app';
import { PageComponent, PageMeta } from '@halodomination/halo-fe-common';

const PortfolioPage: PageComponent = () => {
  const router = useLegacyRouter();

  const termsheetIdQuery = router.query['termsheet_id'];
  const selectedTabQuery = router.query.tab;

  const hasSelectedTab = typeof selectedTabQuery === 'string';
  const selectedTab = hasSelectedTab ? (selectedTabQuery as PortfolioTabEnum) : PortfolioTabEnum.POSITION_TAB;

  const showCompositionDropdown = !selectedTab || selectedTab === PortfolioTabEnum.POSITION_TAB;
  const showChartCurrencyLabel = selectedTab === PortfolioTabEnum.LIFECYCLE_TAB;
  const showAssetSearchBar = selectedTab === PortfolioTabEnum.POSITION_TAB;

  const pageTitle = translations.portfolio.common.title;
  const positionTabName = translations.portfolio.common.positionTab;
  const lifecycleTabName = translations.common.lifecycle;
  const accountTabName = translations.common.accounts;

  const TabsProps = {
    persistedContent: (
      <PortfolioDropdownFilters
        showCompositionDropdown={showCompositionDropdown}
        showChartCurrencyLabel={showChartCurrencyLabel}
        showAssetSearchBar={showAssetSearchBar}
      />
    ),
  };

  useEffect(() => {
    if (typeof termsheetIdQuery === 'string') {
      const newQueryParams = new URLSearchParams({ tab: PortfolioTabEnum.LIFECYCLE_TAB });
      router.combinedQueryParams(newQueryParams);
    }
  }, [termsheetIdQuery]);

  return (
    <PageFooterLayout>
      <SubNavLayout title={pageTitle} right={<AddAccountOrPositionButton />} TabsProps={TabsProps}>
        <SubNav name={positionTabName} noPadding>
          <PortfolioPositionsPage />
        </SubNav>
        <SubNav name={lifecycleTabName} noPadding>
          <PortfolioLifecycleTab />
        </SubNav>
        <SubNav name={accountTabName} noPadding>
          <AccountsTable />
        </SubNav>
      </SubNavLayout>
      <AddAccountOrPositionModal />
      <AddPositionToMultipleAccountsModal />
      <AddPositionsToAccountModal />
      <GenerateReportModal />
      <PortfolioAddAccountModal />
      <PortfolioProductDetailsModal />
      <AddCustodianPositionsModal />
      <MustCompleteOnboardingModal />
    </PageFooterLayout>
  );
};

export const PortfolioPageMeta: PageMeta = {
  pageName: translations.portfolio.common.title,
  route: 'app/portfolio',
  routeExpression: '/portfolio',
};

PortfolioPage.pageName = PortfolioPageMeta.pageName;
PortfolioPage.route = PortfolioPageMeta.route;

export default PortfolioPage;
