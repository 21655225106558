import { ReactElement } from 'react';

import { useUserInfoQuery } from '@halo-data-sources/queries';
import { PageMessageAnimation } from '@halo-modules/app/onboarding';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Box, Skeleton } from '@mui/material';

const skeletonStyling = { transform: 'none' };

export type PageMessageBackgroundProps = {
  alternateBackground?: ReactElement;
  zIndex?: number;
  loading?: boolean;
  fullPage?: boolean;
};

export const PageMessageBackground = ({
  alternateBackground,
  zIndex,
  loading = false,
  fullPage = false,
}: PageMessageBackgroundProps): ReactElement => {
  const { data: userMetaInfo } = useUserInfoQuery();

  const backgroundImage = userMetaInfo?.whiteLabel?.brand.onboardingBackground?.path;

  const imageStyling = {
    height: '100%',
    objectFit: fullPage ? 'cover' : 'unset',
    transform: 'translate(0, 0)',
    width: fullPage ? '100%' : 'unset',
  };

  const styles = (theme: HaloTheme) => ({
    backgroundColor: backgroundImage && !loading ? 'common.black' : null,
    height: '100%',
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: zIndex ?? theme.zIndex.modal,
  });

  const loadingContent = loading ? (
    <Skeleton sx={skeletonStyling} variant="rectangular" width="100%" height="100%" />
  ) : null;

  const imageComponent = <Box sx={imageStyling} component="img" src={backgroundImage ?? ''} />;
  const defaultAnimation = alternateBackground ?? <PageMessageAnimation />;

  const content = backgroundImage ? imageComponent : defaultAnimation;

  const displayContent = loadingContent ?? content;

  return <Box sx={styles}>{displayContent}</Box>;
};
