import { ReactElement, ReactNode } from 'react';

import { ActionButton } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { Divider, Stack, Typography } from '@mui/material';

import { BulkAllocationFooter, BulkAllocationFooterProps } from './BulkAllocationFooter';

export type BulkAllocationLayoutProps = {
  error?: ReactNode;
  onDownload?: () => void;
  children: ReactNode;
  slotProps?: { footer?: BulkAllocationFooterProps };
};

export const BulkAllocationLayout = ({
  error,
  onDownload,
  children,
  slotProps,
}: BulkAllocationLayoutProps): ReactElement => {
  const downloadButton = onDownload ? (
    <ActionButton variant="text" color="primary" onClick={onDownload}>
      {translations.orderTicket.downloadTemplateButton}
    </ActionButton>
  ) : null;

  return (
    <Stack direction="column" spacing={2} sx={{ pb: 2 }}>
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
        <Typography variant="h6" color="primary">
          Allocations
        </Typography>
        <Divider />
        {downloadButton}
      </Stack>
      {error}
      <Stack direction="row" spacing={2} flexWrap="wrap" useFlexGap>
        {children}
      </Stack>
      <BulkAllocationFooter {...slotProps?.footer} />
    </Stack>
  );
};
