import { ReactElement } from 'react';

import { OnboardingFullDrawer, OnboardingMiniDrawer } from '@halo-modules/app/onboarding';
import { Skeleton } from '@mui/material';

const skeletonSx = {
  transform: 'none',
};

const normalSkeletonSx = {
  ...skeletonSx,
  flexBasis: '50%',
};

export type OnBoardingProps = {
  isLoading: boolean;
  variant?: 'normal' | 'small';
};

export const OnboardingDrawer = ({ isLoading, variant = 'normal' }: OnBoardingProps): ReactElement | null => {
  if (isLoading) {
    return {
      small: <Skeleton sx={skeletonSx} variant="rectangular" width={400} height="100vh" />,
      normal: <Skeleton sx={normalSkeletonSx} variant="rectangular" width="100vw" height="100vh" />,
    }[variant];
  }

  return {
    small: <OnboardingMiniDrawer />,
    normal: <OnboardingFullDrawer />,
  }[variant];
};
