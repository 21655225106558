import { ReactElement } from 'react';

import { COMBINED_DATE_TIME_ZONE_FORMAT } from '@halo-common/constants';
import { useLiveTime } from '@halo-common/hooks';
import { OrderTicketFormModelFields } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { LinedCollapsibleHeader, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack, TextField, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

export const OrderDetailSection = (): ReactElement => {
  const { register, formState } = useFormContext<OrderTicketFormModelFields>();

  const { errors } = formState;
  const orderFromError = errors?.orderFrom?.message;
  const hasOrderFromError = Boolean(orderFromError);

  const orderFromFieldProps = register('orderFrom');

  const orderDate = useLiveTime();
  const orderDateText = orderDate.toFormat(COMBINED_DATE_TIME_ZONE_FORMAT);

  return (
    <Stack direction="column" spacing={3}>
      <LinedCollapsibleHeader title="Order Details" alwaysExpanded />
      <Stack direction="row" spacing={2} flexWrap="wrap">
        <Stack direction="column" sx={{ flex: 1 }}>
          <LocalizedTypography variant="caption">{translations.orderTicket.dateTimeCaption}</LocalizedTypography>
          <Typography variant="h6">{orderDateText}</Typography>
        </Stack>
        <TextField
          {...orderFromFieldProps}
          label="Order Received From"
          sx={{ flex: 1 }}
          size="large"
          helperText={orderFromError}
          error={hasOrderFromError}
        />
      </Stack>
    </Stack>
  );
};
