import { ChangeEvent, KeyboardEvent, ReactElement, ReactNode } from 'react';

import { BulkOrderTicketFormFields } from '@halo-common/schemas';
import { AccountTypeAhead, AccountTypeAheadOption } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { LocalizedTextField } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';

import { BulkAllocationInputAction } from './BulkAllocationInputAction';

export type BulkAllocationInputProps = {
  index: number;
  density?: 'standard' | 'condensed';
  status: 'idle' | 'valid' | 'invalid' | 'pending';
  onDelete: () => void;
  children?: ReactNode;
};

export const BulkAllocationInput = ({
  index,
  density = 'standard',
  status,
  onDelete,
  children,
}: BulkAllocationInputProps): ReactElement => {
  const { control, setValue, watch, formState } = useFormContext<BulkOrderTicketFormFields>();

  const accountFieldName = `allocations.${index}.accountOption` as const;
  const quantityFieldName = `allocations.${index}.quantity` as const;

  const { field: accountFieldProps, fieldState: accountState } = useController({ name: accountFieldName, control });
  const { field: quantityFieldProps, fieldState: quantityState } = useController({ name: quantityFieldName, control });

  const accountError = accountState?.error?.message ?? formState.errors?.allocations?.[index]?.message;
  const quantityError = quantityState.error?.message;

  const hasAccountError = Boolean(accountError);
  const hasQuantityError = Boolean(quantityError);

  const quantityValue = watch(quantityFieldName);

  const spacing = density === 'condensed' ? 0.25 : 1;
  const shrink = typeof quantityValue === 'number' || false;
  const qtyLabelProps = { shrink };

  const selectAccountHandler = (value: AccountTypeAheadOption | null) => {
    setValue(accountFieldName, value, { shouldValidate: true });
  };

  const handleChangeQuantity = (event: ChangeEvent<HTMLInputElement>) => {
    const sanitizedValue = event.target.value.replace(/[^0-9]/g, '');
    setValue(quantityFieldName, Number(sanitizedValue), { shouldValidate: true });
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = /^[0-9]|Tab|Backspace|Delete|Enter|Left|Right$/;
    if (!allowedKeys.test(event.key)) event.preventDefault();
  };

  const handleAccountFormat = (option: AccountTypeAheadOption) => {
    const { account, household, action, clearedOnSelection } = option;

    if (action) return !clearedOnSelection ? action : '';
    else if (household) return household.name;
    else {
      const name = account?.name ?? '--';
      const accountId = account?.accountId ?? '--';
      const nameAndIdMatch = name === accountId;
      return nameAndIdMatch ? name : `${name} - #${accountId}`;
    }
  };

  return (
    <Stack sx={{ height: 70 }} direction="row" spacing={spacing}>
      <BulkAllocationInputAction status={status} onDelete={onDelete} />
      <AccountTypeAhead
        {...accountFieldProps}
        sx={{ minWidth: 250 }}
        onSelect={selectAccountHandler}
        label={translations.common.account}
        rules={{ disableHouseholds: true }}
        helperText={accountError}
        error={hasAccountError}
        disableClearable
        accountFormatter={handleAccountFormat}
      />
      <LocalizedTextField
        {...quantityFieldProps}
        sx={{ maxWidth: 130 }}
        fullWidth
        size="large"
        variant="outlined"
        type="text"
        label={translations.allocationsModal.common.qtyThousand}
        helperText={quantityError}
        error={hasQuantityError}
        onChange={handleChangeQuantity}
        onKeyDown={handleKeyDown}
        slotProps={{ inputLabel: qtyLabelProps }}
      />
      {children}
    </Stack>
  );
};
