import { ChangeEvent, ReactElement } from 'react';

import { assetIdSearchValueAtom } from '@halo-atoms/portfolio';
import { AssetIdentifierEnum } from '@halo-common/enums';
import { AddPositionToMultipleAccountsFormFields } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { validateFundServCode } from '@halo-common/utils';
import { usePortfolioAssetExistenceQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import { useAssetIdListText } from '@halo-modules/app';
import { Iconography, LocalizedTextField } from '@halodomination/halo-fe-common';
import { IconButton, InputAdornment } from '@mui/material';
import { useAtom } from 'jotai';
import { useFormContext } from 'react-hook-form';

export const AssetSearchField = (): ReactElement => {
  const { formState, clearErrors } = useFormContext<AddPositionToMultipleAccountsFormFields>();
  const { errors } = formState;

  const [assetIdSearchValue, setAssetIdSearchValue] = useAtom(assetIdSearchValueAtom);

  const assetIdQuery = assetIdSearchValue ? [assetIdSearchValue] : undefined;

  const { data: assets, isFetching } = usePortfolioAssetExistenceQuery(assetIdQuery);

  const { data: userInfo } = useUserInfoQuery();

  const whiteLabel = userInfo?.whiteLabel;
  const assetIdentifierListText = useAssetIdListText(whiteLabel?.assetIdentifiers);
  const searchLabel = translations.portfolio.common.assetPositionSearchLabel;

  const selectedAsset = assets?.[0];
  const assetId = selectedAsset?.assetId;

  const assetIdExists = Boolean(selectedAsset?.location);

  const isFundServ = selectedAsset?.assetIdType === AssetIdentifierEnum.FUNDSERVCODE;
  const isValidFundServ = validateFundServCode(assetId, selectedAsset?.assetIdType);

  const assetNotFoundError = !assetIdExists && Boolean(selectedAsset) && assetIdSearchValue && !isFetching;
  const jhnError = isFundServ && !isValidFundServ && assetIdExists && Boolean(selectedAsset) && !isFetching;
  const formError = Boolean(errors?.assetId);

  const errorText = translations.portfolio.common.assetSearchValidationMessage;
  const hasError = formError || assetNotFoundError || jhnError;
  const noAssetIdText = formError ? errorText : undefined;

  const labelDynamicContent = { assetId: assetIdentifierListText };
  const helperTextDynamicContent = { assetIdList: assetIdentifierListText };
  const dynamicContent = { label: labelDynamicContent, helperText: helperTextDynamicContent };

  const handleAssetIdChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newAssetId = event?.target?.value?.trim();
    const cleanAssetId = newAssetId?.toUpperCase();
    if (errors?.assetId) clearErrors('assetId');
    setAssetIdSearchValue(cleanAssetId);
  };

  const endAdornment = (
    <IconButton aria-label="search asset" edge="end">
      <Iconography iconName="search" color="text.faded" />
    </IconButton>
  );

  return (
    <LocalizedTextField
      label={searchLabel}
      variant="outlined"
      size="large"
      value={assetIdSearchValue}
      fullWidth
      onChange={handleAssetIdChange}
      error={hasError}
      helperText={noAssetIdText}
      dynamicContent={dynamicContent}
      slotProps={{
        input: {
          endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment>,
        },
      }}
    />
  );
};
