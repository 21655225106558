import { ReactElement } from 'react';

import { calendarSelectedAtom } from '@halo-atoms/calendars';
import { ModalLayout } from '@halodomination/halo-fe-common';
import { useAtomValue } from 'jotai';

import { OrderTicketReceiptContent } from './OrderTicketReceiptContent';
import { OrderTicketReceiptFooter } from './OrderTicketReceiptFooter';

const modalContentProps = { sx: { paddingTop: 3 } };

export type OrderTicketReceiptProps = {
  review?: boolean;
  onClose: () => void;
};

export const OrderTicketReceipt = ({ review = false, onClose }: OrderTicketReceiptProps): ReactElement => {
  const selectedCalendar = useAtomValue(calendarSelectedAtom);

  const cusip = selectedCalendar?.cusip ?? '';
  const title = `Submit Indication ${cusip}`;
  const subtitle = review ? `To submit the order, please click "Acknowledge & Submit Order"` : undefined;

  const footer = <OrderTicketReceiptFooter review={review} onClose={onClose} />;

  return (
    <ModalLayout title={title} subtitle={subtitle} ContentProps={modalContentProps} onClose={onClose} footer={footer}>
      <OrderTicketReceiptContent review={review} />
    </ModalLayout>
  );
};
