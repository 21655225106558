import { ReactElement } from 'react';

import { useLoadingDockUpload } from '@halo-common/hooks';
import { translations } from '@halo-common/translations';
import { ProgressBar, ProgressBarEnum } from '@halodomination/halo-fe-common';
import { useT } from '@transifex/react';

export const UploadFilePendingContent = (): ReactElement => {
  const translator = useT();
  const { total, processed } = useLoadingDockUpload();

  const percent = Math.abs(processed / total) * 100;
  const progressLabel = translator(translations.calendars.common.cusipsProcessed, { processed, total });

  return <ProgressBar label={progressLabel} variant={ProgressBarEnum.PRIMARY} percentFilled={percent} />;
};
