import { calendarPDFViewerAtom, calendarSelectedAtom, calendarSelectedTermsheetIdAtom } from '@halo-atoms/calendars';
import { modalAtom } from '@halo-atoms/common';
import { CalendarProductCardProps } from '@halo-common/components';
import { CALENDAR_ALLOCATION_MODAL_NAME } from '@halo-common/modals';
import { CalendarModel, CalendarPreviewModel } from '@halo-common/models';
import { useRecordActivityMutation } from '@halo-data-sources/mutations';
import { CALENDAR_DETAILS_MODAL_NAME, CALENDAR_PDF_VIEWER_MODAL_NAME } from '@halo-modules/app';
import { useSetAtom } from 'jotai';

export const useCalendarProductCardActions = (): CalendarProductCardProps['actions'] => {
  const setModalMap = useSetAtom(modalAtom);
  const setSelectedCalendar = useSetAtom(calendarSelectedAtom);
  const setContentDisposition = useSetAtom(calendarPDFViewerAtom);
  const setSelectedCalendarTermsheet = useSetAtom(calendarSelectedTermsheetIdAtom);

  const { mutate: recordActivity } = useRecordActivityMutation();

  const isCalendarPreviewModel = (calendar: CalendarModel | CalendarPreviewModel): calendar is CalendarPreviewModel => {
    return calendar.id === undefined;
  };

  const onOfferingDoc = (calendar: CalendarModel | CalendarPreviewModel) => {
    if (isCalendarPreviewModel(calendar)) return;

    setContentDisposition({ calendar, disposition: 'inline' });
    setModalMap({ [CALENDAR_PDF_VIEWER_MODAL_NAME]: true });
  };

  const onSubmitIndication = (calendar: CalendarModel | CalendarPreviewModel, termsheetId?: number) => {
    if (isCalendarPreviewModel(calendar)) return;

    setSelectedCalendar(calendar);
    setSelectedCalendarTermsheet(termsheetId);
    setModalMap({ [CALENDAR_ALLOCATION_MODAL_NAME]: true });

    const activity = [{ verb: 'opened submit allocation modal via calendar reinvestment', model_name: 'Lifecycle' }];
    recordActivity({ activity, fromIFrame: false });
  };

  const onDetails = (calendar: CalendarModel | CalendarPreviewModel, termsheetId?: number) => {
    if (isCalendarPreviewModel(calendar)) return;

    setSelectedCalendar(calendar);
    setSelectedCalendarTermsheet(termsheetId);
    setModalMap({ [CALENDAR_DETAILS_MODAL_NAME]: true });
  };

  const actions = {
    onOfferingDoc,
    onSubmitIndication,
    onDetails,
  };

  return actions;
};
