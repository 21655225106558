import { ReactElement } from 'react';

import { productDetailModalAnalyticsTabStateAtom } from '@halo-atoms/pdm';
import { MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { NoteTypeEnum } from '@halo-common/enums';
import { useMoneyness } from '@halo-common/hooks';
import { NoteModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useRollingReturnsChartQuery } from '@halo-data-sources/queries';
import { LineChart, LineChartProps, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Box, Chip, Skeleton } from '@mui/material';
import { useAtom } from 'jotai';
import { DateTime } from 'luxon';

const lifeTimeMessageSx = {
  backgroundColor: 'common.charts.accent1.background',
  color: 'text.primary',
};

const chartContainerSx = {
  width: '100%',
  ['& .recharts-brush']: {
    ['& .recharts-text']: {
      display: 'none',
    },
  },
};

const tooltipMessageSx = {
  backgroundColor: 'info.background',
  borderRadius: 1,
  color: 'info.dark',
  display: 'block',
  padding: 1,
  mt: 1,
  textAlign: 'center',
  width: '100%',
};

export type ProductDetailModalAnalyticsRollingReturnsChartProps = {
  product?: NoteModel | null;
  loading?: boolean;
};

export const ProductDetailModalAnalyticsRollingReturnsChart = ({
  product,
  loading = false,
}: ProductDetailModalAnalyticsRollingReturnsChartProps): ReactElement => {
  const { calculateProtection } = useMoneyness();
  const [state, setState] = useAtom(productDetailModalAnalyticsTabStateAtom);

  const { brushStart, brushEnd } = state;

  const { data: rollingReturnsMeta, isPending } = useRollingReturnsChartQuery(product);

  const lines = rollingReturnsMeta?.chart?.lines ?? [];
  const maturityDates = rollingReturnsMeta?.chart?.maturityDates ?? {};
  const lifeTimeMessages = rollingReturnsMeta?.chart?.lifeTimeMessages ?? {};
  const totalNoteReturns = rollingReturnsMeta?.chart?.totalNoteReturns ?? {};
  const data = rollingReturnsMeta?.chart?.data ?? [];
  const references = rollingReturnsMeta?.chart?.references ?? [];
  const tabs = rollingReturnsMeta?.filters?.names ?? [];

  const isIncome = product?.type === NoteTypeEnum.Income;
  const isBrushDirty = brushEnd !== undefined && brushStart !== undefined;
  const numberOfPoints = isBrushDirty ? brushEnd - brushStart : data.length;
  const interval = Math.floor(numberOfPoints / 4) - 1;

  const yAxisLabel = isIncome ? 'Performance / Annualized Yield' : 'Performance';

  const isLoading = loading || isPending;

  if (isLoading) return <Skeleton variant="rounded" height={450} width="100%" />;

  const tooltipFormatter = (value: string | number, name: string) => {
    const parsedValue = typeof value !== 'number' ? parseFloat(value.replace('%', '')) : value;
    if (name === 'Breach Level') {
      const calculatedProtection = calculateProtection(parsedValue * -1, product?.protectionType);
      return `${calculatedProtection?.toFixed(2)}%`;
    }
    return `${parsedValue.toFixed(2)}%`;
  };

  const yLabelFormatter = (value: string) => {
    return `${value}%`;
  };

  const handleClick: LineChartProps['onClick'] = ({ activeLabel }) => {
    const starDateSeconds = typeof activeLabel === 'string' ? parseInt(activeLabel) : activeLabel;
    const endDateSeconds = typeof starDateSeconds === 'number' ? maturityDates[starDateSeconds] : null;

    const hasStartDateSeconds = typeof starDateSeconds === 'number';
    const hasEndDateSeconds = isIncome && typeof endDateSeconds === 'number';

    const selectedStartDate = hasStartDateSeconds ? DateTime.fromSeconds(starDateSeconds) : null;
    const selectedEndDate = hasEndDateSeconds
      ? DateTime.fromSeconds(endDateSeconds)
      : (selectedStartDate?.plus({ months: product?.termInMonths ?? 0 }) ?? null);

    const modalOpen = Boolean(selectedStartDate && selectedEndDate);

    setState((prev) => ({
      ...prev,
      underlyingModalOpen: modalOpen,
      underlyingModalStartDate: selectedStartDate,
      underlyingModalEndDate: selectedEndDate,
    }));
  };

  const debouncedBrushDragEnd = (range: { startIndex: number; endIndex: number }) => {
    const startDate = DateTime.fromSeconds(data[range.startIndex].x);
    const endDate = DateTime.fromSeconds(data[range.endIndex].x);

    const starDateText = startDate.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);
    const endDateText = endDate.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);
    const dateRangeText = `${starDateText} - ${endDateText}`;

    const daysBetween = Math.round(endDate.diff(startDate, 'days').days);

    setState((prev) => ({
      ...prev,
      daysBetween,
      startDate,
      endDate,
      underlyingModalOpen: false,
      selectedTab: tabs.length - 1,
      brushStart: range.startIndex,
      brushEnd: range.endIndex,
      dateRangeError: undefined,
      dateRangeText,
    }));
  };

  return (
    <Box sx={chartContainerSx}>
      <LineChart
        height={450}
        lines={lines}
        data={data}
        referenceLines={references}
        variant="brush"
        dataType="date"
        xLabel="Time"
        onClick={handleClick}
        yLabel={{ label: yAxisLabel, formatter: yLabelFormatter }}
        XAxisProps={{ interval, textAnchor: 'end' }}
        TooltipProps={{
          formatter: tooltipFormatter,
          subLabel: (title: string) => {
            const seconds = parseInt(title);
            const lifeTimeMessage = lifeTimeMessages[seconds];
            return lifeTimeMessage ? <Chip sx={lifeTimeMessageSx} label={lifeTimeMessage} size="small" /> : null;
          },
          dataEnhancer: isIncome
            ? (key: string, data: Array<Record<string, string | number>>) => {
                const seconds = parseInt(key);
                const totalNoteReturn = totalNoteReturns[seconds];
                return typeof totalNoteReturn === 'number'
                  ? [...data, { value: totalNoteReturn, color: 'transparent', name: 'Note Total Return' }]
                  : data;
              }
            : undefined,
          titleFormatter: (title: string) => {
            const seconds = parseInt(title);
            const maturityDateSeconds = maturityDates[seconds];
            const tooltipStartDate = DateTime.fromSeconds(seconds);
            const tooltipEndDate = maturityDateSeconds ? DateTime.fromSeconds(maturityDateSeconds) : null;
            const formattedStartDate = tooltipStartDate?.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);
            const formattedEndDate = tooltipEndDate?.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);
            const hasDates = formattedStartDate && formattedEndDate;
            return hasDates ? `${formattedStartDate} - ${formattedEndDate}` : undefined;
          },
          message: (
            <LocalizedTypography sx={tooltipMessageSx} variant="caption" className="wm-rr-returndetails">
              {translations.pdm.preTrade.clickChartToSeeMoreReturnDetails}
            </LocalizedTypography>
          ),
        }}
        BrushProps={{
          height: 80,
          startIndex: brushStart,
          endIndex: brushEnd,
          onDragEnd: debouncedBrushDragEnd,
        }}
        walkMeNamespace="wm-rr"
      />
    </Box>
  );
};
