import React, { useRef, useState } from 'react';

import { PrincipalProtectionPopper } from '@halo-common/components';
import { NoteCreateProtectionTypeEnum } from '@halo-common/enums';
import { translations } from '@halo-common/translations';
import { Iconography, LocalizedTypography, NumericSelector } from '@halodomination/halo-fe-common';
import { Box, capitalize, InputLabel, Stack, SxProps, Typography } from '@mui/material';

export type ProtectionSelection = { value: number; type: NoteCreateProtectionTypeEnum };

type PrincipalProtectionDropdownProps = {
  sx?: SxProps;
};

export const PrincipalProtectionDropdown = ({ sx }: PrincipalProtectionDropdownProps) => {
  const [protection, setProtectionType] = useState<ProtectionSelection>({
    value: 0,
    type: NoteCreateProtectionTypeEnum.Soft,
  });
  const [open, setOpen] = useState(false);

  const dropdownAnchorRef = useRef(null);

  const handleClick = () => {
    return open ? setOpen(false) : setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectProtection = (protection: ProtectionSelection) => {
    setProtectionType(protection);
  };

  const handleNumericChange = (input: number) => {
    setProtectionType({ type: protection.type, value: input });
  };

  const dropdownIconName = open ? 'caret-up' : 'caret-down';

  return (
    <Box sx={sx} ref={dropdownAnchorRef}>
      <Box onClick={handleClick}>
        <InputLabel shrink>
          <LocalizedTypography>{translations.noteCreate.principalProtection}</LocalizedTypography>
        </InputLabel>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Box onClick={(e) => e.stopPropagation()}>
            <NumericSelector
              increment={5}
              label="%"
              min={0}
              max={100}
              value={protection.value}
              onChange={handleNumericChange}
            />
          </Box>
          <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
            <Typography variant="subtitle1">{capitalize(protection.type)}</Typography>
            <Iconography color="text.secondary" iconName={dropdownIconName} />
          </Stack>
        </Stack>
      </Box>
      <PrincipalProtectionPopper
        anchorRef={dropdownAnchorRef}
        open={open}
        onClose={handleClose}
        onSelectProtection={handleSelectProtection}
        currentProtection={protection}
      />
    </Box>
  );
};
