export enum AssetIdentifierEnum {
  NONE = 'NONE',
  CUSIP = 'CUSIP',
  ISIN = 'ISIN',
  FUNDSERVCODE = 'FUNDSERVCODE',
}

export enum AssetIdStatusEnum {
  pending = 'pending',
  resolved = 'resolved',
  unresolved = 'unresolved',
}

export enum AssetIdLocationEnum {
  halo = 'Halo',
  edgar = 'EDGAR',
}
