import { ReactElement } from 'react';

import { useWhiteLabelRole } from '@halo-common/hooks';

import { OrderTicketJanneyForm } from './OrderTicketFormVariants/OrderTicketJanneyForm';
import { OrderTicketPershingForm } from './OrderTicketFormVariants/OrderTicketPershingForm';

export type OrderTicketFormProps = {
  onClose: () => void;
};

export const OrderTicketForm = ({ onClose }: OrderTicketFormProps): ReactElement | null => {
  const role = useWhiteLabelRole();

  switch (role) {
    case 'janney':
      return <OrderTicketJanneyForm onClose={onClose} />;

    case 'pershing':
    case 'pershingDev':
      return <OrderTicketPershingForm onClose={onClose} />;

    default:
      return null;
  }
};
