import { ReactElement, useEffect, useState } from 'react';

import { ActionButton } from '@halo-common/components';
import { PENDING_ALLOCATION_STATUSES } from '@halo-common/constants';
import { useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { translations } from '@halo-common/translations';
import { LocalizedButton, LocalizedTypography, Stack } from '@halodomination/halo-fe-common';
import { DateTime } from 'luxon';

export type SubmittedAllocationsTableActionsProps = {
  disableCancel?: boolean;
  expirationDate?: string;
  id: number;
  accountId?: number;
  loading?: boolean;
  onCancel: (id: number, accountId?: number) => void;
  status: string;
};

/**
 * ### SubmittedAllocationsTableActions
 *
 * #### Required Props
 *
 * **dateSubmitted** &rarr; The date the allocation was submitted.
 *
 * **id** &rarr; The id of the allocation to take action on.
 *
 * **status** &rarr; The current status of the allocation: NEW, PENDING, FILLED, REJECTED, CANCELLED
 *
 * **onCancel** &rarr; Function called when a user cancels an allocation.
 *
 * #### Optional Props
 *
 * **loading** &rarr; Flag used to manage the buttons in the actions column.
 *
 */
export const SubmittedAllocationsTableActions = ({
  id,
  accountId = undefined,
  loading = false,
  disableCancel = false,
  expirationDate,
  onCancel,
  status,
}: SubmittedAllocationsTableActionsProps): ReactElement => {
  const [pendingAction, setPendingAction] = useState<boolean>(false);

  const configureDateTime = useWhiteLabelDateConfiguration();

  const todaysDateTime = configureDateTime(DateTime.utc().toISO()) as DateTime;
  const expirationDateTime = configureDateTime(expirationDate) ?? todaysDateTime;
  const orderExpired = expirationDateTime && expirationDateTime < todaysDateTime;

  const orderFilled = status === 'filled';
  const orderPending = PENDING_ALLOCATION_STATUSES.map((entry) => entry.toLowerCase()).includes(status);

  const cancelDisabled = orderExpired || orderFilled || disableCancel;
  const cancelActionEnabled = orderPending || orderFilled;

  const handleCancel = () => setPendingAction(true);
  const handleNo = () => setPendingAction(false);
  const handleYes = () => onCancel(id, accountId);

  useEffect(() => {
    setPendingAction(false);
  }, [status]);

  if (pendingAction) {
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <LocalizedTypography variant="body2">{translations.messages.areYouSure}</LocalizedTypography>
        <LocalizedButton
          size="extraSmall"
          variant="outlined"
          color="primary"
          type="button"
          onClick={handleNo}
          disabled={loading}
        >
          {translations.common.no}
        </LocalizedButton>
        <ActionButton
          size="extraSmall"
          variant="contained"
          color="primary"
          type="button"
          onClick={handleYes}
          loading={loading}
        >
          {translations.common.yes}
        </ActionButton>
      </Stack>
    );
  } else if (cancelActionEnabled) {
    return (
      <LocalizedButton
        size="extraSmall"
        variant="outlined"
        color="primary"
        type="button"
        onClick={handleCancel}
        disabled={cancelDisabled}
      >
        Cancel
      </LocalizedButton>
    );
  }

  return <LocalizedTypography variant="body2">{translations.common.canceled}</LocalizedTypography>;
};
