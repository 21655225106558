import { AccountDesignationEnum } from '@halo-common/enums';
import { AccountModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useT } from '@transifex/react';

export type OrderTicketDesignationSingleResult = {
  label: AccountDesignationEnum | null;
  isAdvisoryAccount: boolean;
  isBrokerageAccount: boolean;
};

export type OrderTicketDesignationResult =
  | Array<OrderTicketDesignationSingleResult>
  | OrderTicketDesignationSingleResult
  | ((model?: AccountModel | null) => OrderTicketDesignationSingleResult);

export function useOrderTicketDesignation(): (model?: AccountModel | null) => OrderTicketDesignationSingleResult;
export function useOrderTicketDesignation(model?: AccountModel | null): OrderTicketDesignationSingleResult;
export function useOrderTicketDesignation(model?: Array<AccountModel>): Array<OrderTicketDesignationSingleResult>;
export function useOrderTicketDesignation(
  model?: AccountModel | Array<AccountModel> | null,
): OrderTicketDesignationResult {
  const t = useT();

  const buildOrderTicketDesignationResult = (designation?: string | null): OrderTicketDesignationSingleResult => {
    const isAdvisoryAccountSelected = designation === AccountDesignationEnum.Advisory;
    const isBrokerageAccountSelected = designation === AccountDesignationEnum.Brokerage;

    const label = (
      isAdvisoryAccountSelected
        ? translations.common.advisory
        : isBrokerageAccountSelected
          ? translations.common.brokerage
          : null
    ) as AccountDesignationEnum | null;

    const translatedLabel = label ? (t(label) as AccountDesignationEnum) : null;

    return {
      label: translatedLabel,
      isAdvisoryAccount: isAdvisoryAccountSelected,
      isBrokerageAccount: isBrokerageAccountSelected,
    };
  };

  if (Array.isArray(model)) return model.map(({ designation }) => buildOrderTicketDesignationResult(designation));
  else if (model) return buildOrderTicketDesignationResult(model?.designation);
  else return (model?: AccountModel | null) => buildOrderTicketDesignationResult(model?.designation);
}
