import { ChangeEvent, ReactElement } from 'react';

import { AdminCreateCalendarFields } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { useWholesalersQuery } from '@halo-data-sources/queries';
import { LocalizedTextField } from '@halodomination/halo-fe-common';
import { MenuItem } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';

export type WholesalerFieldProps = {
  index: number;
};

export const WholesalerField = ({ index }: WholesalerFieldProps): ReactElement => {
  const { data: wholesalers } = useWholesalersQuery();

  const { trigger } = useFormContext<AdminCreateCalendarFields>();

  const wholesaler = useController({ name: `settings.${index}.wholesaler` });

  const wholeSalerError = wholesaler.fieldState.error?.message;

  const handleWholesaler = (event: ChangeEvent<HTMLInputElement>) => {
    wholesaler.field.onChange(event.target.value);
    void trigger(`settings`);
  };

  return (
    <LocalizedTextField
      {...wholesaler.field}
      inputRef={wholesaler.field.ref}
      sx={{ flex: 1 }}
      label={translations.calendars.common.wholesaler}
      helperText={wholeSalerError}
      error={Boolean(wholeSalerError)}
      onChange={handleWholesaler}
      select
    >
      {wholesalers?.map((wholesaler) => (
        <MenuItem key={wholesaler.id} value={String(wholesaler.id)}>
          {wholesaler.name}
        </MenuItem>
      ))}
    </LocalizedTextField>
  );
};
