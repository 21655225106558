import { CREATE_CALENDAR_ERROR_TRANSLATIONS } from './createCalendarErrors';

export const CREATE_CALENDAR_TRANSLATIONS = {
  addNew: 'Add New',
  addNewInstructions:
    'To add a new Product Shelf Offering, you must have a Note ID already created with all of the Note Terms. Click below to create a new Note.',
  addNewOffering: 'Add New Product Shelf Offering',
  enableNow: 'Enable Now',
  preliminaryTermsheet: 'Preliminary Termsheet',
  previewTitle: 'Preview Product Shelf Offering',
  errors: CREATE_CALENDAR_ERROR_TRANSLATIONS,
};
