import { useUserInfoQuery } from '@halo-data-sources/queries';
import {
  DisclaimerText,
  DocumentLinks,
  LoginForm,
  LoginFormSkeleton,
  OnboardingLayout,
} from '@halo-modules/app/onboarding';
import { LocalizedTypography, PageComponent, PageMeta, Stack } from '@halodomination/halo-fe-common';
import { Box } from '@mui/material';

const subtitleSx = {
  color: 'text.secondary',
  display: 'flex',
  justifyContent: 'center',
  mb: 5,
};

const logoSx = {
  mb: 4,
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: '190px',
};

const haloLogoSx = {
  mr: 6,
  maxWidth: '190px',
};

const logoContainerSx = {
  mb: 2,
  alignItems: 'center',
};

const logoSmallSx = {
  maxWidth: '95px',
  height: '27px',
};

const LoginPage: PageComponent = () => {
  const { data: user, isPending } = useUserInfoQuery();

  const whiteLabel = user?.whiteLabel;
  const brand = whiteLabel?.brand;
  const logoDark = brand?.logoDark;
  const subtitles = brand?.subtitles;
  const poweredByHalo = brand?.showPoweredByHalo;

  const loading = !whiteLabel || isPending;

  const disclaimerContent = !loading ? <DisclaimerText /> : null;

  const poweredByHaloLogo = (
    <Box sx={logoSmallSx} component="img" src="/assets/images/logo_poweredbyhalo.svg" alt="Powered By Halo Logo" />
  );

  const logo = poweredByHalo ? (
    <Stack sx={logoContainerSx} direction="row" justify="center">
      <Box marginRight={6} sx={haloLogoSx} component="img" src={logoDark?.path} alt="Brand Logo" />
      {poweredByHaloLogo}
    </Stack>
  ) : (
    <Box sx={logoSx} component="img" src={logoDark?.path} alt="Brand Logo" />
  );

  const subtitleText = subtitles ? (
    <LocalizedTypography sx={subtitleSx} variant="overline">
      {subtitles}
    </LocalizedTypography>
  ) : null;

  return (
    <OnboardingLayout fallback={<LoginFormSkeleton />}>
      {logo}
      {subtitleText}
      <LoginForm />
      {disclaimerContent}
      <DocumentLinks />
    </OnboardingLayout>
  );
};

export const LoginPageMeta: PageMeta = {
  pageName: 'Login',
  route: '/app/onboarding/login',
  iconName: 'sign-in',
};

LoginPage.pageName = LoginPageMeta.pageName;
LoginPage.route = LoginPageMeta.route;
LoginPage.iconName = LoginPageMeta.iconName;

export default LoginPage;
