import { ReactElement } from 'react';

import { orderTicketCSVUploadModalAtom } from '@halo-atoms/orderTicket';
import { useExcelFileUploader } from '@halo-common/hooks';
import { UploadFileModal } from '@halo-common/modals';
import { AuctionDetailsAllocationFields } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { useAccountAllocationCSVUploadMutation } from '@halo-data-sources/mutations';
import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { Alert, Box, Divider, Stack } from '@mui/material';
import { useAtom } from 'jotai';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { AuctionDetailsAccountNotionalInput } from '../AuctionDetailsAccountNotionalInput';
import { AuctionDetailsAllocationsFooter } from '../AuctionDetailsAllocationsFooter';
import { DEFAULT_ALLOCATION_ENTRY } from '../AuctionDetailsAllocationsTab';
import { DownloadTemplateButton } from '../DownloadTemplateButton';

export type AddAllocationsFormProps = {
  onCreateAccount: (index?: number) => void;
};

export const AddAllocationsForm = ({ onCreateAccount }: AddAllocationsFormProps): ReactElement => {
  const { control, formState } = useFormContext<AuctionDetailsAllocationFields>();
  const [csvUploadModalOpen, setCSVUploadModal] = useAtom(orderTicketCSVUploadModalAtom);
  const { fields, remove, append } = useFieldArray<AuctionDetailsAllocationFields>({ name: 'allocations', control });

  const { mutate: uploadCSV, isPending: csvLoading } = useAccountAllocationCSVUploadMutation();

  const handleExcelUpload = useExcelFileUploader(uploadCSV, ['Account Number', 'Account Notional']);

  const { errors } = formState;
  const allocationError = errors?.allocations?.message;
  const hasAllocationError = Boolean(allocationError);

  const handleAddAllocation = (): void => {
    append(DEFAULT_ALLOCATION_ENTRY);
  };

  const accountAllocationList = fields.map((field, index) => {
    const handleDelete = (): void => remove(index);
    return (
      <AuctionDetailsAccountNotionalInput
        key={field.id}
        index={index}
        onDelete={handleDelete}
        onCreateAccount={onCreateAccount}
      />
    );
  });

  const allocationErrorContent = hasAllocationError ? (
    <Alert variant="outlined" severity="error">
      {allocationError}
    </Alert>
  ) : null;

  const handleCloseCSVUpload = () => {
    setCSVUploadModal(false);
  };

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
        <LocalizedTypography variant="h6" color="primary">
          {translations.common.allocations}
        </LocalizedTypography>
        <Divider sx={{ flex: 1 }} />
        <DownloadTemplateButton />
      </Stack>
      {allocationErrorContent}
      <Box display="grid" gap={2} gridTemplateColumns="repeat(12, 1fr)">
        {accountAllocationList}
      </Box>
      <AuctionDetailsAllocationsFooter fields={fields} addAllocation={handleAddAllocation} />
      <UploadFileModal
        open={csvUploadModalOpen}
        onSubmit={handleExcelUpload}
        subtitle={translations.orderBook.auctions.uploadAllocationsSubtitle}
        title={translations.orderBook.auctions.uploadAllocationsTitle}
        fileTypes=".csv,.xlsx"
        loading={csvLoading}
        onClose={handleCloseCSVUpload}
      />
    </Stack>
  );
};
