import { ReactElement } from 'react';

import { calendarManagerAtom } from '@halo-atoms/calendars';
import { CalendarProductCard } from '@halo-common/components';
import { useCalendarProductCardActions } from '@halo-common/hooks';
import { translations } from '@halo-common/translations';
import { cleanObject, navigateParentTo } from '@halo-common/utils';
import { useCalendarsInfiniteQuery } from '@halo-data-sources/queries';
import { CalendarEmptyResult } from '@halo-modules/app';
import { Iconography, LocalizedButton } from '@halodomination/halo-fe-common';
import { Skeleton, Stack } from '@mui/material';
import { useAtomValue } from 'jotai';

const limit = 3;

export const CalendarsList = (): ReactElement => {
  const { query, sideBarFilters } = useAtomValue(calendarManagerAtom);

  const { data, isPending } = useCalendarsInfiniteQuery(query);

  const calendars = data?.pages?.[0]?.calendars;

  const actions = useCalendarProductCardActions();

  if (isPending) {
    return (
      <Stack direction="column" spacing={2}>
        {Array.from({ length: limit }).map((_, index) => (
          <Skeleton variant="rounded" width="100%" height={212} data-testid="skeleton" key={index} />
        ))}
      </Stack>
    );
  }

  if (!Array.isArray(calendars) || calendars.length === 0) return <CalendarEmptyResult />;

  const viewMore = () => {
    const activeFilters = cleanObject(sideBarFilters) as Partial<typeof sideBarFilters>;
    const searchParams = new URLSearchParams();
    searchParams.set('initialState', JSON.stringify(activeFilters));
    const url = `/calendar?${searchParams.toString()}`;
    navigateParentTo(url);
  };

  const viewMoreButton =
    calendars.length > limit ? (
      <div>
        <LocalizedButton
          variant="text"
          size="small"
          color="primary"
          endIcon={<Iconography iconName="arrow-right" />}
          onClick={viewMore}
          data-testid="view-more-button"
        >
          {translations.common.viewMore}
        </LocalizedButton>
      </div>
    ) : null;

  return (
    <Stack direction="column">
      {calendars.slice(0, limit).map((calendar) => (
        <CalendarProductCard key={calendar.id} calendar={calendar} actions={actions} />
      ))}
      {viewMoreButton}
    </Stack>
  );
};
