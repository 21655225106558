import { ChangeEvent, ReactElement, useState } from 'react';

import { EmptyResultsCard, ProductCard } from '@halo-common/components';
import { ProductTypeEnum } from '@halo-common/enums';
import { NoteModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { navigateParentTo } from '@halo-common/utils';
import { useRecentQuotesQuery, useRecentlyCreatedNoteQuery } from '@halo-data-sources/queries';
import {
  BrandIcon,
  LocalizedButton,
  LocalizedMenuItem,
  LocalizedTextField,
  LocalizedTypography,
} from '@halodomination/halo-fe-common';
import { Skeleton, Stack, capitalize } from '@mui/material';

const fullWidthSx = {
  width: '100%',
};

const dropdownSx = {
  minWidth: 175,
};

export type RollingReturnsProductListProps = {
  onSelect: (product: NoteModel) => void;
};

export const RollingReturnsProductList = ({ onSelect }: RollingReturnsProductListProps): ReactElement => {
  const [selectedProductType, setSelectedProductType] = useState<string>(translations.common.all);

  const { data: recentlyCreatedQuery, isPending, isSuccess } = useRecentlyCreatedNoteQuery();

  const products = recentlyCreatedQuery?.products ?? [];
  const productIds = products.map(({ id }) => id);

  const isProductTypeSelected = selectedProductType !== translations.common.all;
  const filteredProducts = isProductTypeSelected
    ? products.filter((product) => product.productType.toLowerCase() === selectedProductType.toLowerCase())
    : products;

  const noResults = isSuccess && !filteredProducts?.length;
  const productTypes = [...new Set(products.map(({ productType }) => capitalize(productType)))];

  const { isPending: isLoadingQuotes } = useRecentQuotesQuery(productIds);

  const handleTypeChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSelectedProductType(ev.target.value);
  };

  const isLoading = isLoadingQuotes || isPending;

  const cards = isLoading
    ? [...Array(4)].map((value) => <Skeleton key={value} variant="rounded" width={350} height={300} />)
    : filteredProducts.map((product) => (
        <ProductCard
          key={product.id}
          type={ProductTypeEnum.note}
          product={product}
          onClick={() => void onSelect(product)}
        />
      ));

  const list = !noResults ? (
    <Stack direction="row" flexWrap="wrap" justifyContent="center" spacing={2} sx={fullWidthSx} useFlexGap>
      {cards}
    </Stack>
  ) : (
    <EmptyResultsCard
      title={translations.analytics.rollingReturns.noResultsTitle}
      description={translations.analytics.rollingReturns.noResultsMessage}
      brandIcon={<BrandIcon variant="noResults" color="primary" />}
      action={
        <LocalizedButton onClick={() => void navigateParentTo('/note_creator', true)}>
          {translations.common.createNote}
        </LocalizedButton>
      }
    />
  );

  const select = !noResults ? (
    <LocalizedTextField
      select
      sx={dropdownSx}
      label={translations.analytics.rollingReturns.filterByNoteTypeSearchLabel}
      value={selectedProductType}
      onChange={handleTypeChange}
    >
      <LocalizedMenuItem key={translations.common.all} value={translations.common.all}>
        {translations.common.all}
      </LocalizedMenuItem>
      {productTypes.map((type) => (
        <LocalizedMenuItem key={type} value={type}>
          {type}
        </LocalizedMenuItem>
      ))}
    </LocalizedTextField>
  ) : null;

  return (
    <Stack direction="column" spacing={4}>
      <Stack direction="row" sx={fullWidthSx} alignItems="flex-start" justifyContent="space-between">
        <Stack direction="column">
          <LocalizedTypography variant="h6">
            {translations.analytics.rollingReturns.lastCreatedNotesTitle}
          </LocalizedTypography>
          <LocalizedTypography variant="caption" color="textSecondary">
            {translations.analytics.rollingReturns.lastCreatedNotesSubTitle}
          </LocalizedTypography>
        </Stack>
        {select}
      </Stack>
      {list}
    </Stack>
  );
};
