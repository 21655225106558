import { ReactElement } from 'react';

import { ProductDetailTable } from '@halo-common/components';
import { SHORT_MONTH_SHORT_DAY_YEAR_FORMAT } from '@halo-common/constants';
import { useTranslatePdmDates } from '@halo-common/hooks';
import { TableStatusChip } from '@halo-common/modals';
import { CurrencyModel } from '@halo-common/models';
import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { PostTradeV2Event, usePostTradeV2EventsQuery } from '@halo-data-sources/queries';
import {
  GridColDef,
  GridRenderCellParams,
  HaloDataGridProps,
  mapNumberToLocalCurrency,
} from '@halodomination/halo-fe-common';
import { Typography } from '@mui/material';

const emphasisSx = {
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  width: '100%',
};

const commonColumnProps: Partial<GridColDef> = {
  align: 'left',
  flex: 1,
};

export type V2PostTradeNoteDetailModalCouponTableProps = {
  showDate?: boolean;
  showAmount?: boolean;
  showStatus?: boolean;
  loading?: boolean;
  account?: AccountTypeAheadOption | null;
  id?: number;
  currency?: CurrencyModel;
};

export const V2PostTradeNoteDetailModalCouponTable = ({
  showDate = true,
  showAmount = true,
  showStatus = true,
  loading = true,
  id,
  account,
  currency,
}: V2PostTradeNoteDetailModalCouponTableProps): ReactElement => {
  const translateDate = useTranslatePdmDates();

  const { data: events, isPending: isEventsLoading } = usePostTradeV2EventsQuery(id, account);

  const rows = events?.coupon ?? [];
  const height = rows.length >= 16 ? 600 : 'auto';

  const isLoading = loading || isEventsLoading;

  const columns: HaloDataGridProps['columns'] = [];

  if (showDate) {
    columns.push({
      ...commonColumnProps,
      field: 'eventDate',
      headerName: translations.common.date,
      valueFormatter: (value: string) => translateDate(value, SHORT_MONTH_SHORT_DAY_YEAR_FORMAT),
    });
  }

  if (showAmount) {
    columns.push({
      ...commonColumnProps,
      field: 'cashFlowAmt',
      headerName: translations.common.amount,
      renderCell: ({ value }: GridRenderCellParams<PostTradeV2Event, number>) => {
        const valueAsCurrency = mapNumberToLocalCurrency(
          value,
          { currency: currency?.code ?? 'USD' },
          currency?.symbol,
        );

        return (
          <Typography sx={emphasisSx} variant="body2">
            {valueAsCurrency}
          </Typography>
        );
      },
    });
  }

  if (showStatus) {
    columns.push({
      ...commonColumnProps,
      field: 'paid',
      headerName: translations.common.paid,
      renderCell: ({ value }) => {
        return <TableStatusChip status={value} />;
      },
    });
  }

  return <ProductDetailTable<PostTradeV2Event> height={height} columns={columns} rows={rows} loading={isLoading} />;
};
