import { ReactElement, useEffect } from 'react';

import { bulkOrderTicketManagerAtom, fixTimeoutErrorAtom, orderTicketStepAtom } from '@halo-atoms/orderTicket';
import { BulkOrderTicketFormFields } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { PERSHING_DEFAULT_TIMEOUT_MESSAGE } from '@halo-data-sources/switches';
import { Alert } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { JanneyBulkAllocationInput } from './JanneyBulkAllocationInput';
import { BulkAllocationLayout } from '../../BulkAllocationLayout';

export const JanneyBulkAllocationSection = (): ReactElement => {
  const { control, watch } = useFormContext<BulkOrderTicketFormFields>();

  const { fields, remove, append } = useFieldArray<BulkOrderTicketFormFields>({ name: 'allocations', control });

  const handleNextStep = useSetAtom(orderTicketStepAtom.handleNextAtom);
  const orderTicketState = useAtomValue(bulkOrderTicketManagerAtom);
  const fixTimeoutError = useAtomValue(fixTimeoutErrorAtom);

  const allocationError = fixTimeoutError ? PERSHING_DEFAULT_TIMEOUT_MESSAGE : undefined;
  const hasAllocationError = Boolean(allocationError);

  const validAllocations = orderTicketState.validatedAllocations;
  const pendingAllocations = orderTicketState.pendingValidateAllocations;

  const pendingAllocationLength = pendingAllocations.length || null;
  const validationSuccess = validAllocations.length === pendingAllocationLength;

  const count = fields.length;
  const allocations = watch('allocations');
  const sum = allocations.reduce((prev, field) => {
    if (!field.quantity) return prev;
    return prev + Number(field.quantity);
  }, 0);

  const handleAddAllocation = () => {
    append({ accountOption: null });
  };

  const slotProps = {
    footer: {
      onAddAllocation: handleAddAllocation,
      allocationSum: sum,
      allocationCount: count,
      slots: {
        totalLabel: translations.orderTicket.janneyFooterNotionalTotal,
      },
    },
  };

  const accountAllocationList = fields.map((field, index) => {
    const handleDelete = (): void => remove(index);
    return <JanneyBulkAllocationInput key={field.id} index={index} onDelete={handleDelete} />;
  });

  const allocationErrorContent = hasAllocationError ? (
    <Alert variant="outlined" severity="error">
      {allocationError}
    </Alert>
  ) : null;

  useEffect(() => {
    if (validationSuccess) handleNextStep();
  }, [validationSuccess]);

  return (
    <BulkAllocationLayout error={allocationErrorContent} slotProps={slotProps}>
      {accountAllocationList}
    </BulkAllocationLayout>
  );
};
