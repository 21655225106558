import { ReactElement } from 'react';

import { OrderTicketFormModelFields } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { useController } from 'react-hook-form';

export const DisclaimerSection = (): ReactElement => {
  const {
    fieldState: { error },
    field: { ref, value, ...field },
  } = useController<OrderTicketFormModelFields, 'disclaimer'>({ name: 'disclaimer' });

  const label = translations.orderTicket.disclaimer;
  const errorMessage = error?.message;

  const containerSx = {
    border: errorMessage ? (theme: HaloTheme) => `1px solid ${theme.palette?.error.main}` : undefined,
    borderRadius: 2,
    py: 2,
    px: 1,
  };

  return (
    <Stack direction="column" spacing={1}>
      <FormControlLabel
        {...field}
        sx={containerSx}
        inputRef={ref}
        control={<Checkbox checked={value} sx={{ pr: 1 }} />}
        label={<LocalizedTypography variant="body2">{label}</LocalizedTypography>}
      />
      <LocalizedTypography variant="caption" color="error.main">
        {errorMessage}
      </LocalizedTypography>
    </Stack>
  );
};
