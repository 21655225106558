import { ReactElement, KeyboardEvent } from 'react';

import { Dropzone } from '@halo-common/components';
import { YEAR_MONTH_DAY_DATE_FORMAT } from '@halo-common/constants';
import { CalendarOrderCategoryEnum } from '@halo-common/enums';
import { AdminCreateCalendarFields } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { handleRestrictedKeyDown } from '@halo-common/utils';
import { SettlementTypeEnum } from '@halo-data-sources/enums';
import { useIssuersQuery, useTradingDaysQuery } from '@halo-data-sources/queries';
import { LocalizedInputLabel, LocalizedTextField, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Box, MenuItem, Stack, Switch } from '@mui/material';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers-pro';
import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import { useController, useFormContext } from 'react-hook-form';

import { SettingsSection } from './SettingsSection';

const preliminaryTermsheetSx = {
  py: 2.125,
};

const ACCEPTED_FILE_CONTENT = {
  '.pdf': [],
};

export const AdminCreateCalendarForm = (): ReactElement => {
  const { control, formState, register, setValue, trigger } = useFormContext<AdminCreateCalendarFields>();
  const { data: issuers } = useIssuersQuery(true);

  const startDate = DateTime.now().startOf('day');
  const endDate = startDate.plus({ year: 1 });
  const tradingDaysQuery = { endDate, startDate };

  const { data: tradingDays } = useTradingDaysQuery(tradingDaysQuery);
  const nonTradingDays = tradingDays?.nonTradingDays ?? [];

  const termsheetField = useController({ control, name: 'preliminaryTermsheet' });
  const issuerField = useController({ control, name: 'issuer' });
  const categoryField = useController({ control, name: 'category' });
  const settlementTypeField = useController({ control, name: 'settlementType' });

  const { errors } = formState;

  const { ref: noteIdRef, ...noteIdInputProps } = register('noteId');
  const { ref: noteQuoteRef, ...noteQuoteInputProps } = register('noteQuote');
  const { ref: displayNameRef, ...displayNameInputProps } = register('displayName');
  const { ref: cusipRef, ...cusipInputProps } = register('cusip');
  const { ref: reofferRef, ...reofferInputProps } = register('reoffer');
  const { ref: tradeDateRef, ...tradeDateInputProps } = register('tradeDate');
  const { ref: issueDateRef, ...issueDateInputProps } = register('issueDate');
  const { ref: closeDateRef, ...closeDateInputProps } = register('closeDate');
  const { ref: enableNowRef, ...enableNowInputProps } = register('enableNow');

  const handleDateChange = (date: DateTime | null, name: keyof AdminCreateCalendarFields) => {
    setValue(name, date?.toISODate() ?? '', { shouldValidate: true });
  };

  const file = termsheetField.field.value;
  const fileHandler = async (file: Array<File>) => {
    termsheetField.field.onChange(file?.[0]);
    await trigger('preliminaryTermsheet');
  };

  const termsheetError = errors?.preliminaryTermsheet?.message;
  const noteIdError = errors?.noteId?.message;
  const noteQuoteError = errors?.noteQuote?.message;
  const displayNameError = errors?.displayName?.message;
  const cusipError = errors?.cusip?.message;
  const reofferError = errors?.reoffer?.message;
  const issuerError = errors?.issuer?.message;
  const categoryError = errors?.category?.message;
  const settlementTypeError = errors?.settlementType?.message;
  const tradeDateError = errors?.tradeDate?.message;
  const issueDateError = errors?.issueDate?.message;
  const closeDateError = errors?.closeDate?.message;

  const handleIntegersOnly = (event: KeyboardEvent<HTMLInputElement>) => {
    handleRestrictedKeyDown({ event, invalidKeyRegex: /[^\d]/ });
  };

  const handleDecimalOnly = (event: KeyboardEvent<HTMLInputElement>) => {
    handleRestrictedKeyDown({ event, invalidKeyRegex: /[^\d.]/ });
  };

  const shouldDisableDates = (date: DateTime) => {
    return nonTradingDays.some((day) => day === date.toFormat(YEAR_MONTH_DAY_DATE_FORMAT));
  };

  return (
    <form>
      <Stack spacing={3}>
        <LocalizedTypography variant="h6">
          {translations.calendars.createCalendar.preliminaryTermsheet}
        </LocalizedTypography>
        <Box sx={preliminaryTermsheetSx}>
          <Dropzone accept={ACCEPTED_FILE_CONTENT} onDrop={fileHandler} file={file} error={termsheetError} />
        </Box>
        <LocalizedTextField
          {...noteIdInputProps}
          label={translations.calendars.common.noteId}
          inputRef={noteIdRef}
          helperText={noteIdError}
          error={Boolean(noteIdError)}
          onKeyDown={handleIntegersOnly}
        />
        <LocalizedTextField
          {...noteQuoteInputProps}
          label={translations.calendars.common.noteQuote}
          inputRef={noteQuoteRef}
          helperText={noteQuoteError}
          error={Boolean(noteQuoteError)}
          onKeyDown={handleDecimalOnly}
        />
        <LocalizedTextField
          {...displayNameInputProps}
          label={translations.calendars.common.displayName}
          inputRef={displayNameRef}
          helperText={displayNameError}
          error={Boolean(displayNameError)}
        />
        <LocalizedTextField
          {...cusipInputProps}
          label={translations.calendars.common.cusip}
          inputRef={cusipRef}
          helperText={cusipError}
          error={Boolean(cusipError)}
          onKeyDown={handleIntegersOnly}
        />
        <LocalizedTextField
          {...reofferInputProps}
          label={translations.calendars.common.reoffer}
          inputRef={reofferRef}
          helperText={reofferError}
          error={Boolean(reofferError)}
          onKeyDown={handleDecimalOnly}
        />
        <LocalizedTextField
          {...issuerField.field}
          inputRef={issuerField.field.ref}
          fullWidth
          size="medium"
          select
          label={translations.common.issuer}
          helperText={issuerError}
          error={Boolean(issuerError)}
        >
          {issuers?.map((issuer) => (
            <MenuItem key={issuer.id} value={issuer.id}>
              {issuer.name}
            </MenuItem>
          ))}
        </LocalizedTextField>
        <LocalizedTextField
          {...categoryField.field}
          inputRef={categoryField.field.ref}
          fullWidth
          size="medium"
          select
          label={translations.calendars.common.category}
          helperText={categoryError}
          error={Boolean(categoryError)}
        >
          <MenuItem key={CalendarOrderCategoryEnum.advisory} value={CalendarOrderCategoryEnum.advisory}>
            {capitalize(CalendarOrderCategoryEnum.advisory)}
          </MenuItem>
          <MenuItem key={CalendarOrderCategoryEnum.brokerage} value={CalendarOrderCategoryEnum.brokerage}>
            {capitalize(CalendarOrderCategoryEnum.brokerage)}
          </MenuItem>
        </LocalizedTextField>
        <LocalizedTextField
          {...settlementTypeField.field}
          inputRef={settlementTypeField.field.ref}
          fullWidth
          size="medium"
          select
          label={translations.calendars.common.settlementType}
          helperText={settlementTypeError}
          error={Boolean(settlementTypeError)}
        >
          {Object.values(SettlementTypeEnum).map((type) => (
            <MenuItem key={type} value={type}>
              {capitalize(type.toLowerCase())}
            </MenuItem>
          ))}
        </LocalizedTextField>
        <Stack direction="row" spacing={1}>
          <DateTimePicker
            {...closeDateInputProps}
            label={translations.common.close}
            inputRef={closeDateRef}
            onChange={(date) => handleDateChange(date, 'closeDate')}
            slotProps={{
              textField: {
                helperText: closeDateError,
                error: Boolean(closeDateError),
              },
            }}
            shouldDisableDate={shouldDisableDates}
            minDate={startDate}
            maxDate={endDate}
          />
          <DatePicker
            {...tradeDateInputProps}
            label={translations.calendars.common.tradeDate}
            inputRef={tradeDateRef}
            onChange={(date) => handleDateChange(date, 'tradeDate')}
            slotProps={{
              textField: {
                helperText: tradeDateError,
                error: Boolean(tradeDateError),
              },
            }}
            shouldDisableDate={shouldDisableDates}
            minDate={startDate}
            maxDate={endDate}
          />
        </Stack>
        <Stack direction="row" spacing={1}>
          <DatePicker
            {...issueDateInputProps}
            label={translations.calendars.common.issueDate}
            inputRef={issueDateRef}
            onChange={(date) => handleDateChange(date, 'issueDate')}
            slotProps={{
              textField: {
                helperText: issueDateError,
                error: Boolean(issueDateError),
              },
            }}
            shouldDisableDate={shouldDisableDates}
            minDate={startDate}
            maxDate={endDate}
            sx={{ flex: 1 }}
          />
          <Stack direction="row" spacing={1} alignItems="center" sx={{ flex: 1 }}>
            <Switch {...enableNowInputProps} defaultChecked name="enableNow" inputRef={enableNowRef} />
            <LocalizedInputLabel>{translations.calendars.createCalendar.enableNow}</LocalizedInputLabel>
          </Stack>
        </Stack>
        <SettingsSection />
      </Stack>
    </form>
  );
};
