import { ChangeEvent, ReactElement } from 'react';

import { DEFAULT_LOCALE_REGION } from '@halo-common/constants';
import { useLegacyRouter } from '@halo-common/providers';
import { translations } from '@halo-common/translations';
import { formatLocale } from '@halo-common/utils';
import { USER_LOCAL_KEY, useUserInfoQuery } from '@halo-data-sources/queries';
import {
  LocalizedMenuItem,
  LocalizedTextField,
  LocalizedTextFieldProps,
  useCombinedStyling,
} from '@halodomination/halo-fe-common';
import { Skeleton } from '@mui/material';
import { useTX } from '@transifex/react';

const formControlSx = {
  height: 40,
  width: 220,
};

export type LocaleDropdownPropTypes = LocalizedTextFieldProps;

export const LocaleDropdown = ({
  variant = 'standard',
  sx,
  ...props
}: LocaleDropdownPropTypes): ReactElement | null => {
  const tx = useTX();

  const legacyRouter = useLegacyRouter();

  const { data: user, isPending } = useUserInfoQuery();

  const combinedSx = useCombinedStyling(formControlSx, sx);

  if (isPending) return <Skeleton sx={combinedSx} height={40} width={220} />;

  const urlParams = new URLSearchParams(window.location.search);
  const urlLocale = urlParams.get('locale') ?? window.localStorage.getItem(USER_LOCAL_KEY) ?? DEFAULT_LOCALE_REGION;

  const availableLocales = user?.whiteLabel.availableLocales ?? [];
  const hasLocales = availableLocales?.length > 1;

  const handleLocaleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const locale = ev.target.value;
    const query = new URLSearchParams({ locale });
    legacyRouter.combinedQueryParams(query);
    window.localStorage.setItem(USER_LOCAL_KEY, locale);
    void tx.setCurrentLocale(locale);
  };

  const menuItems = availableLocales?.map((locales) => {
    const formattedLocale = formatLocale(locales, urlLocale);

    return (
      <LocalizedMenuItem key={locales} value={locales}>
        {formattedLocale}
      </LocalizedMenuItem>
    );
  });

  return hasLocales ? (
    <LocalizedTextField
      fullWidth
      select
      variant={variant}
      id="locale-dropdown"
      value={urlLocale}
      onChange={handleLocaleChange}
      label={translations.common.language}
      sx={combinedSx}
      {...props}
    >
      {menuItems}
    </LocalizedTextField>
  ) : null;
};
