import { ReactElement } from 'react';

import { modalAtom } from '@halo-atoms/common';
import { selectedWatchlistNoteProductAtom } from '@halo-atoms/watchlists';
import { ProductCard, ProductCardRibbon } from '@halo-common/components';
import { ProductTypeEnum } from '@halo-common/enums';
import type { NoteflixNoteModel } from '@halo-common/models';
import { Stack } from '@mui/material';
import { useSetAtom } from 'jotai';

export type NoteflixNoteCardProps = {
  note: NoteflixNoteModel;
  enableCategoryQuotes: boolean;
  hasRibbonNotes: boolean;
};

export const NoteflixNoteCard = ({
  note,
  enableCategoryQuotes,
  hasRibbonNotes,
}: NoteflixNoteCardProps): ReactElement => {
  const overrides = enableCategoryQuotes ? { quote: note.quote } : undefined;

  const setModalMap = useSetAtom(modalAtom);
  const setSelectedWatchlistNoteProductAtom = useSetAtom(selectedWatchlistNoteProductAtom);

  const onClick = () => {
    setModalMap({ pdm: true });
    setSelectedWatchlistNoteProductAtom(note);
  };

  const fallback = hasRibbonNotes ? <div /> : null;

  const ribbon = note.ribbon ? (
    <ProductCardRibbon
      createSimilarClassName="wm-dashboard-noteflix-createsimilar"
      startAuctionClassName="wm-noteflix-lifecycle-startauction"
      id={note.id}
    >
      {note.ribbon}
    </ProductCardRibbon>
  ) : (
    fallback
  );

  return (
    <Stack sx={{ mx: 2, width: 360 }} direction="column" spacing={2} key={note.id}>
      <ProductCard type={ProductTypeEnum.note} product={note} onClick={onClick} overrides={overrides} />
      {ribbon}
    </Stack>
  );
};
