import { useWhiteLabelRole } from '@halo-common/hooks';
import { OrderTicketReceiptModel } from '@halo-common/models';
import { BulkAllocationError } from '@halo-data-sources/errors';
import { OrderTicketSubmissionSwitchPayload } from '@halo-data-sources/switches';
import { UseMutationOptions } from '@tanstack/react-query';

import { useJanneySubmissionHandlers } from './useJanneySubmissionHandlers';
import { usePershingSubmissionHandlers } from './usePershingSubmissionHandlers';

export type OrderTicketSubmissionSwitchHandlerResult = Pick<
  UseMutationOptions<OrderTicketReceiptModel | null, BulkAllocationError, OrderTicketSubmissionSwitchPayload>,
  'onMutate' | 'onSuccess' | 'onError'
>;

export const useOrderTicketSubmissionSwitchHandlers = (): OrderTicketSubmissionSwitchHandlerResult => {
  const role = useWhiteLabelRole();

  const janneySubmissionHandlers = useJanneySubmissionHandlers();
  const pershingSubmissionHandlers = usePershingSubmissionHandlers();

  switch (role) {
    case 'pershing':
    case 'pershingDev':
      return pershingSubmissionHandlers;

    default:
      return janneySubmissionHandlers;
  }
};
