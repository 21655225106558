import { ReactElement, SyntheticEvent } from 'react';

import { DEFAULT_LOCALE_REGION } from '@halo-common/constants';
import { UserCountryModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { getCountryNameFormat } from '@halo-common/utils';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { RegisterFormSchema } from '@halo-modules/app/onboarding';
import { LocalizedTextField } from '@halodomination/halo-fe-common';
import { Autocomplete } from '@mui/material';
import { useRouter } from 'next/router';
import { useController, useFormContext } from 'react-hook-form';

export const CountrySelect = (): ReactElement | null => {
  const router = useRouter();

  const { data: user } = useUserInfoQuery();

  const urlLocale = (router.query.locale ?? DEFAULT_LOCALE_REGION) as string;

  const countries = user?.geographicSettingSet?.countries ?? [];

  const {
    control,
    setValue,
    resetField,
    formState: { errors },
  } = useFormContext<RegisterFormSchema>();

  const {
    field: { value, onChange, ...countryField },
  } = useController({ name: 'country', control });

  const countryValue = countries.find((country) => country.id === value);

  const selectHandler = (_: SyntheticEvent, data: string | UserCountryModel | null) => {
    const selectedValue = typeof data === 'string' ? data : data?.isoCode;
    const selectedCountry = countries.find((country) => country.isoCode === selectedValue);
    const regions = selectedCountry?.regions ?? [];

    if (regions.length === 1) setValue('region', regions[0].id);
    else resetField('region', { defaultValue: undefined });

    onChange(selectedCountry?.id);
  };

  const getOptionLabel = (option: UserCountryModel) => getCountryNameFormat(option.isoCode, urlLocale);
  const getOptionEquality = (option: UserCountryModel, val: UserCountryModel) => option.id === val.id;

  return (
    <Autocomplete
      {...countryField}
      openOnFocus
      getOptionLabel={getOptionLabel}
      value={countryValue}
      options={countries}
      onChange={selectHandler}
      isOptionEqualToValue={getOptionEquality}
      renderInput={(params) => (
        <LocalizedTextField
          {...params}
          size="large"
          label={translations.common.country}
          fullWidth
          helperText={errors?.country?.message}
          error={Boolean(errors?.country)}
        />
      )}
    />
  );
};
