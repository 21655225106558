import React, { ReactElement, useMemo } from 'react';

import { createCalendarModalAtom } from '@halo-atoms/createCalendar';
import { CalendarProductCard } from '@halo-common/components';
import { IssuerModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { mapNoteToCalendarPreviewModel } from '@halo-data-sources/mappers';
import { useIssuersQuery, useNoteQuery } from '@halo-data-sources/queries';
import { LocalizedButton, Modal } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';
import { useAtom } from 'jotai';

export const AdminCreateCalendarPreviewModal = (): ReactElement => {
  const [createCalendarModal, setCreateCalendarModal] = useAtom(createCalendarModalAtom);
  const { noteId, displayName, issuerId, cusip, category, closeDate, quote, previewModalOpen } = createCalendarModal;
  const { data: note, isLoading: loadingNote } = useNoteQuery(noteId);
  const { data: issuers, isLoading: loadingIssuer } = useIssuersQuery(true);

  const loading = loadingNote || loadingIssuer;
  const issuer = issuers?.find((issuer: IssuerModel) => issuer.id === issuerId);

  const calendar = useMemo(() => {
    if (!note || !displayName || !issuer || !cusip || !category || !closeDate || !quote) return {};
    return mapNoteToCalendarPreviewModel(note, displayName, issuer, cusip, category, closeDate, quote);
  }, [note, displayName, issuer, cusip, category, closeDate, quote]);

  const handleClose = () => setCreateCalendarModal({ previewModalOpen: false });

  const footer = (
    <Stack direction="row" justifyContent="flex-end">
      <LocalizedButton variant="contained" color="primary" onClick={handleClose}>
        {translations.common.done}
      </LocalizedButton>
    </Stack>
  );

  return (
    <Modal
      title={translations.calendars.createCalendar.previewTitle}
      size="large"
      open={previewModalOpen}
      footer={footer}
      onClose={handleClose}
    >
      <CalendarProductCard calendar={calendar} loading={loading} />
    </Modal>
  );
};
