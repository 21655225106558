import { ReactElement } from 'react';

import { HOUR_MINUTE_SECONDS_TIME_ZONE_FORMAT, MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { translations } from '@halo-common/translations';
import { LocalizedAlert } from '@halodomination/halo-fe-common';

export type ExecutionBulkOrderAlertProps = {
  review?: boolean;
  date?: string | null;
};

export const ExecutionBulkOrderAlert = ({ date, review }: ExecutionBulkOrderAlertProps): ReactElement | null => {
  const configureDateTime = useWhiteLabelDateConfiguration();

  const dateTime = configureDateTime(date);

  if (review || !dateTime) return null;

  const dateText = dateTime.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);
  const timeText = dateTime.toFormat(HOUR_MINUTE_SECONDS_TIME_ZONE_FORMAT);

  return (
    <LocalizedAlert variant="filled" severity="success" dynamicContent={{ dateText, timeText }}>
      {translations.orderTicket.submissionAlertMessage}
    </LocalizedAlert>
  );
};
