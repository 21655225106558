import { ReactElement } from 'react';

import type { FieldRenderValues, FormBuilderField } from '@halo-common/components';
import { renderAutocomplete, renderSelectInput } from '@halo-common/components';
import { AssetIdentifierEnum, NoteTypeEnum } from '@halo-common/enums';
import { translations } from '@halo-common/translations';
import { isFileOrFromS3 } from '@halo-common/utils';
import { useTermsheetRepoFiltersQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import { CurrencyInput } from '@halodomination/halo-fe-common';
import { DateTime } from 'luxon';
import { useWatch } from 'react-hook-form';
import * as yup from 'yup';

const requiredText = translations.messages.requiredField;
const fileRequiredText = translations.messages.youMustSelectAFile;
const invalidDateText = translations.messages.invalidDate;

const IssuerSelect = (props: FieldRenderValues) => {
  const { data: user } = useUserInfoQuery();
  const issuers = user?.whiteLabel.issuers ?? [];
  const options = issuers.map((issuer) => ({ label: issuer.name, value: issuer.id }));
  return renderSelectInput({
    ...props,
    options,
  });
};

export const getIdentifierColumn = (identifier: AssetIdentifierEnum, required = true): FormBuilderField => {
  const validation = required ? yup.string().required(requiredText) : yup.string().nullable();
  return {
    label: identifier.toUpperCase(),
    name: `asset_identifiers.${identifier.toLowerCase()}`,
    type: 'text',
    validation,
    required,
  };
};

export const renderNotionalInput = ({
  field: { onChange, ref, value },
  fieldState,
  props,
}: FieldRenderValues): ReactElement => {
  const { data: user } = useUserInfoQuery();
  const currencies = user?.whiteLabel.currencies ?? [];
  const currencyId = useWatch<{ currencyId: number }>({ name: 'currencyId' });

  const currency = currencies.find((currency) => currency.id === currencyId);

  return (
    <CurrencyInput
      onChange={(value) => {
        const parsedValue = value?.toString() ?? '';
        onChange(parsedValue);
      }}
      value={value}
      inputRef={ref}
      size="large"
      fullWidth
      valueType="string"
      allowNegative={false}
      error={!!fieldState.error}
      helperText={fieldState.error?.message}
      label={props?.label}
      required={props?.required}
      CurrencyOptions={{ currency: currency?.code }}
      symbolOverride={currency?.symbol}
    />
  );
};

const CurrencySelect = (props: FieldRenderValues) => {
  const { data: user } = useUserInfoQuery();
  const currencies = user?.whiteLabel.currencies ?? [];
  const options = currencies.map((currency) => ({ label: currency.code, value: currency.id }));
  return renderSelectInput({
    ...props,
    options,
  });
};

export const renderCurrencySelect = (props: FieldRenderValues): ReactElement => <CurrencySelect {...props} />;

const ProductTypeSelect = (props: FieldRenderValues) => {
  const { data } = useTermsheetRepoFiltersQuery();
  const productTypes = data?.termsheetsProductType ?? [];
  const options = productTypes as string[];
  return renderAutocomplete({
    ...props,
    options,
  });
};

export const renderProductTypeSelect = (props: FieldRenderValues): ReactElement => <ProductTypeSelect {...props} />;

const requiredFileValidation = yup
  .mixed()
  .required(fileRequiredText)
  .test('is-file-or-object', fileRequiredText, (value) => isFileOrFromS3(value));

const requiredDateValidation = yup
  .mixed()
  .required(requiredText)
  .test('required', requiredText, (value) => !!value)
  .test('is-valid-date', invalidDateText, (value) => value instanceof DateTime && value.isValid);

export const fieldsMap: Record<string, FormBuilderField> = {
  document: {
    name: 'document',
    type: 'file',
    validation: requiredFileValidation,
    required: true,
  },
  note_type: {
    label: translations.documents.termsheets.noteType,
    name: 'noteType',
    type: 'select',
    validation: yup.string().required(requiredText),
    options: Object.entries(NoteTypeEnum).map(([label, type]) => ({ label, value: type })),
    required: true,
  },
  product_type: {
    label: translations.documents.termsheets.productType,
    name: 'productType',
    type: 'autocomplete',
    validation: yup.string().required(requiredText),
    render: renderProductTypeSelect,
    required: true,
  },
  bond_code: {
    label: translations.documents.termsheets.bondCode,
    name: 'bondCode',
    type: 'text',
    validation: yup.string().required(requiredText),
    required: true,
  },
  notional: {
    label: translations.documents.termsheets.issuanceSize,
    name: 'notional',
    type: 'text',
    validation: yup.string().required(requiredText),
    required: true,
    render: renderNotionalInput,
  },
  currency_id: {
    label: translations.documents.termsheets.currency,
    name: 'currencyId',
    type: 'select',
    validation: yup.string().required(requiredText),
    required: true,
    render: renderCurrencySelect,
  },
  issue_date: {
    label: translations.documents.termsheets.issueDate,
    name: 'issueDate',
    type: 'date',
    validation: requiredDateValidation,
    required: true,
  },
  maturity_date: {
    label: translations.documents.termsheets.maturityDate,
    name: 'maturityDate',
    type: 'date',
    validation: requiredDateValidation,
    required: true,
  },
  description: {
    label: translations.documents.termsheets.description,
    name: 'description',
    type: 'text',
    multiline: true,
    validation: yup.string().nullable(),
  },
  issuer_id: {
    label: translations.documents.termsheets.issuer,
    name: 'issuerId',
    type: 'select',
    validation: yup.string().required(requiredText),
    required: true,
    render: (props: FieldRenderValues) => <IssuerSelect {...props} />,
  },
};
