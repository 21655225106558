import { ReactElement } from 'react';

import { TileCard, TileCardIconProps, TileCardLocalization } from '@halo-common/components';
import { Stack, StackProps } from '@halodomination/halo-fe-common';

export type TileGridTile<T = unknown> = {
  description?: string;
  icon: TileCardIconProps['iconName'];
  name: string;
  position?: number;
  value: T;
  className?: string;
  Localization?: TileCardLocalization;
  disabled?: boolean;
  tooltip?: string;
};

export type TileGridProps<T = unknown> = {
  tiles: Array<TileGridTile<T>>;
  loading: boolean;
  onClick: (tile: TileGridTile<T>) => void;
  StackProps?: Partial<StackProps>;
};

export const TileGrid = <T extends unknown>({
  tiles,
  loading,
  onClick,
  StackProps,
}: TileGridProps<T>): ReactElement => {
  const loadedTiles = tiles.map((tile) => (
    <TileCard
      key={tile.name}
      iconName={tile.icon}
      name={tile.name}
      description={tile.description}
      onClick={() => onClick(tile)}
      className={tile.className}
      Localization={tile.Localization}
      disabled={tile.disabled}
      tooltip={tile.tooltip}
    />
  ));

  const skeletonTiles = [...Array(9)].map((_, i) => <TileCard loading key={i} />);

  return (
    <Stack direction="row" wrap="wrap" spacing={3} xs={12} md={6} lg={4} {...StackProps}>
      {loading ? skeletonTiles : loadedTiles}
    </Stack>
  );
};
