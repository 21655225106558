import { ReactElement } from 'react';

import {
  CalendarProductCardActions,
  CalendarProductCardActionsProps,
  CalendarProductCardDetailRow,
  CalendarProductCardIssuer,
  ProductFeatureList,
  ProductNoteTypeChip,
  UnderlyingGlyphs,
} from '@halo-common/components';
import { useProductProgress } from '@halo-common/hooks';
import { CalendarModel, CalendarPreviewModel } from '@halo-common/models';
import { ApiCalendarPriceDisplayEnum } from '@halo-data-sources/enums';
import { LocalizedTypography, ProgressBar, ProgressBarEnum, useCombinedStyling } from '@halodomination/halo-fe-common';
import { Box, Divider, Paper, PaperProps, Stack, Typography } from '@mui/material';

const calendarCardSx = {
  padding: 2,
  marginBottom: 2,
  border: '1px solid',
  borderColor: 'grey.300',
};

const calendarNameSx = {
  marginBottom: 2,
  wordWrap: 'break-word',
};

const calendarCardDetailSx = {
  width: '100%',
};

const progressBarSx = {
  width: 250,
};

export type CalendarProductCardProps = PaperProps & {
  calendar: CalendarModel | CalendarPreviewModel;
  actions?: CalendarProductCardActionsProps['actions'];
  loading?: boolean;
  termsheetId?: number;
};

export const CalendarProductCard = ({
  calendar,
  actions,
  loading = false,
  termsheetId,
  sx,
  elevation = 0,
  ...props
}: CalendarProductCardProps): ReactElement => {
  const { note, issuer, category, displayName, price: calendarPrice, priceDisplay = null } = calendar;

  const features = note?.features ?? [];
  const type = note?.type;
  const tradables = note?.tradables;
  const currencyCode = note?.currencyCode;
  const productType = note?.productType;
  const tradableWeights = note?.tradableWeights;

  const { percentFilled, variant, label, dynamicContent } = useProductProgress({ calendar });

  const isCommission = priceDisplay === ApiCalendarPriceDisplayEnum.COMMISSION;
  const notePrice = (isCommission && note?.price ? 100 - note.price : note?.price) ?? null;
  const price = calendarPrice ?? notePrice;

  const isUnderlyingsScrollable = tradables?.length ? tradables.length >= 4 : false;
  const isCalendarClosed = variant === ProgressBarEnum.DISABLED;

  const closedCalendarSx = isCalendarClosed ? { backgroundColor: 'grey.100' } : null;
  const calendarItemStyling = useCombinedStyling(calendarCardSx, closedCalendarSx, sx);

  return (
    <Paper sx={calendarItemStyling} elevation={elevation} {...props}>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Stack sx={calendarCardDetailSx} direction="column" spacing={1} justifyContent="space-between">
          <Stack direction="row" spacing={0.5} alignItems="center">
            <ProductFeatureList features={features} type={type} subtype={productType}>
              <ProductNoteTypeChip type={type} subtype={productType} />
            </ProductFeatureList>
            <Typography color="textSecondary" variant="caption">
              {currencyCode}
            </Typography>
          </Stack>
          <Stack direction={{ md: 'row', xs: 'column' }} spacing={2}>
            <Box sx={{ flex: 1 }}>
              <UnderlyingGlyphs
                underlyings={tradables}
                scrollable={isUnderlyingsScrollable}
                weights={tradableWeights}
              />
            </Box>
            <Stack sx={{ flex: 3 }} direction="column" justifyContent="center" spacing={1}>
              <LocalizedTypography sx={calendarNameSx} marginBottom={2} variant="subtitle1">
                {displayName}
              </LocalizedTypography>
              <CalendarProductCardDetailRow
                calendar={calendar}
                category={category}
                price={price}
                priceDisplay={priceDisplay}
              />
            </Stack>
          </Stack>
          <Stack
            sx={{ pt: { md: 0, xs: 1 } }}
            spacing={{ md: 0, xs: 2 }}
            direction={{ md: 'row', xs: 'column' }}
            alignItems={{ md: 'center' }}
            justifyContent={{ md: 'space-between' }}
          >
            <CalendarProductCardIssuer category={category} issuer={issuer} loading={loading} />
            <ProgressBar
              variant={variant}
              label={label}
              percentFilled={percentFilled}
              sx={progressBarSx}
              dynamicContent={dynamicContent}
            />
          </Stack>
        </Stack>
        <CalendarProductCardActions calendar={calendar} termsheetId={termsheetId} actions={actions} />
      </Stack>
    </Paper>
  );
};
