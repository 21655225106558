export enum OnboardingFamiliarityEnum {
  new = 'new',
  experienced = 'experienced',
  intermediate = 'intermediate',
}

export type OnboardingFamiliarity = keyof typeof OnboardingFamiliarityEnum;

export enum OnboardingGoalEnum {
  newInvestments = 'newInvestments',
  manageNotes = 'manageNotes',
  investmentChoices = 'investmentChoices',
  analyzeNotePerformance = 'analyzeNotePerformance',
}

export type OnboardingGoal = keyof typeof OnboardingGoalEnum;

export enum OnboardingCustodyEnum {
  fidelity = 'fidelity',
  pershing = 'pershing',
  schwab = 'schwab',
  td = 'td',
  other = 'other',
}

export type OnboardingCustody = keyof typeof OnboardingCustodyEnum;
