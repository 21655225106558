import { ProtectionSelection } from '@halo-common/components';
import { Chipography } from '@halo-common/components/Chipography';
import { NoteCreateProtectionTypeEnum } from '@halo-common/enums';

const commonProtections: ProtectionSelection[] = [
  { value: 30, type: NoteCreateProtectionTypeEnum.Hard },
  { value: 30, type: NoteCreateProtectionTypeEnum.Soft },
  { value: 20, type: NoteCreateProtectionTypeEnum.Hard },
  { value: 20, type: NoteCreateProtectionTypeEnum.Soft },
];

type PrincipalProtectionCommonChipsProps = {
  onSelectProtection: (input: ProtectionSelection) => void;
};

export const PrincipalProtectionCommonChips = ({ onSelectProtection }: PrincipalProtectionCommonChipsProps) => {
  return commonProtections.map((protection) => (
    <Chipography
      size="small"
      key={protection.value.toString() + protection.type}
      label={`${protection.value}% ${protection.type}`}
      variant="outlined"
      sx={{
        bgcolor: 'common.white',
        color: 'primary.main',
        borderColor: 'primary.main',
        m: 0.5,
        cursor: 'pointer',
      }}
      onClick={() => onSelectProtection(protection)}
    />
  ));
};
