import { KeyboardEvent } from 'react';

type HandleRestrictedKeyDownArgs = {
  event: KeyboardEvent<HTMLDivElement>;
  allowedKeys?: string[];
  additionalValidation?: (event: KeyboardEvent) => boolean | undefined;
  invalidKeyRegex?: RegExp;
};

export const handleRestrictedKeyDown = ({
  event,
  allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'],
  additionalValidation,
  invalidKeyRegex = /[^0-9/.-]/,
}: HandleRestrictedKeyDownArgs): void => {
  const isAllowedKey = allowedKeys.includes(event.key);
  const isCtrlOrCmdA = event.key === 'a' && (event.ctrlKey || event.metaKey);
  const isCtrlOrCmdC = event.key === 'c' && (event.ctrlKey || event.metaKey);
  const isCtrlOrCmdV = event.key === 'v' && (event.ctrlKey || event.metaKey);
  const isInvalidKey = invalidKeyRegex.test(event.key);

  // Prioritize additional validation to block even valid keys
  const shouldPreventAction =
    additionalValidation?.(event) === false ||
    (!(isAllowedKey || isCtrlOrCmdA || isCtrlOrCmdC || isCtrlOrCmdV) && isInvalidKey);

  if (shouldPreventAction) {
    event.preventDefault();
  }
};
