import { BaseSyntheticEvent, ReactElement, ReactNode, useState } from 'react';

import { calendarSelectedAtom } from '@halo-atoms/calendars';
import { PENDING_ALLOCATION_STATUSES } from '@halo-common/constants';
import { CalendarOrderStatusEnum } from '@halo-common/enums';
import { useProductProgress } from '@halo-common/hooks';
import { useCalendarAllocationsQuery } from '@halo-data-sources/queries';
import { TabbedModalLayout } from '@halodomination/halo-fe-common';
import { useAtomValue } from 'jotai';

import { OrderTicketFormFooter } from '../OrderTicketFormFooter';

export type OrderTicketFormLayoutProps = {
  children: ReactNode;
  loading: boolean;
  onSubmit: (e?: BaseSyntheticEvent) => Promise<void>;
  onClose: () => void;
};

export const OrderTicketFormLayout = ({
  children,
  loading,
  onSubmit,
  onClose,
}: OrderTicketFormLayoutProps): ReactElement => {
  const [tab, setTab] = useState(0);

  const selectedCalendar = useAtomValue(calendarSelectedAtom);

  const { data: allocations = [] } = useCalendarAllocationsQuery(selectedCalendar?.id);
  const { status } = useProductProgress({ calendar: selectedCalendar });

  const allocationCount = allocations.reduce(
    (count, allocation) => (PENDING_ALLOCATION_STATUSES.includes(allocation.status) ? count + 1 : count),
    0,
  );

  const isClosed = status === CalendarOrderStatusEnum.Closed;
  const hideForm = isClosed || selectedCalendar?.paused;
  const newIOI = !hideForm ? [{ label: 'New IOI' }] : [];
  const tabs = [...newIOI, { label: 'Submitted IOI', count: allocationCount }];
  const hideReviewButton = tab === 1;

  const cusip = selectedCalendar?.cusip ?? '';
  const title = `Submit Indication ${cusip}`;
  const subtitle = selectedCalendar?.displayName;

  return (
    <TabbedModalLayout
      tabs={tabs}
      title={title}
      subtitle={subtitle}
      onClose={onClose}
      TabsProps={{
        value: tab,
        onChange: (index: number) => setTab(index),
      }}
      footer={
        <OrderTicketFormFooter
          onSubmit={onSubmit}
          onCancel={onClose}
          loading={loading}
          hideReviewButton={hideReviewButton}
        />
      }
    >
      {children}
    </TabbedModalLayout>
  );
};
