import { useWhiteLabelRole } from '@halo-common/hooks';
import { OrderTicketReceiptModel } from '@halo-common/models';
import { BulkAllocationError } from '@halo-data-sources/errors';
import { OrderTicketValidationSwitchPayload } from '@halo-data-sources/switches';
import { UseMutationOptions } from '@tanstack/react-query';

import { useJanneyValidationHandlers } from './useJanneyValidationHandlers';
import { usePershingValidationHandlers } from './usePershingValidationHandlers';

export type OrderTicketValidationSwitchHandlerResult = Pick<
  UseMutationOptions<OrderTicketReceiptModel | null, BulkAllocationError, OrderTicketValidationSwitchPayload>,
  'onMutate' | 'onSuccess' | 'onError'
>;

export const useOrderTicketValidationSwitchHandlers = (): OrderTicketValidationSwitchHandlerResult => {
  const role = useWhiteLabelRole();

  const janneyValidationHandlers = useJanneyValidationHandlers();
  const pershingValidationHandlers = usePershingValidationHandlers();

  switch (role) {
    case 'pershing':
    case 'pershingDev':
      return pershingValidationHandlers;

    default:
      return janneyValidationHandlers;
  }
};
