import { ReactElement } from 'react';

import { bulkOrderTicketManagerAtom, bulkOrderTicketReceiptAtom } from '@halo-atoms/orderTicket';
import { useWebSocketContext } from '@halo-common/providers';
import { GridColDef, HaloDataGrid, HaloDataGridProps, Iconography, Stack } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Skeleton, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';

export type OrderReviewAllocationListSectionProps = {
  loading?: boolean;
};

export const OrderReviewAllocationListSection = ({
  loading = false,
}: OrderReviewAllocationListSectionProps): ReactElement => {
  const {
    submittedAllocations,
    invalidAllocations,
    fixSubmissionErrors: errors,
  } = useAtomValue(bulkOrderTicketManagerAtom);

  const { events } = useWebSocketContext();
  const { bulkOrders } = useAtomValue(bulkOrderTicketReceiptAtom);

  const rows = bulkOrders.map(({ order, account }, index) => ({
    id: order.id,
    position: index + 1,
    accountIdentifier: account.accountId,
    accountId: account.id,
    quantity: order.shares,
  }));

  const tableSx = {
    '& .fix-error': {
      backgroundColor: (theme: HaloTheme) => theme.palette.error.background,
      overflowWrap: 'break-word',
    },
  };

  const commonCellProps: Partial<GridColDef> = {
    align: 'left',
    editable: false,
    resizable: true,
    disableColumnMenu: true,
    sortable: true,
  };

  const columns: HaloDataGridProps['columns'] = [
    {
      ...commonCellProps,
      field: 'index',
      align: 'center',
      width: 10,
      headerName: '',
      renderCell: ({ row }) => {
        const accountId = row.accountId;
        const error = errors[accountId];
        const isPending = events.fix.contains(accountId);
        const isSubmitted = accountId ? submittedAllocations.includes(accountId) : false;
        const isFailed = accountId ? invalidAllocations.includes(accountId) : false;

        if (error || isFailed) return <Iconography iconName="exclamation-circle" color="error.main" />;
        else if (isSubmitted) return <Iconography iconName="check" color="success.main" />;
        else if (isPending) return <Iconography iconName="spinner" spin />;
        return row.position;
      },
    },
    {
      ...commonCellProps,
      field: 'accountIdentifier',
      flex: 1,
      headerName: 'Account',
    },
    {
      ...commonCellProps,
      field: 'quantity',
      flex: 1,
      headerName: 'amount',
    },
    {
      ...commonCellProps,
      field: 'error',
      flex: 2,
      headerName: 'Error',
      valueGetter: (_, row) => {
        const error = errors[row.accountId];
        return error ?? null;
      },
    },
  ];

  const errorCount = Object.entries(errors).length;

  if (!errorCount) columns.pop();

  if (loading) {
    return (
      <Stack direction="column" spacing={1}>
        <Skeleton width={80} height={24} />
        <Skeleton width={80} height={24} />
      </Stack>
    );
  }

  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="body1" color="textSecondary">
        Allocation(s)
      </Typography>
      <HaloDataGrid
        sx={tableSx}
        sortModel={[{ field: 'error', sort: 'desc' }]}
        height="auto"
        rows={rows}
        columns={columns}
        density="compact"
        hideFooterSelectedRowCount
        pagination
        pageSizeOptions={[10, 25, 50, 100]}
        getRowClassName={({ row }) => {
          if (errors[row.account]) return 'fix-error';
          return '';
        }}
      />
    </Stack>
  );
};
