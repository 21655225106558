import { useEffect, forwardRef } from 'react';

import { AuraNoteForm } from '@halo-common/forms';
import { useQuoteSelector } from '@halo-common/hooks';
import { AuraNoteModal, NoteModel } from '@halo-common/models';
import { addAuraNoteSchema } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { Iconography, LocalizedButton, Modal } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

const contentStyling = {
  backgroundColor: 'background.paper',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const ellipsisSx = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '800px',
};

type CategoryMap = { [key in 'fixed' | 'equity' | 'cash' | 'alternative']: string };

const auraPayloadCategories: CategoryMap = {
  fixed: 'Fixed Income',
  equity: 'Equity',
  cash: 'Cash',
  alternative: 'Alternative',
};

export type AddAuraNoteModalProps = {
  open: boolean;
  onClose: () => void;
  product?: NoteModel | null;
};

export const AddAuraNoteModal = forwardRef<HTMLIFrameElement, AddAuraNoteModalProps>(
  ({ open, product, onClose }, ref) => {
    const selectedQuote = useQuoteSelector(product?.id);

    const formMethods = useForm<AuraNoteModal>({
      mode: 'onBlur',
      reValidateMode: 'onSubmit',
      resolver: yupResolver(addAuraNoteSchema),
      defaultValues: {
        category: 'fixed',
      },
    });

    const {
      handleSubmit,
      formState: { isSubmitting, isValid },
      reset,
    } = formMethods;

    const onSubmit = (data: AuraNoteModal) => {
      const updatedNote = {
        note_id: product?.id,
        note_display_name: product?.name,
        quote_value: data?.quote,
        quote_parameter: product?.parameter,
        allocation_percent: data?.allocation,
        allocation_class: auraPayloadCategories[data?.category as 'fixed' | 'equity' | 'cash' | 'alternative'],
      };

      if (ref && 'current' in ref && ref.current?.contentWindow) {
        ref.current.contentWindow.postMessage(
          JSON.stringify({
            add_note: updatedNote,
          }),
          '*',
        );
      }

      handleBack();
    };

    const handleBack = () => {
      reset();
      onClose();
    };

    const disabled = isSubmitting || !isValid;

    const buttonStartIcon = isSubmitting && <CircularProgress size="1rem" />;

    const subTitle = <Typography sx={ellipsisSx}>{product?.name?.replaceAll('_', ' ')}</Typography>;

    const footer = (
      <Stack direction="row" justifyContent="space-between">
        <LocalizedButton
          onClick={handleBack}
          variant="text"
          color="primary"
          startIcon={<Iconography iconName="arrow-left" />}
        >
          {translations.common.cancel}
        </LocalizedButton>
        <LocalizedButton
          startIcon={buttonStartIcon}
          disabled={disabled}
          color="primary"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          {translations.aura.common.finishLabel}
        </LocalizedButton>
      </Stack>
    );

    const modalContent = (
      <FormProvider {...formMethods}>
        <AuraNoteForm />
      </FormProvider>
    );

    useEffect(() => {
      reset({
        category: 'fixed',
        quote: selectedQuote?.value,
      });
    }, [selectedQuote]);

    return (
      <Modal
        onClose={onClose}
        open={open}
        ContentProps={{ sx: contentStyling }}
        title={translations.aura.common.modalTitle}
        subtitle={subTitle}
        footer={footer}
        size="medium"
      >
        {modalContent}
      </Modal>
    );
  },
);

AddAuraNoteModal.displayName = 'AddAuraNoteModal';
