import { ReactElement } from 'react';

import { OrderTicketFormModelFields } from '@halo-common/schemas';
import { TextField } from '@mui/material';
import { useController } from 'react-hook-form';

type TTORepCodeTypeAheadFieldNames = keyof OrderTicketFormModelFields | `allocations.${number}.ttoRepCode`;

export type TTORepCodeTypeAheadProps = {
  name: TTORepCodeTypeAheadFieldNames;
};

export const TTORepCodeTypeAhead = ({ name }: TTORepCodeTypeAheadProps): ReactElement => {
  const {
    fieldState: { error },
    field: { ref, value, ...field },
  } = useController<OrderTicketFormModelFields, TTORepCodeTypeAheadFieldNames>({ name });

  const ttoRepCodeError = error?.message;
  const parsedValue = (value as string)?.toUpperCase();

  return (
    <TextField
      {...field}
      value={parsedValue}
      inputRef={ref}
      label="TTO Rep"
      size="large"
      placeholder="Optional"
      slotProps={{ inputLabel: { shrink: true } }}
      error={Boolean(ttoRepCodeError)}
      helperText={ttoRepCodeError}
    />
  );

  // TODO: Uncomment when rep code data is complete on BE
  // const endAdornmentSx = {
  //   display: 'flex',
  // };

  // const loadingIndicatorSx = {
  //   marginRight: 1,
  // };

  // const { onChange: onTTORepCodeChange, value: ttoRepCode } = ttoRepCodeMethods.field;

  // const { data: advisees = [], isPending } = useAdviseeSearchQuery();

  // const getOptionLabel = (option?: AdviseeSearchModel) => option?.name ?? '';

  // const handleChange = (_: unknown, option: AdviseeSearchModel | Array<AdviseeSearchModel> | null) => {
  //   onTTORepCodeChange(option);
  // };

  // const handleRenderOption = (props: HTMLAttributes<HTMLLIElement>, option: AdviseeSearchModel) => (
  //   <Box {...props} component="li" key={option.id}>
  //     <Typography>{option.name}</Typography>
  //   </Box>
  // );

  // const renderInput = (params: AutocompleteRenderInputParams) => {
  //   const textFieldInputLoadingIndicator = isPending ? <CircularProgress sx={loadingIndicatorSx} size={20} /> : null;

  //   const textFieldInputProps = {
  //     ...params.InputProps,
  //     endAdornment: (
  //       <Box sx={endAdornmentSx}>
  //         {textFieldInputLoadingIndicator}
  //         {params.InputProps.endAdornment}
  //       </Box>
  //     ),
  //   };

  //   return (
  //     <TextField
  //       {...params}
  //       label="TTO Rep (Optional)"
  //       size="large"
  //       sx={clientInputSx}
  //       slotProps={{ input: textFieldInputProps }}
  //     />
  //   );
  // };

  // return (
  //   <Autocomplete<AdviseeSearchModel, boolean, boolean, undefined>
  //     value={ttoRepCode}
  //     options={advisees}
  //     onChange={handleChange}
  //     renderInput={renderInput}
  //     getOptionLabel={getOptionLabel}
  //     renderOption={handleRenderOption}
  //   />
  // );
};
