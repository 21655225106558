import { ReactElement } from 'react';

import { AuctionStatusEnum, AuctionStatusKeyEnum } from '@halo-common/enums';
import { OrderHubOrderedColumnsModel } from '@halo-common/models';
import { useUserSettingMutation } from '@halo-data-sources/mutations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import {
  AuctionTable,
  AuctionTableLayout,
  OH_ORDERED_COLUMNS_KEY,
  OH_PINNED_COLUMNS_KEY,
  useAuctionTableData,
} from '@halo-modules/admin';
import { GridPinnedColumnFields } from '@mui/x-data-grid-pro';

import { OpsApprovalModal } from './OpsApprovalModal';

export const OpsApprovalTable = (): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();
  const { mutate: updateUserSetting } = useUserSettingMutation();
  const { data, gridRef, issuersVisible, handleDataSwitch } = useAuctionTableData(AuctionStatusEnum.OpsApproval);

  const orderHubPinnedColumns = userInfo?.settings?.orderHubPinnedColumns;
  const orderHubOrderedColumns = userInfo?.settings?.orderHubOrderedColumns;

  const selectOrderHubPinnedColumns = orderHubPinnedColumns?.[AuctionStatusKeyEnum.OpsApproval];
  const selectOrderHubOrderedColumns = orderHubOrderedColumns?.[AuctionStatusKeyEnum.OpsApproval];

  const handleColumnPin = (updatedGridPinnedColumns: GridPinnedColumnFields) => {
    updateUserSetting({
      key: OH_PINNED_COLUMNS_KEY,
      value: { ...orderHubPinnedColumns, inAuction: updatedGridPinnedColumns },
    });
  };

  const handleColumnReorder = (updatedColumnOrders: OrderHubOrderedColumnsModel) => {
    updateUserSetting({
      key: OH_ORDERED_COLUMNS_KEY,
      value: { ...orderHubOrderedColumns, inAuction: updatedColumnOrders },
    });
  };

  const description = `Orders sit here waiting to be pushed out to banks by Halo's Trade Desk.`;

  return (
    <AuctionTableLayout
      description={description}
      title="Ops-Approval"
      onIssuersToggle={handleDataSwitch}
      onExport={gridRef.current?.exportDataAsCsv}
    >
      <AuctionTable
        onColumnPin={handleColumnPin}
        onColumnReorder={handleColumnReorder}
        defaultPinnedColumns={selectOrderHubPinnedColumns}
        defaultOrderedColumns={selectOrderHubOrderedColumns}
        issuersVisible={issuersVisible}
        DataGridProps={{
          data,
          cta: {
            icon: 'check-double',
            label: 'Ops-Approve',
            color: 'orange',
          },
        }}
        gridRef={gridRef}
      />
      <OpsApprovalModal icon="check-double" />
    </AuctionTableLayout>
  );
};
