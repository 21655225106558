import { OrderBookCalendarModel, OrderBookCalendarsQueryFields, PaginationModel } from '@halo-common/models';
import { getCalendars, getOrderBookCalendars } from '@halo-data-sources/clients';
import { ApiComparisonOptionEnum } from '@halo-data-sources/enums';
import { ApiCalendarsMapper, ApiOrderBookMapper, OrderBookMapper, PaginationMapper } from '@halo-data-sources/mappers';
import { OrderBookCalendarQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryOptions, UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query';

export type OrderBookCalendarsQueryResult = {
  calendars?: Array<OrderBookCalendarModel>;
  pagination?: PaginationModel;
};

export type UseOrderBoolCalendarsQueryOptions = UseQueryOptions<OrderBookCalendarsQueryResult, Error>;
export type UseOrderBookCalendarsQueryResult = UseQueryResult<OrderBookCalendarsQueryResult, Error>;

const getOrderBookCalendarsFn = async (searchParams: OrderBookCalendarsQueryFields) => {
  const mappedParams = OrderBookMapper.toApiCalendarSearchParams(searchParams);

  const orderBookCalendarsResponse = await getOrderBookCalendars(mappedParams);

  const mappedOrderBookCalendars = ApiOrderBookMapper.toOrderBookCalendarsQueryResult(orderBookCalendarsResponse);

  const calendarIds = mappedOrderBookCalendars?.calendars?.map((calendar) => calendar.id) ?? [];

  if (!calendarIds.length) {
    return {
      calendars: [],
      pagination: PaginationMapper.toPaginationModel({
        ...orderBookCalendarsResponse.pagination,
        items_total_count: 0,
      }),
    };
  }

  const calendarsResponse = await getCalendars({
    comparisons: [
      {
        field: 'calendar_notes.id',
        value: calendarIds,
        op: ApiComparisonOptionEnum.IN,
      },
    ],
    page_length: calendarIds.length,
  });

  const mappedCalendars = calendarsResponse.calendars.map(ApiCalendarsMapper.toCalendarModel);

  mappedOrderBookCalendars.calendars?.forEach((calendar) => {
    const foundCalendar = mappedCalendars.find((cal) => cal.id === calendar.id);
    calendar.currencyCode = foundCalendar?.note?.currencyCode;
    calendar.currencySymbol = foundCalendar?.note?.currencySymbol;
  });

  return mappedOrderBookCalendars;
};

export const useOrderBookCalendarsQuery = (
  searchParams: OrderBookCalendarsQueryFields,
  options?: UseOrderBoolCalendarsQueryOptions,
): UseOrderBookCalendarsQueryResult =>
  useQuery<OrderBookCalendarsQueryResult, Error>({
    queryKey: OrderBookCalendarQueryKeyFactory.calendars(searchParams),
    queryFn: () => getOrderBookCalendarsFn(searchParams),
    placeholderData: keepPreviousData,
    ...options,
  });
