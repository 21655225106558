import { useEffect, useState } from 'react';

import { useLoadingDockUpload } from '@halo-common/hooks';
import { SubNavLayout } from '@halo-common/layouts';
import { CalendarModel } from '@halo-common/models';
import { useWebSocketContext } from '@halo-common/providers';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import {
  AdminCalendarStatusFilter,
  EditCalendarModal,
  LoadingDockPageHeader,
  LoadingDockStatusEnum,
  LoadingDockTableList,
  PershingAdminContextModel,
  PershingAdminProvider,
  PershingAdminSorting,
  UploadCalendarModal,
} from '@halo-modules/admin';
import { CalendarActions, CalendarSorting } from '@halo-stores/Calendar';
import {
  FetchLoadingDockCalendarsThunkAction,
  PershingActions,
  PershingSelectors,
  PershingStatusEnum,
} from '@halo-stores/Pershing';
import { PageComponent, PageMeta, Stack } from '@halodomination/halo-fe-common';
import { Container } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

const containerSx = { padding: 3 };

const LoadingDockPage: PageComponent = () => {
  const { events } = useWebSocketContext();
  const { reset: resetLoadingDockUpload } = useLoadingDockUpload(events?.toast);

  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [loaded, setLoaded] = useState(false);

  // TODO: Remove this
  const dispatch = useDispatch();
  const selectedCalendar = useSelector(PershingSelectors.selectCalendar);

  const { data: userInfo } = useUserInfoQuery();

  const calendarPageId = userInfo?.organization?.calendarPageId;
  const openEditCalendarModal = Boolean(selectedCalendar);

  const formMethods = useForm({
    defaultValues: {
      cusip: '',
      closingDate: null,
    },
  });

  const handleCloseEditCalendarModal = () => {
    dispatch(PershingActions.selectCalendar());
  };

  const handleToggleUploadCalendarModal = () => {
    setOpenUploadModal(!openUploadModal);
    resetLoadingDockUpload();
  };

  const handleEditCalendarModal = (calendar: CalendarModel) => {
    dispatch(PershingActions.selectCalendar({ calendar }));
  };

  const handlePagination = (
    filter: AdminCalendarStatusFilter,
    sorting: PershingAdminSorting,
    context: PershingAdminContextModel,
  ) => {
    const { queryParams, tableConfiguration } = context;
    const { pageMap, pageSizeMap } = tableConfiguration;

    const pageNumber = pageMap[filter];
    const pageSize = pageSizeMap[filter];

    const paginationParams =
      filter === LoadingDockStatusEnum.pending
        ? { ...queryParams, page: pageNumber, pagesize: pageSize }
        : { ...queryParams, page: pageNumber, pagesize: pageSize, status: filter };

    const action = { query: paginationParams, sorting };

    dispatch(PershingActions.fetchLoadingDockCalendars({ [filter]: action }));
  };

  const handleSorting = (
    filter: AdminCalendarStatusFilter,
    sorting: PershingAdminSorting,
    context: PershingAdminContextModel,
  ) => {
    const { statusFilter, queryParams, tableConfiguration } = context;
    const { pageMap, pageSizeMap } = tableConfiguration;

    const pageNumber = pageMap[filter];
    const pageSize = pageSizeMap[filter];

    const updateAvailable = filter === LoadingDockStatusEnum.available;

    const action: FetchLoadingDockCalendarsThunkAction = {};
    const parsedParams = { ...queryParams, page: pageNumber, pagesize: pageSize };

    switch (statusFilter) {
      case LoadingDockStatusEnum.active:
        action.available = updateAvailable ? { query: { ...parsedParams, status: filter }, sorting } : undefined;
        action.pending = !updateAvailable ? { query: { ...parsedParams, status: filter }, sorting } : undefined;
        break;
      case LoadingDockStatusEnum.pending:
        action.pending = { query: parsedParams, sorting };
        break;
      case LoadingDockStatusEnum.available:
        action.available = { query: { ...parsedParams, status: filter }, sorting };
        break;
      default:
        action.archived = { query: { ...parsedParams, status: filter }, sorting };
        break;
    }

    dispatch(PershingActions.fetchLoadingDockCalendars(action));
  };

  const actions = {
    onOpenEditCalendarModal: handleEditCalendarModal,
    onToggleUploadModal: handleToggleUploadCalendarModal,
  };

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    } else {
      const defaultParams = { page: 0, pagesize: 25 };

      const sorting: Array<CalendarSorting> = [
        { field: 'expirationDate', direction: 'asc' },
        { field: 'cusip', direction: 'asc' },
      ];

      const availableParams = { query: { ...defaultParams, status: LoadingDockStatusEnum.available }, sorting };
      const archivedParams = { query: { ...defaultParams, status: LoadingDockStatusEnum.archived }, sorting };
      const pendingParams = { query: { ...defaultParams }, sorting };

      const params = { available: availableParams, archived: archivedParams, pending: pendingParams };

      dispatch(PershingActions.fetchLoadingDockCalendars(params));
    }
  }, [loaded]);

  useEffect(() => {
    if (calendarPageId) {
      dispatch(CalendarActions.fetchCalendarPageIssuers({ calendarPageId, params: { parent: true } }));
    }
  }, [calendarPageId]);

  return (
    <PershingAdminProvider
      actions={actions}
      defaultStatus="active"
      onPagination={handlePagination}
      onSorting={handleSorting}
      contentLoadedOptions={{
        failureStatus: PershingStatusEnum.failureFetchLoadingDockCalendars,
        loadingStatus: PershingStatusEnum.requestFetchLoadingDockCalendars,
        successStatus: PershingStatusEnum.successFetchLoadingDockCalendars,
      }}
      pageLoadedOptions={{
        failureStatus: PershingStatusEnum.failureFetchLoadingDockCalendars,
        successStatus: PershingStatusEnum.successFetchLoadingDockCalendars,
      }}
    >
      <FormProvider {...formMethods}>
        <SubNavLayout title="Loading Dock" hideTabs>
          <Container sx={containerSx} maxWidth="xl">
            <Stack direction="column" spacing={2}>
              <LoadingDockPageHeader />
              <LoadingDockTableList />
            </Stack>
          </Container>
        </SubNavLayout>
        <UploadCalendarModal open={openUploadModal} onClose={handleToggleUploadCalendarModal} />
        <EditCalendarModal open={openEditCalendarModal} onClose={handleCloseEditCalendarModal} />
      </FormProvider>
    </PershingAdminProvider>
  );
};

export const LoadingDockPageMeta: PageMeta = {
  pageName: 'LoadingDock',
  route: '/admin/calendars/upload',
  routeExpression: '/admin/calendar/upload',
};

LoadingDockPage.pageName = LoadingDockPageMeta.pageName;
LoadingDockPage.route = LoadingDockPageMeta.route;

export default LoadingDockPage;
