import { OrderBookCalendarDataModel, OrderBookPershingQueryFields } from '@halo-common/models';
import { getCalendars, getCalendarAllocationsQuery } from '@halo-data-sources/clients';
import { ApiComparisonOptionEnum } from '@halo-data-sources/enums';
import {
  ApiAllocationMapper,
  ApiCalendarsMapper,
  OrderBookMapper,
  PaginationMapper,
  PershingOrderBookMapper,
} from '@halo-data-sources/mappers';
import { OrderBookClientQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query';

const getOrderBookPershingFn = async (query: OrderBookPershingQueryFields) => {
  const sort = OrderBookMapper.toApiOrderBookSortList(query);
  const comparisons = PershingOrderBookMapper.toApiFiltersComparisonList(query);

  const allocationResponse = await getCalendarAllocationsQuery({
    comparisons,
    sort,
    page: query.page,
    page_length: query.pageSize,
  });

  const calendarIds = allocationResponse.results.map((model) => model.calendar_id);

  if (!calendarIds.length) {
    return {
      details: [],
      pagination: PaginationMapper.toPaginationModel({ ...allocationResponse.pagination, items_total_count: 0 }),
    };
  }

  const calendarResponse = await getCalendars({
    comparisons: [{ field: 'calendar_notes.id', value: calendarIds, op: ApiComparisonOptionEnum.IN }],
    page_length: calendarIds.length,
  });

  return {
    details: allocationResponse.results.map((model) => {
      const calendar = calendarResponse.calendars.find(({ id }) => id === model.calendar_id);

      return {
        id: model.id,
        calendar: calendar ? ApiCalendarsMapper.toCalendarModel(calendar) : undefined,
        allocation: ApiAllocationMapper.toAllocationModel(model),
      };
    }),
    pagination: PaginationMapper.toPaginationModel(allocationResponse.pagination),
  };
};

export const useOrderBookPershingQuery = (
  searchParams: OrderBookPershingQueryFields,
): UseQueryResult<OrderBookCalendarDataModel, Error> =>
  useQuery<OrderBookCalendarDataModel, Error>({
    queryKey: OrderBookClientQueryKeyFactory.pershing(searchParams),
    queryFn: () => getOrderBookPershingFn(searchParams),
    placeholderData: keepPreviousData,
  });
