import { ReactElement } from 'react';

import { calendarSelectedAtom } from '@halo-atoms/calendars';
import { SubmittedAllocationsTable } from '@halo-common/components';
import { MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { AllocationModel } from '@halo-common/models';
import { useCalendarAllocationsQuery } from '@halo-data-sources/queries';
import { HaloDataGridProps } from '@halodomination/halo-fe-common';
import { capitalize } from '@mui/material';
import { useAtomValue } from 'jotai';
import { DateTime } from 'luxon';

export type OrderTicketAllocationsTableProps = {
  onCancel: (orderId: number, accountId?: number) => void;
  loading: boolean;
};

export const OrderTicketAllocationsTable = ({ onCancel, loading }: OrderTicketAllocationsTableProps): ReactElement => {
  const configureDateTime = useWhiteLabelDateConfiguration();

  const selectedCalendar = useAtomValue(calendarSelectedAtom);

  const { data: allocations = [] } = useCalendarAllocationsQuery(selectedCalendar?.id);

  const commonCellProps = {
    editable: false,
    resizable: false,
    disableReorder: true,
  };

  const columns: HaloDataGridProps['columns'] = [
    {
      ...commonCellProps,
      field: 'account',
      headerName: 'ACCOUNT',
      flex: 0.9,
      valueGetter: (_, row: AllocationModel) => row?.account?.accountId,
    },
    {
      ...commonCellProps,
      field: 'amount',
      headerName: 'QUANTITY',
      flex: 0.9,
      valueGetter: (_, row: AllocationModel) => {
        return new Intl.NumberFormat().format(row.amount / 1000);
      },
    },
    {
      ...commonCellProps,
      field: 'updatedOn',
      headerName: 'DATE SUBMITTED',
      flex: 1.25,
      valueFormatter: (value: string) => {
        const date = DateTime.fromJSDate(new Date(value ?? ''), { zone: 'utc' }).toISO();
        return configureDateTime(date)?.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);
      },
    },
    {
      ...commonCellProps,
      field: 'status',
      headerName: 'STATUS',
      flex: 0.9,
      valueFormatter: (value: string) => {
        const status = value ?? '';
        return capitalize(status);
      },
    },
  ];

  return (
    <SubmittedAllocationsTable allocations={allocations} columns={columns} loading={loading} onCancel={onCancel} />
  );
};
