import { ReactElement } from 'react';

import { LinedCollapsibleHeader } from '@halodomination/halo-fe-common';
import { Box, Stack } from '@mui/material';

import {
  AccountTypeDropdown,
  CapacityTypeDropdown,
  DiscretionDropdown,
  SolicitedDropdown,
} from '../SubmissionSectionFields';
import { TicketDetails } from './TicketDetails';

const fieldContainerSx = {
  flex: 1,
};

export type TicketSectionProps = {
  showParOfferingPrice?: boolean;
  showAccountTypeDropdown?: boolean;
  showSolicitedDropdown?: boolean;
  showDiscretionDropdown?: boolean;
  showCapacityTypeDropdown?: boolean;
};

export const TicketSection = ({
  showParOfferingPrice,
  showAccountTypeDropdown,
  showSolicitedDropdown,
  showDiscretionDropdown,
  showCapacityTypeDropdown,
}: TicketSectionProps): ReactElement => {
  const accountTypeDropdown = showAccountTypeDropdown ? (
    <Box sx={fieldContainerSx}>
      <AccountTypeDropdown />
    </Box>
  ) : null;

  const solicitedDropdown = showSolicitedDropdown ? (
    <Box sx={fieldContainerSx}>
      <SolicitedDropdown name="solicited" />
    </Box>
  ) : null;

  const discretionDropdown = showDiscretionDropdown ? (
    <Box sx={fieldContainerSx}>
      <DiscretionDropdown name="discretion" />
    </Box>
  ) : null;

  const capacityField = showCapacityTypeDropdown ? (
    <Box sx={fieldContainerSx}>
      <CapacityTypeDropdown />
    </Box>
  ) : null;

  return (
    <Stack direction="column" spacing={3}>
      <LinedCollapsibleHeader title="Ticket" alwaysExpanded />
      <TicketDetails showParOfferingPrice={showParOfferingPrice} />
      <Stack direction="row" spacing={2} flexWrap="wrap">
        {accountTypeDropdown}
        {solicitedDropdown}
        {discretionDropdown}
        {capacityField}
      </Stack>
    </Stack>
  );
};
